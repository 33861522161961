import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';
import { utils_api } from '../utils/utils_api';
import { api_mapquest } from '../api/api_mapquest.js';

function latlng_get(location, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'latlng_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.results[0].locations[0].latLng;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_mapquest.get_latlong(success, fail, location);
  };
}

function getGeometry(region) {
  return (dispatch) => {
    const success = (response) => {
      return response.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_mapquest.getGeometry(success, fail, region);
  };
}

export {
  latlng_get,
  getGeometry
}