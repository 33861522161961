export const REDUX_ACTION = {
  LOGIN_POST: 'login_post',
  LOGIN_GET: 'login_get',
  LOGIN_FROM_LOCAL_STORAGE: 'login_from_local_storage',
  LOGOUT_GET: 'logout_get',

  LANGUAGE_GET: 'language_get',
  LANGUAGE_SET: 'language_set',

  CATEGORIZATION_GET: 'categorization_get',

  SPINNER: 'spinner',
  SPINNER_COMPLETE: 'spinner_complete',

  SURVEYS_GET: 'surveys_get',
  SURVEY_TAKE: 'survey_take',
  SURVEY_CURRENT: 'survey_current',
  HIGHLIGHTED_SURVEY_GET: 'highlighted_survey_get',

  ACTIONCARDS_FIRSTCALL_GET: 'actioncards_firstcall_get',
  ACTIONCARDS_GET: 'actioncards_get',

  USER_ATTRIBUTE: 'user_attribute',

  POLL_GET: 'poll_get',

  OTP_GET: 'otp_get',
  FEATURE: 'feature_flag',

  TERMS_GET: 'terms_get',
  PRIVACY_GET: 'privacy_get',

  GLOBAL_ERROR: 'global_error',
  ANCESTRY_STATUS: 'ancestry_status',

  REGISTRATION_FLOW: 'registration_flow',
  REGISTRATION_TYPE: 'registration_type',

  REGISTRATION_COMPLETE: 'registration_complete',
  SELF_SETUP_ACCOUNT_CONFIG: 'self_setup_account_config',

  CONSENT: 'consent',

  SELF_REG_CONTENT_GET: 'self_reg_content',

  WORKFLOW: 'workflow',
  WORKFLOW_LOADING: 'workflow_loading',
  SUBWORKFLOW: 'subworkflow',

  EMR_EXISTING_CONNECTIONS: 'emr_existing_connections',
  EMR_EXISTING_CONNECTIONS_LOADING: 'emr_existing_connections_loading',
  EMR_WORKFLOW: 'emr_workflow',
};
