import React, { useEffect, useRef, useState } from "react";
import { InnerWorkflow, InnerWorkflowStepProps } from "src/services/types/WorkflowTypes";
import InnerWorkflowProgress from "./InnerWorkflowProgress";


// These are the props directly used when returning the component: <InnerWorkflowComponent prop1={} prop2={} />
type InnerWorkflowComponentComponentProps = {
  innerWorkflow: InnerWorkflow,
  onInnerWorkflowComplete: () => void,
  elementSelector: (props: InnerWorkflowStepProps) => React.ReactElement,
}

export type InnerWorkflowComponentProps = InnerWorkflowComponentComponentProps;

function InnerWorkflowComponent({ innerWorkflow, onInnerWorkflowComplete, elementSelector: ElementSelector }: InnerWorkflowComponentProps) {

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    topRef.current?.parentElement?.scrollIntoView(true);
  }, [currentStepIndex])

  const handleStepComplete = () => {
    const nextStep = currentStepIndex + 1;

    if (nextStep >= innerWorkflow.length) {
      return onInnerWorkflowComplete();
    }

    setCurrentStepIndex(nextStep);
  };

  const handleStepBack = () => {
    const previousStep = currentStepIndex - 1;

    if (previousStep >= 0) {
      setCurrentStepIndex(previousStep);
    }
  };

  const currentStep = innerWorkflow[currentStepIndex];

  return (
    <div ref={topRef}>
      <InnerWorkflowProgress currentStep={currentStep} innerWorkflow={innerWorkflow} />

      <ElementSelector
        step={currentStep}
        onStepComplete={handleStepComplete}
        onStepBack={handleStepBack}
      />
    </div>
  )
}

const defaultProps: InnerWorkflowComponentComponentProps = {
  innerWorkflow: [],
  elementSelector: () => <>Unknown Inner Workflow Element</>,
  onInnerWorkflowComplete: () => { },
}

InnerWorkflowComponent.defaultProps = defaultProps;

export default InnerWorkflowComponent;