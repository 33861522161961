import React from 'react';
import { connect } from 'react-redux';
import { CONFIG } from '../../../constants/config';
import VideoPlayer from '../../global/videoPlayer';

export const ConsentSubcompVideo = (props) => {
  const videoFile = props.video.file[props.lang] || props.video.file.en;
  return (
    <div className="consent-component consent-component-video mb-4">
      {props.video.title ? <h2 className="label mb-1">{props.video.title[props.lang] || props.video.title.en}</h2> : null}
      <VideoPlayer videoUrl={videoFile} forceFullscreen reqWatchSec={CONFIG.CONSENT_VIDEO_REQ_LENGTH} onVidComplete={props.onVidComplete} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.app.language,
});

export default connect(mapStateToProps)(ConsentSubcompVideo);
