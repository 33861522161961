export const UNEXPECTED_ERROR = 'Unexpected error.';

export const RESPONSE_CODE = {
  '200_success': 200,
  '204_success_nocontent': 204,
  '401_unauthorized': 401,
  '404_notfound': 404,
  '405_data_invalid': 405,
  '409_data_conflict': 409,
  '499_fatal_error': 499,

  '410_user_not_found': 410,
  '411_wrong_password': 411,
  '412_token_expired': 412,
  '413_already_active': 413,
  '414_user_lockout': 414,
  '415_user_suspended': 415,
  '416_user_deleted': 416,
  '417_user_pending': 417,
  '418_email_not_confirmed': 418,
  '419_email_already_confirmed': 419,

  '440_token_invalid': 440,
  '441_token_still_active': 441,

  '490_max_reached': 490,
};

// LOGIN
export const LOGIN_ERROR_DISPLAY = {
  USER_LOCKOUT: 'This account has been locked or disabled. Please contact support if there is an error.',
  USER_NOT_FOUND: 'The email and/or password is incorrect. Please contact support if there is an error.',
  SESSION_INVALID: 'Invalid session.',
  SESSION_EXPIRED: 'Session expired.',
  USER_SUSPENDED: 'This account has been requested for deletion. Please contact support if there is an error.',
  USER_DELETED: 'This account has been deleted. Please contact support if there is an error.',
};

// SIGNUP
export const SIGNUP_ERROR_DISPLAY = {
  TOKEN_INVALID: 'Invalid token.',
  TOKEN_EXPIRED: 'Sorry your link is expired.',
  MAX_REACHED: 'Max request per day reached.',
};

// SET PASSWORD
export const SET_PASSWORD_ERROR_DISPLAY = {
  PASSWORD_INVALID: 'Invalid password format.',
  PASSWORD_EMPTY: 'Password cannot be left empty.',
};

export const SET_PASSWORD_ERROR_RESPONSES = {
  PASSWORD_INVALID: 'error_invalid_password_format',
  PASSWORD_EMPTY: 'error-empty',
};

export const UPDATE_SELF_ERROR_DISPLAY = {
  EMAIL_EMPTY: 'Email cannot be left empty.',
  EMAIL_INVALID: 'Invalid email format.',
  PHONE_INVALID: 'Invalid phone number format.',
  EMAIL_ALREADY_REGISTERED: 'This email is already in use',
};

export const UPDATE_SELF_ERROR_RESPONSES = {
  EMAIL_INVALID: 'error-invalid-email',
  PHONE_INVALID: 'error-invalid-phone',
  EMPTY: 'error-empty',
  EMAIL_ALREADY_REGISTERED: 'email already in use',
};

// PASSWORD FORMAT
export const PASSWORD_FORMAT_ERRORS = {
  CASE: 'case',
  NUMBER: 'number',
  SPECIAL_CHARACTER: 'special_character',
  LENGTH: 'length',
};

export const RESET_PWD_ERROR_DISPLAY = {
  USER_NOT_FOUND: 'User not found. Please contact your Administrator.',
  TOKEN_INVALID: 'Invalid token.',
  INVALID_PASSWORD: 'Your new password cannot be the same as your current password',
  TOKEN_EXPIRED: 'Sorry your link is expired.',
  CURRENT_PASSWORD_INCORRECT: 'Incorrect password.',
  MAX_REACHED: 'You have reached the maximum number of attempts to reset the password. Please try again later.',
  USER_LOCKOUT: 'This account has been locked or disabled. Please contact support if there is an error.',
};

export const OTP_ERROR_DISPLAY = {
  CODE_INCORRECT: 'The code you entered was incorrect. Please try again or re-send code.',
  INVALID_PHONE: 'The phone number is invalid',
  MAX_REACHED: 'You have reached the maximum attempt. Please try again later.',
};

export const SIGNUP_OTP_ERROR_DISPLAY = {
  GENERIC: "We've hit an error while verifying your email.",
  VERIFICATION_EXPIRED: 'Your verification code has expired.',
  MAX_REACHED: 'You have reached the maximum attempt. Please request another code.',
  MAX_REQUEST_REACHED: 'Max number of verification code request reached.',
  INVALID: 'The code you entered is incorrect. Please try again.',
  NO_ACTIVE_OTP: "Looks like you don't have a verification code on file.",
};

export const FIELD_ERROR_DISPLAY = {
  err_field_required: 'This field is required.',
  error_invalid_password_format: 'Invalid password format',
  err_field_email_invalid: 'Invalid email format.',
  err_field_name_invalid: 'Invalid name.',
  err_field_name_too_long: "Name is too long.",
  err_field_name_too_short: "Name is too short.",
  err_field_last_name_invalid: 'Invalid last name.',
  err_field_last_name_too_long: "Last name is too long.",
  err_field_last_name_too_short: "Last name is too short.",
  err_field_potential_xss: "HTML content is not supported by this field.",
  err_field_invalid_characters: "Invalid characters.",
  err_field_zip_invalid: 'error-invalid-zipcode',
  VERIFICATION_EXPIRED: 'Your verification code has expired.',
  MAX_REACHED: 'You have reached the maximum attempt. Please request another code.',
  MAX_REQUEST_REACHED: 'Max number of verification code request reached.',
  INVALID: 'The code you entered is incorrect. Please try again.',
  NO_ACTIVE_OTP: "Looks like you don't have a verification code on file.",
};

export const WORKFLOW_DISPLAY_ERRORS = {
  GENERIC: 'Some unexpected error ocured. Please contact support.',
  INCORRECT_WORKFLOW_CODE: `This workflow doesn't exist. Please contact support.`,
};

export const EMR_DISPLAY_ERRORS = {
  EMR_INVALID_INPUT: "EMR_INVALID_INPUT",
  EMR_INVALID_CHARACTER_INPUT: "EMR_INVALID_CHARACTER_INPUT"
}
