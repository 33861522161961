import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { workflow_get, update_workflow } from '../../actions/workflowAction';
import { update_subworkflows, subworkflow_get } from '../../actions/subworkflowAction';
import { WORKFLOW, WORKFLOW_VALUE } from '../../constants/workflow';
import { get_user_attributes_get, post_user_attributes_post } from '../../actions/userAction';
import { login_get } from '../../actions/loginAction';
import WorkflowLayout from './workflow_layout';
import PageLoader from '../global/page-loader';
import { utils_workflow } from '../../utils/utils_workflow';
import { utils } from '../../utils/utils_general';
import { get_survey_by_id_post } from '../../actions/surveyAction';
import { RESPONSE_CODE, UNEXPECTED_ERROR } from '../../constants/errors';
import { utils_registration_flow } from '../../utils/utils_registration_flow';
import PAGES from 'src/constants/pages';
import WorkflowContentRenderer from './workflow_content_renderer';

class DashboardWorkflow extends React.Component {
  constructor() {
    super();
    this.state = {
      body: null,
      id: null,
      step: null,
      errors: null,
      updated: false,
    };
  }

  componentDidMount() {
    if (this.props.workflow && !utils.is_obj_empty(this.props.workflow)) {
      this.setBody(this.props.workflow);
    } else {
      this.props.workflow_get();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.workflow && !utils.is_obj_empty(this.props.workflow) && !utils.check_objects_identical(this.props.workflow, prevProps.workflow)) {
      this.setBody(this.props.workflow);
      this.setState({ updated: false });
    } else if (this.state.step && this.state.step.value && !this.state.updated) {
      this.setBody(this.props.workflow);
      this.setState({ updated: true });
    }

    // WEIRD:
    // when we update_workflow using goNext(), the prevprop and prop change at the same time...
    // was using this for combined step but now we just call setbody
    // if (id && this.state.id !== id && id.length === 36 && this.props.workflow) {
    //     this.setBody(this.props.workflow);
    // }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  setBody(workflow) {
    if (!workflow) {
      return null;
    }
    this.props.get_user_attributes_get()
      .then((dbAttr) => {
        this.loadComponent(workflow, dbAttr);
      });
  }

  loadComponent(workflow, dbAttr) {
    const id = utils_workflow.getIdThroughUrl(this.props.location.pathname);
    const subworkflow_code = utils_workflow.getWorkflowCodeThroughUrl(this.props.location.pathname);
    const subworkflowStep = subworkflow_code ? workflow.find((c) => c.subworkflow_code === subworkflow_code && c.component === WORKFLOW.SUBWORKFLOW) : null;
    const stepBasedOnId = id ? workflow.find((c) => c.id === id) : null;
    // handles direct link to subworkflow to workflow portal - must have workflow_code in url
    if (subworkflowStep || stepBasedOnId) {
      const step = subworkflowStep || stepBasedOnId;
      const exit_attr_incompleted = utils_registration_flow.checkAttrCompleted(step.exit_attribute, dbAttr);
      if (exit_attr_incompleted.length === 0 && step.exit_attribute[0] !== '*') {
        // go to next incomplete with entry met
        this.completeWorkflow(dbAttr, workflow);
      } else {
        this.startDirectStep(step);
      }
    } else {
      const step = workflow.find((c) => !c.value && utils_registration_flow.checkAttrCompleted(c.entry_attribute, dbAttr).length === 0);
      if (!step) {
        this.completeWorkflow(dbAttr, workflow);
      } else {
        // check entry attributes - if entry not met or exit complete
        if (this.checkAttributesShouldSkipComponent(step, dbAttr)) {
          // go to next incomplete with entry met
          this.completeWorkflow(dbAttr, workflow);
        } else {
          this.setState({ body: step ? step.body : null, id, step });
        }
        if (step.component === WORKFLOW.SURVEY) {
          this.getSurveyDetails(step.survey_id);
        }
      }
    }
  }

  checkAttributesShouldSkipComponent(step, attr) {
    const entry_attr_errors = utils_registration_flow.checkAttrCompleted(step.entry_attribute, attr);
    const exit_attr_incompleted = utils_registration_flow.checkAttrCompleted(step.exit_attribute, attr);
    return entry_attr_errors.length > 0 || (exit_attr_incompleted.length === 0 && step.exit_attribute[0] !== '*');
  }

  startDirectStep(step) {
    const entryAttrFlags = step.entry_attribute;
    const attr = utils_workflow.getWorkflowStartAttr(entryAttrFlags);
    if (step.value === WORKFLOW_VALUE.DONE_FOR_NOW) {
      const updatedWorkflow = this.props.workflow.map((c) => {
        if (c.id === step.id) {
          c.value = null;
        }
        return c;
      });
      this.props.update_workflow(updatedWorkflow);
    }
    if (attr) {
      this.props.post_user_attributes_post(attr, true)
        .then(() => {
          this.setState({ step, body: step ? step.body : null, id: step.id });
        });
    } else {
      this.setState({ step, body: step ? step.body : null, id: step.id });
    }

    if (step.component === WORKFLOW.SURVEY) {
      this.getSurveyDetails(step.survey_id);
    }
  }

  getSurveyDetails(surveyId) {
    if (!surveyId) {
      return this.setState({ errors: 'Survey not found' });
    }
    this.props.get_survey_by_id_post(surveyId)
      .then((response) => {
        this.setState({ surveyUrl: response });
      })
      .catch((error) => {
        if (error.response && error.response.status === RESPONSE_CODE['404_notfound']) {
          this.setState({ errors: 'Survey Unavailable' });
        } else {
          this.setState({ errors: UNEXPECTED_ERROR });
        }
      });
  }

  onComponentComplete() {
    // ugh some components handle their own submissions others dont
    // text_media needs to submit attributes here for components completion:
    const attr = this.state.step.exit_attribute[0].split('|')[0];
    this.props.post_user_attributes_post(attr, true)
      .then(() => {
        this.onFormComplete();
      });
  }

  onFormComplete() {
    // check attribute is complete
    this.props.get_user_attributes_get()
      .then((res) => {
        const attr_errors = utils_registration_flow.checkAttrCompleted(this.state.step.exit_attribute, res);
        if (attr_errors.length > 0) {
          console.log('attrerrors', attr_errors);
        } else {
          this.removeStartAttrAndRedirect(this.state.step);
        }
      });
  }

  async onWorkflowExit() {
    const {
      match,
      subworkflows,
      history,
      post_user_attributes_post,
      get_user_attributes_get,
    } = this.props;
    const { workflow_code = null } = match.params;

    if (!workflow_code) return history.push(PAGES.DASHBOARD);

    // remove workflow start attribute and first component
    // entry attribute
    const subWorkflow = subworkflows[workflow_code];
    const startAttr = utils_workflow.getWorkflowStartAttr(subWorkflow);

    const updateAttributes = startAttr ? [ startAttr ] : [];
    await Promise.all(updateAttributes.map((attr) => post_user_attributes_post(attr, 'false')));

    return get_user_attributes_get();
  }

  async onWorkflowSkip() {
    const {
      match,
      subworkflows,
      post_user_attributes_post,
      get_user_attributes_get,
    } = this.props;
    const { workflow_code = null } = match.params;
    const subWorkflow = subworkflows[workflow_code];
    // post last components exit attributes
    const endAttributes = utils_workflow.getWorkflowEndAttributes(subWorkflow);
    await Promise.all(Object.entries(endAttributes).map(([attr, value]) => post_user_attributes_post(attr, value)));
    return get_user_attributes_get();
  }

  removeStartAttrAndRedirect(step) {
    const startAttr = utils_workflow.getWorkflowStartAttr(step.entry_attribute);
    if (startAttr) {
      console.log('remove start', startAttr);
      this.props.post_user_attributes_post(startAttr, 'false')
        .then(() => {
          this.getAttributesAndComplete();
        });
    } else {
      this.getAttributesAndComplete();
    }
  }

  getAttributesAndComplete() {
    this.props.get_user_attributes_get()
      .then((res) => {
        const attr_errors = utils_registration_flow.checkAttrCompleted(this.state.step.exit_attribute, res);
        if (attr_errors.length > 0) {
          console.log('attrerrors', attr_errors);
        } else {
          this.checkStepComplete(res, this.state.step);
        }
      });
  }

  checkStepComplete(dbAttr, step) {
    if (!step) {
      this.props.login_get()
        .then((res) => {
          this.props.history.push(PAGES.DASHBOARD);
        });
    } else {
      const req_attr = step.exit_attribute.filter((c) => c[0] !== '*');
      const req_attr_flat = [].concat.apply([], req_attr);
      const attr_errors = utils_registration_flow.checkAttrCompleted(req_attr_flat, dbAttr);
      if (attr_errors.length > 0) {
        console.log('not complete, attr errors', attr_errors);
      } else {
        this.completeWorkflow(dbAttr, this.props.workflow);
      }
    }
  }

  completeWorkflow(dbAttr, workflow) {
    const updatedWorkflow = workflow.map((c) => {
      if (c.exit_attribute[0] !== '*') {
        const attr_errors = utils_registration_flow.checkAttrCompleted(c.exit_attribute, dbAttr);
        if (attr_errors.length === 0) {
          c.value = WORKFLOW_VALUE.COMPLETE;
        }
      }
      return c;
    });

    this.props.update_workflow(updatedWorkflow);
    this.props.history.push(PAGES.DASHBOARD);
  }

  renderContent() {
    const content = (
      <WorkflowContentRenderer
        body={this.state.body}
        step={this.state.step}
        surveyUrl={this.state.surveyUrl}
        onFormComplete={() => this.onFormComplete()}
        onComponentComplete={() => this.onComponentComplete()}
        onWorkflowExit={() => this.onWorkflowExit()}
        onWorkflowSkip={() => this.onWorkflowSkip()}
      />
    );
    const aside = this.state.step;
    return this.state.step.component === WORKFLOW.SUBWORKFLOW
      ? content
      : <WorkflowLayout aside={aside} mainSection={content} />;
  }

  render() {
    if (!this.state.step) {
      return <PageLoader loader={!this.state.step} loaderMessage={this.props.t('loading data')} />;
    }
    return (
      <>
        <PageLoader loader={!this.props.workflow} loaderMessage={this.props.t('loading data')} />
        {this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  subworkflows: state.workflow.subworkflows,
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
  ...ownProps,
  loginUser: state.loginUser,
});

export default withRouter(connect(mapStateToProps, {
  login_get,
  workflow_get,
  update_workflow,
  update_subworkflows,
  get_user_attributes_get,
  post_user_attributes_post,
  get_survey_by_id_post,
  subworkflow_get,
})(withTranslation(['workflow', 'texts'])(DashboardWorkflow)));
