import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ModalReferralBanner } from '../referral/modal-referral-banner';
import { CONFIG } from '../../constants/config';
import GetStarted from './get_started';
import ActionCards from './action_cards';
import SuggestedSurveys from './suggested_surveys';
import QuickPolls from './quick_polls';
import PageLoader from '../global/page-loader';
import DashboardSurvey from './dashboard_survey';

import Prefetch from './prefetch';
import { FEATURE } from '../../constants/features';
import { workflow_get } from '../../actions/workflowAction';
import { utils_workflow } from '../../utils/utils_workflow';

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      loader: false,
      isSurveyClosed: false,
    };
  }

  componentDidMount() {
    const { workflow, userAttribute, history} = this.props;
    if (!workflow) {
      this.getDashboardWorkflow();
    } else {
      utils_workflow.checkDashboardWorkflow(workflow, userAttribute, history.push);
    }
  }

  getDashboardWorkflow() {
    const {workflow_get, userAttribute, history} = this.props;
    workflow_get()
      .then((resp) => {
        utils_workflow.checkDashboardWorkflow(resp, userAttribute, history.push);
      });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.surveys || !prevProps.actionCards) && this.props.surveys && this.props.actionCards) {
      setTimeout(() => {
        this.setState({ loader: false });
      }, 1000);
    } else if (this.state.loader) {
      // in case theres an error
      setTimeout(() => {
        this.setState({ loader: false });
      }, 8000);
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.surveys || !this.props.actionCards) {
      this.setState({ loader: true });
    }
  }

  handleCloseSurvey = () => {
    this.setState({ isSurveyClosed: true });
  }

  render() {
    // this is so annoying, i feel like the bootstrap grid's spacing dont match the layout too well
    // so i made "left-section, center-section, right-section" to adujst the padding

    const { highlightedSurvey } = this.props;
    const surveyId = highlightedSurvey && highlightedSurvey[0] ? highlightedSurvey[0].survey_id : null;
    const { isSurveyClosed } = this.state;

    return (
      <>
        <section className="dashboard container">
          <div className="row">
            <aside className="col-12 col-xl-2 left-section">
              <GetStarted featureFlag={this.props.featureFlag} />
            </aside>
            <div className="col-12 col-lg-9 col-xl-8 center-section">
              {CONFIG.REFERRAL && CONFIG.REFERRAL_DASHBOARD_BANNER ? (
                <ModalReferralBanner />
              ) : null}
              <ActionCards />
              {surveyId && !isSurveyClosed ? (
                <div className="w-100">
                  <DashboardSurvey
                    surveyId={surveyId}
                    survey={highlightedSurvey[0]}
                    onRequestClose={this.handleCloseSurvey}
                  />
                </div>
              ) : null}
              {this.props.featureFlag && this.props.featureFlag[FEATURE.QUICKPOLL] ? <QuickPolls /> : null}
            </div>
            <aside className="col-12 col-lg-3 col-xl-2 right-section">
              {this.props.featureFlag && this.props.featureFlag[FEATURE.SURVEY] ? <SuggestedSurveys /> : null}
            </aside>
          </div>
        </section>

        <PageLoader loader={this.state.loader} loaderMessage={this.props.t('loading data')} />

        <Prefetch />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
});

export default withRouter(connect(mapStateToProps, { workflow_get })(withTranslation()(Dashboard)));
