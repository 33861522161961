import types from './types';
import ERROR_IDS from './constants';

const DEFAULT_STATE = {
  workflows: {
    data: {},
    isLoading: false,
    isCompleted: false,
    errors: [],
  },
  currentStep: {
    data: {},
    isLoading: false,
    errors: [],
  },
};

function getDataFromApiResponse(response) {
  const { data } = response;
  return data;
}

export default function workflowReducer(state, action) {
  if (!state) {
    return DEFAULT_STATE;
  }

  const { type } = action;

  const currentState = state || { ...DEFAULT_STATE };

  switch (type) {
    case types.SET_NEXT_COMPONENT: {
      const { data } = action;
      if (data) {
        const { next_component: nextStep } = getDataFromApiResponse(data);
        if (!nextStep) {
          currentState.currentStep.data = null;
        } else {
          currentState.currentStep.data = nextStep;
        }
      } else {
        currentState.currentStep.errors.push(ERROR_IDS.NO_COMPONENT);
      }
      currentState.currentStep.isLoading = false;
      return {
        ...state,
        ...currentState,
      };
    }
    case types.SET_STEP_ERROR: {
      const { data: error } = action;
      if(currentState.currentStep){
        currentState.currentStep.errors = [error];
        currentState.currentStep.isLoading = false;
      }
      return {
        ...state,
        ...currentState,
      };
    }
    case types.SET_WORKFLOW: {
      const { data, workflowCode } = action;
      if (workflowCode && data) {
        currentState.workflows.data[workflowCode] = getDataFromApiResponse(data);
      } else {
        currentState.workflows.errors.push(ERROR_IDS.NO_WORKFLOW);
      }
      currentState.workflows.isLoading = false;
      return {
        ...state,
        ...currentState,
      };
    }
    case types.CLEAR_WORKFLOW: {
      const { workflowCode } = action;
      if (workflowCode) {
        delete currentState.workflows[workflowCode];
      }
      currentState.workflows.isLoading = false;
      return {
        ...state,
        ...currentState,
      };
    }
    case types.SET_NEXT_COMPONENT_LOADING: {
      currentState.currentStep.isLoading = true;
      return {
        ...state,
        ...currentState,
      };
    }
    case types.SET_WORKFLOW_LOADING: {
      currentState.workflows.isLoading = true;
      return {
        ...state,
        ...currentState,
      };
    }
    case types.SET_WORKFLOW_COMPLETED: {
      currentState.workflows.isCompleted = true;
      return {
        ...state,
        ...currentState,
      };
    }
    default: {
      return state;
    }
  }
}
