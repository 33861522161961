import { API_URL } from '../constants/api';
import { utils_api } from '../utils/utils_api';

export const api_questionnaire = {
  get_questionnaire(success, fail, code) {
    return utils_api.unauth_get(`${API_URL.QUESTIONNAIRE_GET}?code=${code}`, success, fail);
  },
  post_questionnaire_resp(success, fail, code, data) {
    return utils_api.auth_post(`${API_URL.QUESTIONNAIRE_GET}/${code}/resp`, data, success, fail);
  },
};
