import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Swipeable } from 'react-swipeable';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { poll_get, poll_post } from '../../actions/pollsAction';
import { utils_user } from '../../utils/utils_user';
import { utils } from '../../utils/utils_general';
import { utils_card_animation } from '../../utils/utils_card_animation';
import QuickPollsSurvey from './quick_polls_survey';
import { FEATURE } from '../../constants/features';
import utilsGA, { eventEnum } from 'src/utils/utils_ga';
import HtmlContentComponent from 'src/components/core/HtmlContent/HtmlContentComponent';

const AnswerTitle = withTranslation()(
  ({title, t}) => <div className="answer-title">{t(title)}</div>
);

class QuickPolls extends React.Component {
  constructor() {
    super();
    this.state = {
      questionNumber: 0,
      showSurvey: null,
      selectedAnswer: null,
      prevAnswers: {},
      polls: null,
      showConsentModal: false,
      random: 0,
      pollsTaken: 0,
      quickpollerror: null,
    };
  }

  componentDidMount() {
    if (this.props.quickPolls) {
      this.setState({ polls: this.props.quickPolls });
    } else if (!utils.is_obj_empty(this.props.loginUser) && utils_user.get_user_jwt()) {
      this.getQuickPolls();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.state.polls && !prevProps.quickPolls && this.props.quickPolls) {
      this.setState({ polls: this.props.quickPolls });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  getRandomSurvey() {
    if (this.props.surveys) {
      const randomSurveyIndex = Math.floor(Math.random() * this.props.surveys.length);
      const randomVersion = Math.floor(Math.random() * 2);
      this.setState({ random: randomVersion });
      return this.props.surveys[randomSurveyIndex];
    }
    return {};
  }

  getQuickPolls() {
    const survey = this.getRandomSurvey();
    this.props.poll_get(true)
      .then((response) => {
        this.setState({ polls: response });
        return response;
      })
      .catch((error) => this.setState({ showSurvey: survey, polls: null }));
  }

  handleAnswerPoll(answer) {
    const { prevAnswers } = this.state;
    prevAnswers[this.state.questionNumber] = answer;
    this.setState({ selectedAnswer: answer, prevAnswers });
    this.submitQuickPolls(answer);
  }

  submitQuickPolls(answer) {
    this.props.poll_post(answer.poll_qn_id, answer.poll_ans_id, true)
      .then(() => utilsGA.ga4Event(eventEnum.quickPollSubmit, {
        qn_id: answer.poll_qn_id,
        ans_id: answer.poll_ans_id,
        answer: answer.title,
        question: this.getPollTitle()
      }))
      .catch((error) => this.setState({ errors: { api: error } }));
  }

  swipeForward() {
    if (utils.is_mobile()) {
      utils_card_animation.cardForwardAnimation('.quick-polls-card', this.goForwardCard.bind(this));
    } else {
      this.goForwardCard();
    }
  }

  swipeBack() {
    if (utils.is_mobile()) {
      utils_card_animation.cardBackwardAnimation('.quick-polls-card', this.goBackCard.bind(this));
    } else {
      this.goBackCard();
    }
  }

  resetScrollForMobile() {
    const el_quickpolls = document.querySelector('.quick-polls');
    if (el_quickpolls && utils.is_mobile()) {
      el_quickpolls.scrollIntoView();
    }
  }

  goForwardCard(taken = null) {
    if (!taken && !this.state.showSurvey) {
      utilsGA.ga4Event(eventEnum.quickPollSkip, {
        question_number: this.state.questionNumber,
        question: this.getPollTitle()
      });
    } else {
      utilsGA.ga4Event(eventEnum.quickPollNext, {
        question_number: this.state.questionNumber,
        question: this.getPollTitle()
      });
    }

    const survey = this.getRandomSurvey();
    // if no polls, just show survey
    if (this.state.polls) {
      const nextPage = this.state.questionNumber + 1;
      // last card
      if (nextPage === this.state.polls.length && !this.state.showSurvey) {
        this.setState({ showSurvey: survey, questionNumber: 0, prevAnswers: {} });
        this.getQuickPolls();
      } else {
        this.setState({ questionNumber: nextPage, showSurvey: null });
      }
      this.setState({ selectedAnswer: null });
    } else if (this.props.featureFlag && this.props.featureFlag[FEATURE.SURVEY]) {
      this.setState({ showSurvey: survey, selectedAnswer: null });
    } else {
      this.setState({ quickpollerror: 'There are no quick polls available' });
    }
    this.setState({ pollsTaken: taken ? this.state.pollsTaken + 1 : this.state.pollsTaken });
    this.resetScrollForMobile();
  }

  goBackCard() {
    utilsGA.ga4Event(eventEnum.quickPollPrevious, {
      question_number: this.state.questionNumber,
      question: this.getPollTitle()
    });
    if (this.state.polls) {
      const nextPage = this.state.questionNumber - 1;
      if (nextPage >= 0) {
        this.setState({ questionNumber: nextPage, showSurvey: null });
        if (!this.state.prevAnswers[nextPage]) {
          this.setState({ selectedAnswer: null });
        }
      }
    } else {
      const survey = this.getRandomSurvey();
      this.setState({ showSurvey: survey, selectedAnswer: null });
    }

    this.resetScrollForMobile();
  }

  renderAnswerButton(answer, i) {
    const { title } = answer;
    return (
      <Button onClick={() => this.handleAnswerPoll(answer)} className="quick-poll-button p-3 mb-2 rounded text-left" key={i} block>
        <AnswerTitle title={title} />
      </Button>
    );
  }

  renderAnswerResults(answer, i, totalCount, selectedAnswerId) {
    const { title } = answer;
    const isSelected = (answer.poll_ans_id === selectedAnswerId) || (this.state.prevAnswers[this.state.questionNumber] ? this.state.prevAnswers[this.state.questionNumber].poll_ans_id : null) === answer.poll_ans_id;
    const width = ((Number(answer.count) + (isSelected ? 1 : 0)) / (totalCount + 1)) * 100;
    return (
      <div className={`quick-poll-answer-graph p-3 mb-2 rounded text-left block font-callout font-weight-bold ${isSelected ? 'selected' : 'bg-primary '}`} key={i}>
        <AnswerTitle title={title} />
        <div className="results-graph" style={{ width: `${width}%` }}>
          <div className="results-animation" />
        </div>
        <div className="results-percentage">
          {Math.round(width)}
          %
        </div>
      </div>
    );
  }

  getQuestionNumber() {
    return this.state.questionNumber || this.state.questionNumber === 0 ? this.state.questionNumber : null;
  }

  getPoll(questionIndex) {
    return (!questionIndex && questionIndex !== 0) ? null : this.state.polls[questionIndex];
  }

  getPollTitle(){
    const questionIndex = this.getQuestionNumber();
    const poll = this.getPoll(questionIndex) || {};
    return poll.title;
  }

  renderQuestionPage() {
    const questionIndex = this.state.questionNumber || this.state.questionNumber === 0 ? this.state.questionNumber : null;
    if (!this.state.polls) {
      return null;
    }
    const poll = (!questionIndex && questionIndex !== 0) ? null : this.state.polls[questionIndex];

    if (!poll) {
      return null;
    }

    const showResults = this.state.selectedAnswer || this.state.selectedAnswer === 0 || this.state.prevAnswers[this.state.questionNumber];

    const totalCount = poll.answers.map((ans) => Number(ans.count)).reduce((a, b) => a + b);
    const selectedAnswerId = (this.state.selectedAnswer ? this.state.selectedAnswer.poll_ans_id : null) || (this.state.prevAnswers[this.state.questionNumber]);

    return (
      <div className="row quick-poll-wrapper">
        <div className="quick-poll-question pl-3 pl-md-4 mb-3 mb-md-0 col-12 col-md-6">
          {/* <p className="color-brand pb-3">{`Question ${questionIndex + 1}`}</p> */}
          <HtmlContentComponent tag={"p"} markup={poll.title} />
        </div>

        { !showResults
          ? (
            <div className="quick-poll-answer col-12 col-md-6">
              {poll.answers.map((a, i) => this.renderAnswerButton(a, i))}
            </div>
          )
          : (
            <div className="quick-poll-answer col-12 col-md-6">
              {poll.answers.map((a, i) => this.renderAnswerResults(a, i, totalCount, selectedAnswerId))}
            </div>
          )}
      </div>
    );
  }

  continueWithQuickPolls() {
    utilsGA.ga4Event(eventEnum.quickPollContinuePolls);
    return this.goForwardCard();
  }

  render() {
    if (this.state.quickpollerror) {
      return (
        <div className="quick-polls section-component">
          <h2 className="mb-3 h3">{this.props.t('Quick Polls')}</h2>
          <div className="quick-polls-card-wrapper text-center rounded p-4" style={{ background: 'white' }}>
            {this.props.t('There are no quick polls available')}
          </div>
        </div>
      );
    }
    const hasAnswer = this.state.selectedAnswer || Object.keys(this.state.prevAnswers).includes(String(this.state.questionNumber));
    const showBack = !this.state.showSurvey && this.state.questionNumber > 0;
    const showForward = Boolean(this.state.polls);
    const surveyVersion = this.state.showSurvey && !utils.is_obj_empty(this.state.showSurvey) && this.state.pollsTaken && this.state.pollsTaken > 1 ? (this.state.random === 1 ? 1 : 2) : 2;
    const surveyNotSupported = this.props.featureFlag && !this.props.featureFlag[FEATURE.SURVEY];
    return (
      <div className="quick-polls section-component">
        <h2 className="mb-3 h3">{this.props.t('Quick Polls')}</h2>
        <div className={`quick-polls-card-wrapper ${showBack ? 'has-back' : ''} ${showForward ? 'has-forward' : ''}`}>
          <div className={`quick-polls-card bg-secondary p-4 rounded clearfix ${this.state.showSurvey ? ' quick-polls-card-survey' : ''}`}>
            <Swipeable className={`quick-polls-survey-wrapper quick-polls-survey-wrapper-${surveyNotSupported ? 'no-survey' : surveyVersion}`} onSwipedLeft={() => this.swipeForward()} onSwipedRight={() => (showBack ? this.swipeBack() : null)}>
              {this.state.showSurvey ? <QuickPollsSurvey surveyNotSupported={surveyNotSupported} pollsTaken={this.state.pollsTaken} survey={this.state.showSurvey} history={this.props.history} random={this.state.random} /> : this.renderQuestionPage()}
            </Swipeable>

            {this.state.polls
              ? (
                <div className="quick-polls-skip mt-3 pt-3 row">

                  {!this.state.showSurvey
                    ? (
                      <React.Fragment>
                            <div className="col-4 text-left">
                    { showBack ? <Button variant="link" onClick={() => this.goBackCard()}>{this.props.t('Previous')}</Button> : null}
                  </div>
                            <div className="col-8 text-right">
                    <Button variant="link" onClick={() => this.goForwardCard(hasAnswer ? true : null)}>{this.props.t(hasAnswer ? 'Next' : 'Skip')}</Button>
                  </div>
                          </React.Fragment>
                    )
                    : (
                      <div className="col-12 text-center continue-with-quick-polls">
                            <Button variant="link" onClick={() => this.continueWithQuickPolls()}>{this.props.t('Continue with quick polls')}</Button>
                          </div>
                    )}

                </div>
              )
              : null }
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

export default withRouter(connect(mapStateToProps, { poll_get, poll_post })(withTranslation()(QuickPolls)));
