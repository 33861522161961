import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';
import { api_survey } from '../api/api_survey.js';
import { utils_api } from '../utils/utils_api.js';

export function get_survey_list_get(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'get_survey_list_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.SURVEYS_GET,
        data: response.data.data,
      });
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_survey.get_surveys_list(success, fail);
  };
}

export function get_survey_by_id_post(surveyId, customFail) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'get_survey_by_id_post');

    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.SURVEY_TAKE,
        data: response.data.data,
      });

      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error, customFail);
    return api_survey.get_survey_by_id(success, fail, surveyId);
  };
}

export function get_one_survey_details_get(surveyId) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'get_one_survey_details_get');

    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      const survey = response.data.data.length === 1 ? response.data.data[0] : {};
      dispatch({
        type: REDUX_ACTION.SURVEY_CURRENT,
        data: survey,
      });

      return survey;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_survey.get_one_survey_details(success, fail, surveyId);
  };
}

export function get_highlighted_survey(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'get_survey_list_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.HIGHLIGHTED_SURVEY_GET,
        data: response.data.data,
      });
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_survey.get_highlighted_survey(success, fail);
  };
}

