import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import IconGenerator from '../icons/icon_generator';
import { workflow_get, update_workflow } from '../../actions/workflowAction';
import PAGES from 'src/constants/pages';
import { CONFIG } from '../../constants/config';
import { get_user_attributes_get, post_workflow_complete_post } from '../../actions/userAction';
import { logout_get } from '../../actions/logoutAction';
import WorkflowLayout from './workflow_layout';
import PageLoader from '../global/page-loader';
import { RESPONSE_CODE } from '../../constants/errors';
import { LOCAL_STORAGE } from '../../constants/localStorage';
import { utils } from '../../utils/utils_general';
import { utils_workflow } from '../../utils/utils_workflow';
import { WORKFLOW_VALUE } from '../../constants/workflow';
import EnrollmentProgressCards from './enrollment_progress_cards';
import { EnrollmentReferralBanner } from '../referral/enrollment-referral-banner';
import utilsGA, { workflowNames } from 'src/utils/utils_ga';

class Enrollment extends React.Component {
  state = {
    isAutoContinuing: false,
  }

  componentDidMount() {
    if (!this.props.workflow) {
      this.props.workflow_get();
    }
    this.checkAutoContinue();
  }

  componentDidUpdate() {
    this.checkAutoContinue();
  }

  isEnrollmentComplete() {
    // old comment: maybe check attributes? or do it after the click...
    const { workflow } = this.props;
    return workflow && !workflow.find(c => !c.value);
  }

  shouldAutoContinue() {
    const { REFERRAL, REFERRAL_ENROLLMENT_BANNER } = CONFIG;
    const hasEnrollmentBanner = REFERRAL && REFERRAL_ENROLLMENT_BANNER;
    const hasBanner = CONFIG.ENROLLMENT_BANNER_ENABLED || hasEnrollmentBanner;
    return this.isEnrollmentComplete() && !hasBanner;
  }

  checkAutoContinue() {
    const { isAutoContinuing } = this.state;
    if (this.shouldAutoContinue() && !isAutoContinuing) {
      this.setState({ isAutoContinuing: true });
      this.completeEnrollment();
    }
  }

  completeEnrollment() {
    this.props.post_workflow_complete_post()
      .then((resp) => {
        // delete enrollment workflow
        this.props.update_workflow(null);
        utils.remove_local_storage(LOCAL_STORAGE.WORKFLOW);
        utilsGA.sendWorkflowEndToGA(workflowNames.enrollment);
        this.goToDashboard();
      });
  }

  goToDashboard() {
    this.props.workflow_get()
      .then((resp) => {
        // check if all done
        utils_workflow.checkDashboardWorkflow(resp, this.props.userAttribute, this.props.history.push);
      })
      .catch((error) => {
        if (error && error.response.status === RESPONSE_CODE['404_notfound']) {
          // no enforced workflow, cool. go to dash
          this.props.history.push(PAGES.DASHBOARD);
        }
      });
  }

  exitEnrollment() {
    this.props.history.push(PAGES.ENROLLMENT_EXIT);
  }

  renderHighlightedAction() {
    return (
      <div className="workflow-section">
        <h2 className="mb-3 h3">{this.props.t('Highlighted Action')}</h2>
        {this.isEnrollmentComplete() ? this.renderCompleteEnrollment() : this.renderNext()}
      </div>
    );
  }

  getNextAvailableStep() {
    if (!this.props.workflow) return null;
    return this.props.workflow.find((c) => c.value !== WORKFLOW_VALUE.COMPLETE);
  }

  goToStep(step) {
    if (!step.component) {
      return this.props.history.push(PAGES.ENROLLMENT);
    }
    return this.props.history.push(`${PAGES.ENROLLMENT}/${step.id}`);
  }

  renderNext() {
    const { t } = this.props;
    const nextStep = this.getNextAvailableStep() || {};
    const ctaTitle = nextStep.details?.cta_title || `Complete step "${nextStep.name}"`;
    const ctaText = nextStep.details?.cta_text || 'Some default text?';
    const ctaButton = nextStep.details?.cta_button || 'Start';
    const { id, uuid, code, name} = nextStep
    const baseIconNumber = '197100'; // this number was used previously
    const iconKey = uuid || id || code || name || baseIconNumber;
    return (
      <div className="workflow-action-block mb-5">
        <div className="row">
          <div className="col-12 col-md-3 workflow-action-image">
            <IconGenerator iconKey={iconKey} iconType="actioncard" />
          </div>
          <div className="col-12 col-md-9 workflow-action-content">
            <h2>{t(`workflow:${ctaTitle}`)}</h2>
            <p>{t(`workflow:${ctaText}`)}</p>
          </div>
        </div>
        <div className="text-center">
          <Button onClick={() => this.goToStep(nextStep)}>{t(`workflow:${ctaButton}`)}</Button>
        </div>
      </div>
    );
  }

  renderCompleteEnrollment() {
    const { t } = this.props;

    if (CONFIG.REFERRAL && CONFIG.REFERRAL_ENROLLMENT_BANNER) {
      return (
        <EnrollmentReferralBanner
          onRequestContinue={() => {
            this.completeEnrollment();
          }}
        />
      );
    }

    return (
      <div className="workflow-section workflow-banner dark p-4 rounded mb-4">
        <div className="workflow-banner-content mb-5">
          <div>
            <p className="mb-3">{this.props.t('Thanks!')}</p>
            <h2>
              {t(
                "Congratulations! You've successfully enrolled. Let's go do more together!"
              )}
            </h2>
          </div>
        </div>
        <div className="workflow-banner-button">
          <Button
            className="primary-invert mr-4"
            onClick={() => this.completeEnrollment()}
          >
            {t('Continue to Contribute')}
          </Button>
          {CONFIG.ENROLLMENT_BANNER_EXIT_ENABLED ? (
            <Button onClick={() => this.exitEnrollment()}>
              {t('No thanks')}
            </Button>
          ) : null}
        </div>
      </div>
    );
  }

  renderContent() {
    return (
      <>
        {this.renderHighlightedAction()}
        <EnrollmentProgressCards />
      </>
    );
  }

  render() {
    if (this.shouldAutoContinue()) {
      return null;
    }

    const aside = {
      name: this.props.t('Enrollment Overview'),
      details: {
        description: this.props.t('Enrollment Overview Description'),
      },
    };
    return (
      <>
        <PageLoader loader={!this.props.workflow} loaderMessage={this.props.t('loading data')} />
        <WorkflowLayout aside={aside} mainSection={this.renderContent()} />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
  ...ownProps,
});

export default withRouter(connect(mapStateToProps, {
  workflow_get, update_workflow, get_user_attributes_get, post_workflow_complete_post, logout_get,
})(withTranslation(['workflow', 'texts'])(Enrollment)));
