import React from "react";

type Predicate = (() => boolean) | boolean;

type RenderProps = {
  component?: any;
  props?: object;
  render?: () => any;
  children?: React.ReactNode;
};

type WhenProps = {
  predicate: Predicate;
} & RenderProps;

function evaluatePredicate(predicate: Predicate): boolean {
  return typeof predicate === "function" ? predicate() : predicate;
}

function doRender({ component, props, render, children }: RenderProps) {
  if (component) {
    const Component = component;
    return <Component {...(props ?? {})} />;
  }
  if (typeof render === "function") {
    return render();
  }
  return <>{children}</>;
}

function When(props: WhenProps) {
  return doRender(props);
}

type WhenElement = React.ReactElement<WhenProps, typeof When>;

type CaseProps = {
  allMatching?: boolean;
  children?: WhenElement | WhenElement[];
};

function Fallback(props: RenderProps) {
  return doRender(props);
}

type ComponentMatchesParams = {
  props: WhenProps, 
  type: Function
}

function componentMatches({ props, type }: ComponentMatchesParams): boolean {
  return props && evaluatePredicate(props.predicate) && !(type === Fallback);
}

function getFallback(children: WhenElement[]) {
  return children.find(({ type }) => type === Fallback);
}

function renderCaseChildren(childrenToRender: WhenElement | WhenElement[] | undefined) {
  const renderedChildren = childrenToRender ? <>{childrenToRender}</> : null;
  if (renderedChildren === null) {
    throw new Error("No element matched and no Fallback was provided");
  }
  return renderedChildren;
}

function Case({ allMatching, children }: CaseProps) {
  const notNullChildren = children ?? [];
  const actualChildren =
    notNullChildren instanceof Array ? notNullChildren : [notNullChildren];

  if (allMatching) {
    const matchingChildren = actualChildren.filter(componentMatches);
    const childrenToRender =
      matchingChildren.length > 0
        ? matchingChildren
        : getFallback(actualChildren);

    return renderCaseChildren(childrenToRender);
  }

  const child =
    actualChildren.find(componentMatches) ?? getFallback(actualChildren);

  return renderCaseChildren(child);
}

export { Case, When, Fallback }
