import React from 'react';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { utils } from '../../utils/utils_general';
import { ReactComponent as SurveyIcon } from '../../assets/icons/quick-poll-survey.svg';
import PAGES from 'src/constants/pages';
import IconGenerator from '../icons/icon_generator';
import SurveyConsentModal from '../survey/survey_consent_modal';
import { ReactComponent as QuickPollsBg } from '../../assets/images/quick-poll-bg.svg';
// import {ReactComponent as QuickPollSurveyV1} from '../../assets/images/quick-poll-survey-v1.svg';
import { ReactComponent as QuickPollSurveyV2 } from '../../assets/images/quick-poll-survey-v2.svg';
import { TRUNCATE } from '../../constants/truncateString';
import utilsGA, { eventEnum } from 'src/utils/utils_ga';

class QuickPollsSurvey extends React.Component {
  constructor() {
    super();
    this.state = {
      showConsentModal: false,
    };
  }

  goToSurvey(survey) {
    utilsGA.ga4Event(eventEnum.quickPollTakeSurvey);
    if (utils.is_mobile()) {
      this.props.history.push(`${PAGES.SURVEY_CONSENT}/${survey.survey_id}`, { survey });
    } else {
      this.setState({ showConsentModal: true });
    }
  }

  goToSurveyPage() {
    utilsGA.ga4Event(eventEnum.quickPollTakeSurvey);
    this.props.history.push(PAGES.SURVEYS);
  }

  renderSurveyButton() {
    if (this.props.survey && !utils.is_obj_empty(this.props.survey)) {
      const { survey } = this.props;
      const { title } = survey;
      return (
        <>
          <p className="h2 mb-3 quick-poll-take-survey">
            {' '}
            {title ? utils.truncate_string(title, TRUNCATE.QUICKPOLL_SURVEY_TITLE) : ''}
            {' '}
            {this.props.t('Survey!')}
          </p>
          <Button variant="primary" className="quick-poll-survey-button" onClick={() => this.goToSurvey(survey)}>
            {this.props.t(survey.completed ? 'Re-take the survey' : 'Take the survey')}
          </Button>
        </>
      );
    }
    return (
      <Button variant="primary" className="quick-poll-survey-button" onClick={() => this.props.history.push(PAGES.SURVEYS)}>
        {this.props.t('See all surveys')}
      </Button>
    );
  }

  renderSurveyIcon() {
    if (this.props.survey && !utils.is_obj_empty(this.props.survey)) {
      return <div className="survey-icon"><IconGenerator iconKey={this.props.survey.limesurvey_id} /></div>;
    }
    return <SurveyIcon />;
  }

  renderV1() {
    return (
      <div className="quick-poll-survey-wrapper quick-poll-survey-1">
        <div className="quick-poll-survey-content col-9 col-md-12 p-2 pt-md-5 pl-md-5 pr-md-5">
          <p className="h1 pt-3">
            {this.props.t(`You've just completed`)}
            <br />
            {' '}
            {this.props.pollsTaken}
            {' '}
            {this.props.t('Quick Polls!')}
            {' '}
          </p>
          <p className="h2 pt-3">{this.props.t(`Let's go take the`)}</p>
          {this.renderSurveyButton()}
        </div>
        <div className="quick-poll-survey-img-2"><QuickPollSurveyV2 /></div>
      </div>
    );
  }

  renderV2() {
    return (
      <div className="quick-poll-survey-wrapper quick-poll-survey-2">
        <div className="quick-poll-survey-content col-9 col-md-12 p-2 pt-5 p-md-5">
          <p className="h1 pt-3 pt-md-5">{this.props.t(`You're on a roll!`)}</p>
          <p className="h2 pt-3">{this.props.t(`Let's go take the`)}</p>
          {this.renderSurveyButton()}
        </div>
        <div className="quick-poll-survey-img-2"><QuickPollSurveyV2 /></div>
      </div>
    );
  }

  renderGeneric() {
    return (
      <div className="quick-poll-survey-wrapper quick-poll-survey-2">
        <div className="quick-poll-survey-content col-9 col-md-12 p-2 pt-5 p-md-5">
          <p className="h1 pt-3 pb-3">
            {this.props.t(`You're on a roll!`)}
            <br />
            {this.props.t('Why not take a survey?')}
          </p>
          <Button variant="primary" className="quick-poll-survey-button" onClick={() => this.goToSurveyPage()}>
            {this.props.t('See all surveys')}
          </Button>
        </div>
        <div className="quick-poll-survey-img-2"><QuickPollSurveyV2 /></div>
      </div>
    );
  }

  renderNoSurvey() {
    return (
      <div className="quick-poll-survey-wrapper">
        <div className="quick-poll-survey-content col-9 col-md-12 p-2 pt-5 p-md-5">
          <p className="h1 pt-3 pb-3">{this.props.t(`You're on a roll!`)}</p>
        </div>

        <div className="quick-poll-survey-img-2"><QuickPollSurveyV2 /></div>
      </div>
    );
  }

  randomlyRenderSurveyLayout() {
    if (this.props.surveyNotSupported) {
      return this.renderNoSurvey();
    }
    if (this.props.survey && !utils.is_obj_empty(this.props.survey) && this.props.pollsTaken && this.props.pollsTaken > 1) {
      switch (this.props.random) {
        case 1:
          return this.renderV1();
        case 0:
          return this.renderV2();
        default:
          return this.renderV1();
      }
    } else if (!this.props.pollsTaken || this.props.pollsTaken < 2) {
      return this.renderV2();
    } else {
      return this.renderGeneric();
    }
  }

  render() {
    return (
      <div className="quick-poll-survey row">
        <div className="quick-poll-survey-bg"><QuickPollsBg /></div>
        {this.randomlyRenderSurveyLayout()}
        <SurveyConsentModal show={this.state.showConsentModal} onHide={() => this.setState({ showConsentModal: false })} survey={this.props.survey} />
      </div>
    );
  }
}

export default withTranslation()(QuickPollsSurvey);
