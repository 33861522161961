import { LOCAL_STORAGE } from '../constants/localStorage';
import PAGES from 'src/constants/pages';
import { WORKFLOW, WORKFLOW_TYPE, WORKFLOW_VALUE } from '../constants/workflow';
import { utils } from './utils_general';
import { utils_registration_flow } from './utils_registration_flow';

export const utils_workflow = {
  goToNextWorkflowStep: (flow, currentStep, function_update_flow, function_changePage, workflow_type, workflow_code = null) => {
    if (!flow) {
      console.log('no workflow found');
      return null;
    } if (!currentStep) {
      console.log('no current step found');
      return null;
    }

    const new_flow = flow.map((c) => {
      // make sure they can reenter component
      if (c.id === currentStep.id && currentStep.exit_attribute[0] !== '*') {
        c.value = WORKFLOW_VALUE.COMPLETE;
      }
      return c;
    });

    // updating subworkflows is a dif function which requires its code
    const new_flow_data = workflow_code ? { data: new_flow, code: workflow_code } : new_flow;
    function_update_flow(new_flow_data);

    const nextStep = getNextComponent(new_flow, currentStep);
    if (workflow_type === WORKFLOW_TYPE.ENROLLMENT) {
      getEnrollmentPageChange(nextStep, function_changePage);
    } else {
      getDashboardPageChange(new_flow, nextStep, function_changePage, workflow_code);
    }
    return new_flow;
  },
  checkAttributesAndRedirect: (dbAttr, currentStep, function_changePage) => {
    const attr_errors = utils_registration_flow.checkAttrCompleted(currentStep.entry_attribute, dbAttr);
    if (attr_errors.length > 0) {
      // entry attribute not met, back to enrollment landing
      // FOR CASE ENROLLMENT ONLY, need to handle for dashboard
      function_changePage(PAGES.ENROLLMENT);
    }

    if (currentStep.exit_attribute[0] !== '*') {
      const attr_errors = utils_registration_flow.checkAttrCompleted(currentStep.exit_attribute, dbAttr);
      if (attr_errors.length === 0) {
        // exit attribute already done
        function_changePage(PAGES.ENROLLMENT);
      }
    }
  },
  getWorkflowFromStorage: (function_updateworkflow) => {
    const workflow = utils.get_local_storage(LOCAL_STORAGE.WORKFLOW);
    if (workflow) {
      function_updateworkflow(workflow);
      return workflow;
    }
  },
  checkDashboardWorkflow(workflow, attr, function_changePage, authPage = null) {
    const incompleteEnforcedWorkflow = utils_workflow.incompleteEnforcedWorkflow(workflow, attr);
    if (incompleteEnforcedWorkflow) {
      const firstIncompleteStep = incompleteEnforcedWorkflow[0];
      let workflow_code = `workflow_dashboard/${firstIncompleteStep.id}`;
      if (firstIncompleteStep.component === WORKFLOW.SUBWORKFLOW) {
        workflow_code = firstIncompleteStep.subworkflow_code;
      }
      function_changePage(`${PAGES.DASHBOARD_WORKFLOW}/${workflow_code}`);
    } else {
      function_changePage(authPage || PAGES.DASHBOARD);
    }
  },
  incompleteEnforcedWorkflow(workflow, attr) {
    // if entry attribute met or no entry attribute, then go directly to that workflow step
    if (!workflow || workflow.length <= 0) {
      return null;
    }
    const enforcedWorkflow = workflow.filter((c) => c.entry_attribute[0] === '*' || utils_workflow.entryAttrFulfilled(c.entry_attribute, attr));
    if (!enforcedWorkflow) {
      return null;
    }
    const enforcedNotDone = enforcedWorkflow.filter((c) => !c.value);
    return enforcedNotDone.length > 0 ? enforcedNotDone : null;
  },
  entryAttrFulfilled(entryAttrs, attr) {
    const fulfilledAttrs = entryAttrs.filter((a) => {
      const key = a.split('|')[0];
      const value = a.split('|')[1];
      return attr ? String(attr[key]) === String(value) : false;
    });
    return fulfilledAttrs.length >= entryAttrs.length;
  },
  getIdThroughUrl(pathname) {
    let id = pathname.split('/').pop();
    if (!id || id === 'undefined') {
      id = null;
    }
    return id;
  },
  getWorkflowCodeThroughUrl(pathname) {
    return pathname.split('/').find((c) => c.includes('workflow_'));
  },
  /**
   * 
   * @param {string[]} workflow 
   * @returns {string|null}
   */
  getWorkflowStartAttr(workflow = []) {
    const [firstStep] = workflow;
    const attrString = firstStep.entry_attribute?.find((c) => c.includes('_start'));
    return attrString ? attrString.split('|')[0] : null;
  },
  getWorkflowEndAttributes(workflow) {
    const lastStep = workflow[workflow.length - 1];
    return this.getPostAttributes(lastStep.exit_attribute);
  },
  getPostAttributes(attributes) {
    return attributes.reduce((acc, attrString) => {
      const [attr, value] = attrString.split('|');
      if (!value) return acc;
      return {
        ...acc,
        [attr]: value === '*' ? 'true' : String(value),
      };
    }, {});
  },
};

const getNextComponent = (new_flow, currentStep) => {
  const currentIndex = new_flow.findIndex((c) => c.id === currentStep.id);
  // const nextStep = new_flow.find((c,i) => !c.value && i > currentIndex);
  const nextStep = utils.sortForward(new_flow, currentIndex).find((c) => !c.value);
  return nextStep || null;
};

const getDashboardPageChange = (flow, nextStep, changePage, workflow_code) => {
  const code = workflow_code || 'workflow_dashboard';
  const firstIncompleteStep = flow.find((c) => !c.value);
  if (nextStep.component === WORKFLOW.SUBWORKFLOW) {
    changePage(`${PAGES.DASHBOARD_WORKFLOW}/${nextStep.subworkflow_code}`);
  } else if (!nextStep && firstIncompleteStep) {
    changePage(`${PAGES.DASHBOARD_WORKFLOW}/${code}/${firstIncompleteStep.id}`);
  } else {
    changePage(`${PAGES.DASHBOARD_WORKFLOW}/${code}/${nextStep.id}`);
  }
};

const getEnrollmentPageChange = (nextStep, changePage) => {
  changePage(`${PAGES.ENROLLMENT}/${nextStep.id}`);
};
