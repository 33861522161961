import { ComponentStepProgress } from "src/components/global/progress";
import { InnerWorkflow, InnerWorkflowStep } from "src/services/types/WorkflowTypes";

// These are the props directly used when returning the component: <InnerWorkflowProgress prop1={} prop2={} />
export type InnerWorkflowProgressComponentProps = {
  currentStep: InnerWorkflowStep,
  innerWorkflow: InnerWorkflow
}

export type InnerWorkflowProgressProps = InnerWorkflowProgressComponentProps;

function InnerWorkflowProgress({ currentStep, innerWorkflow }: InnerWorkflowProgressProps) {

  if (currentStep.excludeFromProgress === true) {
    return null;
  }

  const currentIndex = innerWorkflow.findIndex(s => s.step === currentStep.step);

  return (
    <section className="text-center signup-progress pb-4 d-flex justify-content-center mt-3">
      {innerWorkflow.map(({ step, excludeFromProgress }, index) => (
        excludeFromProgress === true ? null :
          <ComponentStepProgress
            key={step}
            title={step}
            activeStepIndex={index === currentIndex ? 0 : undefined}
            totalSteps={1}
            doneSteps={index < currentIndex ? 1 : 0}
            compact
          />
      ))}
    </section>
  )
}

export default InnerWorkflowProgress;