// Disable camelCase esLint because types in this file represent data coming from server on which we don't have any control.
/* eslint-disable camelcase */
import apiFetch from 'src/utils/apiWrapper';
import { API_URL } from 'src/constants/api';

export type StructuredFormatting = {
  main_text: string,
  secondary_text: string,
}

export type Term = {
  offset: number,
  value: string,
}

export type Prediction = {
  description: string,
  place_id: string,
  reference: string,
  structured_formatting: StructuredFormatting,
  terms: Term[],
  types: string[],
}

export type AutoCompleteResult = {
  data: {
    predictions: Prediction[]
  },
}

export type AddressComponentType =
  | 'accounting'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'administrative_area_level_4'
  | 'administrative_area_level_5'
  | 'administrative_area_level_6'
  | 'administrative_area_level_7'
  | 'airport'
  | 'amusement_park'
  | 'aquarium'
  | 'archipelago'
  | 'art_gallery'
  | 'atm'
  | 'bakery'
  | 'bank'
  | 'bar'
  | 'beauty_salon'
  | 'bicycle_store'
  | 'book_store'
  | 'bowling_alley'
  | 'bus_station'
  | 'cafe'
  | 'campground'
  | 'car_dealer'
  | 'car_rental'
  | 'car_repair'
  | 'car_wash'
  | 'casino'
  | 'cemetery'
  | 'church'
  | 'city_hall'
  | 'clothing_store'
  | 'colloquial_area'
  | 'continent'
  | 'convenience_store'
  | 'country'
  | 'courthouse'
  | 'dentist'
  | 'department_store'
  | 'doctor'
  | 'drugstore'
  | 'electrician'
  | 'electronics_store'
  | 'embassy'
  | 'establishment'
  | 'finance'
  | 'fire_station'
  | 'floor'
  | 'florist'
  | 'food'
  | 'funeral_home'
  | 'furniture_store'
  | 'gas_station'
  | 'general_contractor'
  | 'geocode'
  | 'gym'
  | 'hair_care'
  | 'hardware_store'
  | 'health'
  | 'hindu_temple'
  | 'home_goods_store'
  | 'hospital'
  | 'insurance_agency'
  | 'intersection'
  | 'jewelry_store'
  | 'landmark'
  | 'laundry'
  | 'lawyer'
  | 'library'
  | 'light_rail_station'
  | 'liquor_store'
  | 'local_government_office'
  | 'locality'
  | 'locksmith'
  | 'lodging'
  | 'meal_delivery'
  | 'meal_takeaway'
  | 'mosque'
  | 'movie_rental'
  | 'movie_theater'
  | 'moving_company'
  | 'museum'
  | 'natural_feature'
  | 'neighborhood'
  | 'night_club'
  | 'painter'
  | 'park'
  | 'parking'
  | 'pet_store'
  | 'pharmacy'
  | 'physiotherapist'
  | 'place_of_worship'
  | 'plumber'
  | 'plus_code'
  | 'point_of_interest'
  | 'police'
  | 'political'
  | 'post_box'
  | 'post_office'
  | 'postal_code'
  | 'postal_code_prefix'
  | 'postal_code_suffix'
  | 'postal_town'
  | 'premise'
  | 'primary_school'
  | 'real_estate_agency'
  | 'restaurant'
  | 'roofing_contractor'
  | 'room'
  | 'route'
  | 'rv_park'
  | 'school'
  | 'secondary_school'
  | 'shoe_store'
  | 'shopping_mall'
  | 'spa'
  | 'stadium'
  | 'storage'
  | 'store'
  | 'street_address'
  | 'street_number'
  | 'sublocality'
  | 'sublocality_level_1'
  | 'sublocality_level_2'
  | 'sublocality_level_3'
  | 'sublocality_level_4'
  | 'sublocality_level_5'
  | 'subpremise'
  | 'subway_station'
  | 'supermarket'
  | 'synagogue'
  | 'taxi_stand'
  | 'tourist_attraction'
  | 'town_square'
  | 'train_station'
  | 'transit_station'
  | 'travel_agency'
  | 'university'
  | 'veterinary_care'
  | 'zoo';

export type AddressComponent = {
  long_name: string,
  short_name: string,
  types: AddressComponentType[]
}

export type AddressDetails = {
  address_components: AddressComponent[],
}

export type AddressDetailsResult = {
  data: {
    html_attributions: string[],
    result: AddressDetails,
  },
}

export default function getGoogleAddresses(value = ''): Promise<AutoCompleteResult> {
  return apiFetch({
    endpoint: API_URL.PROXY_PLACE_AUTOCOMPLETE,
    uriParams: { input: value },
  });
}

export function getGoogleAddressDetails(placeId: string): Promise<AddressDetailsResult> {
  return apiFetch({
    endpoint: API_URL.PROXY_PLACE_DETAILS,
    uriParams: { place_id: placeId },
  });
}

export type ExtractedComponents = Partial<Record<AddressComponentType, AddressComponent>>;

export function extractComponentsFrom(result: AddressDetailsResult, ...components: AddressComponentType[]): ExtractedComponents {
  const { address_components: addressComponents } = result.data.result;

  return components.reduce((accumulate, componentName) => ({
    ...accumulate,
    [componentName]: addressComponents.find((component) => component.types.includes(componentName)),
  }), {});
}
