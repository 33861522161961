import { Case, When } from "src/components/core/PredicateRouter";
import { InnerWorkflow, InnerWorkflowStepProps, WorkflowStepProps } from "src/services/types/WorkflowTypes";
import InnerWorkflowComponent from "../../InnerWorkflow/InnerWorkflowComponent";
import OrderDnaKitCheck from "./OrderDnaKitCheck";
import OrderDnaKitConfirm from "./OrderDnaKitConfirm";
import OrderDnaKitForm from "./OrderDnaKitForm";
import OrderDnaKitReceipt from "./OrderDnaKitReceipt";

const OrderDnaKitInnerWorkflow: InnerWorkflow = [
  { step: "OrderDnaKitCheck", excludeFromProgress: true },
  { step: "OrderDnaKitForm" },
  { step: "OrderDnaKitConfirm" },
  { step: "OrderDnaKitReceipt", excludeFromProgress: true },
];

function OrderDnaKitStep({ onComponentComplete }: WorkflowStepProps) {
  return (
    <InnerWorkflowComponent
      innerWorkflow={OrderDnaKitInnerWorkflow}
      onInnerWorkflowComplete={() => onComponentComplete()}
      elementSelector={(stepProps: InnerWorkflowStepProps) => {
        const { step: currentStep } = stepProps.step;
        return (
          <Case>
            <When predicate={currentStep === "OrderDnaKitCheck"} component={OrderDnaKitCheck} props={{...stepProps, onComponentComplete}} />
            <When predicate={currentStep === "OrderDnaKitForm"} component={OrderDnaKitForm} props={stepProps} />
            <When predicate={currentStep === "OrderDnaKitConfirm"} component={OrderDnaKitConfirm} props={stepProps} />
            <When predicate={currentStep === "OrderDnaKitReceipt"} component={OrderDnaKitReceipt} props={stepProps} />
          </Case>
        );
      }}
    />
  )
}


export default OrderDnaKitStep;