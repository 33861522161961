import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';
import { api_config } from '../api/api_config';
import { utils_api } from '../utils/utils_api.js';
import { utils_registration_flow } from '../utils/utils_registration_flow.js';
import { LOCAL_STORAGE } from '../constants/localStorage.js';
import { TOKEN_TYPE } from '../constants/tokenType.js';

export function config_terms_get(hidespinner = false) {
  return (dispatch) => {
    if (!hidespinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'config_terms_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.TERMS_GET,
        data: response.data.data,
      });
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_config.get_config_terms(success, fail);
  };
}

export function config_privacy_get(hidespinner = false) {
  return (dispatch) => {
    if (!hidespinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'config_privacy_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.PRIVACY_GET,
        data: response.data.data,
      });
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_config.get_config_privacy(success, fail);
  };
}

// TODO: Tech-debt. Should remove premature optimisation and DO NOT load reg-type and regflow
export function js_config_get(file, hideSpinner = false) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'js_config_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE, 'js_config_get');
      // probably should redo this at somepoint, maybe try importing
      const jsonText = response.data.replace('export const app_config =', '').replace('module.exports =', '');

      const jsonWorkflow = JSON.parse(jsonText).workflow;
      const workflow = Object.values(jsonWorkflow)[0];
      const final_flow = utils_registration_flow.prep_regflow_data(workflow);

      utils.set_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW, final_flow);

      dispatch({
        type: REDUX_ACTION.REGISTRATION_FLOW,
        data: final_flow,
      });

      utils.set_local_storage(LOCAL_STORAGE.REGISTRATION_TYPE, TOKEN_TYPE.REGISTRATION_SELF);

      dispatch({
        type: REDUX_ACTION.REGISTRATION_TYPE,
        data: TOKEN_TYPE.REGISTRATION_SELF,
      });

      const { feature } = JSON.parse(jsonText);
      dispatch({
        type: REDUX_ACTION.FEATURE,
        data: feature,
      });
      return feature;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_config.get_config_js(file, success, fail);
  };
}
