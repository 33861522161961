import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HtmlContentComponent from 'src/components/core/HtmlContent/HtmlContentComponent';

export const AncestrySubcompCollapsibleSection = props => {
  const { collapsibleSection, subcomponentKey } = props;
  const { text, collapsed_text } = collapsibleSection;

  const { t } = useTranslation('consent');
  const [isExpanded, setIsExpanded] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const toggleExpanded = () => {
    if (
      collapsibleSection.collapsed_required === 'true' &&
      !isRead &&
      !isExpanded
    ) {
      setIsRead(true);
      props.onRequiredCollapsibleRead();
    }
    setIsExpanded(!isExpanded);
  };

  const activeClass = isExpanded ? 'active' : '';
  const nonCollapsibleText = text[props.lang] || text.en;
  const collapsibleSectionId = `collapsible-${subcomponentKey}`;
  const collapsibleText = collapsed_text[props.lang] || collapsed_text.en;

  return (
    <div className="mb-3 collapsible-section">
      {!collapsibleText ? (
        <HtmlContentComponent tag={'div'} markup={nonCollapsibleText} />
      ) : (
        <>
          <button
            className={`border-0 px-4 py-23 float-right accordion-button ${activeClass}`}
            aria-expanded={isExpanded}
            aria-controls={collapsibleSectionId}
            onClick={toggleExpanded}
            aria-label={isExpanded ? t('<collapse>') : t('<expand>')}
          ></button>
          <HtmlContentComponent tag={'div'} markup={nonCollapsibleText} />
          <HtmlContentComponent
            tag={'div'}
            className={isExpanded ? '' : 'd-none'}
            markup={collapsibleText}
            id={collapsibleSectionId}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.app.language,
});

export default connect(mapStateToProps)(AncestrySubcompCollapsibleSection);
