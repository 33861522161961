import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import AppLogo from '../../assets/images/app-logo.png';
import PAGES from 'src/constants/pages';
import { CONFIG, hasLanguageSwitcher } from '../../constants/config';
import WorkflowProgress from './WorkflowProgress/workflow_progress';
import Logout from '../global/logout';
import LanguageSwitch from '../global/language_switch';
import { utils } from '../../utils/utils_general';
import { TRUNCATE } from '../../constants/truncateString';

class AuthHeaderWorkflow extends React.Component {
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  renderNav() {
    const isEnrollment = this.props.location && this.props.location.pathname ? this.props.location.pathname.includes('enrollment') : null;
    const name = this.props.user.first_name ? (this.props.user.last_name ? `${this.props.user.first_name} ${this.props.user.last_name}` : this.props.user.first_name) : '';
    return (
      <div className="container-fluid pr-xl-5 pl-xl-5 pb-4">
        <div className="row header-row">
          <div className="col-6">
            <div className="header-logo"><Link to={PAGES.DASHBOARD}><img src={AppLogo} alt={`${CONFIG.APP_NAME} logo`} /></Link></div>
          </div>
          <div className="col-6 text-right header-signout">
            <div>
              {hasLanguageSwitcher ? (
                <div className="nav-language">
                  {' '}
                  <LanguageSwitch />
                </div>
              ) : null}
              <div className="nav-name name font-callout">
                { utils.truncate_string(name, TRUNCATE.NAME) }
              </div>
            </div>
            <div className="font-callout">
              { isEnrollment
                ? <Link style={{ color: 'white' }} to={PAGES.ENROLLMENT_PROFILE}>{this.props.t('Your profile')}</Link>
                :								<a style={{ color: 'white' }} href={PAGES.PROFILE}>{this.props.t('Your profile')}</a>}
            </div>
            <Logout />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const path = `header-${this.props.location.pathname.replace('/', '').replace('/', '-')}`;
    const workflowName = this.props.t(this.props.workflowName || 'unknown');
    return (
      <header className={`header pt-4 pb-3 header-enrollment ${path}`}>
        {this.renderNav()}
        <div className="header-workflow-content">
          <div className="header-workflow-progress">
            <div className="workflow-title">
              <h1>{workflowName}</h1>
            </div>
            <WorkflowProgress workflowName={workflowName}/>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
  user: state.loginUser,
  ...ownProps,
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(AuthHeaderWorkflow)));
