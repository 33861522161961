import { CONFIG } from 'src/constants/config';
import imageDark from 'src/assets/images/logo.png';
import imageLight from 'src/assets/images/logo-white.png';

const { COBRANDING_TEXT, COBRANDING_URL, COBRANDING_LOGO_ENABLED } = CONFIG;

function CobrandingImage({ variant }) {
  const image = variant === 'light' ? imageLight : imageDark;
  if (COBRANDING_LOGO_ENABLED) {
    return <img src={image} alt={COBRANDING_TEXT} />;
  }
  return COBRANDING_TEXT;
}

export default function CobrandingLogo({ variant }) {
  if (!COBRANDING_URL) {
    return <CobrandingImage variant={variant} />;
  }
  return (
    <a className="d-inline-block" href={COBRANDING_URL}>
      <CobrandingImage variant={variant} />
    </a>
  );
}
