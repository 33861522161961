import { REDUX_ACTION } from '../constants/reduxAction.js';

export function dismiss_global_error() {
  return (dispatch) => {
    dispatch({
      type: REDUX_ACTION.GLOBAL_ERROR,
      data: null,
    });
  };
}
