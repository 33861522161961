import React from 'react';
import { Button } from 'react-bootstrap';
import { ENV_STRING } from 'src/constants/api';

const isDevEnvironment = ENV_STRING === 'DEV'
  || ENV_STRING === 'DEV2'
  || window.location.origin.includes('localhost')
  || window.location.origin.includes('local');

// These are the props directly used when returning the component: <DevSkipSurveyButton prop1={} prop2={} />
export type DevSkipSurveyButtonComponentProps = {
  onSkipSurvey: () => void,
}

const defaultProps: Partial<DevSkipSurveyButtonComponentProps> = {
};

export type DevSkipSurveyButtonProps = DevSkipSurveyButtonComponentProps;

function DevSkipSurveyButton({ onSkipSurvey }: DevSkipSurveyButtonProps): React.ReactElement | null {
  if (!isDevEnvironment) {
    return null;
  }

  return (
    <Button size="sm" variant="light" onClick={() => onSkipSurvey()}>
      Dev env only, get on with your life
    </Button>
  );
}

DevSkipSurveyButton.defaultProps = defaultProps;

export default DevSkipSurveyButton;
