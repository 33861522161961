import { get } from 'lodash/fp';
import { WORKFLOW_REDUCER_NAMESPACE } from './constants';

export const selectWorkflowStoreData = get(WORKFLOW_REDUCER_NAMESPACE);
export const selectWorkflows = get(`${WORKFLOW_REDUCER_NAMESPACE}.workflows.data`);
export const selectWorkflowIsLoading = get(`${WORKFLOW_REDUCER_NAMESPACE}.workflows.isLoading`);
export const selectWorkflowErrors = get(`${WORKFLOW_REDUCER_NAMESPACE}.workflows.errors`);
export const selectWorkflowIsCompleted = get(`${WORKFLOW_REDUCER_NAMESPACE}.workflows.isCompleted`);

export const selectWorkflowByCode = (state, props = {}) => {
  const { workflowCode } = props;
  if (!workflowCode) {
    return null;
  }
  const workflows = selectWorkflows(state);
  const workflowByCode = workflows[workflowCode];
  return workflowByCode;
};

export const selectCurrentStep = get(`${WORKFLOW_REDUCER_NAMESPACE}.currentStep.data`);
export const selectCurrentStepIsLoading = get(`${WORKFLOW_REDUCER_NAMESPACE}.currentStep.isLoading`);
export const selectCurrentStepErrors = get(`${WORKFLOW_REDUCER_NAMESPACE}.currentStep.errors`);
