import React from "react";
import { withTranslation } from "react-i18next";
import ConsentSubcompRenderer from "src/components/consent/consent_subcomp_renderer";
import { CONSENT_COMPONENT } from "src/constants/consentComponents";
import WorkflowNextButton from "src/components/workflow/WorkflowNextButton";

class ConsentCompForm extends React.Component {
  constructor() {
    super();
    this.state = {
      videoComplete: false,
      radioAnswers: [],
      collapsibleSectionsRead: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.component && this.props.component && JSON.stringify(prevProps.component) !== JSON.stringify(this.props.component)) {
      this.setState({
        videoComplete: false,
        radioAnswers: [],
        collapsibleSectionsRead:0,
      });
    }
  }

  onVideoComplete() {
    this.setState({ videoComplete: true });
  }

  onRadioAnswered(qnAndAnswer) {
    const { radioAnswers } = this.state;
    // remove prev answer if qn has been answered already
    const index_exist = radioAnswers.findIndex((r) => r.qn_code === qnAndAnswer.qn_code);
    if (index_exist >= 0) {
      radioAnswers.splice(index_exist, 1);
    }
    radioAnswers.push(qnAndAnswer);

    this.setState({ radioAnswers });
  }

  onRequiredCollapsibleRead() {
    const { collapsibleSectionsRead } = this.state;
    this.setState({ collapsibleSectionsRead: collapsibleSectionsRead + 1 });
  }

  render() {
    const { t, component, index, user_response, onComponentComplete } = this.props;
    const { body, title } = component;

    const hasVideo = body.filter((c) => c.type === CONSENT_COMPONENT.VIDEO)[0];
    const noVidOrComplete = hasVideo ? !!this.state.videoComplete : true;

    const numOfQnRadio = body.filter((c) => c.type === CONSENT_COMPONENT.QN_RADIO).length;
    const noQnRadioOrComplete = numOfQnRadio > 0 ? this.state.radioAnswers.length === numOfQnRadio : true;

    const numOfRequiredCollapsibles = body
      .filter((c) => c.type === CONSENT_COMPONENT.COLLAPSIBLE_SECTION)
      .filter((section) => section.collapsed_required === "true").length;
 
    const noCollapsiblesOrComplete = numOfRequiredCollapsibles > 0 ? this.state.collapsibleSectionsRead === numOfRequiredCollapsibles : true;

    const disabled = !(noVidOrComplete && noQnRadioOrComplete && noCollapsiblesOrComplete);

    return (
      <div className="consent-component">
        {title && title !== "" ? <h2 className="h5 mb-3">{t(title)}</h2> : null}
        <div className="consent-subcomponents">
          {body.map((c, key) => (
            <ConsentSubcompRenderer
              key={key}
              type={c.type}
              component={c}
              index={index}
              subcomponentKey={key}
              user_response={user_response}
              onVidComplete={() => this.onVideoComplete()}
              onQnComplete={(value) => onComponentComplete(value)}
              onRadioAnswered={(value) => this.onRadioAnswered(value)}
              onRequiredCollapsibleRead={() => this.onRequiredCollapsibleRead()}
            />
          ))}
        </div>

        <div className="unauth-body-footer">
          <WorkflowNextButton
            disabled={disabled}
            size={"sm"}
            inline
            type="submit"
            onClick={() => onComponentComplete({ index, radio: this.state.radioAnswers })}
          >
            {t("Continue to next Consent Step")}
          </WorkflowNextButton>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ConsentCompForm);
