import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

class InfoPopup extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }

  showPopup() {
    this.setState({ show: true });
  }

  hidePopup() {
    this.setState({ show: false });
  }

  render() {
    const { show } = this.state;
    return (
      <>
        <button onClick={() => this.showPopup()} className="info-popup-button" />

        <Modal className="info-modal" show={show} onHide={() => this.hidePopup()}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.props.body}</Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, {})(InfoPopup));
