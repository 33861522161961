import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { GenericWorkflow, WorkflowCodeType } from 'src/services/types/WorkflowTypes';
import WorkflowHeaderComponent from './WorkflowHeader/WorkflowHeaderComponent';
import WorkflowStepsComponent from './WorkflowStepsComponent';

type WorkflowComponentProps = {
  workflow: GenericWorkflow | null,
  workflowName: string | null,
  workflowCode: WorkflowCodeType | null,
  isWorkflowLoading?: boolean,
  onWorkflowExit: ()=>Promise<any>,
  onWorkflowSkip: ()=>Promise<any>,
} & RouteComponentProps;

const defaultProps = {
  isWorkflowLoading: false,
};

function WorkflowComponent({
  workflow, workflowCode, workflowName, isWorkflowLoading, onWorkflowExit, onWorkflowSkip,
}: WorkflowComponentProps) {
  const { t } = useTranslation();

  const workflowNamei18ned = workflowCode ? t(workflowCode) : null;
  const workflowNameResolved = workflowName || workflowNamei18ned?.toString() || workflowCode;

  return (
    <>
      <WorkflowHeaderComponent
        workflowName={workflowNameResolved}
        workflow={workflow}
        isWorkflowLoading={isWorkflowLoading || false}
      />
      <section className='container'>
        <WorkflowStepsComponent workflowCode={workflowCode} onSkipWorkflow={onWorkflowSkip} onExitWorkflow={onWorkflowExit} />
      </section>
    </>
  );
}

WorkflowComponent.defaultProps = defaultProps;

// @ts-ignore
export default withRouter(WorkflowComponent);
