import jwtDecode from 'jwt-decode';
import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';
import { LOCAL_STORAGE } from '../constants/localStorage';
import { api_login } from '../api/api_login';
import { utils_api } from '../utils/utils_api.js';
import { utils_user } from 'src/utils/utils_user.js';

export function loginSuccess(dispatch, type){
  return (response) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const jwt = response.data.data;
    utils.set_local_storage(LOCAL_STORAGE.USER, jwt);
    const jwt_decoded = jwtDecode(jwt);
    dispatch({
      type: type,
      data: jwt_decoded,
    });
    return jwt_decoded;
  };
}

/**
 * 
 * @param {bool|null} [hideSpinner] 
 * @returns {function(dispatch): Promise<import('src/services/types/UserTypes.js').UserData>}
 */
export function login_get(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'login_get');
    }
    const success = loginSuccess(dispatch, REDUX_ACTION.LOGIN_GET);

    const fail = (error) => utils_api.on_fail_default(dispatch, error);

    return api_login.get_login(success, fail);
  };
}

export function login_post(data, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'login_post');
    }

    const success = loginSuccess(dispatch, REDUX_ACTION.LOGIN_POST);

    const fail = (error) => utils_api.on_fail_default(dispatch, error);

    if(utils.get_local_storage(LOCAL_STORAGE.SKIP_VERIFY)){ utils.remove_local_storage(LOCAL_STORAGE.SKIP_VERIFY);}

    return api_login.login(success, fail, data);
  };
}

export function login_skip_emailverif_post(otp_id, token, hideSpinner = null) {
  // returns registration token
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'login_emailverif_post');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_login.login_skip_emailverif(success, fail, otp_id, token);
  };
}

export function login_emailverif_post(otp_id, token, hideSpinner = null) {
  // returns registration token
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'login_emailverif_post');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_login.login_emailverif(success, fail, otp_id, token);
  };
}

export function login_magic_link_request_post(email, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'login_magic_link_request_post');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    if(utils.get_local_storage(LOCAL_STORAGE.SKIP_VERIFY)){ utils.remove_local_storage(LOCAL_STORAGE.SKIP_VERIFY);}

    return api_login.login_magic_link_request(success, fail, email);
  };
}

export function login_magic_link_validation_post(token, email, otp, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'login_magic_link_validation_post');
    }
    const success = loginSuccess(dispatch, REDUX_ACTION.LOGIN_POST);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_login.login_magic_link_validation(success, fail, token, email, otp);
  };
}

export function login_mfa_otp_request_post(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'login_mfa_otp_request_post');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_login.login_mfa_otp_request(success, fail);
  };
}

export function login_mfa_otp_validation_post(otpId, otp, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'login_mfa_otp_validation_post');
    }
    const success = loginSuccess(dispatch, REDUX_ACTION.LOGIN_POST);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_login.login_mfa_otp_validation(success, fail, otpId, otp);
  };
}

export function set_login_user_from_local_storage() {
  return (dispatch) => {
    const user = utils_user.get_user();

    if (user) {
      dispatch({
        type: REDUX_ACTION.LOGIN_FROM_LOCAL_STORAGE,
        data: user,
      });
    }
  };
}
