import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import PAGES from 'src/constants/pages';
import { post_user_attributes_post } from 'src/actions/userAction';
import { ATTRIBUTES } from 'src/constants/attributes';
import { utils } from 'src/utils/utils_general';
import WorkflowNextButton from 'src/components/workflow/WorkflowNextButton';

class AppPolicies extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      errors: null,
    };
  }

  handleSubmit(e, answer) {
    this.props.post_user_attributes_post(ATTRIBUTES.GA_COOKIE, String(answer))
      .then(() => this.props.onComplete())
      .catch((error) => {
        this.setState({ errors: error });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors && !utils.check_objects_identical(this.props.errors, prevProps.errors)) {
      this.setState({ errors: this.props.errors });
    }
  }

  renderErrorPage() {
    return (
      <>
        <h1> Error</h1>
        <div className="h2 mt-5 mb-5" style={{ fontSize: '18px', fontWeight: 'normal', lineHeight: '24px' }}>
          {this.t("We've hit an error with your policies form.")}
        </div>
        <Link className="btn btn-primary" to="/">Go back to home</Link>
      </>
    );
  }

  renderPolicyListItem(description, requirement, key) {
    return (
      <li className="mt-4" key={key}>
        <div className="row">
          <div className="col-7 col-md-9">
            <b>
              {this.props.t(description)}
              {' '}
            </b>
          </div>
          <div className="col-5 col-md-3 text-center">
            <div><i>{this.props.t(requirement)}</i></div>
          </div>
        </div>
      </li>
    );
  }

  render() {
    if (this.state.errors && !utils.is_obj_empty(this.state.errors)) {
      return this.renderErrorPage();
    }

    const { t } = this.props;
    const POLICY_COPY = [
      {
        desc: t('Anonymous Usage cookies are used to understand feature performance, usage of features at an aggregate and anonymous level.  You opt out at within your account profile at anytime.'),
        req: t('Recommended'),
      },
      {
        desc: t('Essential Functional cookies are required to support utilization of the application, e.g. the state of a survey you are taking..  All data used for essential needs are de-identified.'),
        req: t('Required'),
      },
    ];
    return (
      <>
        <h1>{t('Application Policies')}</h1>
        <div>
          <div className="mb-2">
            <div className="pb-1">
              <Trans i18nKey="policiesOverview">
                <h2>Overview</h2>
                <p>This application uses cookies, which are small amounts of data generated by a website and saved by your web browser. The purpose of these cookies are to support essential functional feature and help us improve our software.</p>
              </Trans>
              <ol className="app-policy-list">
                {POLICY_COPY.map(({ desc, req }, key) => this.renderPolicyListItem(desc, req, key))}
              </ol>
            </div>
            <div className="pb-3">
              <Trans i18nKey="policiesReminder">
                <h2>Reminder</h2>
                <p className="mb-2">No personal identifiable information is ever shared with Regeneron or any third&nbsp;party.</p>
                <p>
                  You can learn more about the application policies in the&nbsp;
                  <a href={PAGES.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                  {' '}
                  and&nbsp;
                  <a href={PAGES.TERMS_OF_SERVICE} target="_blank" rel="noopener noreferrer">Terms&nbsp;&&nbsp;Conditions.</a>
                </p>
              </Trans>
            </div>
          </div>

          <div className="unauth-body-footer text-center">
            <div className="app-policy-button-wrapper">
              <WorkflowNextButton variant="link" size={"sm"} inline className="pl-0 pr-0 mr-4" type="submit" onClick={(e) => this.handleSubmit(e, false)}>
                {t('Decline')}
              </WorkflowNextButton>

              <WorkflowNextButton size="sm" type="submit" inline onClick={(e) => this.handleSubmit(e, true)}>
                {t('Accept')}
              </WorkflowNextButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withRouter(connect(mapStateToProps, { post_user_attributes_post })(withTranslation()(AppPolicies)));
