import classNames from 'classnames';
import { ChangeEventHandler, FocusEventHandler } from 'react';
import ReactInputMask from 'react-input-mask';

// These are the props directly used when returning the component: <ZipCodeComponent prop1={} prop2={} />
export type ZipCodeComponentComponentProps = {
  name?: string,
  value?: string,
  isInvalid: boolean,
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  onFocus?: FocusEventHandler<HTMLInputElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
}

export type ZipCodeComponentProps = ZipCodeComponentComponentProps;

function ZipCodeComponent({
  name, value, onChange, onFocus, onBlur, isInvalid,
}: ZipCodeComponentProps) {
  return (
    <ReactInputMask
      className={classNames('form-control', { 'is-invalid': isInvalid })}
      mask="99999"
      maskPlaceholder="_"
      placeholder="00000"
      onChange={onChange}
      name={name}
      id={name}
      type="text"
      inputMode="tel"
      pattern="[0-9]*"
      value={value || ''}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}

const defaultProps: ZipCodeComponentComponentProps = {
  isInvalid: false,
};

ZipCodeComponent.defaultProps = defaultProps;

export default ZipCodeComponent;
