import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CheckboxListComponent from 'src/components/core/CheckboxList/CheckboxListComponent';
import MultiSelectDropdownComponent from 'src/components/core/MultiSelectDropdown/MultiSelectDropdownComponent';
import DropdownComponent from 'src/components/core/Dropdown/DropdownComponent';

function optionsMaxHeightCalculator(inputBoundingBox) {
  const rootElementBottom = document.querySelector('main .unauth-content')?.getBoundingClientRect()?.bottom;
  if (!rootElementBottom) {
    return 200;
  }

  return rootElementBottom - inputBoundingBox.bottom;
}

const isTextNotCode = (text) => text && !text.includes(': ') && !text.includes('_');

const getOptionLabel = ({ text, id, t }) => (text && isTextNotCode(text) ? text : t(`answers_text_${id}`));

const handleMultipleOptionsClicked = (key, selectedAnswers) => {
  if (selectedAnswers.includes(key)) {
    return selectedAnswers.filter((answer) => answer !== key);
  }
  return [...(new Set([...selectedAnswers, key]))];
};

const ComponentMap = {
  dropdown: {
    multiple: ({ items, selectedAnswers, handleOptionClick }) => (
      <MultiSelectDropdownComponent
        items={items}
        selectedValues={selectedAnswers}
        onItemClicked={(e, item) => handleOptionClick(e, item)}
        filterInputDisplay="inline"
        optionsMaxHeightCalculator={optionsMaxHeightCalculator}
      />
    ),
    single: ({ items, selectedAnswers, handleOptionClick }) => (
      <DropdownComponent
        items={items}
        selectedValue={selectedAnswers[0]}
        onItemSelect={(option, e) => handleOptionClick(e, option)}
        filterInputDisplay="inline"
        optionsMaxHeightCalculator={optionsMaxHeightCalculator}
      />
    ),
  },
  button: {
    multiple: ({
      items, selectedAnswers, handleOptionClick, questionAnswered, withCorrectIcons
    }) => (
      <CheckboxListComponent
        questionAnswered={questionAnswered}
        items={items}
        selectedValues={selectedAnswers}
        onOptionClick={(e, option) => handleOptionClick(e, option)}
        withCorrectIcons={withCorrectIcons}
      />
    ),
    single: ({
      items, selectedAnswers, handleOptionClick, questionAnswered, withCorrectIcons
    }) => (
      <CheckboxListComponent
        questionAnswered={questionAnswered}
        items={items}
        selectedValues={selectedAnswers}
        onOptionClick={(e, option) => handleOptionClick(e, option)}
        withCorrectIcons={withCorrectIcons}
      />
    ),
  },
};

function OptionsList({
  multiple, viewType, questionAnswered, options, selectedAnswers, onOptionClick, t, withCorrectIcons
}) {
  if (!options) return null;

  const handleOptionClick = (e, { key }) => {
    const newSelectedAnswers = multiple ? handleMultipleOptionsClicked(key, selectedAnswers) : [key];

    return onOptionClick(e, newSelectedAnswers);
  };

  // Choose what control to use.
  const multiplicity = multiple ? 'multiple' : 'single';
  // Map the options to "standard" items.
  const items = options.map(({
    id, text, is_correct: optionIsCorrect, correct,
  }) => ({ key: id, name: getOptionLabel({ text, id, t }), isCorrect: correct ?? optionIsCorrect }));

  if (!ComponentMap[viewType]) return null;
  if (!ComponentMap[viewType][multiplicity]) return null;

  const Component = ComponentMap[viewType][multiplicity];

  return Component({
    items, selectedAnswers, questionAnswered, handleOptionClick, withCorrectIcons
  });
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, {})(withTranslation('codes')(OptionsList)));
