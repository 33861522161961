import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { get_survey_by_id_post, get_survey_list_get, get_one_survey_details_get } from 'src/actions/surveyAction';

import { RESPONSE_CODE, UNEXPECTED_ERROR } from 'src/constants/errors';
import PAGES from 'src/constants/pages';

import { utils } from 'src/utils/utils_general';

import { withService } from 'src/services/ServiceProvider';
import SurveyWrapper from './survey_wrapper';

class SurveyFrame extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: null,
      survey: null,
      surveyUrl: null,
    };
  }

  componentDidMount() {
    const { match, userService } = this.props;

    userService.keepCurrentUserAlive();

    const surveyId = match.params.survey_id;

    this.checkForSurvey(surveyId);
    this.checkForSurveyUrl(surveyId);

    window.addEventListener('message', this.onSurveyEnd.bind(this), false);
  }

  componentDidUpdate({ app: previousApp }) {
    const { match, app: currentApp } = this.props;
    const surveyId = match.params.survey_id;

    if (previousApp?.language !== currentApp?.language) {
      this.checkForSurveyUrl(surveyId);
    }
  }

  componentWillUnmount() {
    const { userService } = this.props;

    userService.stopKeepingCurrentUserAlive();

    window.removeEventListener('message', this.onSurveyEnd.bind(this), false);
  }

  onSurveyEnd(event) {
    if (event.data === 'SURVEY_ENDED') {
      this.props.history.push(PAGES.SURVEY_COMPLETE, { survey: this.state.survey });
    }
  }

  getSurveyElseRedirect(surveys, survey_id) {
    const surveyDetails = surveys.filter((survey) => survey.survey_id === survey_id);
    if (surveyDetails && surveyDetails.length === 1) {
      this.setState({ survey: surveyDetails[0] });
    } else {
      this.props.history.push(PAGES.SURVEYS);
    }
  }

  checkForSurvey(surveyid) {
    if (!this.props.surveys) {
      if (this.props.currentSurvey && this.props.currentSurvey.survey_id === surveyid) {
        this.setState({ survey: this.props.currentSurvey });
      } else {
        this.props.get_one_survey_details_get(surveyid)
          .then((resp) => {
            this.setState({ survey: resp });
          });
      }
    } else {
      this.getSurveyElseRedirect(this.props.surveys, surveyid);
    }
  }

  checkForSurveyUrl(surveyId) {
    if (!surveyId) {
      this.setState({ errors: 'Survey not found' });
      return;
    }

    const { get_survey_by_id_post: getSurveyByIdPost } = this.props;

    getSurveyByIdPost(surveyId)
      .then((response) => {
        this.setState({ surveyUrl: response });
      })
      .catch((error) => {
        if (error.response && error.response.status === RESPONSE_CODE['404_notfound']) {
          this.setState({ errors: 'Survey Unavailable' });
        } else {
          this.setState({ errors: UNEXPECTED_ERROR });
        }
      });
  }

  renderFrame() {
    const surveyUrl = utils.getSurveyLangUrl(this.state.surveyUrl, this.props.app?.language);
    return (
      <>
        {this.state.errors
          ? <p className="text-center">{this.state.errors}</p>
          : (
            <iframe
              src={surveyUrl}
              title="survey"
              style={{
                width: '100%', minHeight: '80vh', overflow: 'hidden', border: '0',
              }}
            />
          )}
      </>
    );
  }

  render() {
    if (!this.state.survey || !this.state.surveyUrl) {
      return null;
    }
    const { survey } = this.state;

    return (
      <SurveyWrapper content={this.renderFrame(survey)} survey={survey} />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, { get_survey_by_id_post, get_survey_list_get, get_one_survey_details_get })(
    withService(['userService'])(SurveyFrame),
  ),
);
