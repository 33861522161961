import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { login_get } from '../../../actions/loginAction';
import { post_workflow_complete_post, get_user_attributes_get } from '../../../actions/userAction';
import { utils } from '../../../utils/utils_general';
import { LOCAL_STORAGE } from '../../../constants/localStorage';
import { update_registration_flow } from '../../../actions/registrationFlowAction';
import { utils_signup } from '../../../utils/utils_signup';
import { utils_registration_flow } from '../../../utils/utils_registration_flow';
import SignupProgress from '../signup_progress';
import TextMediaWithNext from '../../global/text_media_with_next';
import { clearReloadState } from './service_regflow';
import { WORKFLOW } from 'src/constants/workflow';

const CURRENT_REG_STEP = WORKFLOW.TEXT_MEDIA;

class SignupTextMedia extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      errors: null,
      reg_flow: null,
    };
  }

  componentDidMount() {
    const reg_flow = this.props.registrationFlow || utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
    if (reg_flow) {
      this.setState({ reg_flow });

      const currentStep = utils_registration_flow.current_registration_component(this.state.reg_flow, CURRENT_REG_STEP);
      this.props.get_user_attributes_get()
        .then((res) => {
          this.checkEntryAttributes(res, currentStep, reg_flow);
        })
        .catch((error) => this.setState({ errors: error }));
    } else {
      window.location = '/signup';
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  checkEntryAttributes(dbAttr, currentStep, reg_flow) {
    const attr_errors = utils_registration_flow.checkAttrCompleted(currentStep.entry_attribute, dbAttr);
    if (attr_errors.length > 0) {
      // entry attribute not met
      const redirectInfo = utils_registration_flow.redirectToAttribute(attr_errors, reg_flow);
      utils_signup.goToRegistrationStep(redirectInfo.flow, redirectInfo.step, dbAttr, this.props.update_registration_flow, this.props.history.push, this.props.post_workflow_complete_post);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.registrationFlow && !utils.check_objects_identical(this.props.registrationFlow, prevProps.registrationFlow)) {
      this.setState({ reg_flow: this.props.registrationFlow });
    }
  }

  goToRegisteredNextStep() {
    clearReloadState();
    const currentStep = utils_registration_flow.current_registration_component(this.state.reg_flow, CURRENT_REG_STEP);

    this.props.get_user_attributes_get()
      .then((res) => {
        const attr_errors = utils_registration_flow.checkAttrCompleted(currentStep.exit_attribute, res);
        if (attr_errors.length > 0) {
          this.componentDidMount();
        } else {
          utils_signup.goToNextRegistrationStep(this.state.reg_flow, currentStep, res, this.props.update_registration_flow, this.props.history.push, this.props.post_workflow_complete_post);
        }
      })
      .catch((errors) => this.setState({ errors }));
  }

  render() {
    const currentStep = utils_registration_flow.current_registration_component(this.state.reg_flow, CURRENT_REG_STEP);
    return (
      <section className="signup-component signup-text">

        {currentStep
          ? (
            <>
              <SignupProgress currentStep={currentStep} regFlow={this.state.reg_flow} />
              <div className="signup-body-wrapper">
                <h1>{this.t(currentStep.name)}</h1>
                <TextMediaWithNext body={currentStep.body} onNext={() => this.goToRegisteredNextStep()} />
              </div>
            </>
          )
				  : null}

      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withRouter(connect(mapStateToProps, {
  login_get, update_registration_flow, post_workflow_complete_post, get_user_attributes_get,
})(withTranslation()(SignupTextMedia)));
