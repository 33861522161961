import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PersonalInfo from './personal_info';
import Notifications from './notifications';
import { post_user_action_post } from '../../actions/userAction';
import Legal from './legal';
import Security from './security';
import EMRResultsProfile from './emr_results_profile';
import { USER_ACTION } from '../../constants/userAction';
import HtmlContentComponent from 'src/components/core/HtmlContent/HtmlContentComponent';

class Profile extends React.Component {
	_isMounted = false;

	constructor() {
		super();
		this.state = {
			width: 0,
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this._isMounted = true;
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.updateUserAction();
	}

	shouldComponentUpdate(nextProps, nextState) {
		const _nextProps = {
			...nextProps, i18n: null, t: null, post_user_action_post: null,
		};
		const _thisProps = {
			...this.props, i18n: null, t: null, post_user_action_post: null,
		};
		// TODO: use redux instead of manual checking
		if (JSON.stringify(_nextProps) === JSON.stringify(_thisProps) && JSON.stringify(nextState) === JSON.stringify(this.state)) {
			return false;
		}
		return true;
	}

	updateWindowDimensions() {
		if (this._isMounted) {
			this.setState({ width: window.innerWidth });
		}
	}

	updateUserAction() {
		return this.props.post_user_action_post(USER_ACTION.PROFILE_VIEW, true);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {

		};

		this._isMounted = false;
	}

	renderProfileDescription() {
		const { t } = this.props;
		return (
			<div className="get-started section-component">
				<h2 className="side-heading mb-3">{t('Profile')}</h2>
				<div className="mb-3">
					<HtmlContentComponent markup={t('content:copy_page_description_profile')} />
				</div>
			</div>
		);
	}

	render() {
		const { featureFlag } = this.props;
		return (
			<section className="profile container">
				<div className="row">
					<aside className="col-12 col-xl-2 left-section desktop-only">
						{this.renderProfileDescription()}
					</aside>
					<div className="col-12 col-lg-9 col-xl-8 center-section">
						<PersonalInfo />
						{this.state.width > 991 ? (
							<>
								<Legal />
								<Security />
								{featureFlag && featureFlag.ehr && <EMRResultsProfile />}
							</>
						) : null}
					</div>
					<aside className="col-12 col-lg-3 col-xl-2 right-section">
						<Notifications />
						{this.state.width <= 991 ? (
							<>
								<Legal />
								<Security />
								{featureFlag && featureFlag.ehr && <EMRResultsProfile />}
							</>
						) : null}
					</aside>

				</div>
			</section>
		);
	}
}

const mapStateToProps = (state) => ({
	...state,
	featureFlag: state.featureFlag,
});

export default connect(mapStateToProps, { post_user_action_post })(withTranslation('content')(Profile));
