import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { utils } from '../../utils/utils_general';
import PAGES from 'src/constants/pages';
import SurveyWrapper from './survey_wrapper';
import SurveyConsentText from './survey_consent_text';
import { withTranslation } from 'react-i18next';

class SurveyConsent extends React.Component {
  constructor() {
    super();
    this.state = {
      survey: null,
    };
  }

  componentDidMount() {
    if (this.props.location && this.props.location.state && this.props.location.state.survey && !utils.is_obj_empty(this.props.location.state.survey)) {
      this.setState({ survey: this.props.location.state.survey });
    } else {
      this.props.history.push(PAGES.SURVEYS);
    }
  }

  renderSurveyConsentText(survey) {
    const { t, location } = this.props;
    return (
      <>
        <SurveyConsentText survey={survey} />
        <div className="mt-3 text-center">
          <Link className="btn-link-callout" to={PAGES.SURVEYS}>
            {' '}
            {t(location && location.state && location.state.fromSurvey ? 'Back to all surveys' : 'View all surveys')}
          </Link>
        </div>
      </>
    );
  }

  render() {
    const { survey } = this.state;
    if (!survey) {
      return null;
    }
    return (
      <SurveyWrapper content={this.renderSurveyConsentText(survey)} survey={survey} />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withTranslation()(withRouter(connect(mapStateToProps, {})(SurveyConsent)));
