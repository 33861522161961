import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

import { CONFIG } from 'src/constants/config';

import styles from './referral-link.module.scss';

function highlight(id) {
  var range = document.createRange();
  range.selectNode(document.getElementById(id));
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
}

async function copyToClipboard(text) {
  try {
    if (navigator.clipboard) {
      // TODO fallback
      await navigator.clipboard.writeText(text);
      console.log('copy to clipboard');
      return true;
    } else {
      console.log('navigator.clipboard not found');
      return false;
    }
  } catch (e) {
    console.log('error trying to copy to clipboard');
    return false;
  }
}

export function ReferralLinkSmall() {
  const { t } = useTranslation();
  const referralUrl = `${CONFIG.APP_BASE_URL}/referral`;
  const [isCopied, setIsCopied] = useState(false);
  const onClick = async () => {
    if (await copyToClipboard(referralUrl)) {
      setIsCopied(true);
    }
  };
  return navigator.clipboard ? (
    // Just a button to copy the link
    <Button className="primary" variant="primary" onClick={onClick}>
      {isCopied ? t('Link copied!') : t('Copy link')}
    </Button>
  ) : (
    // Show the link as a fallback
    <div
      id="referral-url"
      type="text"
      className={classNames(
        styles.referralLink,
        styles.referralLinkFallback,
        'bg-white'
      )}
      onClick={() => highlight('referral-url')}
    >
      {referralUrl}
    </div>
  );
}

export function ReferralLink(props) {
  const { withBorder } = props;
  // Show link and a button to copy it
  const { t } = useTranslation();
  const referralUrl = `${CONFIG.APP_BASE_URL}/referral`;
  const [isCopied, setIsCopied] = useState(false);
  const onClick = async () => {
    if (await copyToClipboard(referralUrl)) {
      setIsCopied(true);
    }
  };
  return (
    <div className={classNames(styles.copyButton, 'input-group')}>
      <div
        id="referral-url"
        type="text"
        className={classNames(
          styles.referralLink,
          withBorder ? styles.withBorder : undefined,
          'bg-white'
        )}
        onClick={() => highlight('referral-url')}
      >
        {referralUrl}
      </div>
      {navigator.clipboard ? (
        <div className="input-group-append">
          <button
            className="btn btn-primary btn-sm"
            type="button"
            onClick={onClick}
          >
            {isCopied ? t('Copied!') : t('Copy')}
          </button>
        </div>
      ) : null}
    </div>
  );
}
