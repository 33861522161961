import { API_URL } from '../constants/api';
import { utils_api } from '../utils/utils_api';

export const api_config = {
  get_config_terms(success, fail) {
    return utils_api.unauth_get(API_URL.CONFIG_TERM_GET, success, fail);
  },
  get_config_privacy(success, fail) {
    return utils_api.unauth_get(API_URL.CONFIG_PRIVACY_GET, success, fail);
  },
  get_config_js(file, success, fail) {
    return utils_api.js_get(file, success, fail);
  },
};
