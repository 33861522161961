import {
  APP_BASE_URL,
  APP_NAME,
  PARTNER_NAME,
  PARTNER_CONTACT,
  CONSENT_LANGUAGE,
  CONSENT_VIDEO_REQ_LENGTH,
  LANGUAGES,
  DEFAULT_LANGUAGE,
  ENROLLMENT_BANNER_ENABLED,
  ENROLLMENT_BANNER_EXIT_ENABLED,
  debugLanguage,
  COBRANDING_LOGO_ENABLED,
  COBRANDING_URL,
  COBRANDING_TEXT,
  MAGIC_LINK_ENABLED,
  MAGIC_LINK_TOKEN_EXPIRY_MIN,
  REFERRAL,
  REFERRAL_ENROLLMENT_BANNER,
  REFERRAL_DASHBOARD_BANNER,
  REFERRAL_SURVEY_BANNER,
  REFERRAL_QUICK_POLL_BANNER,
  REFERRAL_LANDING_PAGE_BANNER,
  REFERRAL_SOCIAL_MEDIA,
  REFERRAL_SOCIAL_MEDIA_LINKEDIN,
  REFERRAL_SOCIAL_MEDIA_TWITTER,
  REFERRAL_SOCIAL_MEDIA_FACEBOOK,
  REFERRAL_SOCIAL_MEDIA_INSTAGRAM,
  REFERRAL_SOCIAL_MEDIA_WHATSAPP,
  REFERRAL_EMAIL_SMS,
  FF_WEBPAGE_FAQ,
  FF_WEBPAGE_CONTACT,
  FF_WEBPAGE_SUPPORT,
  FF_WEBPAGE_RECRUITMENT,
  FF_WEBPAGE_DIVERSITY,
  FF_WEBPAGE_FUNDING,
  FF_WEBPAGE_BLOG,
  FF_WEBPAGE_NEWS,
  FF_WEBPAGE_VOLUNTEER,
  FF_WEBPAGE_WITHDRAWAL,
  FF_WEBPAGE_PRIVACY_POLICY,
  FF_WEBPAGE_DATA_PROTECTION,
  FF_WEBPAGE_GENETICS_101,
  FF_WEBPAGE_STUDY_PARTNERSHIPS,
  FF_WEBPAGE_STUDY_TEAM,
  FF_WEBPAGE_RESEARCH_CONSENT,
  FF_WEBPAGE_COOKIE_POLICY,
  FF_WEBPAGE_CORE_VALUES,
  FF_WEBPAGE_TOS,
  FF_WEBPAGE_BANNER,
  ANCESTRY_USER_AGREE,
} from './api_config';

const supportedLanguages = LANGUAGES ? LANGUAGES.split(',') : ['en', 'es'];

export const CONFIG = {
  APP_BASE_URL: APP_BASE_URL,
  APP_NAME: APP_NAME || 'Zygene',
  PARTNER_NAME: PARTNER_NAME || '',
  PARTNER_CONTACT: PARTNER_CONTACT || '',
  CONSENT_LANGUAGE: CONSENT_LANGUAGE || 'en',
  CONSENT_VIDEO_REQ_LENGTH: CONSENT_VIDEO_REQ_LENGTH || 3,
  LANGUAGES: supportedLanguages,
  DEFAULT_LANGUAGE: DEFAULT_LANGUAGE || 'en',
  ENROLLMENT_BANNER_ENABLED: ENROLLMENT_BANNER_ENABLED !== false,
  ENROLLMENT_BANNER_EXIT_ENABLED: ENROLLMENT_BANNER_EXIT_ENABLED !== false,
  COBRANDING_LOGO_ENABLED: COBRANDING_LOGO_ENABLED !== false,
  COBRANDING_URL: COBRANDING_URL !== 'none' ? COBRANDING_URL : '',
  COBRANDING_TEXT: COBRANDING_TEXT !== 'none' ? COBRANDING_TEXT : '',
  MAGIC_LINK_ENABLED,
  MAGIC_LINK_TOKEN_EXPIRY_MIN,
  REFERRAL,
  REFERRAL_ENROLLMENT_BANNER,
  REFERRAL_DASHBOARD_BANNER,
  REFERRAL_SURVEY_BANNER,
  REFERRAL_QUICK_POLL_BANNER,
  REFERRAL_LANDING_PAGE_BANNER,
  REFERRAL_SOCIAL_MEDIA,
  REFERRAL_SOCIAL_MEDIA_LINKEDIN,
  REFERRAL_SOCIAL_MEDIA_TWITTER,
  REFERRAL_SOCIAL_MEDIA_FACEBOOK,
  REFERRAL_SOCIAL_MEDIA_INSTAGRAM,
  REFERRAL_SOCIAL_MEDIA_WHATSAPP,
  REFERRAL_EMAIL_SMS,
  FF_WEBPAGE_FAQ,
  FF_WEBPAGE_CONTACT,
  FF_WEBPAGE_SUPPORT,
  FF_WEBPAGE_RECRUITMENT,
  FF_WEBPAGE_DIVERSITY,
  FF_WEBPAGE_FUNDING,
  FF_WEBPAGE_BLOG,
  FF_WEBPAGE_NEWS,
  FF_WEBPAGE_VOLUNTEER,
  FF_WEBPAGE_WITHDRAWAL,
  FF_WEBPAGE_PRIVACY_POLICY,
  FF_WEBPAGE_DATA_PROTECTION,
  FF_WEBPAGE_GENETICS_101,
  FF_WEBPAGE_STUDY_PARTNERSHIPS,
  FF_WEBPAGE_STUDY_TEAM,
  FF_WEBPAGE_RESEARCH_CONSENT,
  FF_WEBPAGE_COOKIE_POLICY,
  FF_WEBPAGE_CORE_VALUES,
  FF_WEBPAGE_TOS,
  FF_WEBPAGE_BANNER,  
  ANCESTRY_USER_AGREE,
};

export const hasLanguageSwitcher = CONFIG.LANGUAGES.length > 1 || debugLanguage;
