import { Modifier, ModifierArguments } from '@popperjs/core';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ListGroup, Overlay, Spinner } from 'react-bootstrap';
import usePrevious from 'src/utils/usePrevious';
import classnames from 'classnames/bind';
import styles from './AutoComplete.module.scss';

const cx = classnames.bind(styles);

export type CandidateEntry = {
  key: string,
  text: string,
  value: any,
}

// These are the props directly used when returning the component: <AutoCompleteComponent prop1={} prop2={} />
export type AutoCompleteComponentComponentProps = {
  target: React.RefObject<HTMLElement>,
  selectedResultKey?: string,
  query?: string,
  onQuery: (query: string) => Promise<CandidateEntry[]>,
  onResultSelected: (result: CandidateEntry) => void
}

export type AutoCompleteComponentProps = AutoCompleteComponentComponentProps;

function adjustAutoCompleteResults({ state }: ModifierArguments<any>): void {
  state.styles.popper.width = `${state.rects.reference.width}px`;
}

const sameWidthModifier: Modifier<any, any> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: adjustAutoCompleteResults,
};

const popperConfig = {
  modifiers: [
    sameWidthModifier,
  ],
};

function queryIsInCandidates(candidates: CandidateEntry[], candidateKey?: string): boolean {
  return candidateKey !== undefined && candidates.some(({ key }) => key === candidateKey);
}

function AutoCompleteComponent({
  selectedResultKey, target, query, onQuery, onResultSelected,
}: AutoCompleteComponentProps): React.ReactElement {
  const [isQuerying, setIsQuerying] = useState<boolean>(false);
  const [candidates, setCandidates] = useState<CandidateEntry[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [firstTimeRender, setFirsTimeRender] = useState<boolean>(true);
  const [highlightedOption, setHighlightedOption] = useState<number>();

  const previousState = usePrevious({ query });

  const debouncedOnQuery = debounce(
    (q: string) => onQuery(q)
      .then(setCandidates)
      .finally(() => setIsQuerying(false)),
    1000,
    { leading: false },
  );

  // Avoid performing search at the first render, no matter the value of query.
  useEffect(() => {
    if (firstTimeRender) {
      setFirsTimeRender(false);
    }
  }, [firstTimeRender]);

  // Show the result list when the query changes.
  useEffect(() => {
    const queryHasChanged = query !== previousState?.query;
    const isQueryNonEmpty = query !== undefined && query.length > 0;

    if (!show
      && !firstTimeRender
      && queryHasChanged
      && isQueryNonEmpty
      && !queryIsInCandidates(candidates, selectedResultKey)
    ) {
      setShow(true);
    }
  }, [show, setShow, query, previousState, candidates, firstTimeRender]);

  // Perform the search.
  useEffect(() => {
    if (firstTimeRender) {
      return;
    }
    const queryHasChanged = query !== previousState?.query;
    const isQueryNonEmpty = query !== undefined && query.length > 0;

    if (queryHasChanged && isQueryNonEmpty && !isQuerying && !queryIsInCandidates(candidates, selectedResultKey)) {
      setIsQuerying(true);
      setCandidates([]);
      debouncedOnQuery(query || '');
    }
  }, [
    query,
    isQuerying,
    setIsQuerying,
    setCandidates,
    previousState,
    candidates,
    firstTimeRender,
  ]);

  const handleTargetLostFocus = () => setShow(false);
  const handleKeyPressed = (e: KeyboardEvent) => {
    const { key } = e;
    if (key === 'Escape') {
      setShow(false);
      return;
    }
    if (candidates.length === 0 || !show) {
      return;
    }
    if (key === 'ArrowDown') {
      setHighlightedOption(
        highlightedOption === undefined ? 0 : Math.min(highlightedOption + 1, candidates.length - 1),
      );
      e.preventDefault();
    }
    if (key === 'ArrowUp') {
      setHighlightedOption(Math.max((highlightedOption || 0) - 1, 0));
      e.preventDefault();
    }
    if (key === 'Enter' && highlightedOption !== undefined) {
      const selectedItem = candidates[highlightedOption];
      if (selectedItem !== undefined) {
        handleItemClicked(selectedItem);
      }
    }
  };

  // Close results when target loses focus or escape is pressed.
  useEffect(() => {
    target?.current?.addEventListener('keydown', handleKeyPressed);
    target?.current?.addEventListener('blur', handleTargetLostFocus);
    return () => {
      target?.current?.removeEventListener('keydown', handleKeyPressed);
      target?.current?.removeEventListener('blur', handleTargetLostFocus);
    };
  }, [target, candidates, highlightedOption, show]);

  const handleItemClicked = (result: CandidateEntry) => {
    onResultSelected(result);
    setShow(false);
    setHighlightedOption(undefined);
    target?.current?.focus();
  };

  return (
    <>
      <Overlay target={target.current} show={show} placement="bottom-start" popperConfig={popperConfig}>
        {({
          placement, arrowProps, show: _show, popper, ...props
        }) => (
          <ListGroup {...props}>
            {!isQuerying && candidates.map((candidate, index) => (
              <ListGroup.Item
                key={candidate.text}
                action
                className={cx('rounded-0', { [styles.highlighted]: index === highlightedOption })}
                onClick={() => handleItemClicked(candidate)}
              >
                {candidate.text}
              </ListGroup.Item>
            ))}
            {!isQuerying && candidates.length === 0 && <ListGroup.Item className="text-muted">No Results</ListGroup.Item>}
            {isQuerying && (
            <ListGroup.Item>
              <Spinner animation="border" variant="primary" />
            </ListGroup.Item>
            )}
          </ListGroup>
        )}
      </Overlay>
    </>
  );
}

export default AutoCompleteComponent;
