import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

import PAGES from 'src/constants/pages';
import { UNEXPECTED_ERROR } from '../../constants/errors';
import { login_get } from '../../actions/loginAction';
import { actioncards_firstcall_get } from '../../actions/actioncardsAction';
import { consent_get, consent_post, consent_file_get } from '../../actions/consentAction';
import { post_user_action_post } from '../../actions/userAction';
import { utils } from '../../utils/utils_general';
import { LOCAL_STORAGE } from '../../constants/localStorage';
import { URL_ROOT_API } from '../../constants/api';
import { CONFIG } from '../../constants/config';

import { USER_ACTION } from '../../constants/userAction';
import { LINKS } from '../../constants/links';

import ConsentSignature from './consent_signature';
import HtmlContentComponent from 'src/components/core/HtmlContent/HtmlContentComponent';
import { withTranslation } from 'react-i18next';


class Consent extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      scrollerReached: false,
      canSubmit: false,
      consent: null,
      bodyListener: false,
      consentScrollListener: false,
      showConfirmation: false,
      showDownload: false,
      // this is the sig after user hits done
      doneSig: null,
      loader: false,
      firstTime: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.getConsent();
    const isFirstTime = utils.get_local_storage(LOCAL_STORAGE.FIRST_TIME_REG);
    if (isFirstTime) {
      this.setState({ firstTime: true });
    }
  }

  componentDidUpdate() {
    const consentScroll = document.querySelector('.consent-scroll');
    if (consentScroll && !this.state.consentScrollListener) {
      consentScroll.addEventListener('scroll', this.handleScroll);
      this.setState({ consentScrollListener: true });
    }

    const body = document.querySelector('body');
    if (body && !this.state.bodyListener) {
      body.addEventListener('scroll', this.handleScroll);
      this.setState({ bodyListener: true });
    }
  }

  componentWillUnmount() {
    const consentScroll = document.querySelector('.consent-scroll');
    if (consentScroll) {
      consentScroll.removeEventListener('scroll', this.handleScroll);
    }

    const body = document.querySelector('body');
    if (body) {
      body.removeEventListener('scroll', this.handleScroll);
    }
  }

  checkScrollReached(obj) {
    return obj.scrollTop >= (obj.scrollHeight - obj.offsetHeight) - 40;
  }

  handleScroll(e) {
    const obj = e.target;
    if (this.checkScrollReached(obj)) {
      this.setState({ scrollReached: true });
      if (this.state.doneSig) {
        this.setState({ canSubmit: true });
      }
    }
  }

  initActionCard() {
    return this.props.actioncards_firstcall_get()
      .then(() => this.setState({ showDownload: true }))
      .catch((error) => this.setState({ error: { system: error } }));
  }

  getConsent() {
    return this.props.consent_get()
      .then((response) => {
        if (response.consent_signed) {
          this.checkUserLogin();
        }
        this.setState({ consent: response });
      })
      .catch((error) => this.setState({ error: { system: error } }));
  }

  checkUserLogin() {
    return this.props.login_get()
      .then((response) => {
        if (response.esign === true) {
          this.setState({ errors: {} });
          this.props.history.push(PAGES.DASHBOARD);
        }
      })
      .catch((error) => {
        if (error.response) {
          return this.props.history.push(PAGES.LOGIN);
        }
        return this.setState({ errors: { system: UNEXPECTED_ERROR } });
      });
  }

  handleSubmit(e) {
    if (!this.state.doneSig) {
      this.setState({ errors: { sig: this.props.t('Please provide your signature.') } });
    } else {
      this.setState({ showConfirmation: true });
    }
  }

  confirmSubmit() {
    this.setState({ loader: true, showConfirmation: false });
    const fail = () => {
      this.initActionCard();
      this.setState({ errors: { system: UNEXPECTED_ERROR } });
    };
    const success = (resp) => this.initActionCard();
    this.props.consent_post(this.state.consent.consent_v, this.state.doneSig)
      .then((response) => success(response))
      .catch((error) => fail(error));
  }

  onConsentComplete() {
    this.closeModal();
    if (this.props.onComplete) {
      this.props.onComplete();
    } else {
      this.props.history.push(PAGES.DASHBOARD);
    }
  }

  skipDownload() {
    this.onConsentComplete();
  }

  closeModal() {
    this.setState({ showConfirmation: false, showDownload: false });
  }

  onCloseClick() {
    this.closeModal();
  }

  onSigModalComplete(signature) {
    if (signature) {
      const body = document.querySelector('body');
      const consentScroll = document.querySelector('.consent-scroll');
      this.setState({ doneSig: signature });
      if (this.state.scrollerReached || (this.checkScrollReached(consentScroll) && this.checkScrollReached(body))) {
        this.setState({ canSubmit: true });
      }
    } else {
      this.setState({ canSubmit: false, doneSig: false });
    }
    this.closeModal();
  }

  onDownload() {
    this.props.consent_file_get()
      .then((res) => {
        window.location.assign(`${URL_ROOT_API}/download?token=${res}`);
        this.updateUserAction();
      })
      .catch((error) => this.setState({ error: { system: error } }));
  }

  updateUserAction() {
    return this.props.post_user_action_post(USER_ACTION.CONSENT_DOWNLOAD, true)
      .then((response) => {
        this.onConsentComplete();
      })
      .catch((error) => {
        // show message and go to dash?
        this.setState({ error: { system: JSON.stringify(error) } });
        setTimeout(() => {
          this.onConsentComplete();
        }, 3000);
      });
  }

  renderConfirmation() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1 className="h4">{this.props.t('Confirming Consent')}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3 font-weight-bold">{this.props.t('By clicking the button below, you acknowledge your understanding of the informed consent presented to you for participation in this research program.')}</p>
          <p className="mb-3 font-weight-bold">{this.props.t('You have the right to remove your consent at any time in your account profile.')}</p>
          <p>
            {' '}
            {this.props.t('If you have any questions please')}
            <a href={LINKS.SUPPORT} target="_blank" rel="noopener noreferrer">{this.props.t('contact support')}</a>
            {' '}
            {this.props.t('or the')}
            {CONFIG.PARTNER_NAME}
            {' '}
            {this.props.t('contact number at')}
            {CONFIG.PARTNER_CONTACT}
          </p>
          <div className="text-center mt-3"><Button variant="primary" className="btn-sm" onClick={() => this.confirmSubmit()}>{this.props.t('I confirm my consent and understanding')}</Button></div>
          <div className="mt-2 text-center"><Button variant="link" className="btn-link-callout p-0 m-0" onClick={() => this.closeModal()}>{this.props.t('Cancel')}</Button></div>
        </Modal.Body>
      </>
    );
  }

  renderDownload() {
    return (
      <>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1 className="h4">{this.props.t('Download Consent')}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3 font-weight-bold">{this.props.t('We recommend downloading your consent so you have access to your records and rights in case you need them later.')}</p>
          <div className="text-center mt-4"><Button variant="primary" className="btn-sm" onClick={() => this.onDownload()}>{this.props.t('Download')}</Button></div>
          <div className="mt-2 text-center"><Button variant="link" className="btn-link-callout p-0 m-0" onClick={() => this.skipDownload()}>{this.props.t('Skip')}</Button></div>
        </Modal.Body>
      </>
    );
  }

  renderModal() {
    return (
      <Modal
        show={this.state.showConfirmation || this.state.showDownload}
        onHide={() => this.onCloseClick()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`consent-modal ${utils.get_browser()}`}
        backdrop="static"
      >
        {this.state.showConfirmation ? this.renderConfirmation() : null}
        {this.state.showDownload ? this.renderDownload() : null}

      </Modal>
    );
  }

  renderConsent() {
    if (!this.state.consent) {
      // this is so ghetto... because the consent mobile page is so different than the desktop,
      // theres a rando blue gap at the bottom... this is to cover that
      return null;
    }
    const consentText = this.state.consent.consent_text; // .replace('<!DOCTYPE html><html><head></head><body>', '').replace('</body></html>', '')

    const enableButton = this.state.canSubmit && !this.state.loader;
    return (
      <>
        <h1>{this.props.t('Consent')}</h1>
        <div className="consent-scroll">
          <HtmlContentComponent className="consent-content mb-3" markup={consentText} />

          <div className="consent-footer">
            <ConsentSignature onSignatureComplete={(sig) => this.onSigModalComplete(sig)} />
            <Button variant={enableButton ? 'primary' : 'disable'} className={`btn-sm ${enableButton ? 'primary' : 'disable'}`} type="submit" onClick={(e) => (enableButton ? this.handleSubmit(e) : null)}>
              {this.props.t('I agree')}
            </Button>

            <div className="text-danger form-error text-center mt-2">
              <p style={{ fontSize: '11px' }}>
                {' '}
                {this.state.errors.system || this.state.errors.sig || <br />}
                {' '}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderConsent()}
        {this.renderModal()}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withRouter(connect(mapStateToProps, {
  login_get, consent_get, consent_post, actioncards_firstcall_get, post_user_action_post, consent_file_get,
})(withTranslation()(Consent)));
