import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
/* eslint-disable camelcase */
import { update_registration_flow } from 'src/actions/registrationFlowAction';
import { login_get } from 'src/actions/loginAction';
import Consent from 'src/components/consent/consent';

import { ConsentFlowStep, GenericWorkflow } from '../types';
import { useGetConsentsArray } from './Hooks';
import BlankComponent from 'src/components/core/Blank/BlankComponent';

/* eslint-enable camelcase */

export interface SignupConsent2Props extends PropsFromRedux {
  mandatoryConsentFlow?: ConsentFlowStep[];
  defaultFlow: GenericWorkflow;
  /* eslint-disable camelcase, no-unused-vars */
  updateFlow: (attr: ConsentFlowStep[])=>void;
  /* eslint-enable camelcase, no-unused-vars */
  onConsentCompleted?: ()=>void;
  exitSignupSubworkflow?: ()=>void;
}

const SignupConsent2 = function ({
  /* eslint-disable camelcase,no-shadow */
  mandatoryConsentFlow,
  defaultFlow,
  updateFlow,
  onConsentCompleted,
  exitSignupSubworkflow,
  login_get,
  /* eslint-enable camelcase,no-shadow */
}: SignupConsent2Props) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [currentConsentComponent, setCurrentConsentComponent] = useState<ConsentFlowStep | null>(null);
  const [consentCode, setConsentCode] = useState<string | null>(null);
  const [consentsToGo, setConsentsToGo] = useState<number | null>(null);
  const [isMandatoryConsentCompleted, setMandatoryConsentCompleted] = useState(false);

  const getConsentCode = async () => {
    const { consent_require: requiredConsent = [] } = await login_get();
    const [nextRequiredConsentCode] = requiredConsent;
    setConsentCode(nextRequiredConsentCode);
    setConsentsToGo(requiredConsent.length);
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      getConsentCode();
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  const consentComponentsArray = useGetConsentsArray(mandatoryConsentFlow, consentCode);

  const handleExitSignupSubworkflow = () => {
    if (typeof exitSignupSubworkflow === 'function') {
      setMandatoryConsentCompleted(true);
      exitSignupSubworkflow();
    }
  };

  const handleMandatoryConsentWorkflowCompleted = () => {
    setMandatoryConsentCompleted(true);
    if (typeof onConsentCompleted === 'function') {
      onConsentCompleted();
    }
  };

  const handleConsentFormComplete = () => {
    if (consentComponentsArray && consentsToGo !== null && consentsToGo > 1) {
      getConsentCode();
    } else {
      handleMandatoryConsentWorkflowCompleted();
    }
  };

  const onNextConsentStep = (step: ConsentFlowStep) => step;

  const applyFirstStep = () => {
    setIsLoading(true);
    const foundStep = consentComponentsArray.find(
      (consentComponent: ConsentFlowStep) => (!consentComponent.value && consentComponent.consent_code),
    );

    const firstStep: ConsentFlowStep | null = foundStep || null;
    if (!firstStep && !isEmpty(consentComponentsArray)) {
      handleExitSignupSubworkflow();
    }
    setCurrentConsentComponent(firstStep || null);
    setIsLoading(false);
  };

  useEffect(applyFirstStep, [consentComponentsArray, defaultFlow]);

  const renderContent = () => {
    if (isEmpty(consentComponentsArray) || isLoading) {
      return (<BlankComponent />)
    }

    if (!currentConsentComponent && !isMandatoryConsentCompleted) {
      return (<BlankComponent>{t('User is not eligible to consents please contact support.')}</BlankComponent>);
    }

    if(isMandatoryConsentCompleted){
      return (<BlankComponent />)
    }

    return (
      <Consent
        currentStep={currentConsentComponent}
        flow={[currentConsentComponent]}
        onComplete={() => handleConsentFormComplete()}
        onNextConsentStep={(step: ConsentFlowStep) => onNextConsentStep(step)}
        updateFlow={updateFlow}
        consentCode={consentCode}
      />
    );
  };

  const consentKey = `${currentConsentComponent?.id}_${consentCode}`;

  return (
    <section className="signup-component consent">
      <div className="signup-body-wrapper" key={consentKey}>
        {renderContent()}
      </div>
    </section>
  );
};

const mapDispatchToprops = {
  update_registration_flow,
  login_get,
};

const connector = connect(null, mapDispatchToprops);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SignupConsent2);
