import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { utils } from 'src/utils/utils_general';

import { login_post } from 'src/actions/loginAction';
import { otp_get_email, otp_post } from 'src/actions/otpAction';
import { reset_registration_flow } from 'src/actions/registrationFlowAction';

import { post_user_attributes_post } from 'src/actions/userAction';

import { RESPONSE_CODE, SIGNUP_OTP_ERROR_DISPLAY } from 'src/constants/errors';
import { LINKS } from 'src/constants/links';
import { LOCAL_STORAGE } from 'src/constants/localStorage';

import WorkflowNextButton from 'src/components/workflow/WorkflowNextButton';
import OTPInput from 'src/components/core/otp-input';
import ConfirmEmailError from './confirm_email_error';
import { FEATURE } from 'src/constants/features';
import { ATTRIBUTES } from 'src/constants/attributes';
import HtmlContentComponent from 'src/components/core/HtmlContent/HtmlContentComponent';

class ConfirmEmailToken extends React.Component {
    _isMounted = false;

    constructor() {
      super();
      this.state = {
        errors: {},
        token: ['', '', '', '', '', ''],
        otp_id: null,
        resentToken: false,
      };
    }

    componentDidMount() {
      if (!this.props.email && !this.props.registrationFlow) {
        window.location = '/';
      }
      if (!utils.get_local_storage(LOCAL_STORAGE.TOKEN_SENT)) {
        const onSuccess = () => utils.set_local_storage(LOCAL_STORAGE.TOKEN_SENT, true);
        this.sendToken(onSuccess);
      }
    }

    sendToken(onSuccess) {
      this.props.otp_get_email()
        .then((resp) => {
          this.setState({ otp_id: resp.otp_id });
          utils.set_local_storage(LOCAL_STORAGE.OTP_ID, resp.otp_id);
          onSuccess();
        })
        .catch((error) => {
          if (error.response && error.response.status === RESPONSE_CODE['490_max_reached']) {
            this.setState({ errors: { system: SIGNUP_OTP_ERROR_DISPLAY.MAX_REQUEST_REACHED } });
            utils.set_local_storage(LOCAL_STORAGE.MAX_REQUEST, true);
          } else {
            this.handleError();
          }
        });
    }

    resendToken() {
      const onSuccess = () => this.setState({ resentToken: true });
      this.sendToken(onSuccess);
    }

    handleError() {
      this.setState({ errors: { token: SIGNUP_OTP_ERROR_DISPLAY.GENERIC } });
      this.props.reset_registration_flow();
    }

    handleSkipSubmit() {
      utils.set_local_storage(LOCAL_STORAGE.TOKEN_SENT, true);
      utils.set_local_storage(LOCAL_STORAGE.SKIP_VERIFY, true);

      this.props.post_user_attributes_post(ATTRIBUTES.EMAIL_VERIFICATION_SKIPPED, true)
      .then(() => {
        this.props.post_user_attributes_post(ATTRIBUTES.EMAIL_CONFIRMED, false)
        .then(() => {
          this.props.onComplete(this.props.email);
        })
        .catch(() => this.handleError());
      })
      .catch(() => this.handleError());
    }

    handleSubmit(e) {
      e.preventDefault();
      this.setState({ resentToken: false });

      const token = this.state.token.join('');
      if (token.length !== 6) {
        this.setState({ errors: { token: SIGNUP_OTP_ERROR_DISPLAY.INVALID } });
      }

      const data = {
        token: this.state.token.join(''),
        otp_id: this.state.otp_id || utils.get_local_storage(LOCAL_STORAGE.OTP_ID),
        otp_type: 'emailverf',
      };

      this.props.otp_post(data)
        .then((resp) => {
          utils.remove_local_storage(LOCAL_STORAGE.TOKEN_SENT);
          utils.remove_local_storage(LOCAL_STORAGE.OTP_ID);
          if(utils.get_local_storage(LOCAL_STORAGE.SKIP_VERIFY)) utils.remove_local_storage(LOCAL_STORAGE.SKIP_VERIFY);
          this.props.onComplete(this.props.email);
        })
        .catch((error) => {
          if (error.response && error.response.status === RESPONSE_CODE['405_data_invalid']) {
            if (error.response.data.msg === 'incorrect otp code') {
              this.setState({ errors: { token: SIGNUP_OTP_ERROR_DISPLAY.INVALID } });
            } else if (error.response.data.msg === 'no active otp') {
              this.setState({ errors: { token: SIGNUP_OTP_ERROR_DISPLAY.NO_ACTIVE_OTP } });
            } else if (error.response.data.msg === 'Too late to respond ') {
              this.setState({ errors: { token: SIGNUP_OTP_ERROR_DISPLAY.VERIFICATION_EXPIRED } });
            } else {
              this.handleError();
            }
          } else if (error.response && error.response.status === RESPONSE_CODE['490_max_reached']) {
            this.setState({ errors: { token: SIGNUP_OTP_ERROR_DISPLAY.MAX_REACHED } });
          } else {
            this.handleError();
          }
        });
    }

    handleChange(value) {
      if (this.state.errors.system === SIGNUP_OTP_ERROR_DISPLAY.MAX_REQUEST_REACHED) {
        return null;
      }
      this.setState({ token: value, errors: {}, resentToken: false });
    }

    handlePaste(value) {
      this.setState({ token: String(value).split(''), errors: {}, resentToken: false });
    }

    renderForm(noErrors, supportLink) {
      const { t } = this.props;
      const showSupportLink = this.state.errors.token === SIGNUP_OTP_ERROR_DISPLAY.GENERIC || this.state.errors.system === SIGNUP_OTP_ERROR_DISPLAY.MAX_REQUEST_REACHED;
      return (
        <>
          <h1>{t('Verify Email')}</h1>
          <div className="h2 mt-5 mb-3 text-break" style={{ fontSize: '18px', fontWeight: 'normal', lineHeight: '24px' }}>
            {t('A 6-digit verification code has been sent to {{email}}', { email: this.props.email ? this.props.email : 'you' })}
          </div>

          <Form noValidate>
            <OTPInput
              handlePaste={(value) => this.handlePaste(value)}
              handleChange={(value) => this.handleChange(value)}
              token={this.state.token}
              inputType="number"
            />
          </Form>
          <Form.Text className="my-2 text-right mobile-text-center text-danger form-error">
            {this.state.errors.system || this.state.errors.token || <br />}
            {showSupportLink ? supportLink : null}
          </Form.Text>
          {this.renderResentCode()}

          <br />
          <br />

          <div>
            <div className="signup-body text-right">
              <div className="signup-body-buttons">
                { this.props.featureFlag && this.props.featureFlag[FEATURE.EMAIL_VERIFICATION_SKIP]
                && !this.props.loginUser?.email_verification_skipped
                ? <WorkflowNextButton variant="link" size={"sm"}  className="inlineText pl-0 pr-0 mr-4" type="submit" 
                    onClick={() => (this.handleSkipSubmit())}>
                    {t('skip for now')}
                  </WorkflowNextButton> : null }
                <WorkflowNextButton disabled={!noErrors} size={"sm"} type="submit" onClick={(e) => (noErrors ? this.handleSubmit(e) : e.preventDefault())}>
                  {t('Continue')}
                </WorkflowNextButton>
              </div>
            </div>
          </div>
          
        </>
      );
    }

    renderResentCode() {
      return (
        <div className="mt-2 text-right mobile-text-center">
          {
                this.state.resentToken
                  ? <span>{this.props.t('Verification code sent.')}</span>
                  : <Button variant="link" onClick={(e) => this.resendToken(e)}>{this.props.t('Re-Send Code')}</Button>
                }
        </div>
      );
    }

    render() {

      const noErrors = utils.is_obj_empty(this.state.errors) && this.state.token.join('').length === 6;
      const supportLink = (
        <span>
          <HtmlContentComponent tag={"div"} markup={this.props.t('pleaseContactSupport',{link: LINKS.SUPPORT})}/>
        </span>
      );

      const maxRequestError = this.state.errors.system === SIGNUP_OTP_ERROR_DISPLAY.MAX_REQUEST_REACHED || utils.get_local_storage(LOCAL_STORAGE.MAX_REQUEST);
      return (
        maxRequestError
          ? <ConfirmEmailError error="max request" />
          : this.renderForm(noErrors, supportLink)
      );
    }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, {
  login_post, otp_get_email, otp_post, reset_registration_flow,post_user_attributes_post,
})(withTranslation()(ConfirmEmailToken)));
