import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NotFoundPageImage from 'src/assets/images/not-found-page.svg';
import PAGES from 'src/constants/pages';

const PageNotFound = ({ user }) => {
  const homeRoute = user ? PAGES.DASHBOARD : '/';
  const { t } = useTranslation();
  return (
    <section className="not-found-page">
      <div className="image">
        <img className="not-found hidden-mobile" src={NotFoundPageImage}/>
      </div>
      <div className="text">
        <h1>{t('Oops!')}</h1>
        <h2>{t('It seems you have gotten lost...')}</h2>
        <p className="hidden-mobile">{t('The link you clicked may have been broken or the page may have been removed.')}<br /><br />{t('Don’t worry, maybe you can find what you need in our homepage.')}</p>
        <img className="not-found-mobile hidden-desktop" src={NotFoundPageImage}/>
        <div className="buttons">
          <Link to={homeRoute} className="btn btn-primary">{t('Go back Home')}</Link>
          <br className="hidden-desktop"/>
          <Link to="/support" className="btn btn-link">{t('Support Site')}</Link>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
