import { InnerWorkflowStepProps } from "src/services/types/WorkflowTypes";
import OrderDnaKitLayout from "./OrderDnaKitLayout";
import { ReactComponent as Character3 } from 'src/assets/images/unauth-character-3.svg';
import OrderDisplay from "./OrderDisplay";
import InnerWorkflowActions from "../../InnerWorkflow/InnerWorkflowActions";
import { OrderDnaKitInterface } from "src/services/types/validations/OrderDnaKit";
import { createStructuredSelector } from "reselect";
import { selectDnaKitCreatedOrder } from "src/store/DnaKit/DnaKitSelectors";
import { connect } from "react-redux";
import { useCurrentUser } from "src/services/UserHooks";
import { DnaKitOrderData } from "src/services/types/DnaKit";
import { useTranslation } from "react-i18next";

type OrderDnaKitReceiptStateProps = {
  dnaKitCreatedOrder: DnaKitOrderData,
}

export type OrderDnaKitReceiptComponentProps = {
  title?: string,
}

export type OrderDnaKitReceiptProps = OrderDnaKitReceiptComponentProps & InnerWorkflowStepProps & OrderDnaKitReceiptStateProps

function OrderDnaKitReceipt({ title, dnaKitCreatedOrder, onStepComplete }: OrderDnaKitReceiptProps) {
  const { t } = useTranslation();
  const { email } = useCurrentUser() || {};

  const dnaKitOrderPayload: OrderDnaKitInterface = {
    addressLine1: dnaKitCreatedOrder.address_line1,
    addressLine1Validated: dnaKitCreatedOrder.google_verified,
    addressLine2: dnaKitCreatedOrder.address_line2,
    city: dnaKitCreatedOrder.city,
    phone: dnaKitCreatedOrder.order_phone,
    state: dnaKitCreatedOrder.state,
    zip: dnaKitCreatedOrder.zip,
  };

  return (
    <>
      <OrderDnaKitLayout
        title={<span className="text-success">{t(title || "Order Successfully Placed")}</span>}
        aside={<div className="text-right">
          <Character3 />
        </div>}>
        <p>
          {t("An email confirmation has been sent to")} <strong className="text-nowrap">{email}</strong>
        </p>
        <p>
          <strong>{t("Order #")}:</strong> {dnaKitCreatedOrder.order_id}
        </p>

        <OrderDisplay dnaKitOrderInfo={dnaKitOrderPayload} />

      </OrderDnaKitLayout>
      <InnerWorkflowActions canContinue onStepComplete={onStepComplete} />
    </>
  )
}

const mapStateToProps = createStructuredSelector<any, OrderDnaKitReceiptStateProps>({
  dnaKitCreatedOrder: selectDnaKitCreatedOrder,
});

export default connect(mapStateToProps, {})(OrderDnaKitReceipt);