import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

import styles from './signup-banner.module.scss';
import PAGES from 'src/constants/pages';
import { utils_user } from 'src/utils/utils_user';
import { connect } from 'react-redux';

function SignUpBanner(props) {
  const { onRequestContinue, loginUser } = props;
  const { t } = useTranslation();
  const logged = utils_user.is_logged(loginUser);
  return (
    <div
      className={classNames(
        styles.referralBanner,
        'rounded mb-4 position-relative overflow-hidden'
      )}
    >
      <div className="p-4 position-relative">
        <h3 className={classNames(styles.header, 'mb-3')}>
          {t('Help advance the pace of discovery, together we can make a difference.')}
        </h3>
        <p className={styles.message}>
          {t(
            'Description about the study here and stuff'
          )}
        </p>
        {!logged && (<Button
          className="mt-2 mr-2 mb-2"
          variant="primary"
          href={PAGES.SIGNUP}
        >
          {t('Sign Up')}
        </Button>)}
        <Button
          className="mt-2 mr-2 mb-2 primary-invert"
          variant="primary"
          href={PAGES.ABOUT_US}
        >
          {t('Know More')}
        </Button>

      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps, {})(SignUpBanner);
