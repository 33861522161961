import React, { useContext } from 'react';
import { Container, interfaces } from 'inversify';

const ContextWrapper = React.createContext<{ container: Container | null }>({ container: null });

export const ContextProvider = ContextWrapper;

type Props = {
  container: Container;
  children: any[];
};

export const ServiceProvider: React.FC<Props> = ({ children, container }: Props) => (
  <ContextWrapper.Provider value={{ container }}>
    {children}
  </ContextWrapper.Provider>
);

export function useInjection<T>(identifier: interfaces.ServiceIdentifier<T>): T {
  const { container } = useContext(ContextWrapper);
  if (!container) { throw new Error("Container is not accessible or hasn't been provided."); }
  return container.get<T>(identifier);
}


export function withService<T>(serviceList: string[]) {
  return (Component: React.ComponentType<T>) =>
    (props: T) => (
      <ContextProvider.Consumer>
        {({ container }) => {
          const get = (container as Container).get.bind(container);
          const resolvedServices = (serviceList || [])
            .reduce(
              (services, serviceName) => ({ ...services, [serviceName]: get(serviceName) }),
              {}
            )
          return (<Component {...props} {...resolvedServices} container={container} />)
        }}
      </ContextProvider.Consumer>
    );
}

let containerForLegacyCode: Container | null = null;

export function clearContainerForLegacyCode(){
  containerForLegacyCode = null;
}

export function setContainerForLegacyCode(container: Container){
  containerForLegacyCode = container;
}

export function isContainerForLegacyCodeInitialized() {
  return containerForLegacyCode !== null;
}

export function legacyGetService<T>(identifier: interfaces.ServiceIdentifier<T>): T {
  if(containerForLegacyCode === null){
    throw new Error(`Error getting ${identifier.toString()}. Container is not yet initialized.`);
  }

  return containerForLegacyCode.get(identifier);
}