/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'react-bootstrap';
import { withTranslation, Trans } from 'react-i18next';
import { utils_validation } from 'src/utils/utils_validation';
import { update_self } from 'src/utils/validationrules_api_app';
import {
  UPDATE_SELF_ERROR_RESPONSES, UNEXPECTED_ERROR, UPDATE_SELF_ERROR_DISPLAY, OTP_ERROR_DISPLAY, RESPONSE_CODE,
} from 'src/constants/errors';
import { otp_get_phone, otp_post } from 'src/actions/otpAction';
import { utils } from 'src/utils/utils_general';
import { update_user_put, post_mfa_attribute_post } from 'src/actions/userAction';
import { login_get } from 'src/actions/loginAction';
import { EDIT_PERSONAL_MODAL } from 'src/constants/editPersonalModal';
import Spinner from 'src/components/global/spinner';
import { DEEPLINKS } from 'src/constants/deeplinks';
import PhoneNumberComponent from 'src/components/core/PhoneNumber/PhoneNumberComponent';
import OTPInput from 'src/components/core/otp-input';

const emptyToken = ['', '', '', '', '', ''];

class TwoFactorAuthentication extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      user: null,
      show: false,
      successPage: false,
      confirmPage: false,
      limitReachedPage: false,
      twofaMobile: '',
      token: emptyToken,
      otpId: '',
      callMe: true,
      textMe: false,
      type: '',
      loading: false,
    };
    this.phoneField = React.createRef();
    this.otpField = React.createRef();
    this.confirmCodeButton = React.createRef();
  }

  componentDidMount() {
    if (this.props.loginUser) {
      this.setState({ user: this.props.loginUser });
      this.setState({ twofaMobile: this.props.loginUser.ph_mobile });
    }

    if (this.props.type) {
      this.setState({ type: this.props.type });
    }
    this.checkDeepLink();
  }

  checkDeepLink() {
    const params = utils.get_url_params();
    if (params[DEEPLINKS.SET_2FA] && !this.state.show) {
      this.toggleTwoFAModal(true);
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.loginUser && !this.state.user) || this.props.loginUser.ph_mobile !== prevProps.loginUser.ph_mobile) {
      this.setState({ user: this.props.loginUser });
      this.setState({ twofaMobile: this.props.loginUser.ph_mobile });
    }

    if (this.props.type && this.props.type !== this.state.type) {
      this.setState({ type: this.props.type });
    }

    if ((this.state.type === EDIT_PERSONAL_MODAL.MOBILE_PHONE || this.state.type === EDIT_PERSONAL_MODAL.PASSWORD) && !this.state.show) {
      this.toggleTwoFAModal(true);
    }
  }

  onLoading(state) {
    this.setState({ loading: state });
  }

  handleChange(e) {
    const value = e.target.value.replace(/\(|\)|-| /gi, '') || '';
    this.setState({ [e.target.name]: value });

    if (this.state.error !== null) {
      this.setState({ error: null });
    }
  }

  handleTokenChange(newToken) {
    this.setState({ error: null, token: newToken });

    if (newToken.join('').length === 6) {
      this.confirmCodeButton.current?.focus();
    }
  }

  onMethodChange(e) {
    if (e.currentTarget.id === 'text-me') {
      this.setState({ callMe: false, textMe: true });
    } else {
      this.setState({ callMe: true, textMe: false });
    }
  }

  handlePhoneSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.error !== null) {
      this.setState({ error: null });
    }

    const data = { ph_mobile: this.state.twofaMobile };

    const errors = utils_validation.validate(update_self, data);
    if (!utils.is_obj_empty(errors)) {
      const R = UPDATE_SELF_ERROR_RESPONSES;
      const D = UPDATE_SELF_ERROR_DISPLAY;
      if ((errors.ph_mobile && errors.ph_mobile === R.PHONE_INVALID) || (errors.ph_mobile && errors.ph_mobile === R.PHONE_INVALID)) {
        this.setState({ error: D.PHONE_INVALID });
        if (this.phoneField.current) this.phoneField.current.focus();
      }
    } else {
      this.onLoading(true);
      this.getOTP();
    }
  }

  handleOTPSubmit(e) {
    e.preventDefault();

    const { twofaMobile, otpId, token } = this.state;

    this.setState({ error: null });

    const data = {
      phone: twofaMobile,
      otp_id: otpId,
      token: token.join(''),
    };

    this.onLoading(true);

    this.props.otp_post(data, true)
      .then(() => {
        if (this.state.type === EDIT_PERSONAL_MODAL.SET_2FA) {
          this.updateMFAAttribute('true');
        }

        if (this.props.loginUser.ph_mobile !== this.state.twofaMobile) {
          this.updatePhone();
        } else {
          this.onLoading(false);
          if (this.props.type === EDIT_PERSONAL_MODAL.PASSWORD) {
            this.props.onSuccess();
          } else {
            this.setState({ confirmPage: false, successPage: true });
          }
        }
      })
      .catch((error) => {
        this.onLoading(false);
        this.otpField.current?.focus();
        if (error && error.response.status && error.response.status === RESPONSE_CODE['405_data_invalid']) {
          return this.setState({
            error: OTP_ERROR_DISPLAY.CODE_INCORRECT,
            token: [...emptyToken],
          });
        } if (error && error.response.status && error.response.status === RESPONSE_CODE['490_max_reached']) {
          return this.setState({ error: OTP_ERROR_DISPLAY.MAX_REACHED });
        }
        return this.setState({ error: UNEXPECTED_ERROR });
      });
  }

  onMethodChange(e) {
    if (e.currentTarget.id === 'text-me') {
      this.setState({ callMe: false, textMe: true });
    } else {
      this.setState({ callMe: true, textMe: false });
    }
  }

  onLoading(state) {
    this.setState({ loading: state });
  }

  updatePhone() {
    const data = {
      ph_mobile: this.state.twofaMobile,
    };

    this.props.update_user_put(data, true)
      .then(() => {
        this.props.login_get(true)
          .then(() => {
            this.onLoading(false);
            return this.setState({ confirmPage: false, successPage: true });
          })
          .catch((error) => {
            this.onLoading(false);
            return this.setState({ error: `${UNEXPECTED_ERROR}. Please refresh your page.` });
          });
      })
      .catch((error) => {
        this.onLoading(false);
        if (error.response && error.response.status === RESPONSE_CODE['405_data_invalid']) {
          return this.setState({ error: 'Data invalid.' });
        }
        return this.setState({ error: UNEXPECTED_ERROR });
      });
  }

  updateMFAAttribute(enabled) {
    const data = {
      otp_id: this.state.otpId,
      value: enabled,
    };
    this.props.post_mfa_attribute_post(data, true)
      .then((response) => {
        // refresh user attributes
        if (this.props.handler && this.state.type === EDIT_PERSONAL_MODAL.SET_2FA) this.props.handler();
      })
      .catch((error) => {
        this.setState({ error: UNEXPECTED_ERROR });
      });
  }

  handllerResendCode(e) {
    this.setState({ confirmPage: false, token: [...emptyToken], error: '' });
  }

  getOTP() {
    const { textMe, twofaMobile } = this.state;

    const method = textMe ? 'sms' : 'call';

    this.props.otp_get_phone(method, twofaMobile, true)
      .then((response) => {
        this.onLoading(false);
        this.setState({ confirmPage: true });
        this.setState({ otpId: response.otp_id });
      })
      .catch((error) => {
        this.onLoading(false);
        if (this.phoneField.current) this.phoneField.current.focus();
        if (error.response.status === RESPONSE_CODE['405_data_invalid']) {
          this.setState({ error: OTP_ERROR_DISPLAY.INVALID_PHONE });
        } else if (error.response.status === RESPONSE_CODE['490_max_reached']) {
          this.setState({ limitReachedPage: true });
        } else {
          this.setState({ error: UNEXPECTED_ERROR });
        }
      });
  }

  toggleTwoFAModal(state) {
    const { loginUser, handler } = this.props;
    const { type } = this.state;

    this.setState({
      twofaMobile: loginUser.ph_mobile,
      error: '',
      show: state,
      successPage: false,
      confirmPage: false,
      limitReachedPage: false,
      token: [...emptyToken],
      callMe: true,
      textMe: false,
    });

    if (type && (type === EDIT_PERSONAL_MODAL.MOBILE_PHONE || type === EDIT_PERSONAL_MODAL.PASSWORD) && !state) {
      handler();
    }
  }

  handleFocus = (event) => event.target.select();

  renderLimitReachedPage() {
    return (
      <>
        <Trans i18nKey="2fa_message">
          <p className=" mb-3">
            We sent a 6-digit code to the phone number ending in
            {this.state.twofaMobile ? this.state.twofaMobile.substr(this.state.twofaMobile.length - 4) : null}
            .
          </p>
          <p className=" mb-2 warning-text font-weight-bold">You have reached the maximum number of Re-Sends for this number today.</p>
          <p className=" mb-2 warning-text font-weight-bold">Please check the number by restarting the process or try again in 24 hrs.</p>
          <Button variant="primary" block onClick={() => this.toggleTwoFAModal(false)}>Close</Button>
        </Trans>
      </>
    );
  }

  renderTwoFASuccessPage() {
    return (
      <>
        <p className="mb-4">{this.state.type === EDIT_PERSONAL_MODAL.SET_2FA ? null : 'Your mobile phone has been successfully updated.'}</p>
        <div className="mt-2 mb-3 text-center">
          <div className="checkmark" />
        </div>
        <Button variant="primary" block onClick={() => this.toggleTwoFAModal(false)}>{this.props.t('Done')}</Button>
      </>
    );
  }

  renderTwoFAConfirmPage() {
    const { t, type } = this.props;
    const {
      loading, twofaMobile, error, token,
    } = this.state;
    const buttonVariant = token.length === 6 && !loading ? 'primary' : 'disable';

    return (
      <>
        <h5 className="font-weight-bold mb-2">
          {type === EDIT_PERSONAL_MODAL.PASSWORD ? t('Secure Account Check, verify with OTP') : null}
        </h5>
        <p className=" mb-2">
          {t('We sent a 6-digit code to the phone number ending in')}
          <strong>
            {' '}
            {twofaMobile ? twofaMobile.substring(twofaMobile.length - 4) : null}
          </strong>
          .
        </p>
        <p className=" mb-3">{t('Please enter the code to confirm your 2-FA number.')}</p>
        <Form className="opt-code" noValidate onSubmit={(e) => this.handleOTPSubmit(e)}>
          <Form.Text className="text-danger form-error mb-3" aria-live="polite">
            {' '}
            {t(error) || <br />}
            {' '}
          </Form.Text>
          <OTPInput
            handlePaste={(value) => this.handleTokenChange(value?.split(''))}
            handleChange={(value) => this.handleTokenChange(value)}
            token={token}
            inputType="number"
            firstFieldRef={this.otpField}
          />

          <div className="row mt-2 mb-4">
            <div className="col-12 text-right mobile-text-center">
              <Button variant="link" onClick={(e) => this.handllerResendCode(e)}>{t('Re-Send Code')}</Button>
            </div>
          </div>
          {loading ? <Spinner error="2fa renderTwoFAConfirmPage" /> : null}
          <Button variant={buttonVariant} type="submit" block ref={this.confirmCodeButton}>
            {t('Confirm Code')}
          </Button>
        </Form>

      </>
    );
  }

  renderPhoneField() {
    return (
      <>
        <Form.Label>{this.props.t('Enter your phone number:')}</Form.Label>
        <PhoneNumberComponent onChange={(e) => this.handleChange(e)} name="twofaMobile" value={this.state.twofaMobile || ''} aria-required="true" controlRef={this.phoneField} onFocus={this.handleFocus} />
      </>
    );
  }

  renderSetPhone() {
    const { t, type, loginUser } = this.props;

    let buttonVariant = 'disable';
    if (this.state.twofaMobile && (this.state.textMe || this.state.callMe)) {
      buttonVariant = 'primary';
    }

    if (this.state.loading || (this.state.type === EDIT_PERSONAL_MODAL.MOBILE_PHONE && this.state.twofaMobile === loginUser.ph_mobile)) {
      buttonVariant = 'disable';
    }

    return (
      <>
        <h5 className="font-weight-bold mb-2">{type === EDIT_PERSONAL_MODAL.PASSWORD ? t('Secure Account Check, verify with OTP') : null}</h5>
        <p className=" mb-2">{t('We use your phone number as a way secure your account access or account changes.')}</p>
        <Form noValidate onSubmit={(e) => this.handlePhoneSubmit(e)}>
          <Form.Group controlId="formBasicPhone">
            <Form.Text className="text-danger form-error mb-2" aria-live="polite">
              {' '}
              {t(this.state.error) || <br />}
              {' '}
            </Form.Text>

            {type !== EDIT_PERSONAL_MODAL.PASSWORD ? this.renderPhoneField() : null}

            <h5 className=" mb-2 mt-4">{t('Method')}</h5>

            <div className="row">
              <div className="col-6 col-sm-4">
                <input type="radio" id="text-me" name="method" checked={this.state.textMe} onChange={(e) => this.onMethodChange(e)} />
                <label className="method align-top pt-1 ml-2" htmlFor="text-me">{t('Text Me')}</label>
              </div>
              <div className="col-6 col-sm-4">
                <input type="radio" className="method" id="call-me" name="method" checked={this.state.callMe} onChange={(e) => this.onMethodChange(e)} />
                <label className="method align-top pt-1 ml-2" htmlFor="call-me">{t('Call Me')}</label>
              </div>
            </div>
            {this.state.loading ? <Spinner error="2fa renderSetPhone" /> : null}
          </Form.Group>
          <Button variant={buttonVariant} type="submit" block>
            {t('Continue')}
          </Button>
        </Form>
      </>
    );
  }

  renderModalBody() {
    if (this.state.confirmPage) return this.renderTwoFAConfirmPage();
    if (this.state.successPage) return this.renderTwoFASuccessPage();
    if (this.state.limitReachedPage) return this.renderLimitReachedPage();
    return this.renderSetPhone();
  }

  renderModalTitle() {
    const { t } = this.props;
    const {
      confirmPage, successPage, limitReachedPage, type,
    } = this.state;

    if (confirmPage) {
      return (
        <Modal.Title id="contained-modal-title-vcenter">
          {t('Confirm 2-FA Code')}
        </Modal.Title>
      );
    }
    if (successPage) {
      return (
        <Modal.Title id="contained-modal-title-vcenter">
          {t(type === EDIT_PERSONAL_MODAL.SET_2FA ? '2-FA Number Confirmed!' : 'Edit mobile phone')}
        </Modal.Title>
      );
    }
    if (limitReachedPage) {
      return (
        <Modal.Title className="warning-text" id="contained-modal-title-vcenter">
          {t('Limit Reached')}
        </Modal.Title>
      );
    }

    return (
      <Modal.Title id="contained-modal-title-vcenter">
        {t(type === EDIT_PERSONAL_MODAL.SET_2FA ? 'Set 2-FA' : 'Edit mobile phone')}
      </Modal.Title>
    );
  }

  renderTwoFAModal() {
    if (this.props.type === EDIT_PERSONAL_MODAL.PASSWORD) {
      return this.renderModalBody();
    }

    const browser = utils.get_browser();

    return (
      <Modal
        show={Boolean(this.state.show)}
        onHide={() => this.toggleTwoFAModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`two-factor-modal ${browser}`}
      >
        <Modal.Header closeButton>
          {this.renderModalTitle()}
        </Modal.Header>
        <Modal.Body>
          {this.renderModalBody()}
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    const {
      t, type, state, handler,
    } = this.props;

    if (!type) {
      return (
        <Button className="profile-cta mt-2 btn-secondary" variant="primary" onClick={() => handler()}>{t('Turn off')}</Button>
      );
    }

    return (
      <>
        {(type === EDIT_PERSONAL_MODAL.SET_2FA && !state) ? <Button className="profile-cta mt-2" variant="primary" onClick={() => this.toggleTwoFAModal(true)}>{t('Set 2-FA')}</Button> : null}
        {this.renderTwoFAModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  otp_get_phone, otp_post, update_user_put, login_get, post_mfa_attribute_post,
})(withTranslation()(TwoFactorAuthentication));
