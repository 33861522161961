import { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { WORKFLOW } from '../../constants/workflow';
import CategorizationValidation from '../global/categorization_validation';
import TextMediaWithNext from '../global/text_media_with_next';
import EMR from './emr';
import Subworkflow from './subworkflow';
import Survey from './survey';
import ConsentPage from '../consent/consent_wrapper';
import OrderDnaKitStep from '../WorkflowNext/WorkflowSteps/OrderDnaKit/OrderDnaKitStep';
import WorkflowNextStepWrapper from './WorkflowNextStepWrapper';

function WorkflowContentRenderer({
  step,
  errors,
  body,
  surveyUrl,
  onWorkflowExit,
  onWorkflowSkip,
  onFormComplete,
  onComponentComplete,
}) {

  const { id: stepId } = step;

  const renderedComponent = useMemo(() => {
    switch (step.component) {
      case WORKFLOW.CATEGORIZATION:
        return (
          <div className="workflow-component-wrapper bg-secondary p-4 rounded">
            <CategorizationValidation workflowStep={step} qn={step ? step.qn : null} onComplete={() => onFormComplete()} errors={errors} />
          </div>
        );
      case WORKFLOW.VALIDATION:
        return (
          <div className="workflow-component-wrapper bg-secondary p-4 rounded">
            <CategorizationValidation workflowStep={step} qn={step ? step.qn : null} onComplete={() => onFormComplete()} errors={errors} />
          </div>
        );
      case WORKFLOW.TEXT_MEDIA:
        return (
          <div className="workflow-component-wrapper bg-secondary p-4 rounded">
            <TextMediaWithNext body={body} onNext={() => onComponentComplete()} />
          </div>
        );
      case WORKFLOW.EMR:
        return (
          <div className="workflow-component-wrapper bg-emr p-4 rounded">
            <EMR
              step={step}
              onComplete={() => onFormComplete()}
              onWorkflowExit={onWorkflowExit ? () => onWorkflowExit() : null}
              onWorkflowSkip={onWorkflowSkip ? () => onWorkflowSkip() : null}
            />
          </div>
        );
      case WORKFLOW.SURVEY:
        const survey_attr = step.exit_attribute;
        return (
          <Survey onComplete={() => onFormComplete()} surveyUrl={surveyUrl} errors={errors} survey_attr={survey_attr} />
        );
      case WORKFLOW.CONSENT: {
        return (
          <>
            CONSENT SHOULD BE HERE!
            <ConsentPage consent={step} />
          </>
        );
      }
      case WORKFLOW.ORDER_DNA_KIT: {
        return (
          <WorkflowNextStepWrapper
            step={step}
            stepComponent={OrderDnaKitStep}
            onFormComplete={onFormComplete}
            onWorkflowExit={onWorkflowExit || (() => null)}
            onWorkflowSkip={onWorkflowSkip || (() => null)}
          />
        );
      }
      case WORKFLOW.SUBWORKFLOW: {
        return (
          <Subworkflow
            onComplete={(workflow_code) => onFormComplete(workflow_code)}
            onWorkflowExit={onWorkflowExit ? () => onWorkflowExit() : null}
            onWorkflowSkip={onWorkflowSkip ? () => onWorkflowSkip() : null}
            subworkflowInfo={step}
            errors={errors}
          />
        );
      }
      default: {
        console.warn(`Unknown workflow component '${step.component}'`);
        return <div />;
      }
    }
  }, [stepId, surveyUrl]);

  return renderedComponent;
}

export default withTranslation(['workflow', 'texts'])(WorkflowContentRenderer);
