import { API_URL } from '../constants/api';
import { utils_api } from '../utils/utils_api';

export const api_poll = {
  get_poll(success, fail) {
    return utils_api.auth_get(API_URL.POLL_GET, success, fail);
  },
  post_poll(success, fail, qn, ans) {
    const data = {
      poll_qn_id: qn,
      poll_ans_id: ans,
    };
    return utils_api.auth_post(API_URL.POLL_POST, data, success, fail);
  },
};
