/* eslint-disable camelcase */
import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import resources from './locales';
import { LOCAL_STORAGE } from './constants/localStorage';
import api_config from './constants/api_config';

const DEFAULT_LANGUAGE = 'en';

const {
  api_static_host,
  current_environment,
  hide_language_labels = false,
  debugLanguage,
} = api_config;

let i18nCheck = debugLanguage && localStorage.getItem(LOCAL_STORAGE.I18N_CHECK) === 'true';

export function devToggleDebugLanguage() {
  i18nCheck = debugLanguage && !i18nCheck;
  localStorage.setItem(LOCAL_STORAGE.I18N_CHECK, i18nCheck);
  i18n.changeLanguage(i18n.translator.language || DEFAULT_LANGUAGE);
}

const cookieLang = document.cookie.split('; ').find((row) => row.startsWith(LOCAL_STORAGE.SELECTED_LANGUAGE));

const checkedSymbol = '[Ok]';
const uncheckedSymbol = '[Missing]';

i18n
  .use(backend)
  .use({
    type: 'postProcessor',
    name: 'postProcessDev',
    process(value, key, options, translator) {
      // Can do anything with value here and return it
      const { ns, defaultValue } = options;
      const { language, resourceStore } = translator;
      const { nsSeparator, defaultNS } = translator.options;
      const dict = resourceStore.data[language];
      if (i18nCheck) {
        const actualKey = key[0].split(':').pop();
        const resolvedNs = Object.entries(dict).find(([, translations]) => Object.hasOwn(translations, actualKey));
        const hasKey = resolvedNs !== undefined;
        const availableNamespaces = !hasKey
          ? `@{${Object.keys(dict).reduce((prev, curr) => `${prev}, ${curr}`, '')}}`
          : `@${resolvedNs[0]}`;

        return `${language} :: ${ns} : ${actualKey} ${hasKey ? checkedSymbol : uncheckedSymbol} ${availableNamespaces}`;
      }
      if (current_environment === 'PROD' || hide_language_labels) return value;
      if (language === DEFAULT_LANGUAGE && ns === 'texts') return value;
      const lookupKey = key[0].replace(`${ns}${nsSeparator}`, '');
      if (!ns || (Object.hasOwn(dict, ns) && !Object.hasOwn(dict[ns], lookupKey) && !Object.hasOwn(dict[defaultNS], lookupKey))) {
        return `${language}: ${value || defaultValue}`;
      }
      return value;
    },
  })
  .use(initReactI18next)
  .init({
    postProcess: ['postProcessDev'],
    resources,
    partialBundledLanguages: true,
    ns: 'texts',
    returnNull: false,
    defaultNS: 'texts',
    fallbackNS: 'texts',
    keySeparator: false,
    nsSeparator: ':',
    lng: cookieLang ? cookieLang.split('=')[1] : DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    backend: {
      loadPath: `${api_static_host || ''}/i18n/{{lng}}/{{ns}}.json`,
    },

  });

export default i18n;
