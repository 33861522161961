import types from './types';

const DEFAULT_STATE = {
  forms: {},
  isLoading: false,
  errors: [],
};

export default function freeFormReducer(state, action) {
  if (!state) {
    return DEFAULT_STATE;
  }
  const { type } = action;
  const currentState = state || { ...DEFAULT_STATE };
  switch (type) {
    case types.SET_FREEFORM_CONFIG: {
      const { formConfig } = action;
      if (formConfig && formConfig.uuid) {
        currentState.forms[formConfig?.uuid] = formConfig;
      } else {
        currentState.errors.push('No Form with such UUId');
      }

      currentState.isLoading = false;
      return {
        ...state,
        ...currentState,
      };
    }
    case types.SET_FREEFORM_FIELD_VALUE: {
      const { formId, field } = action;
      const { name, value } = field;
      const currentForm = currentState.forms[formId];
      const currentField = currentForm?.fields?.find((item) => item.name === name);
      if (!currentField) {
        return state;
      }
      currentField.value = value;
      return {
        ...state,
        ...currentState,
      };
    }
    case types.SET_FREEFORM_LOADING: {
      const { isLoading } = action;
      currentState.isLoading = isLoading;
      if (isLoading) {
        currentState.errors = [];
      }
      return {
        ...state,
        ...currentState,
      };
    }
    default:
      return state;
  }
}
