import Joi, { ObjectSchema } from 'joi';
import { stateKeys } from 'src/data/usaStates';
import { phone, zip } from './Common';

export interface OrderDnaKitInterface<
  TPhone = string,

  TAddressLine1 = string,
  TAddressLine1Validated = boolean,

  TAddressLine2 = string,

  TCity = string,
  TState = string,
  TZip = string
> {
  phone: TPhone,

  addressLine1: TAddressLine1,
  addressLine1Validated: TAddressLine1Validated,

  addressLine2: TAddressLine2,

  city: TCity,
  state: TState,
  zip: TZip,
}

export type OrderDnaKitValidationJoi = OrderDnaKitInterface<
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema
>;

export const ORDER_DNA_KIT_VALIDATION: ObjectSchema<OrderDnaKitValidationJoi> = Joi.object({
  phone: phone.required(),

  addressLine1: Joi.string().required(),
  addressLine1Validated: Joi.boolean(),

  addressLine2: Joi.string().allow(null, '').optional(),

  city: Joi.string().required(),
  state: Joi.string().required().valid(...stateKeys),
  zip: zip.required(),
});
