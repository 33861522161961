import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { utils } from '../../../utils/utils_general';
import { LOCAL_STORAGE } from '../../../constants/localStorage';
import PAGES from 'src/constants/pages';
import SignupProgress from '../signup_progress';
import SetPasswordForm from '../../password/set_password_form';
import { WORKFLOW } from '../../../constants/workflow';
import { update_registration_flow } from '../../../actions/registrationFlowAction';
import { workflow_get } from '../../../actions/workflowAction';
import { utils_signup } from '../../../utils/utils_signup';
import { TOKEN_TYPE } from '../../../constants/tokenType';
import { utils_registration_flow } from '../../../utils/utils_registration_flow';
import { post_workflow_complete_post, get_user_attributes_get } from '../../../actions/userAction';
import { withService } from 'src/services/ServiceProvider';

const CURRENT_REG_STEP = WORKFLOW.SET_PASSWORD;

class SignupPassword extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      reg_flow: null,
      submitted: false,
      user: null,
    };
  }

  async componentDidMount() {
    await this.props.workflow_get();
    const reg_flow = this.props.workflow?.workflow;

    if (reg_flow) {
      if (reg_flow.find((c) => c.component === CURRENT_REG_STEP).value) {
        this.goToRegisteredNextStep(reg_flow);
      }
      this.setState({ reg_flow });
    }

    const { userService } = this.props;

    const user = userService.getCurrentUser();

    if (user) {
      if (user.token_type === TOKEN_TYPE.SESSION && reg_flow) {
        this.goToRegisteredNextStep(reg_flow);
      }
      this.setState({ user });
    } else {
      this.props.history.push(PAGES.SIGNUP);
    }
  }

  onPasswordSetSuccess() {
    utils.set_local_storage(LOCAL_STORAGE.FIRST_TIME_REG, true);
    this.goToRegisteredNextStep(this.state.reg_flow);
  }

  goToRegisteredNextStep(reg_flow) {
    const currentStep = utils_registration_flow.current_registration_component(reg_flow, CURRENT_REG_STEP);

    this.props.get_user_attributes_get()
      .then((res) => {
        const attr_errors = utils_registration_flow.checkAttrCompleted(currentStep.exit_attribute, res);
        if (attr_errors.length > 0) {
          this.componentDidMount();
        } else {
          utils_signup.goToNextRegistrationStep(reg_flow, currentStep, res, this.props.update_registration_flow, this.props.history.push, this.props.post_workflow_complete_post);
        }
      });
  }

  render() {
    const reg_flow = this.state.reg_flow || this.props.registrationFlow || utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
    const currentStep = utils_registration_flow.current_registration_component(this.state.reg_flow, CURRENT_REG_STEP);
    if (!reg_flow || !currentStep) {
      return null;
    }
    return (
      <section className="signup-component signup-password">
        <SignupProgress currentStep={currentStep} regFlow={reg_flow} />
        <div className="signup-body-wrapper">
          <h1>{this.t('Set Your Password')}</h1>
          <SetPasswordForm 
            onSuccess={() => this.onPasswordSetSuccess()} 
            user={this.state.user}
            page="signup"
            />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withRouter(connect(mapStateToProps, {
  update_registration_flow,
  post_workflow_complete_post,
  get_user_attributes_get,
  workflow_get,
})(withTranslation()(withService(['userService'])(SignupPassword))));
