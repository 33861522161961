import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';
import { utils_api } from '../utils/utils_api.js';
import { LOCAL_STORAGE } from '../constants/localStorage.js';
import { utils_registration_flow } from '../utils/utils_registration_flow.js';
import { api_config } from '../api/api_config.js';
import { JS_FILES } from '../constants/jsFiles.js';
import { api_reg_flow } from '../api/api_registration_flow.js';
import { TOKEN_TYPE } from '../constants/tokenType.js';
import { REGISTRATION_DB_CODE } from '../constants/registration.js';
import { utils_user } from 'src/utils/utils_user.js';

export function registration_flow_get(hideSpinner = true) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'registration_flow_get');
    }
    // self registration flow is stored in config, faster to get
    const success_self = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      // probably should redo this at somepoint, maybe try importing
      const jsonText = response.data.replace('export const app_config =', '').replace('module.exports =', '');

      const jsonWorkflow = JSON.parse(jsonText).workflow;
      const workflow = Object.values(jsonWorkflow)[0];
      const final_flow = utils_registration_flow.prep_regflow_data(workflow);
      utils.set_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW, final_flow);

      dispatch({
        type: REDUX_ACTION.REGISTRATION_FLOW,
        data: final_flow,
      });
      return final_flow;
    };

    // all other registraiton flow need to come from db via different endpoint then self
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      const workflow = response.data.data;
      const final_flow = utils_registration_flow.prep_regflow_data(workflow);
      utils.set_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW, final_flow);
      dispatch({
        type: REDUX_ACTION.REGISTRATION_FLOW,
        data: final_flow,
      });
      return final_flow;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);

    const user = utils_user.get_user();

    if (user && user.token_type !== TOKEN_TYPE.REGISTRATION_SELF) {
      return api_reg_flow.get_reg_flow(get_workflow_code(user.token_type), success, fail);
    }
    return api_config.get_config_js(JS_FILES.APP_CONFIG, success_self, fail);
  };
}

export function get_workflow_code(token_type) {
  switch (token_type) {
    case TOKEN_TYPE.REGISTRATION_ADMIN:
      return REGISTRATION_DB_CODE.ADMIN_INVITE;

    default:
      break;
  }
}

export function set_registration_type(type) {
  utils.set_local_storage(LOCAL_STORAGE.REGISTRATION_TYPE, type);
  return (dispatch) => {
    dispatch({
      type: REDUX_ACTION.REGISTRATION_TYPE,
      data: type,
    });
  };
}

export function update_registration_flow(flow) {
  utils.set_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW, flow);
  return (dispatch) => {
    dispatch({
      type: REDUX_ACTION.REGISTRATION_FLOW,
      data: flow,
    });
  };
}

export function reset_registration_flow() {
  utils.remove_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
  return (dispatch) => {
    dispatch({
      type: REDUX_ACTION.REGISTRATION_FLOW,
      data: null,
    });
  };
}
