import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';

function showSpinner(properties) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, properties);
  };
}

function hideSpinner(properties) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE, properties);
  };
}

export {
  showSpinner,
  hideSpinner
}