import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import PAGES from 'src/constants/pages';
import { login_get } from '../../actions/loginAction';
import { consent_get } from '../../actions/consentAction';
import { get_user_attributes_get } from '../../actions/userAction';
import { UNEXPECTED_ERROR } from '../../constants/errors';
import Consent from './consent';

import { CONSENT_WORKFLOW_CODE } from './consent_components/constants';

function getConsentComponent(consentCode) {
  return {
    app_feature: 'consent',
    component: 'consent',
    consent_code: consentCode,
    consent_index: 0,
    entry_attribute: ['*'],
    exit_attribute: [`temp_${CONSENT_WORKFLOW_CODE}_${consentCode}_consent_signed|true`],
    name: consentCode,
    step: 'Consent',
  };
}

class ConsentPage extends React.Component {
  constructor() {
    super();
    this.state = {
      currentStepComponent: null,
      consentData: null,
      consentCode: null,
      consentFlow: null,
    };
  }

  async checkConsent() {
    try {
      const { location, login_get, consent_get } = this.props;
      const loginData = await login_get();
      if (loginData.esign) {
        if (location.pathname.match(PAGES.ENROLLMENT)) {
          return window.location.reload();
        }
        return <Redirect to={PAGES.DASHBOARD} />;
      }
      const { consent_require } = loginData;
      if (consent_require && consent_require.length) {
        const [consentCode] = consent_require;
        const consentData = await consent_get(consentCode);
        this.setState({ consentCode, consentData });
      }
    } catch (error) {
      if (error.response) {
        return <Redirect to={PAGES.LOGIN} />;
      }
      return this.setState({ errors: { system: UNEXPECTED_ERROR } });
    }
  }

  componentDidMount() {
    this.checkConsent();
  }

  onNextConsentStep(step, flow) {
    this.setState({ currentStepComponent: step });
  }

  renderConsent(consentCode) {
    if (!consentCode || !this.state.consentData) {
      return null;
    }

    const currentConsentComponent = getConsentComponent(consentCode);
    return (
      <Consent
        currentStep={currentConsentComponent}
        currentConsentComponent={currentConsentComponent}
        flow={[currentConsentComponent]}
        onComplete={() => this.checkConsent()}
        onNextConsentStep={() => this.onNextConsentStep()}
        updateFlow={() => { }}
        consentCode={consentCode}
        consentData={this.state.consentData}
      />
    );
  }

  render() {
    const { consentCode } = this.state;
    return (
      <section className="signup-component consent consent-auth">
        <div className="signup-body-wrapper">
          {this.renderConsent(consentCode)}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
});

export default withRouter(connect(mapStateToProps, {
  login_get, consent_get, get_user_attributes_get,
})(ConsentPage));
