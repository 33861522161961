import axios from 'axios';
import { URL_ROOT_API, URL_STATIC_API } from '../constants/api';
import { utils_user } from './utils_user';
import { utils } from './utils_general';
import { REDUX_ACTION } from '../constants/reduxAction';

export const utils_api = {
  externalGet: async (url) => {
    try {
      const result = await axios
        .get(url, {
          headers: { 'Content-Type': 'application/json' },
        });
      return result;
    } catch (e) {
      throw new Error(e);
    }
  },
  js_get(file, success, fail) {
    const local = (window.location.hostname === 'localhost' || window.location.hostname === 'local') && !URL_STATIC_API.startsWith("https://")
      ? 'https://rgcdtp-dev-app.rgndtp.com'
      : '';
    console.log('URL STATIC API:', URL_STATIC_API);
    return axios
      .get(`${local}${URL_STATIC_API}/${file}`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => success(response))
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  unauth_get(endpoint, success, fail) {
    return axios
      .get(`${URL_ROOT_API}${endpoint}`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => success(response))
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  unauth_post(endpoint, data, success, fail) {
    return axios
      .post(`${URL_ROOT_API}${endpoint}`, JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => success(response))
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  auth_get(endpoint, success, fail) {
    return axios
      .get(`${URL_ROOT_API}${endpoint}`, {
        headers: {
          'x-api-key': utils_user.get_user_jwt(),
          'Content-Type': 'application/json',
        },
      })
      .then((response) => success(response))
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  auth_post(endpoint, data, success, fail) {
    return axios
      .post(`${URL_ROOT_API}${endpoint}`, JSON.stringify(data), {
        headers: {
          'x-api-key': utils_user.get_user_jwt(),
          'Content-Type': 'text/plain',
        },
      })
      .then((response) => success(response))
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  auth_put(endpoint, data, success, fail) {
    return axios
      .put(`${URL_ROOT_API}${endpoint}`, JSON.stringify(data), {
        headers: {
          "x-api-key": utils_user.get_user_jwt(),
          "Content-Type": "application/json",
        },
      }) // eslint-disable-line no-useless-computed-key
      .then((response) => success(response))
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  auth_delete(endpoint, data = null, success, fail) {
    // axios delete has some bugs, need to input header/data like so:
    return axios
      .delete(`${URL_ROOT_API}${endpoint}`, {
        headers: {
          "x-api-key": utils_user.get_user_jwt(),
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      }) // eslint-disable-line no-useless-computed-key
      .then((response) => success(response))
      .catch((error) => {
        fail(error);
        throw error;
      });
  },
  on_fail_default(dispatch, error, customFail = null) {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    if (customFail) {
      customFail();
      return;
    }

    if (
      error
      && error.response
      && error.response.status
      && error.response.status >= 500
    ) {
      on_server_error(dispatch, error.response.status);
    }
    if (error && error.message === 'Network Error') {
      on_server_error(dispatch, 'Network Error');
    }
    return error;
  },
};

const on_server_error = (dispatch, status) => {
  dispatch({
    type: REDUX_ACTION.GLOBAL_ERROR,
    data: status,
  });
};
