import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import PersonalEditModal from './personal_edit_modal';
import { EDIT_PERSONAL_MODAL } from '../../constants/editPersonalModal';
import { utils } from '../../utils/utils_general';
import SetTwoFA from './two-factor-authentication';
import { FEATURE } from '../../constants/features';

class PersonalInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      user: null,
      personalModalShow: null,
      show2FA: false,
      is2FAEnabled: false,
    };
  }

  componentDidMount() {
    if (this.props.loginUser) {
      this.setState({ user: this.props.loginUser });
    }

    if (this.props.userAttribute) {
      const is2FAFeatured = this.props.featureFlag && this.props.featureFlag[FEATURE.MFA];
      this.setState({ is2FAEnabled: this.props.userAttribute.mfa_enable && is2FAFeatured });
    }
  }

  componentDidUpdate(prevProps) {
    const is2FAFeatured = this.props.featureFlag && this.props.featureFlag[FEATURE.MFA];
    if ((this.props.loginUser && !this.state.user) || this.props.loginUser[this.state.personalModalShow] !== prevProps.loginUser[this.state.personalModalShow] || this.props.loginUser.ph_mobile !== this.state.user.ph_mobile) {
      this.setState({ user: this.props.loginUser });
    }
    if (this.props.userAttribute && this.props.userAttribute.mfa_enable != null && prevProps.userAttribute && this.props.userAttribute.mfa_enable !== this.state.is2FAEnabled) {
      this.setState({ is2FAEnabled: this.props.userAttribute.mfa_enable && is2FAFeatured });
    }
  }

  twofaHandler(value) {
    this.setState({ show2FA: value });
  }

  setPersonalModalShow(state) {
    if (state === EDIT_PERSONAL_MODAL.MOBILE_PHONE && !this.state.show2FA && this.state.is2FAEnabled) {
      this.twofaHandler(true);
    } else {
      this.setState({ personalModalShow: state });
    }
  }

  renderField(label, value, editAction = null) {
    return (
      <div className="profile-field">
        <p className="profile-field-label">{label}</p>
        <p className={`profile-field-value overflow-ellipsis ${editAction ? 'can-edit' : ''}`}>
          {value || <br />}
          {editAction ? <Button style={{ textTransform: 'uppercase' }} variant="link" className="font-weight-bold font-callout" onClick={() => this.setPersonalModalShow(editAction)}>{this.props.t('Edit')}</Button> : null}
          {(this.state.show2FA && editAction === EDIT_PERSONAL_MODAL.MOBILE_PHONE) ? <SetTwoFA type={editAction} handler={() => this.twofaHandler()} /> : null}
        </p>
      </div>
    );
  }

  render() {
    if (!this.state.user) {
      return null;
    }
    const formatPhoneNumber = (str) => {
      const cleaned = (`${str}`).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }
      return null;
    };
    return (
      <section className="profile-personal-info section-component">
        <h2 className="mb-3 h3">{this.props.t('Personal Information')}</h2>
        <div className="rounded bg-secondary p-3">
          {this.renderField(this.props.t('fields:First Name'), this.state.user.first_name ? utils.titlecase(this.state.user.first_name) : null)}
          {this.renderField(this.props.t('fields:Last Name'), this.state.user.last_name ? utils.titlecase(this.state.user.last_name) : null)}
          {this.renderField(this.props.t('fields:Mobile Phone'), formatPhoneNumber(this.state.user.ph_mobile), EDIT_PERSONAL_MODAL.MOBILE_PHONE)}
          {this.renderField(this.props.t('fields:Email Address'), this.state.user.email, null)}
          {/* {this.renderField('Home Phone', this.state.user.ph_home, EDIT_PERSONAL_MODAL.HOME_PHONE)} */}
          {this.renderField(this.props.t('fields:Password'), '**********', EDIT_PERSONAL_MODAL.PASSWORD)}
        </div>

        <PersonalEditModal
          show={this.state.personalModalShow}
          is2FAEnabled={this.state.is2FAEnabled}
          onHide={() => this.setPersonalModalShow(null)}
        />

      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {})(withTranslation('fields')(PersonalInfo));
