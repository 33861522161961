import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import PAGES from 'src/constants/pages';
import { CONFIG } from 'src/constants/config';
import { LINKS } from '../../constants/links';
import { config_privacy_get, config_terms_get } from '../../actions/configAction';
import CopyrightText from './copyright_text';
import CobrandingLogo from './cobranding_logo';

class Footer extends React.Component {
  prefetchPrivacyPolicy() {
    if (!this.props.privacyContent) {
      this.props.config_privacy_get(true);
    }
  }

  prefetchTerms() {
    if (!this.props.termsContent) {
      this.props.config_terms_get(true);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <footer className={`footer pt-3 pb-3 ${this.props.location.pathname === PAGES.ANCESTRY ? 'ancestry-footer' : ''}`}>
        <div className="container-fluid">
          <div className="row no-gutters">
            <div className="col-12 col-xl-2 text-left desktop-only pt-0 pt-xl-3">
              <CopyrightText />
            </div>
            <div className="col-12 col-xl-8 text-center pt-0 pt-xl-3">
              {this.props.location.pathname !== PAGES.NOT_FOUND  && <ul>
                <li>
                  <a
                    href={PAGES.ABOUT_US}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('About Us')}
                  </a>
                </li>
                <li>
                  <a
                    href={LINKS.SUPPORT}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('Help')}
                  </a>
                </li>
                <br className="mobile-only" />
                <li>
                  <a
                    href={PAGES.PRIVACY_POLICY}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseEnter={() => this.prefetchPrivacyPolicy()}
                  >
                    {t('Privacy Statement')}
                  </a>
                </li>
                <li>
                  <a
                    href={PAGES.TERMS_OF_SERVICE}
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseEnter={() => this.prefetchTerms()}
                  >
                    {t('Terms & Conditions')}
                  </a>
                </li>
              </ul>}
            </div>
            <div className={`col-12 col-xl-2 text-center text-xl-right ${CONFIG.COBRANDING_LOGO_ENABLED ? 'pt-xl-0' : 'pt-xl-3'}`}>
              <CobrandingLogo />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

export default withRouter(connect(mapStateToProps, { config_privacy_get, config_terms_get })(withTranslation()(Footer)));
