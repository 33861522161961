import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import PAGES from 'src/constants/pages';
import { doSetConsent, doSetConsentLoading } from 'src/store/Consent/ConsentActions';
import { withService } from 'src/services/ServiceProvider';
import { consent_get, consent_post } from 'src/actions/consentAction';
import ConsentCompRenderer from './consent_comp_renderer';
import { update_registration_flow } from 'src/actions/registrationFlowAction';
import { questionnaire_resp_post } from 'src/actions/questionnaireAction';
import { utils } from 'src/utils/utils_general';
import { RESPONSE_CODE, UNEXPECTED_ERROR } from 'src/constants/errors';
import { actioncards_firstcall_get } from 'src/actions/actioncardsAction';
import { utils_registration_flow } from 'src/utils/utils_registration_flow';
import { login_get } from 'src/actions/loginAction';
import ConsentDownload from './consent_download';
import { LOCAL_STORAGE } from 'src/constants/localStorage';
import { WORKFLOW_VALUE } from 'src/constants/workflow';
import { signatureComponentNames } from './consent_components/constants';
import { Button } from 'react-bootstrap';
import BlankComponent from 'src/components/core/Blank/BlankComponent';
import { CONFIG } from 'src/constants/config';

// TODO: remove that once the BE is providing code
const DEFAULT_CONSENT_CODE = 'general';

const ErrorMessage = ({ title, content, canRetry, onRetry }) => {
  const {t} = useTranslation('consent');
  return (
    <>
      {title && <h1>{title}</h1>}
      <div className="h2 mt-5 mb-5" style={{ fontSize: '18px', fontWeight: 'normal', lineHeight: '24px' }}>
        {content}
      </div>
      <Link className="btn btn-primary" to="/">{t('Go back to home')}</Link>
      {" "}
      {canRetry && <Button variant={"secondary"} onClick={() => canRetry && onRetry()}>{t('Restart Consent')}</Button>}
    </>
  );
}

class Consent extends React.Component {

  constructor({ t, consentData, currentConsentComponent }) {
    super();
    this.t = t;
    this.state = {
      errors: {},
      consent: consentData || null,
      currentStep: currentConsentComponent || null,
      reg_flow: null,
      sig: null,
      user_response: [],
      loadingConsent: null,
      sigDetails: null, // used for indexing the download step,
      consent_flow: null, // for non reg flow comp,
      signatureCount: 0,
    };
  }

  componentDidMount() {
    if (this.props.currentStep) {
      this.setState({ currentStep: this.props.currentStep });
    }
    if (this.props.flow) {
      this.setState({ reg_flow: this.props.flow });
    }
    // not yet, backend is always sending esign = true
    // this.checkUserLogin();

    this.getConsent();
  }

  componentDidUpdate(prevProps) {
    if (!utils.check_objects_identical(prevProps.currentStep, this.props.currentStep) && !this.state.currentStep) {
      this.setState({ currentStep: this.props.currentStep });
    }
    if (!utils.check_objects_identical(prevProps.flow, this.props.flow) && !this.state.reg_flow) {
      this.setState({ reg_flow: this.props.flow });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  getConsent() {
    const { props } = this;
    const { doSetConsentLoading, doSetConsent, consentCode, consentService } = props;
    if (!consentCode) return;
    if (!props.consent && !props.consentData) {
      this.setState({ loadingConsent: true });
      return consentService.fetchData(consentCode, { doSetConsentLoading, doSetConsent })
        .then((response) => {
          this.setUpFlow(response);
          this.setState({ consent: response, loadingConsent: false });
        })
        .catch((error) => {
          this.setState({ error: { system: error }, loadingConsent: false });
        });
    }
    const consent = props.consent || props.consentData;
    this.setUpFlow(consent);
    this.setState({ consent });
  }

  consentSignaturesProcess(constentFlow) {
    let signatureCount = 0;
    const newFlow = constentFlow.map((consentComponent) => {
      if (consentComponent.type === signatureComponentNames.signature) {
        signatureCount++;
        consentComponent.signatureIndex = signatureCount;
      }
      if (consentComponent.body && consentComponent.body.length) {
        const signType = consentComponent.body.find((item) => item.type === 'signature_type');
        if (signType && signType.code) {
          consentComponent.signatureType = signType.code;
        }
      }

      return consentComponent;
    });
    return { newFlow, signatureCount };
  }

  setUpFlow(consent) {
    const { signatureCount } = this.consentSignaturesProcess(consent.components);
    // consent.components = newFlow;
    const reg_flow = this.props.flow || utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
    if (reg_flow) {
      const final_flow = utils_registration_flow.combineConsentFlowToRegFlow(consent, reg_flow);
      this.setState({ reg_flow: final_flow, signatureCount });
      // this.props.update_registration_flow(final_flow);
    } else {
      const consentComp = consent.components.map((c, i) => {
        c.consent_index = i;
        c.value = null;
        return c;
      });
      this.setState({ currentStep: consentComp[0], consent_flow: consentComp, signatureCount });
    }
  }

  checkUserLogin() {
    return this.props.login_get()
      .then((response) => {
        if (response.esign === true) {
          this.setState({ errors: {} });
          this.props.onComplete();
        } else {
          // do nothing, they need to go through consent
        }
      })
      .catch((error) => {
        if (error.response) {
          return this.props.history.push(PAGES.LOGIN);
        }
        return this.setState({ errors: { system: UNEXPECTED_ERROR } });
      });
  }

  sigDetailsUpdateOnBack(currentStep, prevStep) {
    const { sigDetails, consent } = this.state;

    if (!sigDetails) {
      return null;
    }

    const prevStepConsentComponent = consent.components[prevStep.consent_index];
    const currentStepConsentComponent = consent.components[currentStep.consent_index];

    if (prevStepConsentComponent.signatureIndex < currentStepConsentComponent.signatureIndex) {
      sigDetails.splice(prevStepConsentComponent.signatureIndex, 1);
    }
    this.setState({ sigDetails });
    return null;
  }

  getCurrentIndex() {
    return this.state.reg_flow.findIndex((c) => c.component === this.state.currentStep.component);
  }

  onBack() {
    const currentIndex = this.getCurrentIndex();
    this.goToStep(currentIndex - 1);
  }

  onRetry() {
    this.setState({ errors: {} });
    this.goToStep(0);
  }

  goToStep(newIndex){
    const { currentStep, sigDetails, reg_flow } = this.state;
    const currentIndex = this.getCurrentIndex();

    const newFlow = reg_flow.map((c, i) => {
      if (i === currentIndex) {
        c.value = null;
      }
      return c;
    });
    
    const destinationStep = reg_flow[newIndex];

    if (!isNil(currentStep.consent_index) && !isNil(destinationStep.consent_index)) {
      this.sigDetailsUpdateOnBack(currentStep, destinationStep);
    }

    this.setState({ currentStep: destinationStep, reg_flow: newFlow, sigDetails });
    this.props.onNextConsentStep(destinationStep);
  }

  getSignature(value) {
    const { sigDetails } = this.state;
    const localStorageSig = utils.get_local_storage(LOCAL_STORAGE.SIG);

    if (sigDetails && sigDetails.length) {
      return sigDetails[sigDetails.length - 1].sig;
    } if (value.sig) {
      return value.sig;
    }
    return localStorageSig;
  }

  addSignatureToState(newSignature = {}) {
    const { sigDetails, consent } = this.state;
    const { components } = consent;
    const signatureToPush = {
      ...newSignature,
    };

    const currentSigDetails = sigDetails || [];
    const currentConsetComponent = components[newSignature.index];
    if (currentConsetComponent) {
      signatureToPush.signatureType = currentConsetComponent.signatureType;
    }

    const existingSignatureIndex = currentSigDetails.findIndex((signatureItem) => signatureItem.index === signatureToPush.index);
    if (existingSignatureIndex > -1) {
      currentSigDetails.splice(existingSignatureIndex, 1);
    }
    currentSigDetails.push(signatureToPush);
    return currentSigDetails;
  }

  async onNext(value) {
    const { sigDetails, reg_flow: regFlow } = this.state;
    if (value.sig) {
      const currentSigDetails = this.addSignatureToState(value);
      this.setState({ sigDetails: currentSigDetails });
    }
    try {
      if (value.resp) {
        this.handleQuestionResponses(value.resp);
      }
      if (value.radio) {
        await this.handleRadioResponses(value.radio);
      }
    } catch (error) {
      return this.setState({ errors: { error, canRetry: true } });
    }

    const isLastStep = this.state.consent.components.length === Number(value.index) + 1;

    const signature = this.getSignature(value);

    if (isLastStep && signature) {
      return this.onSigSubmit(sigDetails);
    }

    if (regFlow && this.state.currentStep) {
      const currentIndex = regFlow.findIndex((c) => c.component === this.state.currentStep.component);
      const new_flow = regFlow.map((c, i) => {
        if (i === currentIndex) {
          c.value = WORKFLOW_VALUE.COMPLETE;
        }
        return c;
      });
      // this.props.update_registration_flow(new_flow);
      const currentStep = regFlow[currentIndex + 1];
      this.setState({ currentStep, reg_flow: new_flow });
      this.props.onNextConsentStep(currentStep);
    } else {
      // no reg flow just consent for active user
      const currentIndex = value.index;
      const new_flow = this.state.consent_flow.map((c, i) => {
        if (i === currentIndex) {
          c.value = WORKFLOW_VALUE.COMPLETE;
        }
        return c;
      });
      const nextStep = this.state.consent_flow[currentIndex + 1];
      this.setState({ currentStep: nextStep, consent_flow: new_flow });
      // this goes to consent-wrapper
      this.props.onNextConsentStep(nextStep, new_flow);
    }
  }

  async submitQuestionnaireResponses(responses) {
    return Promise.all(responses.map((q) => {
      const { qnnr } = q;
      // return new Promise((accept, reject) => reject({ message: "Opps!"}));
      return this.props.questionnaire_resp_post(qnnr, [{
        question: q.qn_code,
        answers: [q.ans_code],
      }]);
    }));
  }

  async handleRadioResponses(radioResp) {
    const { user_response } = this.state;
    radioResp.forEach((rr) => {
      const data = {
        qn_code: rr.qn_code,
        ans_code: [rr.ans_code],
      };
      const index_exist = user_response.findIndex((r) => r.qn_code === data.qn_code);
      if (index_exist >= 0) {
        user_response.splice(index_exist, 1);
      }
      user_response.push(data);
    });
    await this.submitQuestionnaireResponses(radioResp);
    this.setState({ user_response });
  }

  handleQuestionResponses(resp) {
    const { user_response } = this.state;
    resp.questionnaire.forEach((q) => {
      const data = {
        qn_code: q.question,
        ans_code: q.answers,
      };
      // remove prev answer if qn has been answered already
      const index_exist = user_response.findIndex((r) => r.qn_code === data.qn_code);
      if ((index_exist || index_exist === 0) && index_exist > -1) {
        user_response.splice(index_exist, 1);
      }
      user_response.push(data);
    });

    this.setState({ user_response });
  }

  onSigSubmit(signature) {
    // eslint-disable-next-line no-shadow
    const { doSetConsentLoading, consentCode } = this.props;
    const consentVersion = this.state.consent.version;
    const { user_response } = this.state; // need to make sure this is stored somewhere if user refreshes page....
    if (!signature) {
      return;
    }
    const fail = (error) => {
      if (error.response && error.response.status === RESPONSE_CODE['409_data_conflict']) {
        // consent already submitted
        this.onRegistrationComplete();
      } else {
        this.setState({ errors: { system: 'submiterror' } });
      }
    };
    const success = (resp) => {
      // show download when action cards are done
      this.initActionCard();
    };

    this.props.consentService.finalRequest(
      {
        consentVersion, user_response, signature, consentCode,
      },
      { doSetConsentLoading },
    )
      .then((response) => success(response))
      .catch((error) => fail(error));
  }

  onRegistrationComplete() {
    this.props.onComplete();
    utils.remove_local_storage(LOCAL_STORAGE.SIG);
  }

  onDownloadExit() {
    this.setState({ showDownload: false });
    // const lastIndex = this.state.consent.components.length - 1;
    // this.onNext({index: lastIndex})
    this.onRegistrationComplete();
  }

  initActionCard() {
    return this.props.actioncards_firstcall_get()
      .then(() => {
        this.setState({ showDownload: true });
      })
      .catch((error) => this.setState({ error: { system: error } }));
  }

  renderComponents(components, step) {
    const {
      t
    } = this.props;
    const {
      signatureCount, sigDetails, consent, currentStep, errors
    } = this.state;

    const currentConsentComponent = consent?.components[currentStep?.consent_index];
    const currentSignature = sigDetails && sigDetails[sigDetails.length - 1];

    const isComponentsError = !components || (!step && step !== 0);
    const stateHasAnyError = errors && !utils.is_obj_empty(errors);
    const isSubmitError = stateHasAnyError && errors.system && errors.system === 'submiterror';
    const canRetry = stateHasAnyError && errors.canRetry;

    if (isSubmitError) {
      return (<ErrorMessage content={t("We've hit an unexpected error in submitting your consent form. Please try again later.")} />);
    }

    if(isComponentsError || stateHasAnyError){
      return (<ErrorMessage content={t("We've hit an error with your consent form.")} canRetry={canRetry} onRetry={() => this.onRetry()} />);
    }
    
    const signatureType = currentConsentComponent && currentConsentComponent.signatureType;

    return (
      <>
        <ConsentCompRenderer
          index={step}
          component={components[step]}
          onComplete={(value) => this.onNext(value)}
          signature={currentSignature || null}
          signatureCount={signatureCount}
          signatureType={signatureType}
          onCancel={() => this.onBack()}
          user_response={this.state.user_response}
        />

        {this.renderConsentNav()}
        
      </>
    );
  }

  renderConsentNav() {
    const isFirstStep = this.state.currentStep.consent_index === 0 || !this.state.currentStep.consent_index;
    if (isFirstStep) {
      return;
    }
    return (
      <div className="registration-back consent-back">
        <button variant="link" className="btn-link" style={{ border: 'none' }} onClick={() => this.onBack()}>
          {' '}
          {this.props.t('Back')}
        </button>
      </div>
    );
  }

  render() {
    const { t, consentCode, language } = this.props;
    const defaultLanguage = CONFIG.DEFAULT_LANGUAGE;
    const {
      currentStep, consent, showDownload, loadingConsent,
    } = this.state;
    let consentTitle;
    let translatedTitle;
    if (consent) {
      // we will try to parse consent description if it is provided as JSON
      // and fall back to using it as a string for backwards compatibility
      try {
        consentTitle = JSON.parse(consent.description);
      } catch (err) {
        consentTitle = consent.description;
      }
      const titleIsJSON = typeof consentTitle === 'object';
      const consentLanguage = titleIsJSON && consentTitle.hasOwnProperty(language) ? language : defaultLanguage;
      translatedTitle = titleIsJSON ? consentTitle[consentLanguage] : consentTitle;
    }
    if (loadingConsent) {
      return <BlankComponent />;
    }
    if (!consent || !currentStep) {
      return (<ErrorMessage title={t('Error')} content={t("We've hit an error with your consent form.")} />);
    }

    const currentStepIndex = currentStep.consent_index || 0;
    const isConfirmConsent = consent?.components.findIndex((c) => c.type === signatureComponentNames.signatureConfirmation) === currentStepIndex;

    return (
      <>
        <h1 className="mb-2">{isConfirmConsent ? t('Confirming Consent') : translatedTitle}</h1>
        {
          isConfirmConsent
            ? null
            : (
              <p className="label mb-2">
                {t('Screen {{index}} of {{total}}', { index: currentStepIndex + 1, total: consent.components.length })}
              </p>
            )
        }
        {this.renderComponents(consent.components, currentStepIndex)}

        <ConsentDownload
          showDownload={showDownload}
          onComplete={() => this.onDownloadExit()}
          consentCode={consentCode || DEFAULT_CONSENT_CODE}
        />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  language: state.app.language,
  location: ownProps.location,
});

const mapDispatchToProps = {
  questionnaire_resp_post,
  login_get,
  consent_get,
  consent_post,
  update_registration_flow,
  actioncards_firstcall_get,
  doSetConsent,
  doSetConsentLoading,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation(['consent'])(withService(['consentService'])(Consent))));
