import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import WorkflowWrapperComponent from 'src/components/WorkflowNext/WorkflowWrapperComponent';
import PAGES from 'src/constants/pages';
import { WorkflowCodeType } from 'src/services/types/WorkflowTypes';
import { get_user_attributes_get } from 'src/actions/userAction';

type EMRv2PageComponentStateProps = {}

type EMRv2PageComponentProps = {
  get_user_attributes_get: () => Promise<{ data?: any, error?: any }>
} & EMRv2PageComponentStateProps & RouteComponentProps;

function EMRv2PageComponent({ history, get_user_attributes_get }: EMRv2PageComponentProps) {
  const workflowCode: WorkflowCodeType = 'workflow_emr';


  const handleWorkflowExit = async () => {

    await get_user_attributes_get();
    history.push(PAGES.DASHBOARD);
  };

  // @ts-ignore
  return <WorkflowWrapperComponent workflowCode={workflowCode} onWorkflowExit={handleWorkflowExit} />;
}

const mapDispatchToProps = {
  get_user_attributes_get
};

const mapStateToProps = createStructuredSelector<any, EMRv2PageComponentStateProps>({
});

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EMRv2PageComponent));
