import React from 'react';
import { Button } from 'react-bootstrap';
import PAGES from 'src/constants/pages';
import { withTranslation } from 'react-i18next';

const LinkExpired = (props) => (
  <section className="linkexpired">
    <div className="unauth-body-wrapper">
      <h1>{props.t('Oops! This link has expired')}</h1>
      <div className="mb-3">
        {props.t('Don’t worry, we can send you a new one! Just be sure to use it within 24 hours.')}
      </div>

      <Button href={PAGES.LOGIN} variant="primary" type="submit" block>
        {props.t('Login')}
      </Button>

    </div>
  </section>
);

export default withTranslation()(LinkExpired);
