import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get_user_attributes_get, post_user_attributes_post } from '../../actions/userAction';
import Spinner from '../global/spinner';
import { FEATURE } from '../../constants/features';
// import { UNEXPECTED_ERROR } from '../../constants/errors';

class Notifications extends React.Component {
  constructor() {
    super();
    this.state = {
      user: null,
      notifications: null,
      error: null,
    };
  }

  componentDidMount() {
    if (this.props.loginUser) {
      this.setState({ user: this.props.loginUser });
    }

    if (this.props.userAttribute) {
      this.setState({ notifications: this.props.userAttribute });
    } else {
      this.getAttributes();
    }
  }

  getAttributes() {
    this.props.get_user_attributes_get(true)
      .then((resp) => {
        this.setState({ notifications: resp });
      })
      .catch((error) => {
        console.log('getting attribute error', error);
      });
  }

  componentDidUpdate(prevProps) {
    if ((this.props.loginUser && !this.state.user) || this.props.loginUser[this.state.personalModalShow] !== prevProps.loginUser[this.state.personalModalShow]) {
      this.setState({ user: this.props.loginUser });
    }

    if (!prevProps.userAttribute && this.props.userAttribute) {
      this.setState({ notifications: this.props.userAttribute });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  onNotificationChange(code, value) {
    this.props.post_user_attributes_post(code, String(!value), true)
      .then((resp) => {
        this.getAttributes();
      })
      .catch((error) => {
        console.log('posting attribute error', error);
      });
  }

  renderNotification(label, value, checked = false) {
    return (
      <div className="row pt-4 pb-4 border-bottom">
        <div className="col-8 profile-notification-col">
          <label className="font-callout font-weight-bold">
            {this.props.t(label)}
          </label>
        </div>

        <div className="col-4 text-right profile-notification-col">
          <input className="checkbox-toggle" id={value} value={value} defaultChecked={checked} type="checkbox" onChange={() => this.onNotificationChange(value, checked)} />
          <label htmlFor={value} className="checkbox-toggle-label" />
        </div>
      </div>
    );
  }

  renderNotificationsBlock() {
    if (!this.state.notifications) {
      return <Spinner error="renderNotificationsBlock" />;
    }
    if (this.props.featureFlag && !this.props.featureFlag[FEATURE.SURVEY] && !this.props.featureFlag[FEATURE.SURVEY]) {
      return null;
    }
    return (
      <section className="profile-notifications section-component">
        <h2 className="mb-3 h3">{this.props.t('Notifications')}</h2>
        <p>{this.props.t('Configure your email notifications preferences for surveys and ancestry.')}</p>
        <div className="profile-notification-box">
          { this.props.featureFlag && this.props.featureFlag[FEATURE.SURVEY] ? this.renderNotification('New survey', 'new_survey_notifications', this.state.notifications.new_survey_notifications) : null}
          { this.props.featureFlag && this.props.featureFlag[FEATURE.ANCESTRY] ? this.renderNotification('Ancestry updates', 'ancestry_notifications', this.state.notifications.ancestry_notifications) : null}
        </div>
      </section>
    );
  }

  render() {
    return (
      <>
        {this.renderNotificationsBlock()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { get_user_attributes_get, post_user_attributes_post })(withTranslation()(Notifications));
