import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';
import { api_poll } from '../api/api_poll.js';
import { utils_api } from '../utils/utils_api.js';

export function poll_get(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'poll_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.POLL_GET,
        data: response.data.data,
      });
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_poll.get_poll(success, fail);
  };
}

export function poll_post(qn_id, ans_id, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'poll_post');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);

      return response.data.data;
    };
    const fail = (error) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return error;
    };
    return api_poll.post_poll(success, fail, qn_id, ans_id);
  };
}
