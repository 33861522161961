import 'reflect-metadata';
import jwtDecode from 'jwt-decode';
import { inject, injectable } from 'inversify';
import { LOCAL_STORAGE } from 'src/constants/localStorage';
import { utils } from 'src/utils/utils_general';
import { UserData } from './types/UserTypes';
import { login_get  } from 'src/actions/loginAction';
import * as IStore from './types/IStore';

export interface IUserService {
  getCurrentUser(): UserData | null;
  keepCurrentUserAlive(): void;
  stopKeepingCurrentUserAlive(): void;
}

type UserStatus = {
  [key: string]: string
}

export const USER_STATUS: UserStatus = {
  ENROLLMENT: 'enrollment',
  ACTIVE: 'active',
  PENDING: 'pending',
};

@injectable()
class UserService implements IUserService {
  @inject('store') private store!: IStore.IStore;

  private timeoutHandle: any;

  private loadUserFromToken(token: string | null): UserData | null {
    return token ? jwtDecode(token) as UserData : null;
  }

  getCurrentUser(): UserData | null {
    const token = utils.get_local_storage(LOCAL_STORAGE.USER) || null;
    return this.loadUserFromToken(token);
  }

  keepCurrentUserAlive() {
    const currentUser = this.getCurrentUser();

    if (currentUser === null) {
      throw new Error("User is not logged in");
    }
    
    this.startKeepingUserAlive(currentUser);
  }

  stopKeepingCurrentUserAlive() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }

  private renewUser(){
    console.log("Renewing User...");
    
    const { dispatch } = this.store;

    login_get(true)(dispatch).then(() => this.keepCurrentUserAlive());
  }

  private startKeepingUserAlive(user: UserData) {
    const { expiry } = user;

    if(!expiry){
      throw new Error("Invalid User Token, missing expiry date.");
    }

    const currentTimeStamp = Date.parse(new Date().toUTCString());
    const expiryTimeStamp =  Date.parse(expiry);
    const fiveMinutesInMilliseconds = 5 * 60 * 1000;
    const nextRenewal = Math.max(expiryTimeStamp - currentTimeStamp - fiveMinutesInMilliseconds, 0);

    this.timeoutHandle = setTimeout(() => this.renewUser(), nextRenewal);
    console.log(`Keeping user alive. Next renewal in ${(nextRenewal / (1000 * 60)).toFixed(2)} minutes.`);
  }

  checkUserStatus(status: string, user: UserData | null): boolean {
    if (user?.status === status) {
      return true;
    }
    return false;
  }

  isUserStatusActive(user: UserData | null): boolean {
    return this.checkUserStatus(USER_STATUS.ACTIVE, user);
  }

  isUserStatusPending(user: UserData | null): boolean {
    return this.checkUserStatus(USER_STATUS.PENDING, user);
  }

  isUserStatusEnrollment(user: UserData | null): boolean {
    return this.checkUserStatus(USER_STATUS.ENROLLMENT, user);
  }

  isUserEmailVerificationSkipped(user: UserData | null) : boolean {
    if(user && user.email_verification_skipped) {
      return true
    }
    return false;
  }

}

export { UserService };
