import { API_URL } from '../constants/api';
import { utils_api } from '../utils/utils_api';
import { utils } from '../utils/utils_general';

export const api_user = {
  update_user(success, fail, data) {
    return utils_api.auth_put(`${API_URL.USER_PUT}/me`, data, success, fail);
  },
  delete_user(success, fail, type) {
    const data = {
      delete_type: type,
    };
    return utils_api.auth_delete(`${API_URL.USER_DELETE}/me`, data, success, fail);
  },
  get_user_attribute(success, fail) {
    return utils_api.auth_get(API_URL.USER_ATTRIBUTE_GET, success, fail);
  },
  get_one_user_attribute(success, fail, code) {
    const param = `?attribute_code=${code}`;
    return utils_api.auth_get(API_URL.USER_ATTRIBUTE_GET + param, success, fail);
  },
  post_user_attribute(success, fail, code, value) {
    const data = { code, value };
    return utils_api.auth_post(API_URL.USER_ATTRIBUTE_POST, data, success, fail);
  },
  post_user_action(success, fail, actiondata) {
    const data = {
      code: actiondata,
    };
    return utils_api.auth_post(API_URL.USER_ACTION_POST, data, success, fail);
  },
  post_mfa_attribute(success, fail, data) {
    return utils_api.auth_post(API_URL.MFA_POST, data, success, fail);
  },
  post_self_register(success, fail, data) {
    return utils_api.unauth_post(API_URL.USER_SELFREGISTER_POST, data, success, fail);
  },
  post_workflow_complete(success, fail) {
    return utils_api.auth_post(API_URL.USER_WORKFLOWCOMPLETE_POST, '', success, fail);
  },
  get_check_email_avail(success, fail, email) {
    const sanitizeEmail = utils.replaceAllInString(email, '+', '%2B');
    return utils_api.unauth_get(`${API_URL.CHECK_EMAIL_AVAIL}?email=${sanitizeEmail}`, success, fail);
  },
};
