import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { CONFIG } from 'src/constants/config';
import i18n, { devToggleDebugLanguage } from 'src/i18n';
import { LOCAL_STORAGE } from 'src/constants/localStorage';
import { REDUX_ACTION } from 'src/constants/reduxAction';
import { LANGUAGE } from 'src/constants/language';
import { debugLanguage } from 'src/constants/api_config';
import classNames from 'classnames';

const availableLanguages = CONFIG.LANGUAGES;
const defaultLanguage = CONFIG.DEFAULT_LANGUAGE;

class LanguageSwitch extends React.Component {
  onDropdownToggle() {
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  }

  switchLanguage(lang = defaultLanguage) {
    const { dispatch } = this.props;

    document.cookie = `${LOCAL_STORAGE.SELECTED_LANGUAGE}=${lang};path=/`;
    i18n.changeLanguage(lang);
    dispatch({
      type: REDUX_ACTION.LANGUAGE_SET,
      data: lang,
    });
  }

  render() {
    const { app } = this.props;
    const { language } = app || {};
    const selectedLanguage = language || defaultLanguage;

    return (
      <div className="header-languages">
        <DropdownButton title={LANGUAGE[selectedLanguage]} size="sm">
          {availableLanguages.map((lang) => (
            <Dropdown.Item
              key={lang}
              onClick={() => this.switchLanguage(lang)}
              className={classNames('dropdown-item', { selected: lang === selectedLanguage })}
            >
              {LANGUAGE[lang]}
            </Dropdown.Item>
          ))}
          {debugLanguage && <Dropdown.Header><small>Dev Only</small></Dropdown.Header>}
          {debugLanguage && <Dropdown.Divider />}
          {debugLanguage && <Dropdown.Item onClick={devToggleDebugLanguage}><small>Debug Language</small></Dropdown.Item>}
        </DropdownButton>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

// const mapDispatchToProps =>

export default connect(mapStateToProps, null)(LanguageSwitch);
