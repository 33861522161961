import { useTranslation } from "react-i18next";
import WorkflowNextButton from "src/components/workflow/WorkflowNextButton";


// These are the props directly used when returning the component: <InnerWorkflowActions prop1={} prop2={} />
export type InnerWorkflowActionsComponentProps = {
  showStepBack: boolean,
  canContinue: boolean,
  canStepBack: boolean,
  onStepComplete: () => void,
  onStepBack: () => void,
}

export type InnerWorkflowActionsProps = InnerWorkflowActionsComponentProps;

function InnerWorkflowActions({
  showStepBack,
  canContinue,
  canStepBack,
  onStepComplete,
  onStepBack
}: InnerWorkflowActionsProps) {

  const { t } = useTranslation();

  return (
    <div className="text-right">
      {showStepBack && (
        <WorkflowNextButton 
          className={'mr-4'}
          color={'default'}
          variant={'link'} 
          onClick={onStepBack} 
          inline 
          size="sm" 
          disabled={!canStepBack}
          >
          {t("Back")}
        </WorkflowNextButton>
      )}
      <WorkflowNextButton variant={'primary'} onClick={onStepComplete} inline size="sm" disabled={!canContinue}>
        {t("Continue")}
      </WorkflowNextButton>
    </div>
  )
}

const defaultProps: InnerWorkflowActionsComponentProps = {
  showStepBack: false,
  canContinue: false,
  canStepBack: true,
  onStepComplete: () => {},
  onStepBack: () => {},
}

InnerWorkflowActions.defaultProps = defaultProps;

export default InnerWorkflowActions;