export const REGISTRATION_USER_STATUS = {
  REGISTER: 'register',
  NON_REGISTER: 'non-register',
  ACTIVE: 'active',
};

export const REGISTRATION_DB_CODE = {
  SELF_REGISTRATION: 'registration_flow_self',
  REFERRAL_CODE: 'registration_flow_referral',
  ADMIN_INVITE: 'registration_flow_admin',
  SSO: 'registration_flow_sso',
};
