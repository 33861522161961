import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { utils } from 'src/utils/utils_general';

import { post_user_attributes_post } from 'src/actions/userAction';

import { useInjection } from 'src/services/ServiceProvider';
import usePrevious from 'src/utils/usePrevious';
import DevSkipSurveyButton from 'src/components/develop/DevSkipSurvey/DevSkipSurveyButton';
import Spinner from 'src/components/global/spinner.js';

function Survey({
  surveyUrl,
  app,
  errors,
  post_user_attributes_post,
  onComplete,
  survey_attr,
}) {
  const [iframeLoading, setIframeLoading] = useState(false);

  const userService = useInjection('userService');

  const previous = usePrevious({ surveyUrl });

  const onSurveyEnd = async (event) => {
    if (event.data === 'SURVEY_ENDED') {
      // TEMP or no... maybe no - how do we want survey to submit attribute...
      const attr = survey_attr[0]?.split('|')[0];
      await post_user_attributes_post(attr, true);
      onComplete();
    }
  };

  useEffect(() => {
    userService.keepCurrentUserAlive();
    window.addEventListener('message', onSurveyEnd, false);

    return () => {
      userService.stopKeepingCurrentUserAlive();
      window.removeEventListener('message', onSurveyEnd, false);
    };
  }, [userService, onSurveyEnd]);

  useEffect(() => {
    if (!previous?.surveyUrl && !!surveyUrl) {
      setIframeLoading(true);
    }
  }, [surveyUrl]);

  const handleIframeLoad = () => {
    setIframeLoading(false);
  };

  const translatedSurveyUrl = utils.getSurveyLangUrl(surveyUrl, app?.language);

  if (errors) {
    return <p className="text-center">{errors}</p>;
  }

  return (
    <>
      {iframeLoading && <Spinner />}

      <iframe
        src={translatedSurveyUrl}
        title="survey"
        frameBorder="0"
        style={{
          width: '100%',
          minHeight: '80vh',
          overflow: 'hidden',
        }}
        onLoad={handleIframeLoad}
      />

      <DevSkipSurveyButton onSkipSurvey={() => onSurveyEnd({ data: 'SURVEY_ENDED' })} />
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

export default connect(mapStateToProps, { post_user_attributes_post })(Survey);
