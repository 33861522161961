import classNames from "classnames/bind";
import DnaKitDescription from "./DnaKitDescription";
import styles from './DnaKitForm.module.scss';
import React from "react";
import { formNames } from 'src/utils/utils_ga';

const cx = classNames.bind(styles);

// These are the props directly used when returning the component: <OrderDnaKitLayout prop1={} prop2={} />
export type OrderDnaKitLayoutComponentProps = {
  title: React.ReactNode,
  children?: React.ReactNode,
  aside?: React.ReactNode,
}

export type OrderDnaKitLayoutProps = OrderDnaKitLayoutComponentProps;

function OrderDnaKitLayout({ title, children, aside }: OrderDnaKitLayoutComponentProps) {
  return (
    <form data-name={formNames.orderDNAKit} className={'ga4-track ' + cx(styles.dnaKitForm)}>
      <h2>{title}</h2>
      <div className={cx("d-flex flex-column-reverse flex-md-row")}>
        <div className={cx("flex-fill p-md-4 pr-md-4", styles['w-rgc-30em'])}>
          {children}
        </div>
        <aside className={cx("p-2 p-l-3", styles['w-md-250p'])}>
          {aside || <DnaKitDescription />}
        </aside>
      </div>
    </form>
  )
}

export default OrderDnaKitLayout;