import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';
import { utils_api } from '../utils/utils_api.js';
import { api_questionnaire } from '../api/api_questionnaire.js';

export function questionnaire_get(code) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'questionnaire_get');
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_questionnaire.get_questionnaire(success, fail, code);
  };
}

export function questionnaire_resp_post(code, data) {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'questionnaire_resp_post');
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_questionnaire.post_questionnaire_resp(success, fail, code, data);
  };
}
