// file needs to be refactored/componentized
import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import mapboxgl from "!mapbox-gl";
import { Button } from "react-bootstrap";

import { withTranslation } from "react-i18next";
import { CONFIG } from "../../constants/config";
import { mapboxConfig } from "src/constants/api_config";
import RGCLogo from "../../assets/images/logo.png";
// import {ReactComponent as ZoomOutIcon} from '../../assets/icons/zoom-out.svg';
import { utils } from "../../utils/utils_general";
import { ancestry_get } from "../../actions/ancestryAction";
import { showSpinner, hideSpinner } from "../../actions/spinnerAction";
import { latlng_get, getGeometry } from "../../actions/mapquestAction";
import { ReactComponent as AncestryLockIcon } from "../../assets/icons/ancestry-locked.svg";
import { ReactComponent as AncestryUserAgreeIcon } from "../../assets/icons/ancestry-user-agree.svg";
import { ReactComponent as AncestryUserMaybeLaterAgreeIcon } from "../../assets/icons/ancestry-user-maybe-later-agree.svg";
import SuggestedSurveys from "../dashboard/suggested_surveys";
import { ModalReferralBanner } from "../referral/modal-referral-banner";
import PAGES from "src/constants/pages";
import AncestryMessage from "../global/ancestry_message";
import { workflow_get } from "../../actions/workflowAction";
import { utils_workflow } from "../../utils/utils_workflow";
import HtmlContentComponent from "src/components/core/HtmlContent/HtmlContentComponent";

import "mapbox-gl/dist/mapbox-gl.css";
import AncestrySubcompRenderer from "./ancestry_subcomp_renderer";
import { LOCAL_STORAGE } from "src/constants/localStorage";
import { post_user_attributes_post } from "src/actions/userAction";
import { ATTRIBUTES } from "src/constants/attributes";
import { ANCESTRY } from "src/constants/ancestry";

mapboxgl.accessToken = mapboxConfig.accessToken;
const MAPBOX_STYLE = mapboxConfig.styleUrl;
const isMobile = utils.is_mobile();
const DEFAULT_ZOOM = isMobile ? 1 : 2;
const ZOOM_LEVEL_RATIO = isMobile ? 1 : 1;

class Ancestry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: 0,
      lat: 0,
      zoom: DEFAULT_ZOOM,
      ancestry: null,
      map: null,
      showLevel: 1,
      loading: false,
      selectedRegion: {},
      defaultCoordinates: [],
      gatingLevel: null,
      ancestry_agreement_panel: false,
      ancestry_user_agree_later: false,
      latLng: {},
      ancestry_components: null,
      errors: {},
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
  }
  

  componentDidMount() {
    const ANCESTRY_AGREEMENT_LATER = utils.get_local_storage(LOCAL_STORAGE.ANCESTRY_AGREEMENT_LATER) === true ? true : false;
    this.setState({ ancestry_user_agree_later: ANCESTRY_AGREEMENT_LATER });
    this.getAncestry();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }
  
  getAncestry() {
    this.props
      .ancestry_get()
      .then((res) => {
        if (res[0].data && res[0].data.status && res[0].data.status === "block") {
          this.setUpMap(null, 0);
          this.setState({ gatingLevel: 0, loading: false });
        } else {
          const levelsBlocked = res.filter((level) => level?.data?.status === "block");
          const gatingLevel = levelsBlocked.length <= 0 ? 4 : levelsBlocked[0].level - 1;
          const ancestry = res.filter((level) => !level.data.status).map((level) => level.data);
          this.setState({ ancestry, loading: false, gatingLevel });
          this.setUpMap(res, gatingLevel);
        }
      })
      .catch((error) => {
        this.setUpMap(null, -1);
        return error?.response?.status === 423
          ? this.setState({ gatingLevel: -1, loading: false, ancestry_agreement_panel: true })
          : this.setState({ gatingLevel: -1, loading: false, ancestry_agreement_panel: false });
      });

    // we need this in order to lock the user out of ancestry when there is an enforced survey...
    if (!this.props.workflow) {
      this.getDashboardWorkflow();
    } else {
      utils_workflow.checkDashboardWorkflow(this.props.workflow, this.props.userAttribute, this.props.history.push, PAGES.ANCESTRY);
    }
  }

  getDashboardWorkflow() {
    this.props.workflow_get().then((resp) => {
      utils_workflow.checkDashboardWorkflow(resp, this.props.userAttribute, this.props.history.push, PAGES.ANCESTRY);
    });
  }

  setUpMap(ancestryData, gatingLevel) {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: MAPBOX_STYLE,
      zoom: this.state.zoom,
      minZoom: 1,
      maxZoom: 5,
      dragRotate: false,
    });
    map.resize();
    map.on("zoom", () => {
      const currentZoom = Math.round(map.getZoom());
      this.setState({ zoom: currentZoom, level: currentZoom - 1 });
    });
    this.loadMap(map, ancestryData, gatingLevel);
    this.setState({ map });
  }

  loadMap(map, ancestryData, gatingLevel) {
    if (gatingLevel > 0) {
      map.on("load", () => {
        this.loadLayer(map, ancestryData[0].data, 1, gatingLevel);
        if (gatingLevel > 1) {
          this.loadLayer(map, ancestryData[1].data, 2, gatingLevel);
        }
        if (gatingLevel > 2) {
          this.loadLayer(map, ancestryData[2].data, 3, gatingLevel);
        }
        if (gatingLevel > 3) {
          this.loadLayer(map, ancestryData[3].data, 4, gatingLevel);
        }
      });
    }
  }

  async loadLayer(map, regions, level, gatingLevel) {
    if (level == gatingLevel) {
      const levelLngLat = [];
      let requestFinished = 0;
      this.props.showSpinner({ messageI18n: this.props.t("loading_map"), classes: 'cover' });
      for (let i = 0; i < regions.length; i++) {
        this.props.getGeometry(regions[i].code).then((region) => {
          requestFinished++;
          const { properties, geometry, center } = region?.features?.[0];
          levelLngLat.push(center);
          const coords = utils.sortByArrayLength(geometry.coordinates);
          let end = 0;
          for (let index = 0; index < coords.length; index++) {
            if (coords[index].length < 55) {
              end = index;
              break;
            }
            this.setCoordOnMap(map, properties, [coords[index]], index);
          }
          utils.chunkArray(coords.slice(end), 25).map((polygon, index) => {
            this.setCoordOnMap(map, properties, polygon, index + end);
          });
          if (requestFinished == regions.length) {
            this.setMapCenter(levelLngLat);
            this.props.hideSpinner();
          }
        }).catch((error) => {
          console.error("Error loading layer: ", error);
        });
      }
    }
  }

  setCoordOnMap(map, properties, polygon, index) {
    const { name, fill, "fill-opacity": fillOpacity, "stroke-opacity": strokeOpacity, stroke } = properties;
    const strokeLines = strokeOpacity == 1 ? { "fill-outline-color": stroke } : {};
    const polygonData = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            name,
          },
          geometry: {
            type: "Polygon",
            coordinates: polygon,
          },
        },
      ],
    };
    map.addSource(name + index, {
      type: "geojson",
      data: polygonData,
    });

    map.addLayer({
      id: `${name + index}-layer`,
      type: "fill",
      source: name + index,
      paint: {
        "fill-color": fill,
        "fill-opacity": fillOpacity,
        ...strokeLines
      },
    });
  }

  setMapCenter(levelLngLat) {
    const levels = levelLngLat.length;
    if (levels > 0) {
      const sumOfCenters = levelLngLat.reduce((a, b) => [(a[0] + b[0]), (a[1] + b[1])]);
      const centerLevel = [sumOfCenters[0] / levels, sumOfCenters[1] / levels];
      this.setState({ defaultCoordinates: centerLevel }, () => {
        this.resetZoom();
      });
    }
  }

  onRegionClick(region, level) {
    if (
      level === this.state.showLevel &&
      (!this.state.selectedRegion || region.code === this.state.selectedRegion.code) &&
      this.state.zoom !== DEFAULT_ZOOM
    ) {
      this.resetZoom();
    } else if (this.state.latLng[region.region_name]) {
      this.moveMap(this.state.latLng[region.region_name], region, level);
    }
    this.resetScroll();
  }

  moveMap(latLng, region, level) {
    const { map } = this.state;
    map.flyTo({
      center: latLng,
      zoom: level + ZOOM_LEVEL_RATIO,
      speed: 1.5,
      curve: 1,
      easing(t) {
        return t;
      },
    });
    this.changeHighlightAndZoom(level);
    this.setState({
      lat: latLng.lat,
      lng: latLng.lng,
      showLevel: level,
      selectedRegion: region,
    });
  }

  resetScroll() {
    const el_header = document.querySelector(".mapboxgl-canvas");
    if (el_header) {
      el_header.scrollIntoView();
    }
  }

  changeHighlightAndZoom(level) {
    const { map } = this.state;
    const { t } = this.props;

    const level1Names = this.state.ancestry[0] ? this.state.ancestry[0].map((item) => t(item.region_name_i18n)) : [];
    level1Names.forEach((countryName) => {
      const visibility = level <= 1 ? "visible" : "none";
      map.setLayoutProperty(`${countryName}-layer`, "visibility", visibility);
    });

    const level2Names = this.state.ancestry[1] ? this.state.ancestry[1].map((item) => t(item.region_name_i18n)) : [];
    level2Names.forEach((cityName) => {
      const visibility = level === 2 ? "visible" : "none";
      map.setLayoutProperty(`${cityName}-layer`, "visibility", visibility);
    });

    const level3Names = this.state.ancestry[2] ? this.state.ancestry[2].map((item) => t(item.region_name_i18n)) : [];
    level3Names.forEach((cityName) => {
      const visibility = level === 3 ? "visible" : "none";
      map.setLayoutProperty(`${cityName}-layer`, "visibility", visibility);
    });

    const level4Names = this.state.ancestry[3] ? this.state.ancestry[3].map((item) => t(item.region_name_i18n)) : [];
    level4Names.forEach((cityName) => {
      const visibility = level === 4 ? "visible" : "none";
      map.setLayoutProperty(`${cityName}-layer`, "visibility", visibility);
    });
  }

  resetZoom() {
    const { map } = this.state;
    map.flyTo({
      center: this.state.defaultCoordinates,
      zoom: DEFAULT_ZOOM,
      easing(t) {
        return t;
      },
    });
    this.setState({ zoom: DEFAULT_ZOOM });
  }

  renderSelectedRegion() {
    const { region_name_i18n, region_description_i18n } = this.state.selectedRegion;
    const { t } = this.props;
    return (
      <>
        <h2 className="h4 mb-3">{t('Your recent ancestry results')}</h2>
        <h1 className="ancestry-h1 ancestry-h1-region">
          <span className="color-legend" />
          {t(region_name_i18n)}
        </h1>
        <HtmlContentComponent className="ancestry-region-description" markup={t(region_description_i18n)} />
        <Button variant="primary-invert" className="text-center mt-3" block onClick={() => this.setState({ selectedRegion: {} })}>
          {t('Back')}
        </Button>
      </>
    );
  }

  renderAncestryRegionButton(region, level) {
    const { t } = this.props;
    return (
      <Button
        variant="primary-invert"
        block
        className={`ancestry-controls-button ancestry-controls-button-level${level} ${
          this.state.selectedRegion && region.code === this.state.selectedRegion.code ? "selected" : ""
        }`}
        onClick={() => this.onRegionClick(region, level)}
      >
        <span className="color-legend" style={{ backgroundColor: region.color, opacity: 0.5 }}/>
        {t(region.region_name_i18n)}
        <span className="region-percentage">{`${region.percentage}%`}</span>
      </Button>
    );
  }

  renderAncestryList() {
    const { t } = this.props;
    return (
      <>
        <h1 className="ancestry-h1">{t('Ancestry')}</h1>
        <div>
          <p className="color-brand mb-2">
            {t('Nice! Explore your ancestry')}
          </p>
        </div>
        <h2 className="h4 mb-3">{t('Your recent ancestry results')}</h2>
        <div className="ancestry-list">
          {this.state.ancestry[0].map((item, key) => (
            <div key={key} className="ancestry-country mt-3">
              {this.renderAncestryRegionButton(item, 1)}

              {this.state.gatingLevel > 1 && this.state.ancestry[1] ? (
                <article>
                  {this.state.ancestry[1]
                    .filter((item2) => item.code === item2.parent_code)
                    .map((item2, key2) => (
                      <div key={key2}>
                        {this.renderAncestryRegionButton(item2, 2)}

                        {this.state.gatingLevel > 2 && this.state.ancestry[2] ? (
                          <article>
                            {this.state.ancestry[2]
                              .filter((item3) => item2.code === item3.parent_code)
                              .map((item3, key3) => (
                                <div key={key3}>
                                  {this.renderAncestryRegionButton(item3, 3)}

                                  {this.state.gatingLevel > 3 && this.state.ancestry[3] ? (
                                    <article>
                                      {this.state.ancestry[3]
                                        .filter((item4) => item3.code === item4.parent_code)
                                        .map((item4, key4) => (
                                          <div key={key4}>{this.renderAncestryRegionButton(item4, 4)}</div>
                                        ))}
                                    </article>
                                  ) : null}
                                </div>
                              ))}
                          </article>
                        ) : null}
                      </div>
                    ))}
                </article>
              ) : null}
            </div>
          ))}
        </div>
      </>
    );
  }

  renderAncestryPanel() {
    if (!this.state.ancestry) {
      return null;
    }
    const showPanel = true;
    const { t } = this.props;
    const { region_description_i18n } = this.state.selectedRegion;
    const region_description = t(region_description_i18n);
    return (
      <>
        {showPanel ? (
          <div className="ancestry-panel p-3 rounded">
            <button className={`ancestry-zoom-out`} onClick={() => this.resetZoom()}>
              {t('center world map')}
            </button>
            {region_description ? (
              this.renderSelectedRegion()
            ) : (
              <>
                <div className="ancestry-controls">{this.renderAncestryList()}</div>
              </>
            )}
          </div>
        ) : (
          renderAncestryConsentPanel()
        )}
      </>
    );
  }

  // class="px-5 pb-4 pb-sm-0 btn btn-link"
  updateUserAncestryResults(e, user_decisition) {
    const user_agree_later = true;
    let attribute = true;
    if (user_decisition === ANCESTRY.USER_AGREE_MAYBE_LATER) {
      attribute = "later";
    }

    this.props
      .post_user_attributes_post(ATTRIBUTES.ACCEPT_ANCESTRY_RESULTS, attribute)
      .then(() => {
        if (attribute === true) {
          this.getAncestry();
        } else {
          utils.set_local_storage(LOCAL_STORAGE.ANCESTRY_AGREEMENT_LATER, user_agree_later);
          this.setState({ ancestry_user_agree_later: user_agree_later });
        }
      })
      .catch((error) => {
        this.setState({ errors: { system: `We weren't able to process the request, please try again later.`} });
      });
  }

  renderAncestryUserAgree() {
    let ancestry_agree_components = [];
    if (utils.is_json_string(this.props.t("ancestry_agreement_description"))) {
      ancestry_agree_components = JSON.parse(this.props.t("ancestry_agreement_description"));
    }
    return (
      <>
        <div className="ancestry-locked-map-cover" />
        <div className="ancestry-locked">
          <div className="ancestry-locked-box-wrapper">
            <div className="ancestry-locked-icon-container">
              <AncestryUserAgreeIcon />
            </div>
            <div className="ancestry-locked-content">
              <div className="ancetry-locked-content-title">
                <HtmlContentComponent markup={this.props.t("ancestry_agreement_title")} />
              </div>
              <div className="ancetry-conset-content-subtitle">
                <HtmlContentComponent markup={this.props.t("ancestry_agreement_subtitle")} />
              </div>

              <div className="ancetry-locked-content-description">
                {ancestry_agree_components.length > 0 ? (
                  ancestry_agree_components.map((c, key) => <AncestrySubcompRenderer key={key} type={c.type} component={c} subcomponentKey={key} />)
                ) : (
                  <HtmlContentComponent markup={this.props.t("ancestry_agreement_description")} />
                )}
              </div>
              <div className="ancestry-locked-btns pt-3 pb-3 flex-sm-row justify-content-sm">
                <button
                  className="py-3 pr-3 pr-sm-3  inlineText btn btn-link"
                  onClick={(e) => {
                    this.updateUserAncestryResults(e, ANCESTRY.USER_AGREE_MAYBE_LATER);
                  }}
                >
                  {this.props.t("Maybe Later")}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    this.updateUserAncestryResults(e, ANCESTRY.USER_AGREE);
                  }}
                >
                  {this.props.t("I Agree")}
                </button>
              </div>
            </div>

            <div className="ancestry-locked-suggested-surveys">
              <SuggestedSurveys type="card" />
            </div>
          </div>
        </div>
      </>
    );
  }

  renderAncestryLaterAgreement() {
    let ancestry_components = [];
    if (utils.is_json_string(this.props.t("ancestry_agreement_later_description"))) {
      ancestry_components = JSON.parse(this.props.t("ancestry_agreement_later_description"));
    }
    return (
      <>
        <div className="ancestry-locked-map-cover" />
        <div className="ancestry-locked">
          <div className="ancestry-locked-box-wrapper">
            <div className="ancestry-locked-icon-container">
              <AncestryUserMaybeLaterAgreeIcon />
            </div>
            <div className="ancestry-locked-content">
              <div className="ancetry-locked-content-title">
                <HtmlContentComponent markup={this.props.t("ancestry_agreement_later_title")} />
              </div>
              <div className="ancetry-conset-content-subtitle">
                <HtmlContentComponent markup={this.props.t("ancestry_agreement_later_subtitle")} />
              </div>

              <div className="ancetry-locked-content-description">
                {ancestry_components.length > 0 ? (
                  ancestry_components.map((c, key) => <AncestrySubcompRenderer key={key} type={c.type} component={c} subcomponentKey={key} />)
                ) : (
                  <HtmlContentComponent markup={this.props.t("ancestry_agreement_later_description")} />
                )}
              </div>

              <div className="ancestry-locked-btns pt-3 pb-3 flex-sm-row justify-content-sm">
                <Button
                  variant="link"
                  className="py-3 pr-3 pr-sm-3 inlineText btn btn-link"
                  onClick={() =>
                    this.setState({ ancestry_user_agree_later: false }, utils.set_local_storage(LOCAL_STORAGE.ANCESTRY_AGREEMENT_LATER, false))
                  }
                >
                  {this.props.t("Change Response")}
                </Button>
                <Link className="btn btn-primary" to={PAGES.DASHBOARD}>
                  {this.props.t("Go Home")}
                </Link>
              </div>
            </div>
            <div className="ancestry-locked-suggested-surveys">
              <SuggestedSurveys type="card" />
            </div>
          </div>
        </div>
      </>
    );
  }

  renderUserAgreePanel() {
    return <>{this.state.ancestry_user_agree_later ? this.renderAncestryLaterAgreement() : this.renderAncestryUserAgree()}</>;
  }

  renderLockedMessage() {
    const unavailable = this.state.gatingLevel === -1;
    const locked = this.state.gatingLevel === 0;

    let ancestry_components = [];
    if (utils.is_json_string(this.props.t("ancestry_components"))) {
      ancestry_components = JSON.parse(this.props.t("ancestry_components"));
    }

    return (
      <>
        <div className="ancestry-locked-map-cover" />
        <div className="ancestry-locked">
          <div className="ancestry-locked-box-wrapper">
            <div className="ancestry-locked-icon-container">
              <AncestryLockIcon />
            </div>
            <div className="ancestry-locked-content">
              <div className="ancetry-locked-content-title">
                <HtmlContentComponent markup={this.props.t("ancestry_unavailable_title")} />
              </div>
              <div className="ancetry-locked-content-subtitle">
                <HtmlContentComponent markup={this.props.t("ancestry_unavailable_subtitle")} />
              </div>

              {ancestry_components.length > 0 ? (
                ancestry_components.map((c, key) => <AncestrySubcompRenderer key={key} type={c.type} component={c} subcomponentKey={key} />)
              ) : (
                <HtmlContentComponent markup={this.props.t("ancestry_components")} />
              )}
            </div>

            <div className="ancestry-locked-suggested-surveys">
              <SuggestedSurveys type="card" />
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <section className="ancestry">
        <div className="embed-responsive embed-responsive-16by9">
          <div ref={(el) => (this.mapContainer = el)} className="embed-responsive-item" style={{ height: "60vh" }} />
        </div>
        {this.state.gatingLevel > 0
          ? this.renderAncestryPanel()
          : this.state.ancestry_agreement_panel && CONFIG.ANCESTRY_USER_AGREE
          ? this.renderUserAgreePanel()
          : this.renderLockedMessage()}
        {this.ancestry_user_agree && this.state.gatingLevel > 0 && this.state.gatingLevel < 4 ? (
          <div className="ancestry-page-message">
            <AncestryMessage />
          </div>
        ) : null}

        <div className="dashboard-loader" style={!this.state.loading ? { opacity: 0 } : { opacity: 1 }}>
          <div className="dashboard-loader-wrapper">
            <img className="rgc-spinner" src={RGCLogo} alt="" />
            <div className="mt-2 text-center font-callout">{this.props.t("loading your ancestry")}</div>
          </div>
        </div>

        {CONFIG.REFERRAL && CONFIG.REFERRAL_DASHBOARD_BANNER ? (
          <div className="ancestry-referral-banner">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-9 col-xl-8 center-section">
                  <ModalReferralBanner />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
});

export default withRouter(
  connect(mapStateToProps, { ancestry_get, latlng_get, getGeometry, workflow_get, post_user_attributes_post, showSpinner, hideSpinner })(withTranslation('ancestry')(Ancestry))
);
