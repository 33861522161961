import { Case, Fallback, When } from 'src/components/core/PredicateRouter';
import { GenericWorkflowStep, WorkflowComponentType } from 'src/services/types/WorkflowTypes';
import classnames from 'classnames/bind';

import { TextMediaWrapper } from 'src/components/WorkflowNext/WorkflowSteps/TextMediaWrapper';
import { Loading } from 'src/components/WorkflowNext/WorkflowSteps/Loading';
import { EmrWrapper } from 'src/components/WorkflowNext/WorkflowSteps/EmrWrapper';
import styles from './WorkflowComponentRenderer.module.scss';
import OrderDnaKitStep from '../WorkflowSteps/OrderDnaKit/OrderDnaKitStep';

const cx = classnames.bind(styles);

export type WorkflowComponentRendererComponentProps = {
  isCurrentStepLoading?: boolean,
  isWorkflowCompleted?: boolean,
  component?: WorkflowComponentType,
  currentStep?: GenericWorkflowStep,
  onComponentComplete: () => Promise<void>,
  onSkipWorkflow?: ()=>Promise<any> | null;
  onExitWorkflow?: ()=>Promise<any> | null;
};

function WorkflowComponentRendererComponent({
  isCurrentStepLoading, isWorkflowCompleted, component, currentStep, onComponentComplete, onSkipWorkflow, onExitWorkflow,
}: WorkflowComponentRendererComponentProps) {
  const componentProps = { currentStep, onComponentComplete, onSkipWorkflow: onSkipWorkflow || null, onExitWorkflow };

  const loading = !!isCurrentStepLoading;

  const workflowCompleted = !!isWorkflowCompleted;

  return (
    <div className={cx(styles.workflowComponentRenderer, 'p-4', { 'd-flex': loading, [cx(styles.h350)]: loading })}>
      <Case>
        <When predicate={loading} component={Loading} />

        <When predicate={workflowCompleted}>
          Completed...
          <Loading />
        </When>

        <When predicate={component === 'text_media'} component={TextMediaWrapper} props={componentProps} />

        <When predicate={component === 'emr'} component={EmrWrapper} props={componentProps} />

        <When predicate={component === 'order_dna_kit'} component={OrderDnaKitStep} props={componentProps} />

        {/* Add here all the necessary <When /> elements... */}

        <Fallback>
          {/* TODO: RGC-2212: Deal with unknown components and with whatever we show while finishing the workflow. */}
          <h1>What is this?</h1>
        </Fallback>
      </Case>
    </div>
  );
}

export default WorkflowComponentRendererComponent;
