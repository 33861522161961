import React from 'react';
import ConsentSubcompRenderer from '../consent_subcomp_renderer';
import { CONSENT_COMPONENT } from '../../../constants/consentComponents';
import { withTranslation } from 'react-i18next';

class ConsentCompFormQn extends React.Component {
  prepData(hasQuestions, numOfQuestions) {
    let componentBody = this.props.component.body;

    // REARRANGING QUESTIONS STRUCTURE ...
    // group all qn into questions
    // component body is the specific types. ie text, qn
    const questions = { qn: [], type: CONSENT_COMPONENT.QN_LIST };
    if (hasQuestions && numOfQuestions > 1) {
      componentBody = [];
      this.props.component.body.forEach((c, i) => {
        if (c.type.includes(CONSENT_COMPONENT.QN_LIST)) {
          questions.qn.push(c.qn);
          if (this.props.component.body[i + 1] && this.props.component.body[i + 1].type !== 'qn') {
            componentBody.push(c);
          }
        } else {
          componentBody.push(c);
        }
      });
      // if only containing qns, component body will have nothing else, so add the qn type to it
      if (componentBody.length === 0) {
        componentBody.push({ type: CONSENT_COMPONENT.QN_LIST });
      }
    }
    return { componentBody, questions };
  }

  render() {
    const { component, index, user_response, onComponentComplete, t } = this.props;
    const { title } = component;
    const numOfQuestions = component.body.filter((c) => c.type === CONSENT_COMPONENT.QN_LIST).length;
    const hasQuestions = numOfQuestions > 0;
    const comp = this.prepData(hasQuestions, numOfQuestions);
    const { componentBody } = comp;
    const { questions } = comp;

    return (
      <div className="consent-component">
        {title && title !== '' ? <h2 className="h5 mb-3">{t(title)}</h2> : null}
        <div className="consent-subcomponents">
          {componentBody.map((c, key) => (
            <ConsentSubcompRenderer
              key={key}
              type={c.type}
              component={hasQuestions && numOfQuestions > 1 ? questions : c}
              index={index}
              user_response={user_response}
              onQnComplete={(value) => onComponentComplete(value)}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ConsentCompFormQn);
