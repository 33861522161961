import { useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import PAGES from 'src/constants/pages';
import AuthHeaderWorkflow from './auth_header_workflow';
import WorkflowPauseButton from './workflow_pause_button';
import utilsGA from 'src/utils/utils_ga';
import {connect} from 'react-redux';

export const WorkflowLayout = (props) => {
  const { 
    aside = {}, 
    flowName, 
    onWorkflowExit,
    progress,
    workflow,
    additionalClass,
    rightSection,
    wide,
    mainSection, // This property should be deprecated
    children 
  } = props;
  
  const { t } = useTranslation();
  const { name, details, step } = aside || {};
  const { allow_pause, subtitle, description } = details || {};
  const resolvedFlowName = flowName || (step || 'Zygene');
  const isEnrollment = window.location.pathname.includes(PAGES.ENROLLMENT);
  const workflowName = isEnrollment ? t('Enrollment') : t(resolvedFlowName);

  useEffect(() => {
    if (workflow) {
      utilsGA.sendWorkflowStartToGA(workflowName, workflow);
    }
  }, [workflow]);

  const handleWorkflowExit = () => {
    if (typeof onWorkflowExit === 'function') {
      onWorkflowExit();
    }
  };

  return (
    <div>
      <AuthHeaderWorkflow progress={progress} workflowName={workflowName} workflow={workflow} />
      <section className={`workflow container pt-5 pb-md-5 ${additionalClass || ''}`}>
        <div className="row">
          <aside className="col-12 col-lg-3 col-xl-2 left-section workflow-left-section">
            {name ? <h2 className="mb-3 h3">{t(name)}</h2> : null}
            {subtitle ? <p className="mb-3">{t(subtitle)}</p> : null}
            {description ? <p className="mb-3">{t(description)}</p> : null}
            {allow_pause ? <WorkflowPauseButton onWorkflowExit={handleWorkflowExit} step={aside} type={isEnrollment ? 'enrollment' : 'dashboard'} /> : null}
          </aside>
          <div className={`col-12 col-lg-9 ${wide ? 'col-xl-10' : 'col-xl-8'} center-section`}>
            <div className="workflow-component">
              {mainSection || children|| null}
            </div>
          </div>
          {!wide
            ? (
              <aside className="col-12 col-lg-9 col-xl-2 ml-auto workflow-aside">
                {rightSection || null}
              </aside>
            )
            : null}
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  workflow: state.workflow.workflow,
});
export default connect(mapStateToProps)(withTranslation('workflow')(WorkflowLayout));
