/**
* @file components/login/linkexpired.js
*/

import React from 'react';
import { withTranslation } from 'react-i18next';
import { utils } from '../../utils/utils_general';
import IconGenerator from '../icons/icon_generator';
import { TRUNCATE } from '../../constants/truncateString';
import utilsGA from '../../utils/utils_ga';
import ActionCardContent from '../../data/actioncard-content.json';
import HtmlContentComponent from 'src/components/core/HtmlContent/HtmlContentComponent';

const i18nNamespace = 'actioncards';
class ActionCard extends React.Component {
  getActionCardContent(card) {
    const {
      code = null,
      uuid = null,
      is_progress_card,
      workflow_code = null
    } = card;
    if (!code) return null;
    const i18nCodePrefix = workflow_code ? `actioncard_${uuid}` : code;
    const title = is_progress_card ? card.content.title : this.props.t(`actionCards:${i18nCodePrefix}_title`);
    const subtitle = is_progress_card ? card.content.subtitle : this.props.t(`actionCards:${i18nCodePrefix}_subtitle`);
    const description = is_progress_card ? card.content.description : this.props.t(`actionCards:${i18nCodePrefix}_description`);
    return {
      ...ActionCardContent[code],
      title,
      subtitle,
      description,
    };
  }

  actionCardClick(disable, href, survey_id, cardSubtitle){
    utilsGA.ga4EventClick({
      classList: ["action-card"],
      id: survey_id,
      href,
      innerText: cardSubtitle
    });
    if(!disable){
      this.props.action(href, survey_id);
    }
  }

  render() {
    const { card, t } = this.props;
    const { code, uuid, id, limesurvey_id, workflow_code = null } = card;
    // we wanna base the iconKey on some permanent card id
    // different types of cards have different permanent ids, so we try several of them
    const iconKey = limesurvey_id || uuid || id || code;
    const cardStatus = card.status;
    const cardLocked = card.locked;

    const content = this.getActionCardContent(card);
    if (!content) {
      return null;
    }

    let { href = null } = content;
    const { title } = content;
    if (!href && workflow_code) {
      href = `/gateway/workflow/${workflow_code}`;
    }

    // future data support, it is not been used on the current setup in case of never needed can be removed
    // const subtitle = card.survey_title && content.subtitle.includes('{')
    //   ? content.subtitle.replace('{survey_title}', card.survey_title)
    //   : `${content.subtitle}: ${card.survey_title}`;
    const subtitle = card.survey_title
      ? card.survey_title
      : content.subtitle;
    const description = card.survey_description
      ? (card.survey_description !== '' ? utils.remove_html(card.survey_description) : '')
      : content.description;

    if (!card.enable) {
      return false;
    }

    const disable = cardStatus === 'completed' || cardLocked;
    const disableClass = disable ? 'disabled-card' : '';

    const cardTitle = t(`${i18nNamespace}:${title}`);
    const cardSubtitle = t(`${i18nNamespace}:${subtitle}`);
    const cardDesc = t(`${i18nNamespace}:${description}`);

    return (
      <div className="action-card-wrapper col-12 col-md-3 mb-2" data-actioncard-code={code}>
        {cardStatus !== 'pending' ? <div className="checkmark" /> : null}
        {cardLocked ? <div className="lock" /> : null}
        <button
          className={`action-card bg-secondary p-3 rounded clearfix ${disableClass}`}
          tabIndex={cardStatus !== 'completed' ? 0 : -1}
          onClick={() => this.actionCardClick(disable, href, card.survey_id, cardSubtitle)}
        >
          <div className="action-card-img">
            <div className={`action-card-img-wrapper ${disableClass}`}>
              <IconGenerator iconKey={iconKey} iconType="actioncard" />
            </div>
          </div>
          <div className="action-card-description pb-0 pb-md-3">
            <p className="color-brand single-line-clamp">{cardTitle}</p>
            <HtmlContentComponent tag={"p"} className="h4 color-brand  action-card-subtitle single-line-clamp" markup={cardSubtitle || '' } />
            {this.props.showDetails && cardStatus !== 'completed' ? <p className="mobile-description single-line-clamp">{cardDesc}</p> : null}

            <div className="action-cards-detail-wrapper">
              <div className="action-cards-detail p-3">
                <p className="desktop-only mb-2 single-line-clamp" style={{ lineHeight: '16px' }}>{cardTitle}</p>
                <HtmlContentComponent tag={"p"} className="h4 desktop-only action-card-subtitle mb-2 single-line-clamp" markup={cardSubtitle || '' } />
                <p className="small single-line-clamp">{description ? cardDesc : null}</p>
                <div className="btn-sm action-cards-detail-button desktop-only">{this.props.t("Let's go!")}</div>
              </div>
            </div>
          </div>
          {!cardLocked && <div className="action-card-mobile-chevron"><div className="symbol-chevron" /></div>}
        </button>
      </div>
    );
  }
}

export default withTranslation('actioncards')(ActionCard);
