import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

import { ReferralLink, ReferralLinkSmall } from './referral-link';
import { ReferralButtons } from './referral-buttons';

import PAGES from 'src/constants/pages';

import styles from './landing-page-referral-banner.module.scss';

export function LandingPageReferralBanner(props) {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        styles.referralBanner,
        'rounded mb-4 position-relative overflow-hidden'
      )}
    >
      <div className={classNames(styles.description, 'p-4 position-relative')}>
        <h3 className="mb-3">{t('Landing_Referral_Banner_tittle')}</h3>
        <p>
          {t(
            'Landing_Referral_Banner_description'
          )}
        </p>
        <div className='pt-2 pb-2 flex-row flex-wrap referral-links-wrapper'>
          <ReferralLink />
          <ReferralButtons />  
        </div>
        <div className="pt-5 mb-3 d-block d-sm-none">
          <ReferralLinkSmall />
        </div>
        <div className='pb-5 pt-2 d-flex flex-row flex-wrap referral-buttons-wrapper'>
          <Button
            className="primary-invert btn"
            variant="primary"
            href={PAGES.ABOUT_US}
            type="submit"
          >
            {t('Learn more about the study')}
          </Button>
          <Button
            className="btn btn-primary"
            variant="primary"
            href={PAGES.SIGNUP}
            type="submit"
          >
            {t('Sign up now')}
          </Button>
        </div>
        
        
      </div>
    </div>
  );
}
