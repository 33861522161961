import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';
import { api_verification } from '../api/api_verification';
import { utils_api } from '../utils/utils_api.js';

export function verify_post(data, hideSpinner = false, language) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'verify_post');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data;
    };

    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_verification.verify(success, fail, data, language);
  };
}

export function submit_verification_post(id, answers) {
  const data = {
    idNumber: id,
    questions: answers,
  };
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'submit_verification_post');
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_verification.submit_verification_question(success, fail, data);
  };
}
