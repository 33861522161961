// expects a onComplete prop as this component will be used within workflow

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { LOCAL_STORAGE } from '../../constants/localStorage';
import AuthHeaderWorkflow from './auth_header_workflow';
import Spinner from '../global/spinner';

class EMRComplete extends React.Component {
  componentDidMount() {
    const url = new URL(window.location.href);
    const iss = url.searchParams.get('iss');
    const success = url.searchParams.get('success');
    const emrLocalStorageData = localStorage.getItem(`${LOCAL_STORAGE.EMR_CONNECT_PREFIX}${iss}`);
    if (!emrLocalStorageData || !iss) return;
    const emrStoredItem = JSON.parse(emrLocalStorageData);
    if (success === 'true') {
      emrStoredItem.complete = true;
      emrStoredItem.errored = false;
    } else {
      emrStoredItem.complete = true;
      emrStoredItem.errored = true;
    }
    localStorage.setItem(`${LOCAL_STORAGE.EMR_CONNECT_PREFIX}${iss}`, JSON.stringify(emrStoredItem));

    // window.close();
  }

  render() {
    return (
      <>
        <AuthHeaderWorkflow workflowName="EMR Complete" workflow={null} />
        <section className="workflow container pt-5 pb-md-5">
          <div className="row text-center">
            <Spinner relative />
            {/* <p>{this.props.t('Thank you for connecting your EHR data, you can close this tab if its not closed automatically by your browser')}</p> */}
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});
export default withRouter(connect(mapStateToProps)(withTranslation()(EMRComplete)));
