import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { delete_user_delete } from '../../actions/userAction';
import { UNEXPECTED_ERROR } from '../../constants/errors';
import PAGES from 'src/constants/pages';
import { utils } from '../../utils/utils_general';
import Spinner from '../global/spinner';
import HtmlContentComponent from 'src/components/core/HtmlContent/HtmlContentComponent';

const DELETE_TYPE = {
  SOFT: 'soft',
  HARD: 'hard',
};

class DeleteAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
      loading: false,
      confirmPage: false,
      deleteType: null,
    };
  }

  componentDidMount() {
    if (this.props.loginUser) {
      this.setState({ user: this.props.loginUser });
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.loginUser && !this.state.user) || this.props.loginUser[this.state.personalModalShow] !== prevProps.loginUser[this.state.personalModalShow]) {
      this.setState({ user: this.props.loginUser });
    }
  }

  toggleDeleteModal(state) {
    this.setState({ show: state, confirmPage: false, deleteType: null });
  }

  confirmDelete() {
    this.setState({ confirmPage: false, loading: true });
    this.props.delete_user_delete(this.state.deleteType, true)
      .then(() => {
        setTimeout(() => {
          utils.clear_local_storage();
          window.location = PAGES.ACCOUNT_DELETED;
        }, 2500);
      })
      .catch((error) => this.setState({ error: UNEXPECTED_ERROR }));
  }

  handleChangeDeleteType(e) {
    this.setState({ deleteType: e.target.value });
  }

  renderModalBody() {
    if (this.state.confirmPage) {
      // render confirm
      return this.renderConfirmDeletePage();
    } if (this.state.loading) {
      // render loading
      return (
        <div className="text-center p-3">
          <p className="h3 pb-4">{this.props.t('Deleting your account, you will be redirected shortly.')}</p>
          <Spinner error="deleting account" />
        </div>
      );
    }
    // render normal
    return this.renderDeleteInfoPage();
  }

  renderConfirmDeletePage() {
    const { t } = this.props;

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('Are you sure you want to delete your account?')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <p className="h4 mb-3">{t('This is the final step to delete your account.')}</p>

            <p className="h4 mb-2">
              {t('You selected to:')}
              {' '}
            </p>
            <HtmlContentComponent className="mb-1" markup={t(this.state.deleteType === DELETE_TYPE.HARD ? 'content:delete_type_language_hard_title' : 'content:delete_type_language_soft_title')} />
            <HtmlContentComponent markup={t(this.state.deleteType === DELETE_TYPE.HARD ? 'content:delete_type_language_hard_description' : 'content:delete_type_language_soft_description') } />

            <p>{t('Upon deletion, you’ll lose immediate access to system.')}</p>

            <p>{t('You will be emailed a confirmation of your account deletion.')}</p>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.setState({ confirmPage: false })}>{t("Back")}</Button>
          <Button variant="secondary" onClick={() => this.confirmDelete()}>{t("Delete")}</Button>
        </Modal.Footer>
      </>
    );
  }

  renderDeleteInfoPage() {
    const { t } = this.props;
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('You are about to delete your account')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="h4 mb-2">{t('You have two options:')}</p>
          <div className="input-wrapper">
            <input type="radio" checked={this.state.deleteType === DELETE_TYPE.SOFT} id="soft_delete" name="delete_type" value={DELETE_TYPE.SOFT} onChange={(e) => this.handleChangeDeleteType(e)} />
            <HtmlContentComponent tag={"label"} htmlFor="soft_delete" markup={t('content:delete_type_language_soft_title')} />
          </div>
          <HtmlContentComponent markup={t('content:delete_type_language_soft_description') } />

          <div className="input-wrapper">
            <input type="radio" checked={this.state.deleteType === DELETE_TYPE.HARD} id="hard_delete" name="delete_type" value={DELETE_TYPE.HARD} onChange={(e) => this.handleChangeDeleteType(e)} />
            <HtmlContentComponent tag={"label"} htmlFor="hard_delete" markup={t('content:delete_type_language_hard_title')} />
          </div>
          <HtmlContentComponent markup={t('content:delete_type_language_hard_description') } />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.toggleDeleteModal(false)}>{t('Cancel')}</Button>
          <Button variant={this.state.deleteType ? 'secondary' : 'disabled'} onClick={() => this.setState({ confirmPage: true })}>{t('Proceed')}</Button>
          {/* <Button variant="danger" onClick={() => this.confirmDelete()}>Delete</Button> */}
        </Modal.Footer>
      </>
    );
  }

  renderDeleteConfirmationModal() {
    return (
      <Modal
        show={Boolean(this.state.show)}
        onHide={() => this.toggleDeleteModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-account-modal">
        {this.renderModalBody()}
      </Modal>
    );
  }

  render() {
    return (
      <>
        <Button variant="primary" className="profile-cta" onClick={() => this.toggleDeleteModal(true)}>{this.props.t('Delete Account')}</Button>
        {this.renderDeleteConfirmationModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { delete_user_delete })(withTranslation()(DeleteAccount));
