import { CONFIG } from '../constants/config';
import { utils } from './utils_general';

export const utils_questionnaire = {
  get_question_data: (api_questions, text_source = {}) => {
    const isKnowledgeCheck = api_questions.parent_code === 'knowledge_check' || api_questions.child[0].parent_code === 'knowledge_check';
    const qns = api_questions.child
      .filter((q) => q.entity === 'qn')
      .map((q) => {
        let { text, type, view_type } = q;
        if (!text) {
          text = {
            title: utils.get_text_content('questions_title', q.code, text_source),
            subtitle: utils.get_text_content('questions_subtitle', q.code, text_source),
            hintError: utils.get_text_content('questions_hint_error', q.code, text_source),
            hintSuccess: utils.get_text_content('questions_hint_success', q.code, text_source),
          };
        } else if (typeof text !== 'string') {
          if (typeof text[CONFIG.CONSENT_LANGUAGE] === 'string') {
            // handles case for consent qn?
            text = {
              title: text[CONFIG.CONSENT_LANGUAGE],
              subtitle: ' ',
              hintError: 'Good attempt.', // default text
              hintSuccess: 'Correct.', // default text
            };
          } else if (typeof text.title[CONFIG.CONSENT_LANGUAGE] === 'string') {
            text = {
              title: text.title[CONFIG.CONSENT_LANGUAGE],
              subtitle: text.subtitle ? text.subtitle[CONFIG.CONSENT_LANGUAGE] : ' ',
              hintError: text.hintError ? text.hintError[CONFIG.CONSENT_LANGUAGE] : 'Good attempt.', // default text
              hintSuccess: text.hintSuccess ? text.hintSuccess[CONFIG.CONSENT_LANGUAGE] : 'Correct.', // default text
            };
          }
        }
        return {
          code: q.code,
          text,
          type,
          view_type,
          selected_answers: [],
          answers: q.child
            .filter((a) => a.entity === 'ans')
            .sort((a, b) => a.pri - b.pri)
            .map((a) => ({
              id: a.code,
              text: a.text ? (typeof a.text === 'string' ? a.text : a.text[CONFIG.CONSENT_LANGUAGE]) : utils.get_text_content('answers_text', a.code, text_source),
              correct: a.correct,
            })),
        };
      });
    const text = {};
    if (isKnowledgeCheck) {
      text.knowledgeCheckTitle = utils.get_text_content('knowledge_check_title', api_questions.code, text_source);
      text.knowledgeCheckDescription = utils.get_text_content('knowledge_check_subtitle', api_questions.code, text_source);
    }
    return {
      code: api_questions.code,
      knowledge_check: isKnowledgeCheck,
      text,
      qns,
    };
  },
};
