import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { utils } from 'src/utils/utils_general';
import { LOCAL_STORAGE } from 'src/constants/localStorage';
import { get_user_attributes_get } from 'src/actions/userAction';
import { utils_signup } from 'src/utils/utils_signup';
import { WORKFLOW } from 'src/constants/workflow';
import { update_registration_flow } from 'src/actions/registrationFlowAction';
import { utils_registration_flow } from 'src/utils/utils_registration_flow';
import SignupProgress from '../signup_progress';
import Verification from '../../global/verification';
import { clearReloadState } from './service_regflow';

const CURRENT_REG_STEP = WORKFLOW.VERIFICATION;

class SignupVerification extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      reg_flow: null,
      failedVerification: false,
    };
  }

  componentDidMount() {
    const reg_flow = this.props.registrationFlow || utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
    if (reg_flow) {
      const currentStep = reg_flow.find((c) => c.component === WORKFLOW.VERIFICATION);
      if (currentStep && currentStep.value) {
        this.goToRegisteredNextStep(reg_flow);
      }
      this.setState({ reg_flow });
    }
  }

  handleFailVerification() {
    this.setState({ failedVerification: true });
  }

  goToRegisteredNextStep(reg_flow) {
    clearReloadState();
    const currentStep = utils_registration_flow.current_registration_component(reg_flow, CURRENT_REG_STEP);

    this.props.get_user_attributes_get()
      .then((res) => {
        const attr_errors = utils_registration_flow.checkAttrCompleted(currentStep.exit_attribute, res);
        if (attr_errors.length > 0) {
          this.setState({ errors: attr_errors });
        } else {
          utils_signup.goToNextRegistrationStep(reg_flow, currentStep, res, this.props.update_registration_flow, this.props.history.push, this.props.post_workflow_complete_post);
        }
      })
      .catch((errors) => this.setState({ errors }));
  }

  renderFailFlow() {
    return (
      <>
        <h1> {this.props.t('Error')}</h1>
        <div className="h2 mt-5 mb-5" style={{ fontSize: '18px', fontWeight: 'normal', lineHeight: '24px' }}>
          {this.t("We've hit an error with your verification form.")}
        </div>
        <Link className="btn btn-primary" to="/">{this.t('Go back to home')}</Link>
      </>
    );
  }

  renderVerification() {
    const { t } = this.props;
    return (
      <>
        <h1>{t('Verify Identity')}</h1>
        <Verification onFail={() => this.handleFailVerification()} onComplete={(flow) => this.goToRegisteredNextStep(flow)} />
      </>
    );
  }

  render() {
    const reg_flow = this.state.reg_flow || this.props.registrationFlow || utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
    if (!reg_flow) {
      return null;
    }
    const currentStep = utils_registration_flow.current_registration_component(reg_flow, CURRENT_REG_STEP);
    const failFlow = !reg_flow || !currentStep;
    return (
      <section className="verification">
        {!this.state.failedVerification ? <SignupProgress currentStep={currentStep} regFlow={reg_flow} /> : null}
        <div className="signup-body-wrapper">

          { failFlow ? this.renderFailFlow() : this.renderVerification()}

        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, { update_registration_flow, get_user_attributes_get })(withTranslation('fields')(SignupVerification)));
