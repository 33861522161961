// not required if no input, but empty strings should get checked
const invalid_if_nonempty = (data_json) => data_json || data_json === '';

// types 'email', 'password', 'zip_code', and 'phone' have regex defined in the validation function
export const login_credential = [
  { name: 'email', type: 'email', req: true },
  { name: 'password', type: 'password', req: true },
];

export const login_token = [
  {
    name: 'token', type: 'string', req: true, regex: '^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$',
  },
];

export const update_self = [
  { name: 'email', type: 'email', reqfn: (data) => invalid_if_nonempty(data.email) },
  { name: 'ph_mobile', type: 'phone' },
  { name: 'ph_home', type: 'phone' },
  { name: 'zip', type: 'zip_code', reqfn: (data) => invalid_if_nonempty(data.zip) },
  { name: 'password', type: 'password', reqfn: (data) => invalid_if_nonempty(data.password) },
  {
    name: 'consent', type: 'string', fixedvalue: ['true'], reqfn: (data) => invalid_if_nonempty(data.consent),
  },
  { name: 'consent_term_content_id', type: 'string', reqif: 'consent|true' },
];

export const delete_user = [
  {
    name: 'delete_type', type: 'string', req: true, fixedvalue: ['hard', 'soft'],
  },
  {
    name: 'token', type: 'me', req: true, regex: '^(me|[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12})$',
  },
];

export const fetch_users = [
  {
    name: 'user_id', type: 'string', regex: '^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$', reqfn: (data) => invalid_if_nonempty(data.user_id),
  },
  { name: 'email', type: 'email', reqfn: (data) => invalid_if_nonempty(data.email) },
  {
    name: 'status', type: 'string', fixedvalue: ['active', 'pending', 'suspend', 'lockout'], reqfn: (data) => invalid_if_nonempty(data.status),
  },
  { name: 'page_num', type: 'number', reqfn: (data) => invalid_if_nonempty(data.page_num) },
  { name: 'page_size', type: 'number', reqfn: (data) => invalid_if_nonempty(data.page_size) },
  {
    name: 'sort_item', type: 'string', fixedvalue: ['portal_user_id', 'email', 'first_name', 'last_name', 'ph_office', 'ph_mobile', 'ph_home', 'zip', 'created_by', 'created', 'status'], reqfn: (data) => invalid_if_nonempty(data.sort_item),
  },
  { name: 'sort_asc', type: 'number', reqfn: (data) => invalid_if_nonempty(data.sort_asc) },
];

export const rgc_request_password_reset_app = [
  { name: 'email', type: 'email', req: true },
];

export const update_password = [
  { name: 'new_password', type: 'password', req: true },
  { name: 'password', type: 'string', req: true },
];
