const signatureTypes = {
  primary: 'primary',
  secondary: 'secondary',
};

export const signatureComponentNames = {
  signature: 'esign',
  signatureConfirmation: 'esign_confirm',
};

export const CONSENT_WORKFLOW_CODE = 'workflow_mandatory_consent'

export default signatureTypes;
