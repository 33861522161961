import { API_URL } from '../constants/api';
import { utils_api } from '../utils/utils_api';

export const api_ancestry = {
  get_ancestry(success, fail) {
    return utils_api.auth_get(`${API_URL.ANCESTRY_GET}/me`, success, fail);
  },

  get_ancestrystatus(success, fail) {
    return utils_api.auth_get(`${API_URL.ANCESTRY_STATUS_GET}?code=surveythankyou`, success, fail);
  },
};
