import { combineReducers } from 'redux';

// TODO: move all reducers to store directory
import storeReducers from 'src/store/reducers';

import app from './appReducer';
import loginUser from './loginReducer';
import spinner from './spinnerReducer';
import surveys from './surveysReducer';
import highlightedSurvey from './highlightedSurveyReducer';
import surveyUrl from './surveyReducer';
import actionCards from './actioncardsReducer';
import userAttribute from './userAttributeReducer';
import quickPolls from './quickpollsReducer';
import otp from './otpReducer';
import featureFlag from './featureReducer';
import termsContent from './termsReducer';
import privacyContent from './privacyReducer';
import globalError from './globalErrorReducer';
import ancestryStatus from './ancestryStatusReducer';
import registrationFlow from './registrationFlowReducer';
import registrationType from './registrationTypeReducer';
import consent from './consentReducer';
import categorization from './categorizationReducer';
import selfRegContent from './selfRegContent';
import surveyCurrent from './surveyCurrentReducer';
import workflow from './workflowReducer';
import emrConnections from './emrReducer';

const reducers = {
  app,
  loginUser,
  spinner,
  surveys,
  highlightedSurvey,
  surveyUrl,
  actionCards,
  userAttribute,
  quickPolls,
  otp,
  featureFlag,
  termsContent,
  privacyContent,
  globalError,
  ancestryStatus,
  registrationFlow,
  registrationType,
  consent,
  categorization,
  selfRegContent,
  surveyCurrent,
  workflow,
  emrConnections,
  ...storeReducers,
};

export default combineReducers(reducers);
