import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { SIGNUP_OTP_ERROR_DISPLAY } from '../../../constants/errors';
import { LINKS } from '../../../constants/links';
import { useDispatch } from 'react-redux';
import { logout_get } from 'src/actions/logoutAction';
import PAGES from 'src/constants/pages';
import { withRouter, useHistory } from 'react-router-dom';
import HtmlContentComponent from 'src/components/core/HtmlContent/HtmlContentComponent';

const ConfirmEmailError = (props) => {
  let error = props.t(SIGNUP_OTP_ERROR_DISPLAY.GENERIC);
  if (props.error) {
    switch (props.error) {
      case 'Too late to respond':
        error = props.t(SIGNUP_OTP_ERROR_DISPLAY.VERIFICATION_EXPIRED);
        break;
      case 'Reached maximum limit':
        error = props.t(SIGNUP_OTP_ERROR_DISPLAY.MAX_REACHED);
        break;
      case 'max request':
        error = props.t(SIGNUP_OTP_ERROR_DISPLAY.MAX_REQUEST_REACHED);
        break;
      default:
        error = props.t(SIGNUP_OTP_ERROR_DISPLAY.GENERIC);
        break;
    }
  }

  const supportLink = (
    <span>
      <HtmlContentComponent tag={"div"} markup={props.t('pleaseContactSupport',{link: LINKS.SUPPORT})}/>
    </span>
  );
  const dispatch = useDispatch()
  const history = useHistory();
  

  const logoutUser = async () => {
    const logoutUserAction = logout_get();
    await dispatch(logoutUserAction);
    history.push(PAGES.LOGIN);
  }

  return (
    <>
      <h1>{props.t('Error')}</h1>
      <div className="h2 mt-5 mb-5" style={{ fontSize: '18px', fontWeight: 'normal', lineHeight: '24px' }}>
        {error}
        {error === SIGNUP_OTP_ERROR_DISPLAY.MAX_REQUEST_REACHED ? supportLink : null}
      </div>
      <Link className="btn btn-primary"  to={PAGES.LOGIN} onClick={logoutUser}>{props.t('Go back to home')}</Link>      
      {/* <Link className="btn btn-primary" to={PAGES.LOGIN}>{t('Go back to home')}</Link> */}
    </>
  );
};

export default withRouter(withTranslation()(ConfirmEmailError));
