import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import joi, { ValidationError } from 'joi';
import Joi from 'joi';
import { isNil } from 'lodash';
import { IBaseService } from 'src/services/BaseService';
import { doSetFormConfigLoading } from 'src/store/FreeForm/freeFormAction';
import apiFetch from 'src/utils/apiWrapper';
import {
  FORM_FIELD_TYPES, FormFieldTypes, FormConfig, ValidationResults, ValidationRulesFactory, CommonFieldConfig, FreeFormResponse,
} from 'src/services/types/FreeFormTypes';
import { IStore } from './types/IStore';

interface IFreeFormServiceStore extends IStore {
  doDispatchFreeFormConfig?(config: any): void
}

export const defaultFieldValidation = {
  [FORM_FIELD_TYPES.EMAIL]: (): Joi.Schema => (
    joi.string().email({ tlds: { allow: false } })
  ),
  [FORM_FIELD_TYPES.NUMBER]: (): Joi.Schema => joi.number(),
};

type ResponsePayloadItem = {
  name: string,
  value: string | string[] | null
}
type ResponsePayload = ResponsePayloadItem[];
interface iFreeFormService extends IBaseService {
  // eslint-disable-next-line no-unused-vars
  finalRequest(data: any, formId: string): Promise<FreeFormResponse>;
}

@injectable()
class FreeFormService implements iFreeFormService {
  @inject('store')
  private store!: IFreeFormServiceStore;

  static trimValues(fields: any[] = []): ResponsePayload {
    return fields.map(({ name, value }) => ({ name, value: isNil(value) ? null : value }));
  }

  async doFinalRequest(data: ResponsePayload, formId: string) {
    const fieldsToSend = FreeFormService.trimValues(data);
    let result = null;
    try {
      result = await apiFetch({ method: 'POST', endpoint: `/forms/${formId}/response`, body: fieldsToSend });
    } catch (e) {
      console.error(e);
    }
    return result;
  }

  async finalRequest(data: FormConfig | null | undefined, formId: string): Promise<FreeFormResponse> {
    const fieldsToSend = data?.fields;
    if (!fieldsToSend) {
      throw new Error('No fields were sent in the form');
    }

    const { hasErrors, validationErrors } = data ? this.validate(data) : { hasErrors: false, validationErrors: {} };

    if (hasErrors) {
      return { hasErrors, validationErrors };
    }

    // @ts-ignore
    const result = await this.doFinalRequest(fieldsToSend, formId);

    return { result };
  }

  validate(formConfig: FormConfig): ValidationResults {
    const { validationRules, fields } = formConfig;

    let hasErrors = false;

    const validationErrors: any = fields.reduce((errorsAccumulate, field) => {
      const error = this.validateField(field, validationRules);
      if (error) {
        hasErrors = true;
        return { ...errorsAccumulate, [field.name]: error.toString() };
      }

      return errorsAccumulate;
    }, {});

    return { validationErrors, hasErrors };
  }

  validateField(field: CommonFieldConfig, validationRules?: ValidationRulesFactory): ValidationError | undefined {
    const { type, value, settings } = field;

    const typeToGo: FormFieldTypes = type;

    let currentValidationRules = validationRules;

    if (typeToGo in defaultFieldValidation) {
      // @ts-ignore
      currentValidationRules = defaultFieldValidation[typeToGo];
    }

    if (typeof currentValidationRules !== 'function') {
      return undefined;
    }

    let schema = currentValidationRules();

    if (settings?.required) {
      schema = schema.required();
    }

    const validationObject = joi.object({
      value: schema,
    });

    const { error } = validationObject.validate({ value });

    return error;
  }

  fetchData(formId: string, { doDispatchFreeFormConfig }: any): Promise<void> {
    return this.fetchFormConfig(formId, doDispatchFreeFormConfig);
  }

  async fetchFormConfig(formId: string, doDispatchFreeFormConfig: () => void) {
    this.store.dispatch(doSetFormConfigLoading(true));

    let result: { data: any } = { data: null };
    try {
      result = await apiFetch({ endpoint: `/forms/${formId}` });
    } catch (e) {
      console.error(e);
    }
    const formConfig = result?.data;
    const dispatchConfig = this.store.doDispatchFreeFormConfig || doDispatchFreeFormConfig;
    dispatchConfig(formConfig);
    this.store.dispatch(doSetFormConfigLoading(false));
  }
}

export default FreeFormService;
