import { useTranslation } from "react-i18next"
import HtmlContentComponent from '../core/HtmlContent/HtmlContentComponent';

export default function WebPageDescription({webPageType}) {
    const { t } = useTranslation();

    return(
        <div className="web-page-description-container">
            <div className="container">
                <HtmlContentComponent className="web-page-description" markup={t(`${webPageType}_description`)} />
            </div>
        </div>
    )
}
