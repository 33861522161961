import React from 'react';
import { connect } from 'react-redux';

export const ConsentSubcompLink = ({ link, lang }) => {
  const { text, file } = link;
  const label = text[lang] || text.en;
  const pdf = file[lang].pdf || file.en.pdf;
  return (
    <p className="my-4 text-right">
      <a href={`data:application/pdf;base64,${pdf}`} target="_blank">
        {label}
      </a>
    </p>
  );
};

const mapStateToProps = state => ({
  lang: state.app.language,
});

export default connect(mapStateToProps)(ConsentSubcompLink);
