import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ReactComponent as IconUnlock } from '../../assets/icons/unlock.svg';
import { FEATURE } from '../../constants/features';
import PAGES from 'src/constants/pages';
import { ancestrystatus_get } from '../../actions/ancestryAction';
import { utils } from '../../utils/utils_general';
// import Spinner from '../global/spinner';

class AncestryMessage extends React.Component {
  constructor() {
    super();
    this.state = {
      status: null,
    };
  }

  componentDidMount() {
    this.props.ancestrystatus_get(true)
      .then((res) => this.setState({ status: res }));
  }

  componentWillUnmount() {
    this._isMounted = false;
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  render() {
    const noAncestryAvail = this.props.ancestryStatus && this.props.ancestryStatus.title === '';

    if (noAncestryAvail || noAncestryAvail === null) {
      return null;
    }

    if (this.state.status && !utils.is_obj_empty(this.state.status) && this.state.status.title && this.props.featureFlag && this.props.featureFlag[FEATURE.ANCESTRY]) {
      return (
        <>
          <div className="ancestry-message rounded">
            <div className="ancestry-message-wrapper">
              <div className="ancestry-message-icon-wrapper"><div className="ancestry-message-icon"><IconUnlock /></div></div>
              <div className="ancestry-message-content-wrapper">
                {window.location.pathname === PAGES.SURVEYS ? null : <p className="h3">{this.state.status.title}</p>}
                <p className="ancestry-message-subheading">
                  {' '}
                  {window.location.pathname === PAGES.SURVEYS ? (
                          <span className="h3" style={{ display: 'inline-block', fontStyle: 'normal' }}>
                              {this.props.t('Ancestry')}
&nbsp;&nbsp;
                            </span>
                        ) : null}
                  {' '}
                  {this.state.status.subtitle}
                </p>
              </div>
            </div>

          </div>

          {window.location.pathname.includes(PAGES.SURVEY_COMPLETE)
            ? (
              <div className="survey-complete-ancestry-link">
                <Link className="btn" to={PAGES.ANCESTRY}>
                  <span className="mobile-only">{this.props.t('View ancestry data')}</span>
                  <span className="desktop-only">{this.props.t('Ancestry data')}</span>
                </Link>
              </div>
            )
            : null}
        </>
      );
    } if (window.location.pathname.includes(PAGES.SURVEY_COMPLETE)) {
      return (
        <>
          <div className="ancestry-message rounded">
            <div className="ancestry-message-wrapper">
              <div className="ancestry-message-icon-wrapper"><div className="ancestry-message-icon"><IconUnlock /></div></div>
              <div className="ancestry-message-content-wrapper">
                <p className="h3"> &nbsp; </p>
                <p className="ancestry-message-subheading"> &nbsp; </p>
              </div>
            </div>

          </div>

          {window.location.pathname.includes(PAGES.SURVEY_COMPLETE)
            ? (
              <div className="survey-complete-ancestry-link">
                <Link className="btn" to={PAGES.ANCESTRY}>
                  <span className="mobile-only">{this.props.t('View ancestry data')}</span>
                  <span className="desktop-only">{this.props.t('Ancestry data')}</span>
                </Link>
              </div>
            )
            : null}
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { ancestrystatus_get })(withTranslation()(AncestryMessage));
