import classNames from 'classnames';
import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import ReactInputMask from 'react-input-mask';

// These are the props directly used when returning the component: <PhoneNumberComponent prop1={} prop2={} />
export type PhoneNumberComponentComponentProps = {
  name?: string,
  value?: string,
  isInvalid: boolean,
  mask?: string,
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  onFocus?: FocusEventHandler<HTMLInputElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  controlRef?: React.LegacyRef<ReactInputMask>;
}

export type PhoneNumberComponentProps = PhoneNumberComponentComponentProps;

function PhoneNumberComponent({
  controlRef, name, value, mask, isInvalid, onChange, onFocus, onBlur
}: PhoneNumberComponentProps) {
  return (
    <ReactInputMask
      ref={controlRef}
      className={classNames('form-control', { 'is-invalid': isInvalid })}
      mask={mask || '(999) 999-9999'}
      maskPlaceholder="_"
      placeholder="(999) 999-9999"
      onChange={onChange}
      name={name}
      id={name}
      type="tel"
      value={value || ''}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}

const defaultProps: PhoneNumberComponentComponentProps = {
  isInvalid: false,
};

PhoneNumberComponent.defaultProps = defaultProps;

export default PhoneNumberComponent;
