/**
 * @file components/survey/survey_side_details.js
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PAGES from 'src/constants/pages';
import { utils } from '../../utils/utils_general';
import { TRUNCATE } from '../../constants/truncateString';
import { withTranslation } from 'react-i18next';

const SurveySideDetails = (props) => {
  const { survey } = props;
  // const description = survey.description && utils.is_json_string(survey.description) ? JSON.parse(survey.description): null;
  const description = survey.description ? utils.remove_html(survey.description) : null;
  if (!survey) {
    return null;
  }
  return (
    <div className="section-component">
      <h1 className="mb-3 side-heading">{survey.title}</h1>
      {/* {description && description.min ? <div className='font-weight-bold'>Estimated time to complete: {description.min}&nbsp;minutes</div> : null} */}
      {/* {description && description.description ? <div>{description.description}</div> : null} */}
      {description ? (
        <p className="mb-3">
          {' '}
          { utils.truncate_string(String(description), TRUNCATE.SURVEY_DESCRIPTION) }
          {' '}
        </p>
      ) : null}
      <div><Link to={PAGES.SURVEYS} className="btn button primary-invert mt-3 block">{props.t('Back to surveys')}</Link></div>
    </div>

  );
};
export default withTranslation()(SurveySideDetails);
