import { useTranslation } from 'react-i18next';
import { OrderDnaKitInterface } from 'src/services/types/validations/OrderDnaKit';
import { useCurrentUser } from 'src/services/UserHooks';
import { numberAsPhone } from 'src/utils/textUtils';

// These are the props directly used when returning the component: <OrderDisplay prop1={} prop2={} />
export type OrderDisplayComponentProps = {
  dnaKitOrderInfo: OrderDnaKitInterface
}

export type OrderDisplayProps = OrderDisplayComponentProps;

type AddressLineProps = {
  line: string,
}

function AddressLine({ line }: AddressLineProps): React.ReactElement|null {
  if (!line || line.trim().length === 0) {
    return null;
  }

  return (
    <>
      {line}
      <br />
    </>
  );
}

function OrderDisplay({ dnaKitOrderInfo }: OrderDisplayProps): React.ReactElement {
  const { t } = useTranslation();
  const { first_name: firstName, last_name: lastName } = useCurrentUser() || {};
  const {
    phone, addressLine1, addressLine2, city, state, zip,
  } = dnaKitOrderInfo;

  return (
    <>
      <strong>
        {t('Name')}
        :
      </strong>
      <p>
        {firstName}
        {' '}
        {lastName}
      </p>

      <strong>
        {t('Phone Number')}
        :
      </strong>
      <p>{numberAsPhone(phone)}</p>

      <strong>
        {t('Shipping Address')}
        :
      </strong>
      <address>
        <AddressLine line={addressLine1} />
        <AddressLine line={addressLine2} />
        {city}
        ,
        {' '}
        {state}
        ,
        {' '}
        {zip}
      </address>
    </>
  );
}

export default OrderDisplay;
