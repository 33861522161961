export const FEATURE = {
  ANCESTRY: 'ancestry',
  CONSENT: 'consent',
  VERIFICATION: 'verification',
  SURVEY: 'survey',
  QUICKPOLL: 'quickpoll',
  MFA: 'mfa',
  ONBOARD_APP_POLICIES: 'onboard_app_policies',
  USER_IMPORT_FILE: 'users_import_file',
  ANCESTRY_IMPORT_FILE: 'ancestry_import_file',
  SURVEY_IMPORT_FILE: 'survey_import_file',
  LANDING_PAGE: 'landing_page',
  EMR: 'emr',
  SURVEYS_BOARD_WIDGET: 'surveys_board_widget',
  EMAIL_VERIFICATION_SKIP: 'email_verification_skip',
};
