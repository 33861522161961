import Joi from "joi";

export enum FORM_FIELD_TYPES {
  EMAIL = 'email',
  PASSWORD = 'password',
  TEXT = 'text',
  NUMBER = 'number',
  DROPDOWN = 'dropdown'
}

export type InputTypes = 'email' | 'password' | 'text' | 'number';

export type FormFieldTypes = InputTypes | 'dropdown' | 'multiSelect' | 'googlePlaces' | never;

export type FormFieldSettings = {
  uid: string,
  type: FormFieldTypes,
  name?: string,
  label?: string,
  placeholder?: string,
  options?: any[],
  defaultValue?: string | any[],
  value?: any,
  settings?: {
    required?: boolean,
    placeholder?: string,
    ariaRequired?: boolean,
  }
};

export type ValidationRulesFactory = () => Joi.Schema;

export type CommonFieldConfig = {
  uuid?: string;
  type: FormFieldTypes;
  name: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  value?: any;
  settings?: any;
}

export type FormConfig = {
  validationRules?: ValidationRulesFactory,
  fields: CommonFieldConfig[]
}

export type ValidationResults = {
  hasErrors: boolean,
  validationErrors: object
}

export type FreeFormResponse = {
  hasErrors?: boolean; 
  validationErrors?: any, 
  result?: any
}