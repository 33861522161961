import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PAGES from 'src/constants/pages';
import Logout from '../global/logout';
import LanguageSwitch from '../global/language_switch';
import AppLogo from '../../assets/images/app-logo.png';
import { CONFIG, hasLanguageSwitcher } from '../../constants/config';
import { TRUNCATE } from '../../constants/truncateString';
import { utils } from '../../utils/utils_general';

class EnrollmentExit extends React.Component {
  renderHeader() {
    const name = this.props.loginUser.first_name ? (this.props.loginUser.last_name ? `${this.props.loginUser.first_name} ${this.props.loginUser.last_name}` : this.props.loginUser.first_name) : '';
    return (
      <header className="header pt-4 pb-3 header-enrollment enrollment-exit">
        <div className="container-fluid pr-xl-5 pl-xl-5 pb-4">
          <div className="row header-row">
            <div className="col-6">
              <div className="header-logo"><Link to={PAGES.DASHBOARD}><img src={AppLogo} alt={`${CONFIG.APP_NAME} logo`} /></Link></div>
            </div>

            <div className="col-6 text-right header-signout">
              <div>
                {hasLanguageSwitcher ? (
                  <div className="nav-language">
                    {' '}
                    <LanguageSwitch />
                  </div>
                ) : null}
                <div className="nav-name name font-callout">
                  { utils.truncate_string(name, TRUNCATE.NAME) }
                </div>
              </div>
              <Logout />
            </div>
          </div>
        </div>
      </header>

    );
  }

  render() {
    return (
      <>
        {this.renderHeader()}
        <section className="workflow container pt-5 pb-md-5">
          <div className="text-center">
            <p className="h2 pb-3">{this.props.t('Thanks For Enrolling')}</p>
            <div className="enrollment-exit-logout"><Logout /></div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});
export default connect(mapStateToProps, {})(withTranslation()(EnrollmentExit));
