import BSSpinner from 'react-bootstrap/Spinner';

function Loading() {
  return (
    <div className="d-flex justify-content-center align-items-center flex-grow-1">
      <BSSpinner animation="border" role="status" variant="primary" />
    </div>
  );
}

export { Loading }