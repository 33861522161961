import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import { get_user_attributes_get, post_user_attributes_post, post_mfa_attribute_post } from '../../actions/userAction';
import DeleteAccount from './delete_account';
import SetTwoFA from './two-factor-authentication';
import { EDIT_PERSONAL_MODAL } from '../../constants/editPersonalModal';
import { FEATURE } from '../../constants/features';
import { utils } from '../../utils/utils_general';
import { DEEPLINKS, DEEPLINK_ID } from '../../constants/deeplinks';
import { CONFIG } from '../../constants/config';
import { login_get } from '../../actions/loginAction';
import { ATTRIBUTES } from '../../constants/attributes';
import HtmlContentComponent from '../core/HtmlContent/HtmlContentComponent';

class security extends React.Component {
  constructor() {
    super();
    this.state = {
      user: null,
      twoFactorOpen: false,
      accountOpen: false,
      cookiePolicyOpen: false,
      cookiesEnabled: false,
      is2FAEnabled: false,
    };

    this.getAttributes = this.getAttributes.bind(this);
    this.turnOffTwoFA = this.turnOffTwoFA.bind(this);
  }

  componentDidMount() {
    if (this.props.loginUser) {
      this.setState({ user: this.props.loginUser });
    }

    this.getAttributes();
    this.checkDeepLink();
  }

  checkDeepLink() {
    const params = utils.get_url_params();
    if (params[DEEPLINKS.SET_2FA]) {
      this.setState({ twoFactorOpen: true });
      const el = document.getElementById(DEEPLINK_ID.SET_2FA);
      utils.scroll_to_deeplink_el(el);
    }
  }

  componentDidUpdate(prevProps) {
    const is2FAFeatured = this.props.featureFlag && this.props.featureFlag[FEATURE.MFA];
    if ((this.props.loginUser && !this.state.user)) {
      this.setState({ user: this.props.loginUser });
    }

    if (this.props.userAttribute && !utils.check_objects_identical(prevProps.userAttribute, this.props.userAttribute)) {
      this.setState({ cookiesEnabled: this.props.userAttribute.ga_cookie });
      this.setState({ is2FAEnabled: this.props.userAttribute.mfa_enable && is2FAFeatured });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  getAttributes() {
    const is2FAFeatured = this.props.featureFlag && this.props.featureFlag[FEATURE.MFA];
    this.props.get_user_attributes_get()
      .then((resp) => {
        this.setState({
          cookiesEnabled: resp.ga_cookie,
          is2FAEnabled: (this.props.userAttribute ? this.props.userAttribute.mfa_enable : false) && is2FAFeatured,
        });
        // if use allows or disallow cookies, will take into affect immediately
        this.props.login_get(true);
      })
      .catch((error) => {
        console.log('getting attribute error', error);
      });
  }

  accordionState(value) {
    if (value === 'two-factor') {
      this.setState({ twoFactorOpen: !this.state.twoFactorOpen });
    } else if (value === 'account') {
      this.setState({ accountOpen: !this.state.accountOpen });
    } else if (value === 'cookiePolicy') {
      this.setState({ cookiePolicyOpen: !this.state.cookiePolicyOpen });
    }
  }

  policyOnChange() {
    this.setState({ cookiesEnabled: !this.state.cookiesEnabled }, () => {
      this.props.post_user_attributes_post(ATTRIBUTES.GA_COOKIE, String(!!this.state.cookiesEnabled))
        .then((resp) => {
          this.getAttributes();
        })
        .catch((error) => {
          console.log('posting attribute error', error);
        });
    });
  }

  turnOffTwoFA() {
    const data = {
      value: String(!this.state.is2FAEnabled),
    };
    this.props.post_mfa_attribute_post(data, true)
      .then((resp) => {
        this.setState({ is2FAEnabled: !this.state.is2FAEnabled });
        this.getAttributes();
      })
      .catch((error) => {
        console.log('posting attribute error', error);
      });
  }

  render() {
    const { t } = this.props;
    const partner_name = CONFIG.PARTNER_NAME;
    const app_name = CONFIG.APP_NAME;
    return (
      <div className="security section-component">
        <h2 className="mb-3 h3" id={DEEPLINK_ID.SET_2FA}>{this.props.t('Security')}</h2>
        {this.props.featureFlag && this.props.featureFlag[FEATURE.MFA]
          ? (
            <div className="security-wrapper">
              <Button className={`accordion-button ${this.state.twoFactorOpen ? 'active' : ''}`} value="two-factor" variant="primary-invert" block onClick={(e) => this.accordionState(e.target.value)}>
                {t('Two-Factor Authentication')}
              </Button>
              <div className={`accordion-container pt-3 pb-3 pl-lg-3 pr-lg-3 ${this.state.twoFactorOpen ? 'active' : ''}`}>
                <div className="mb-3"><HtmlContentComponent markup={t('security_2fa')} /></div>
                <div className="row desktop-right-align">
                  <div className="col-12">
                    {this.state.is2FAEnabled
                      ? <SetTwoFA state={this.state.is2FAEnabled} handler={this.turnOffTwoFA} />
                      : <SetTwoFA type={EDIT_PERSONAL_MODAL.SET_2FA} state={this.state.is2FAEnabled} handler={this.getAttributes} />}
                  </div>
                </div>
              </div>
            </div>
          )
          : null}
        <div className="security-wrapper">
          <Button className={`accordion-button ${this.state.accountOpen ? 'active' : ''}`} value="account" variant="primary-invert" block onClick={(e) => this.accordionState(e.target.value)}>
            {this.props.t('Account')}
          </Button>
          <div className={`accordion-container pt-3 pb-3 pl-lg-3 pr-lg-3 ${this.state.accountOpen ? 'active' : ''}`}>
            <HtmlContentComponent markup={this.props.t('security_account', {app_name:app_name, partner_name:CONFIG.PARTNER_NAME})}/>
            <div className="row desktop-right-align">
              <div className="col-12">
                <DeleteAccount />
              </div>
            </div>
          </div>

        </div>
        <div className="security-wrapper">
          <Button className={`accordion-button ${this.state.cookiePolicyOpen ? 'active' : ''}`} value="cookiePolicy" variant="primary-invert" block onClick={(e) => this.accordionState(e.target.value)}>
            {this.props.t('Application Policies (cookies)')}
          </Button>
          <div className={`accordion-container pt-3 pb-3 pl-lg-3 pr-lg-3 ${this.state.cookiePolicyOpen ? 'active' : ''}`}>
            <HtmlContentComponent markup={this.props.t('security_overview')} />
            <div className="row desktop-right-align">
              <div className="col-10 col-md-11">
                <p className="font-callout font-weight-bold">{t('Allow Anonymouse Usage Sharing')}</p>
              </div>
              <div className="col-2 col-md-1 security-policies-input">
                <input className="checkbox-toggle" id="policy-usage-data" type="checkbox" defaultChecked={this.state.cookiesEnabled} onChange={() => this.policyOnChange()} />
                <label htmlFor="policy-usage-data" className={`checkbox-toggle-label ${this.state.cookiesEnabled ? 'state-on' : 'state-off'}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  get_user_attributes_get, post_user_attributes_post, post_mfa_attribute_post, login_get,
})(withTranslation()(security));
