import classNames from 'classnames';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IconGenerator from 'src/components/icons/icon_generator';
import { TRUNCATE } from 'src/constants/truncateString';
import { Survey } from 'src/services/types/Survey';
import { utils } from 'src/utils/utils_general';

// These are the props directly used when returning the component: <SuggestedSurvey prop1={} prop2={} />
export type SuggestedSurveyComponentProps = {
  survey: Survey,
  onSurveyClick: (survey: Survey) => void,
};

const defaultProps: Partial<SuggestedSurveyComponentProps> = {

};

export type SuggestedSurveyProps = SuggestedSurveyComponentProps;

function SuggestedSurvey({ survey, onSurveyClick }: SuggestedSurveyProps): React.ReactElement {
  const [focused, setFocused] = useState<boolean>(false);

  const { t } = useTranslation();
  const title = utils.truncate_string(survey.title, TRUNCATE.SUGGESTED_SURVEY_TITLE, false);

  return (
    <div className={classNames('suggested-survey', { focused })}>
      <Button
        variant="link"
        className="suggested-surveys-link block pt-3 pb-3 clearfix"
        onClick={() => onSurveyClick(survey)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        <div className="suggested-survey-img">
          <div className="suggested-survey-img-wrapper"><IconGenerator iconKey={survey.limesurvey_id} /></div>
        </div>
        <div className="suggested-survey-details">
          <p className="h4 color-dark mb-0">{t(title)}</p>
        </div>
        <div className="suggested-survey-chevron">
          {' '}
          <div className="symbol-chevron" />
          {' '}
        </div>
      </Button>
    </div>
  );
}

SuggestedSurvey.defaultProps = defaultProps;

export default SuggestedSurvey;
