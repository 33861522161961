import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import OptionsList from './questionnaire_options_list';
import { utils } from 'src/utils/utils_general';
import classNames from 'classnames';
import WorkflowNextButton from 'src/components/workflow/WorkflowNextButton';

function QuestionResult({ currentQuestionCorrect, children }) {
  return (
    <div className={classNames('question-error', { 'text-danger': currentQuestionCorrect === false, })}>
      {currentQuestionCorrect === null || currentQuestionCorrect === undefined ? null : children}
    </div>
  );
}

class Questionnaire extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      current_question_index: 0,
      questions: null,
      current_question_answered: false,
      current_question_correct: null,
      current_all_ans_accepted: true,
      current_question_submitted: false,
    };
  }

  componentDidMount() {
    if (this.props.questions && this.props.questions.length > 0) {
      this.setQuestions(this.props.questions);
    }
  }

  componentDidUpdate(prevProps) {
    if ((!this.state.questions || (this.state.question && this.state.question.length === 0)) && this.props.questions.length > 0) {
      this.setQuestions(this.props.questions);
    }
    if (this.props.questions && !utils.check_objects_identical(this.props.questions, prevProps.questions)) {
      this.setQuestions(this.props.questions);
    }
  }

  setQuestions(questions) {
    const current_all_ans_accepted = this.checkAllAnswersAccepted(questions[0]);
    const current_question_answered = questions[0] && questions[0].selected_answers && questions[0].selected_answers.length > 0;
    // resetting, since it came be questionnaire component after one anitoner
    this.setState({
      questions,
      current_all_ans_accepted,
      current_question_index: 0,
      current_question_answered,
      current_question_correct: null,
      current_question_submitted: false,
    });
  }

  checkAllAnswersAccepted(question) {
    return question && question.answers.length ? question.answers[0].correct === null : null;
  }

  handleAnswerChange(affectedQuestion, selected_answers) {
    const { current_question_submitted, questions } = this.state;

    if (current_question_submitted) return;

    const modifiedQuestions = questions.map((question) => {
      if (question.id === affectedQuestion.id) {
        return { ...question, selected_answers };
      }
      return question;
    });

    const current_question_answered = selected_answers.length > 0;

    this.setState({ current_question_answered, questions: modifiedQuestions });
  }

  getCorrectAndSelectedAnswers() {
    const { questions, current_question_index } = this.state;

    const question = questions[current_question_index];
    const { selected_answers, answers } = question;
    const correct_answers = answers.filter(({ correct }) => correct);

    return {
      selected_answers,
      correct_answers,
    };
  }

  checkAnswerIsCorrect() {
    const { selected_answers, correct_answers } = this.getCorrectAndSelectedAnswers();

    this.setState({
      current_question_submitted: true,
      current_question_correct: (
        selected_answers.sort().join('') === correct_answers.map(({ id }) => id).sort().join('')
      ),
    });
  }

  goToNextQuestion(e) {
    e.preventDefault();
    const {
      current_question_answered,
      current_question_correct,
    } = this.state;

    if (!current_question_answered) return false;

    // if null, then then all answers accepted
    if (!this.state.current_all_ans_accepted) {
      if (current_question_correct === null) {
        this.checkAnswerIsCorrect();
        return false;
      }
    }

    const is_last_question = this.state.current_question_index === this.state.questions.length - 1;
    if (is_last_question) {
      const data = {
        questionnaire: this.state.questions.map((q) => ({
          question: q.code,
          answers: q.selected_answers,
        })),
      };
      this.onFormComplete(data);
    } else {
      // prep for next question
      const next_all_ans_accepted = this.checkAllAnswersAccepted(this.state.questions[this.state.current_question_index + 1]);
      this.setState({
        current_question_answered: false,
        current_question_submitted: false,
        current_question_index: this.state.current_question_index + 1,
        current_all_ans_accepted: next_all_ans_accepted,
        current_question_correct: null,
      });
    }
  }

  onFormComplete(data) {
    return this.props.onFormComplete(data);
  }

  getCorrectAnswerText(correctAnswers) {
    // const arr = correctAnswers.map(a => this.props.t('answers_text_' + a.id));
    const arr = correctAnswers.map((a) => (a.text && !a.text.includes(': ') ? a.text : this.props.t(`answers_text_${a.id}`)));
    const lastAnswer = arr.pop();
    const lastSeparator = arr.length ? ' & ' : '';
    return `${arr.join(', ')}${lastSeparator}${lastAnswer}`;
  }

  getHintMessageText() {
    const { t } = this.props;

    const {
      selected_answers,
      correct_answers,
    } = this.getCorrectAndSelectedAnswers();
    const correct_answer_text = this.getCorrectAnswerText(correct_answers);
    const num_key_prefix = correct_answers.length > 1 ? 'plural' : 'single';
    if (this.state.current_question_correct) {
      return t(`${num_key_prefix}_correct`, { correct_answer_text });
    }
    const has_incorrect_answers = selected_answers.filter((a) => !correct_answers.includes(a)).length > 0;
    if (has_incorrect_answers) {
      return t(`${num_key_prefix}_wrong`, { correct_answer_text });
    }
    return t(`${num_key_prefix}_almost`, { correct_answer_text });
  }

  render() {
    const { t, topTitle, isKnowledgeCheck } = this.props;
    const {
      questions,
      current_question_index,
      current_question_answered,
      current_question_submitted,
      current_all_ans_accepted,
      errors,
      current_question_correct: currentQuestionCorrect
    } = this.state;

    const question = questions ? questions[current_question_index] : null;

    if (!question) {
      return null;
    }

    const { text, type, view_type, code, answers, selected_answers } = question;

    const isTextNotCode = (text) => text && !text.includes(': ') && !text.includes('_');
    const questionTitleTranslated = text && text.title && isTextNotCode(text.title) ? text.title : t(`codes:questions_title_${code}`);
    const questionSubtitleTranslated = text && text.subtitle && isTextNotCode(text.subtitle) ? text.subtitle : t(`codes:questions_subtitle_${code}`);
    const multiple = answers.filter(({ correct }) => correct).length > 1 || type === "multy_select" || type === "checkbox";

    return (
      <div className="questionnaire">
        <Form noValidate onSubmit={(e) => this.goToNextQuestion(e)}>
          <div className="question-slide-wrapper">
            <div key="custom-radio" className="mb-3">
              <div key={code} className="question-slide">
                {topTitle ? <h2>{t(topTitle)}</h2> : null}

                <h3>{questionTitleTranslated}</h3>
                <h4>{questionSubtitleTranslated}</h4>

                <div className="question-answers">
                  <OptionsList
                    questionAnswered={current_question_answered && current_question_submitted}
                    multiple={multiple}
                    viewType={view_type ?? "button"}
                    onOptionClick={(e, newSelectedAnswers) => this.handleAnswerChange(question, newSelectedAnswers)}
                    options={answers}
                    selectedAnswers={selected_answers}
                    withCorrectIcons={isKnowledgeCheck}
                  />
                </div>
              </div>
            </div>
          </div>
          
          <div className="text-center">
            <Form.Text className="text-danger form-error" aria-live="polite">
              {errors.system || <br />}
            </Form.Text>
          </div>

          <div className='clearfix'></div>

          <div className="next-question text-center">
            {!current_all_ans_accepted && (
              <QuestionResult currentQuestionCorrect={currentQuestionCorrect}>
                {this.getHintMessageText()}
              </QuestionResult>
            )}

            <WorkflowNextButton type="submit" disabled={!current_question_answered} inline size={'sm'}>
              {t(current_question_submitted ? 'Continue' : 'Submit')}
            </WorkflowNextButton>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, {})(withTranslation('codes')(Questionnaire)));
