/**
 * @file components/home.js
 */

import React from 'react';
import { connect } from 'react-redux';
import { healthcheck_get } from '../actions/healthcheckAction';

class Healthcheck extends React.Component {
  constructor() {
    super();
    this.state = {
      healthcheck: null,
    };
  }

  componentDidMount() {
    this.props.healthcheck_get()
      .then((response) => {
        this.setState({ healthcheck: response });
      })
      .catch((error) => {
        this.setState({ healthcheck: ['healthcheck is down, api is probably down'] });
      });
  }

  render() {
    if (!this.state.healthcheck) {
      return (
        <section className="healthcheck container text-center ">
          <p>Something is wrong</p>
        </section>
      );
    }
    return (
      <section className="healthcheck container text-center ">
        { this.state.healthcheck.map((item, key) => <p key={key}>{item}</p>) }
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { healthcheck_get })(Healthcheck);
