/**
 * @file components/password/reset_password_email_sent.js
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetPasswordLinkExpiry } from 'src/constants/api_config';
import { LINKS } from 'src/constants/links';

function ResetPasswordSendEmailComplete({ location }) {
  const { t } = useTranslation('fields');
  const email = location?.state?.email || t('the email that you provided');

  return (
    <section className="reset-password-email-sent">
      <div className="unauth-body-wrapper">
        <h1>{t('Forgot Password')}</h1>
        <p>
          {t('Thanks, if the email is correct - you will receive the reset password at')}
          {' '}
          <strong>{email}</strong>
          .
        </p>
        <p>
          {t('Please follow the instructions in the email to create a new password')}
          .
        </p>
        <p>
          {t('For your security, the link will only be valid for {{resetPasswordLinkExpiry}} days.', { resetPasswordLinkExpiry })}
        </p>

        <p>
          {t('If you don\'t receive the email or have further questions')}
          {', '}
          <a href={LINKS.SUPPORT} target="_blank" rel="noopener noreferrer">
            {t('contact support')}
          </a>
          .
        </p>

      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, {})(ResetPasswordSendEmailComplete));
