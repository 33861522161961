import { API_URL } from '../constants/api';
import { utils_api } from '../utils/utils_api';

export const api_password = {
  send_reset_password_email(success, fail, data) {
    return utils_api.auth_post(API_URL.REQUEST_PASSWORD_RESET_POST, data, success, fail);
  },
  reset_password(success, fail, new_password) {
    const data = {
      new_password,
    };
    return utils_api.auth_put(API_URL.RESET_PASSWORD_PUT, data, success, fail);
  },

  update_password(success, fail, password, new_password) {
    const data = {
      password,
      new_password,
    };
    return utils_api.auth_put(API_URL.UPDATE_PASSWORD_PUT, data, success, fail);
  },

  verify_password(success, fail, password, new_password) {
    let data;

    if (password != null) {
      data = {
        password,
        new_password,
      };
    } else {
      data = {
        new_password,
      };
    }
    return utils_api.auth_post(API_URL.VERIFY_PASSWORD_POST, data, success, fail);
  },
};
