import { inject, injectable } from "inversify";
import { ValidationErrorItem } from "joi";
import { isObject, isString } from "lodash";
import { API_URL } from "src/constants/api";
import { doSetDnaKitCreatedOrder, doSetDnaKitOrderErrors, doSetDnaKitOrderRequesting } from "src/store/DnaKit/DnaKitActions";
import apiFetch, { RequestError } from "src/utils/apiWrapper";
import { DnaKitOrderData, dnaKitOrderDataDefault, ListDnaKitOrderResult } from "./types/DnaKit";
import * as IStore from "./types/IStore";
import { OrderDnaKitInterface } from "./types/validations/OrderDnaKit";

export interface IDnaKitService {
  loadActiveDnaKitOrder: () => Promise<void>;
  orderDnaKit: (payload: OrderDnaKitInterface) => Promise<void>;
  clearErrors: () => void;
}

type ValidationErrorResponse = {
  details: ValidationErrorItem[]
}

@injectable()
abstract class  DnaKitServiceBase implements IDnaKitService {

  @inject('store')
  public store!: IStore.IStore;

  private processError(e: any): Error[] {
    if(e instanceof RequestError){
      const msg = (e as RequestError).response?.data?.msg;
      if(isString(msg)){
        return [new Error(msg)];
      }
      if(isObject(msg)){
        return (msg as ValidationErrorResponse).details.map(({message}) => new Error(message));
      }
    }
    if(e instanceof Error){
      return [e];
    }

    return [new Error(String(e))];
  }

  async orderDnaKit(payload: OrderDnaKitInterface): Promise<void> {
    try{
      this.store.dispatch(doSetDnaKitOrderRequesting());
      const result = await this.doOrderDnaKit(payload);
      this.store.dispatch(doSetDnaKitCreatedOrder(result));
    }catch (e){
      console.log(e);
      this.store.dispatch(doSetDnaKitOrderErrors(this.processError(e)));
    }
  }

  async loadActiveDnaKitOrder(): Promise<void>{
    try{
      this.store.dispatch(doSetDnaKitOrderRequesting());
      const result = await this.doGetActiveDnaKitOrder();
      this.store.dispatch(doSetDnaKitCreatedOrder(result || dnaKitOrderDataDefault));
    }catch (e){
      console.log(e);
      this.store.dispatch(doSetDnaKitOrderErrors(this.processError(e)));
    }
  }

  clearErrors(): void {
    this.store.dispatch(doSetDnaKitOrderErrors([]));
  }

  abstract doOrderDnaKit(payload: OrderDnaKitInterface): Promise<DnaKitOrderData>;

  abstract doGetActiveDnaKitOrder(): Promise<DnaKitOrderData|undefined>;
}

@injectable()
export class  DnaKitService extends DnaKitServiceBase {
  async doGetActiveDnaKitOrder(): Promise<DnaKitOrderData | undefined> {
    const userOrders = await apiFetch({
      endpoint: API_URL.USER_ORDERS,
    }) as ListDnaKitOrderResult;

    return (userOrders?.data || []).find(({ status }) => status !== "deleted");
  }

  doOrderDnaKit({
    addressLine1: address_line1,
    addressLine2: address_line2,
    addressLine1Validated: google_verified,
    city,
    state,
    zip,
    phone: order_phone,
  }: OrderDnaKitInterface): Promise<DnaKitOrderData> {
    return apiFetch({
      endpoint: API_URL.USER_ORDERS,
      method: 'POST',
      body: {
        address_line1,
        address_line2: ((address_line2?.length || 0) > 0 ? address_line2 : ' '),
        google_verified,
        city,
        state,
        zip,
        order_phone
      }
    }).then(({ data }) => data as DnaKitOrderData);
  }
}