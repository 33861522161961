import React from 'react';
import { Link } from 'react-router-dom';
import ImgAwwShucks from '../../../assets/images/character-aww-shucks.png';
import { withTranslation } from 'react-i18next';


export const SignupStop = (props) => (
  <section className="signup-stop">
    <div className="signup-body-wrapper">
      <h1 className="h3">{props.t('Sorry, you will not able to join the study.')}</h1>
      <div className="mt-3 text-center">
        <img src={ImgAwwShucks} alt="" style={{ height: '400px' }} />
      </div>
      <div className="mt-3 text-center">
        <Link className="btn btn-primary" to="/">{props.t('Go back to home')}</Link>
      </div>
    </div>
  </section>
);

export default withTranslation()(SignupStop);
