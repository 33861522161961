import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';
import { api_healthcheck } from '../api/api_healthcheck';
import { utils_api } from '../utils/utils_api.js';

export function healthcheck_get() {
  return (dispatch) => {
    utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'healthcheck_get');
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_healthcheck.get_healthcheck(success, fail);
  };
}
