import React from 'react';
import { withTranslation } from 'react-i18next';

export const AccountDeleted = (props) => (
  <section>
    <div className="account-deleted unauth-body-wrapper">
      <h1>{props.t('Your account has been deleted.')}</h1>
    </div>
  </section>
);

export default withTranslation()(AccountDeleted);
