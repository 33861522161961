import { API_URL } from '../constants/api';
import { utils_api } from '../utils/utils_api';

export const api_actioncards = {
  get_actioncards(success, fail) {
    return utils_api.auth_get(API_URL.ACTIONCARDS_GET, success, fail);
  },
  get_firstcall_actioncards(success, fail) {
    const params = '?num_cards=4&mandatory_cards=consent_download';
    return utils_api.auth_get(API_URL.ACTIONCARDS_GET + params, success, fail);
  },
  delete_actioncards(success, fail) {
    return utils_api.auth_delete(API_URL.ACTIONCARDS_DELETE, success, fail);
  },
};
