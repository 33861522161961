import React from 'react';
import { Form } from 'react-bootstrap';

class OTPInput extends React.Component {
  handleChange() {
    // don't do anything here (changes to the inputs are done by handleKeyDown)
  }

  handleKeyDown(e, index) {
    const { form } = e.target;
    const tokenObj = [...this.props.token];

    // backspace
    if (e.keyCode === 8) {
      if (tokenObj[index]) {
        // existing value -> delete it
        tokenObj[index] = '';
        this.props.handleChange(tokenObj);
      } else {
        // no value -> focus previous input
        if (index > 0) {
          if (form.elements[index - 1]) {
            form.elements[index - 1].focus();
          }
        }
      }
      return;
    }

    // only keypad numbers [keyCode 96 to 105] or number keys are allow [keyCode 48 to 57]
    if (!(e.keyCode >= 96 && e.keyCode <= 105) && !(e.keyCode >= 48 && e.keyCode <= 57))  {
      return;
    }

    const value = (e.keyCode <= 57) ? e.keyCode-48 : e.keyCode-96;

    // only 0-9 allowed
    if (value < 0 || value > 9) {
      return;
    }

    tokenObj[index] = String(value);
    this.props.handleChange(tokenObj);

    // focus next input
    if (index < 5) {
      form.elements[index + 1].focus();
    }
  }

  handleFocus = event => event.target.select();

  handlePaste(e) {
    const pastedData = e.clipboardData.getData('Text');
    if (pastedData && Number(pastedData)) {
      e.preventDefault();
      const data = pastedData.substring(0, 6);
      this.props.handlePaste(data);
    }
  }

  render() {
    const { inputType, token, firstFieldRef } = this.props;
    return (
      <div className="six-digit-code ">
        {[0, 1, 2, 3, 4, 5].map((key) => (
          <Form.Control
            key={key}
            className="otp-code"
            maxLength={1}
            max="9"
            name={`opt-code-box${key}`}
            id={`opt-code-box${key}`}
            type={inputType || 'text'}
            value={token[key] || ''}
            onChange={() => this.handleChange()}
            onKeyDown={e => this.handleKeyDown(e, key)}
            onFocus={this.handleFocus}
            aria-required="true"
            aria-label="6 digit code field 2"
            onPaste={key === 0 ? (e) => this.handlePaste(e) : null}
            autoFocus={key === 0}
            ref={key === 0 && firstFieldRef ? firstFieldRef : undefined}
          />
        ))}
      </div>
    );
  }
}

export default OTPInput;
