import SignUpBanner from "../signup-banner/signup_banner";
import WebPageDescription from "./web_page_description";
import WebPageNav from "./web_page_nav";
import WebPageTitle from "./web_page_title";
import { CONFIG } from 'src/constants/config';

export default function WebPage({ webPageType }) {
    return (
        <div className="web-page container">
            <WebPageNav webPageType={webPageType} />
            <WebPageTitle webPageType={webPageType} />
            <WebPageDescription webPageType={webPageType} />
            { CONFIG.FF_WEBPAGE_BANNER &&
                <div className="web-page-banner container">
                    <SignUpBanner />
                </div>
            }
        </div>
    )
}
