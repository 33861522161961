import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PAGES from 'src/constants/pages';
import ActionCard from '../global/action_card';
import ActionCardsPlaceholder from '../global/actions_cards_placeholder';

class EnrollmentProgressCards extends React.Component {
  renderCards() {
    if (!this.props.workflow) {
      return null;
    }
    return this.props.workflow.map((c, i) => {
      const i18n_code = `wf_workflowenrollment_${c.name?.replace(/\s+/g, '').toLowerCase()}` || '';
      const card = {
        code: c.id,
        status: c.value ? 'completed' : 'pending',
        enable: true,
        locked: c.locked || false,
        is_progress_card: true,
        content: {
          href: '',
          title: this.props.t(`workflow:${i18n_code}_name`),
          subtitle: this.props.t(`workflow:${i18n_code}_subtitle`),
          description: this.props.t(`workflow:${i18n_code}_subtitle`),
        },
      };
      if (i < this.props.workflow.length - 1 && c.step === this.props.workflow[i + 1].step) {
        // next card is the same step
        return null;
      }
      if (i > 0 && c.step === this.props.workflow[i - 1].step) {
        // prev card is the same
        // this card gets rendered
        // find all cards prev with same step
        const stepsInCard = this.props.workflow.filter((comp) => comp.step === c.step);
        // check if all cards in step is complete
        const completedSteps = stepsInCard.filter((comp) => comp.value);
        if (completedSteps.length === stepsInCard.length) {
          card.status = 'completed';
        } else {
          card.status = 'pending';
        }
        // check if all cards in step is locked, if yes, lock, if not, no lock
        const allStepsLocked = stepsInCard.filter((comp) => comp.locked);
        if (allStepsLocked.length === stepsInCard.length) {
          card.locked = true;
        } else {
          card.locked = false;
        }
        const earliestPendingCard = stepsInCard.find((comp) => !comp.value);
        return <ActionCard key={i} card={card} action={() => (earliestPendingCard ? this.props.history.push(`${PAGES.ENROLLMENT}/${earliestPendingCard.id}`) : null)} />;
      }
      return <ActionCard key={i} card={card} action={() => this.props.history.push(`${PAGES.ENROLLMENT}/${c.id}`)} />;
    });
  }

  render() {
    return (
      <div className="workflow-section">
        <h2 className="mb-3 h3">{this.props.t('Your Progress')}</h2>
        <div className="action-cards">
          <div className="row small-gutters">
            { this.props.workflowIsLoading
              ? <ActionCardsPlaceholder />
              :							this.renderCards()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
  ...ownProps,
});

export default withRouter(connect(mapStateToProps, {})(withTranslation(['workflow', 'texts'])(EnrollmentProgressCards)));
