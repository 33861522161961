import classnames from 'classnames/bind';
import React, {
  ChangeEvent, useEffect, useRef, useState, KeyboardEvent,
} from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModifierArguments, Options } from '@popperjs/core';
import { DropdownItem } from './DropdownItem';
import DropdownMenuITem, { MatchedDropdownItem, MatchedPart } from './DropdownMenuItem';
import styles from './Dropdown.module.scss';

const cx = classnames.bind(styles);

export type MatchMode = 'includes' | 'beginsWith'

export type FilterInputLimit = number | 'longestOption'

export type FilterInputDisplay = 'inOptions' | 'inline';

// These are the props directly used when returning the component: <FilteredDropdownMenuComponent prop1={} prop2={} />
export type DropdownMenuProps = {
  items: DropdownItem[],
  selectedItems: DropdownItem[],
  matchMode?: MatchMode,
  filterInputLimit?: FilterInputLimit,
  filterInputDisplay?: FilterInputDisplay,
  isVisible: boolean,
  onFilterInputChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  optionsMaxHeightCalculator?: (filterInputBoundingBox: DOMRect) => number,
  onItemClicked?: (item: DropdownItem, e: React.MouseEvent) => void,
} & React.AriaAttributes;

const defaultMaxOptionsHeight = 320;

const defaultProps: DropdownMenuProps = {
  items: [],
  isVisible: false,
  selectedItems: [],
  matchMode: 'includes',
  filterInputDisplay: 'inOptions',
  optionsMaxHeightCalculator: () => defaultMaxOptionsHeight,
};

type MatcherSettings = {
  searchTerm: string,
  matchMode: MatchMode,
}

function createTextMatcher({ searchTerm, matchMode }: MatcherSettings):
  [(text:string) => boolean, (text: string) => MatchedPart[]] {
  const escapedTerm = searchTerm.replaceAll(/([^\w ])/g, '\\$1');
  const termExpression = new RegExp(
    `${matchMode === 'beginsWith' ? '^' : ''}(${escapedTerm})`, 'gi',
  );

  return [
    (text: string): boolean => termExpression.test(text),
    (text: string): MatchedPart[] => text
      .split(termExpression)
      .map((t, index, array) => (
        { highlighted: termExpression.test(t), internalKey: `${text}_${t}_${index}_${array.length}`, text: t }
      )),
  ];
}

function filterItems(items: DropdownItem[], { searchTerm, matchMode }: Partial<MatcherSettings>): MatchedDropdownItem[] {
  if (!searchTerm) {
    return items.map(({ name, key }: DropdownItem) => ({
      key,
      name,
      matchedParts: [{ highlighted: false, internalKey: key, text: name }],
    }));
  }

  const [textMatches, transformText] = createTextMatcher({ searchTerm, matchMode: matchMode || 'includes' });

  return items.reduce((accumulate: MatchedDropdownItem[], { key, name }: DropdownItem) => {
    if (!textMatches(name)) {
      return accumulate;
    }

    return [...accumulate, { key, name, matchedParts: transformText(name) }];
  }, []);
}

function calculateLongestItem(items: DropdownItem[]): number {
  return items.reduce<number>((previous, { name }: DropdownItem) => (name.length > previous ? name.length : previous), 0);
}

type CreateModifiersOptions = {
  filterInputDisplay?: FilterInputDisplay,
}

function createModifiers({ filterInputDisplay }: CreateModifiersOptions): Options['modifiers'] {
  return [
    {
      name: 'Placement',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn({ state }: ModifierArguments<any>): void {
        const { transform } = state.styles.popper;
        state.styles.popper.transform = filterInputDisplay === 'inline' ? 'translate(0px 0px)' : transform;
      },
    },
  ];
}

function DropdownMenu({
  matchMode,
  filterInputLimit,
  filterInputDisplay,
  items,
  selectedItems,
  isVisible,
  onFilterInputChange,
  optionsMaxHeightCalculator,
  onItemClicked,
  ...ariaAttributes
}: DropdownMenuProps): React.ReactElement {
  const [value, setValue] = useState('');
  const [maxInputLength, setMaxInputLength] = useState<number>(Infinity);
  const [inputShouldGetFocus, setInputShouldGetFocus] = useState<boolean>(false);
  const [highlightedItemSelected, setHighlightedItemSelected] = useState<boolean>(false);
  const [maxOptionsHeight, setMaxOptionsHeight] = useState<string>('');
  const [filteredChildren, setFilteredChildren] = useState<MatchedDropdownItem[]>([]);
  const [highlightedOption, setHighlightedOption] = useState<number>();

  const filterInputElement = useRef<HTMLInputElement>(null);
  const optionsElement = useRef<HTMLDivElement>(null);

  const modifiers = createModifiers({ filterInputDisplay });

  const { t } = useTranslation();

  // Determine the max input limit based on the filterInputLimit option.
  useEffect(() => {
    const limit: FilterInputLimit = filterInputLimit || Infinity;
    setMaxInputLength(limit === 'longestOption' ? calculateLongestItem(items) : limit);
  }, [filterInputLimit, items]);

  // Whenever the options list become visible, signal to give focus to the filter input.
  useEffect(() => {
    if (isVisible) {
      setInputShouldGetFocus(true);
      const inputPosition = filterInputElement?.current?.parentElement?.getBoundingClientRect();
      if (inputPosition) {
        const maxHeight = optionsMaxHeightCalculator ? optionsMaxHeightCalculator(inputPosition) : defaultMaxOptionsHeight;
        setMaxOptionsHeight(`${maxHeight}px`);
      }
    }

    if (!isVisible) {
      setHighlightedOption(undefined);
    }
  }, [isVisible]);

  // When filter input is signaled to get focus, trigger the event in the input element.
  useEffect(() => {
    if (inputShouldGetFocus) {
        filterInputElement?.current?.focus();
        setInputShouldGetFocus(false);
    }
  }, [inputShouldGetFocus]);

  // On filter changes, apply the filters, also highlight the option if there's only one.
  useEffect(() => {
    const filteredItems = filterItems(items, { searchTerm: value, matchMode });

    if (filteredItems.length === 1) {
      setHighlightedOption(0);
    }

    setFilteredChildren(filteredItems);
  }, [value, items, highlightedOption]);

  // Select the highlighted item when user presses enter.
  useEffect(() => {
    if (highlightedOption === undefined) {
      return;
    }

    const rootElement = optionsElement?.current;

    const selectedElement = rootElement?.children?.item(highlightedOption);

    if (!rootElement || !selectedElement) {
      return;
    }

    const { top: containerTop, bottom: containerBottom } = rootElement.getBoundingClientRect();
    const { top: selectedElementTop, bottom: selectedElementBottom } = selectedElement.getBoundingClientRect();
    const isAbove = selectedElementTop < containerTop;
    const isBelow = selectedElementBottom > containerBottom;

    if (isAbove || isBelow) {
      rootElement.scrollBy({
        top: isAbove ? selectedElementTop - containerTop : selectedElementBottom - containerBottom,
      });
    }

    if (highlightedItemSelected) {
      setHighlightedItemSelected(false);
      (selectedElement as HTMLElement).click();
    }
  }, [highlightedItemSelected, highlightedOption]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    setValue(newValue.length <= maxInputLength ? newValue : value);

    if (onFilterInputChange) {
      onFilterInputChange(e);
    }
  };

  const handleFilterKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    if (filteredChildren.length === 0) {
      return;
    }

    if (key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedOption(
        highlightedOption === undefined ? 0 : Math.min(highlightedOption + 1, filteredChildren.length - 1),
      );
    }

    if (key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedOption(
        Math.max((highlightedOption || 0) - 1, 0),
      );
    }

    if (key === 'Enter') {
      setInputShouldGetFocus(true);
      setHighlightedItemSelected(true);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <Dropdown.Menu
      className={cx(styles.dropdownMenu, styles[filterInputDisplay || 'inOptions'])}
      popperConfig={{
        modifiers,
      }}
      {...ariaAttributes}
    >
      <div className={cx(styles.dropDownFilter)}>
        <FormControl
          placeholder={t('Type to search')?.toString()}
          onChange={handleInputChange}
          value={value}
          type="text"
          ref={filterInputElement}
          tabIndex={isVisible ? undefined : -1}
          onKeyDown={handleFilterKeyDown}
        />
      </div>
      <div
        ref={optionsElement}
        className={cx(styles.dropDownOptions, { 'dropdown-menu show': filterInputDisplay === 'inline' })}
        style={{
          maxHeight: maxOptionsHeight,
        }}
      >
        {filteredChildren?.length > 0 ? filteredChildren.map((item, index) => (
          <DropdownMenuITem
            key={item.key}
            item={item}
            highlighted={highlightedOption === index}
            onItemClicked={onItemClicked}
            selected={selectedItems.some(({ key: selectedKey }) => selectedKey === item.key)}
          />
        )) : (
          <Dropdown.Item className={cx(styles.dropDownItem)}>{t('No Items')}</Dropdown.Item>
          )}
      </div>
    </Dropdown.Menu>
  );
}

DropdownMenu.defaultProps = defaultProps;

export default DropdownMenu;
