import React from 'react';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

const EMRListItem = (props) => {
  const { item, connectCopy, connectOnClick, t } = props;
  return (
    <div key={item.id} className="emr-list-item mb-2">
      <div className="emr-list-item-image">
        <img src={item.logo} alt={`${item.name} logo`} />
      </div>
      <div className="emr-list-item-text">
        <p>{item.name || t('Health Institution')}</p>
        <small>{`${[item.city, item.state, item.zipcode].filter((x) => Boolean(x)).join(', ')}`}</small>
      </div>
      <div className="emr-list-item-button">
        {
                !props.hideConnect
                  ? <Button className="btn-sm emr-connect-button" onClick={() => connectOnClick(item)}>{connectCopy}</Button>
                  : null
            }
      </div>
    </div>
  );
};

export default withTranslation()(EMRListItem);
