import React from 'react';
import { utils_questionnaire } from '../../../utils/utils_questionnaire';
import KnowledgeCheck from '../../global/knowledge_check';
import Questionnaire from '../../global/questionnaire';

export const ConsentSubcompQn = (props) => {
  const rawQuestions = { child: props.questions.qn.length ? props.questions.qn : [props.questions.qn] };
  const questions = utils_questionnaire.get_question_data(rawQuestions);

  const onQuestionnaireComplete = (res) => {
    const value = { resp: res, index: props.index };
    props.onQnComplete(value);
  };

  const isKnowledgeCheck = questions.knowledge_check;
  return (
    <div className="consent-component consent-component-qn">
      {isKnowledgeCheck ? <KnowledgeCheck questions={questions} /> : null}
      <Questionnaire
        questions={questions.qns}
        onFormComplete={res => onQuestionnaireComplete(res)}
        isKnowledgeCheck={isKnowledgeCheck}
      />
    </div>
  );
};

export default ConsentSubcompQn;
