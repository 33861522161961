/**
 * @file components.scroll-to-top.js
 *
 * make react-router scroll to top on every page transition
 *
 * https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
 * https://stackoverflow.com/questions/33188994/scroll-to-the-top-of-the-page-after-render-in-react-js
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      ReactDOM.findDOMNode(this).scrollIntoView();
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
