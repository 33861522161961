import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";


// These are the props directly used when returning the component: <AddressNotRecognizedConfirm prop1={} prop2={} />
export type AddressNotRecognizedConfirmComponentProps = {
  addressLine1?: string,
  addressLine2?: string,
  city?: string,
  state?: string,
  zip?: string,
  show: boolean,
  onConfirm: () => void,
  onCancel: () => void,
}

export type AddressNotRecognizedConfirmProps = AddressNotRecognizedConfirmComponentProps;

function AddressNotRecognizedConfirm({
  addressLine1,
  addressLine2,
  city,
  state,
  zip,
  show,
  onConfirm,
  onCancel,
}: AddressNotRecognizedConfirmProps) {

  const { t } = useTranslation();

  return (
    <Modal show={show}>
      <Modal.Header>
        <h4>{t("This address is not listed, Please confirm the address you entered is correct")}</h4>
      </Modal.Header>
      <Modal.Body>
        <strong>{t("Shipping Address")}</strong>
        <address>
          {addressLine1}<br />
          {(addressLine2 || '').length > 0 ? <>{addressLine2}<br /></> : ''}
          {city}, {state}, {zip}
        </address>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-end">
          <Button variant="link" className="mr-4" onClick={() => onCancel()}>{t("Cancel")}</Button>
          <Button variant="primary" size="sm" onClick={() => onConfirm()}>{t("Confirm")}</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

const defaultProps: AddressNotRecognizedConfirmComponentProps = {
  show: false,
  onConfirm: () => { },
  onCancel: () => { },
}

AddressNotRecognizedConfirm.defaultProps = defaultProps;

export default AddressNotRecognizedConfirm;