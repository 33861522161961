import React from 'react';
import { connect } from 'react-redux';
import {
  Router, Route, Switch, Redirect,
} from 'react-router-dom';
import LogRocket from 'logrocket';
import PAGES from 'src/constants/pages';
import { CONFIG } from 'src/constants/config';
import history from './history';
// constants
import { ENV_STRING } from './constants/api';
import { LOCAL_STORAGE } from './constants/localStorage';
import { LOGROCKET_APP_ID } from './constants/api_config';
import { TOKEN_TYPE } from './constants/tokenType';
import { FEATURE } from './constants/features';
// utils
import { utils } from './utils/utils_general';
// actions
import { set_login_user_from_local_storage } from './actions/loginAction';
import { js_config_get } from './actions/configAction';
import { dismiss_global_error } from './actions/globalerrorAction';

// COMPONENTS
import Footer from './components/global/footer';
import FooterUnauth from './components/global/footer_unauth';
import FooterWebPage from './components/global/footer_web_page'
import Spinner from './components/global/spinner';
import UnauthHeader from './components/global/unauth_header';
import UnauthLandingHeader from './components/global/unauth_landing_header';
import UnauthWebPageHeader from './components/global/unauth_web_page_header';

import AuthHeader from './components/global/auth_header';
import Login from './components/login/login';
import LoginPassword from './components/login/login-password';
import MagicLink from './components/login/magic-link';
import MfaOtp from './components/login/mfa-otp';
import MagicLinkError from './components/login/magic-link-error';
// signup
import SignupStop from './components/signup/signup-flow/signup_stop';
import SignupWelcomeBack from './components/signup/signup-flow/signup_welcome_back';
import LinkExpired from './components/signup/link_expired';
import LinkEmailSent from './components/signup/link_expired_email_sent';
// reset pw
import ResetPassword from './components/password/reset_password';
import ResetPasswordEmailSent from './components/password/reset_password_email_sent';
import ResetPasswordExpired from './components/password/reset_password_link_expired';
import ResetPasswordUpdate from './components/password/reset_password_update';
import ResetPasswordComplete from './components/password/reset_password_complete';
// surveys
import Survey from './components/survey/survey';
import SurveyConsent from './components/survey/survey_consent';
import SurveyTake from './components/survey/survey_take';
import SurveyComplete from './components/survey/survey_complete';

import Dashboard from './components/dashboard/dashboard';
import Ancestry from './components/ancestry/ancestry';
import Profile from './components/profile/profile';
import Healthcheck from './components/healthcheck';
import AppPoliciesPage from './components/global/app_policies_wrapper';

import Enrollment from './components/workflow/enrollment';
import EMRComplete from './components/workflow/emr_complete';
import EnrollmentThankyou from './components/workflow/enrollment_thankyou';
import EnrollmentProfile from './components/workflow/workflow_profile';

import AccountDeleted from './components/global/account_deleted';
import LandingPage from './components/landing-page';
import GlobalError from './components/global/global_error';
import PageLoader from './components/global/page-loader';
// import TermsAndConditions from './components/legal/terms_and_conditions'; replaced by a landing page
import PageNotFound from './components/404';
// on refresh, pg goes to top
import ScrollToTop from './components/scroll-to-top';
import { JS_FILES } from './constants/jsFiles';
import EnrollmentExit from './components/workflow/enrollment_exit';
import EMRConnectWorkflow from './components/workflow/emr_connect_workflow';
import DashboardWorkflow from './components/workflow/dashboard_workflow';
import EnrollmentWorkflow from './components/workflow/enrollment_workflow';
import { utils_workflow } from './utils/utils_workflow';
import SignupPage from './components/signup/SignupPage';

import ConsentWrapper from './components/consent/ConsentWrapper';
import { useInjection, withService } from './services/ServiceProvider';
import EMRv2PageComponent from './pages/EMRv2PageComponent';
import OrderDnaKit from './pages/OrderDnaKit';
import ConfirmEmailWrapper from './components/confirm-email/confirm_email_wrapper';
import ConfirmEmailAutoLogin from './components/confirm-email/confirm_email_auto_login'
import { ReferralLandingPage } from './components/referral/referral-landing-page';

import WebPageWrapper from './components/web-pages/web_page_wrapper';
import { WEB_PAGE } from './constants/web_pages';
import SiteMapFooter from './components/sitemap-footer/sitemap_footer';

const checkAndSetIntendedDestination = destination => {
  const DEST = LOCAL_STORAGE.INTENDED_DESTINATION;
  if (
    destination.includes(PAGES.SURVEYS) ||
    destination.includes(PAGES.ANCESTRY) ||
    destination.includes(PAGES.PROFILE) ||
    destination.includes(PAGES.DASHBOARD_WORKFLOW)
  ) {
    utils.set_local_storage(DEST, destination);
  }
};

function WrappedConfirmEmailLogin() {
  return <UnauthWrapper component={ConfirmEmailAutoLogin}/>  
 }

const AuthenticatedRoute = ({
  component: Component, path, consent, user, props, survey_id = null,
}) => {
  const { featureFlag } = props;
  const userService = useInjection('userService');

  const decoded_user = userService.getCurrentUser();
  const isUserStatusPending = userService.isUserStatusPending(user);
  const isUserStatusActive = userService.isUserStatusActive(user);
  const isUserStatusEnrollment = userService.isUserStatusEnrollment(user);
  const isUserEmailVerificationSkipped = userService.isUserEmailVerificationSkipped(props.loginUser);

  const isSKIP_VERIFY = utils.get_local_storage(LOCAL_STORAGE.SKIP_VERIFY);

  if (user && isUserEmailVerificationSkipped && !isSKIP_VERIFY ) {
    return <Route path={path} render={() => <Redirect to={PAGES.VERIFY_EMAIL}/>}/>;
  }

  // gating for active vs enrollment
  if (path.includes(PAGES.ENROLLMENT) && isUserStatusActive) {
    return <Route path={path} render={() => <Redirect to={PAGES.DASHBOARD} />} />;
  }
  if (!path.includes(PAGES.ENROLLMENT) && !path.includes(PAGES.EMR_COMPLETE) && !path.includes(PAGES.EMR_CONNECT_WORKFLOW) && isUserStatusEnrollment) {
    return <Redirect to={PAGES.ENROLLMENT} />;
  }

  // abandoned registration flow
  if (user && isUserStatusPending) {
    return <Route path={path} render={() => <UnauthWrapper component={SignupWelcomeBack} />} />;
  }
  // if all values exist then the flow had an issue and didnt get deleted, remove and move on
  utils.remove_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);

  const survey_full_path = survey_id && path.includes(PAGES.SURVEYS) ? `${path.slice(0, path.lastIndexOf('/'))}/${survey_id}` : null;

  // use not pending
  const enforced_dash_flow = props.workflow && props.workflow.workflow ? utils_workflow.incompleteEnforcedWorkflow(props.workflow.workflow, props.userAttribute) : null;
  const workflow_full_path = path.includes(PAGES.DASHBOARD_WORKFLOW) ? window.location.pathname : null;
  const intended_destination = survey_full_path || workflow_full_path || path;

  // User has to go through this before continuing to their destination
  // Show the appropiate component instead of the one that matches the
  // route
  if (!user) {
    checkAndSetIntendedDestination(intended_destination);
    return <Route path={path} render={() => <UnauthWrapper component={Login} applyWelcomeClass="mobile-welcome" />} />;
  }


  if (!consent && featureFlag && featureFlag[FEATURE.CONSENT]) {
    checkAndSetIntendedDestination(intended_destination);
    return <Route path={path} user={user} render={() => <UnauthWrapper component={ConsentWrapper} />} />;
  }

  if (!user.ga_cookie) {
    checkAndSetIntendedDestination(intended_destination);
    return <Route path={path} render={() => <UnauthWrapper component={AppPoliciesPage} />} />;
  }


  // if (isUserStatusEnrollment && !path.includes(PAGES.ENROLLMENT) && !path.includes(PAGES.EMR_COMPLETE) && !path.includes(PAGES.EMR_CONNECT_WORKFLOW)) {
  //   // is this ever needed since is handled above...?
  //   return <Route path={path} render={() => <Enrollment />} />;
  // }
  // TODO: refactor routing for dashboard components and enforced workflows
  if (isUserStatusActive && enforced_dash_flow && path !== PAGES.ENROLLMENT_PROFILE && path === PAGES.DASHBOARD && enforced_dash_flow[0]?.subworkflow_code) {
    return <Redirect to={`${PAGES.DASHBOARD_WORKFLOW}/${enforced_dash_flow[0]?.subworkflow_code}`} />;
  }
  // for logout.. ?
  if (!decoded_user || new Date(decoded_user.expiry) < new Date()) {
    utils.clear_local_storage();
    window.location = PAGES.LOGIN;
    return null;
  }
  return <Route path={path} render={() => <Component />} />;

};

const UnauthenticatedRoute = ({
  component: Component, path, user, props,
}) => {

  const { featureFlag } = props;
  // const isRegistering = utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
  const registrationType = utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_TYPE) || (user ? user.token_type : null);
  if (user && user.token_type === TOKEN_TYPE.SESSION && path !== PAGES.LOGIN) {
    return <Route path={path} render={() => <Redirect to={PAGES.DASHBOARD} />} />;
    //  } else if ((path === PAGES.SIGNUP_PASSWORD || path === PAGES.CONSENT || path === PAGES.APP_POLICIES) && (!verified && featureFlag && featureFlag[FEATURE.VERIFICATION])) {
    //    return <Route path={path} render={() => <UnauthWrapper component={Verification} />} />
  }
  return (
    <Route
      path={path}
      render={() => {
        const haveWelcomeMessage = path === PAGES.LOGIN
      || path === PAGES.LOGIN_PASSWORD
      || (path === '/' && featureFlag && featureFlag[FEATURE.LANDING_PAGE] === false)
      || (path === PAGES.SIGNUP_CONFIRM_EMAIL && registrationType === TOKEN_TYPE.REGISTRATION_ADMIN);
        return (
          <UnauthWrapper component={Component} applyWelcomeClass={(haveWelcomeMessage ? 'mobile-welcome' : '')} props={props} />
        );
      }}
    />
  );
};

const RootRoute = ({
  path, exact, landingPageFlag = false, user, props,
}) => {
  if (user?.status === 'active') {
    return <Redirect to={PAGES.DASHBOARD} />;
  }
  return (
    <>
      {landingPageFlag
        ? (
          <Route
            path={path}
            exact={exact}
            render={() => <UnauthLandingWrapper component={LandingPage} applyWelcomeClass="landing-wrapper" props={props} />}
          />
        )
        : <UnauthenticatedRoute user={user} props={props} path={path} exact={exact} component={Login} />}
    </>
  );
};

const UnauthWrapper = ({ component: Component, applyWelcomeClass = '', props }) => (
  <>
    <section className="unauth">
      <div className={`col-12 unauth-header-wrapper ${applyWelcomeClass}`}><UnauthHeader /></div>
      <div className={`unauth-component-wrapper ${applyWelcomeClass}`}>
        <Component props={props} />
        {' '}
      </div>
    </section>
    <FooterUnauth />
  </>
);

const UnauthLandingWrapper = ({ component: Component, applyWelcomeClass = '', featureFlag, props }) => (
  <>
    <section className="unauth unauth-landing">
      <div className={`col-12 unauth-header-wrapper ${applyWelcomeClass}`}><UnauthLandingHeader /></div>
      <div className={`unauth-component-wrapper ${applyWelcomeClass}`}>
        <Component props={props} featureFlag={featureFlag} />
        {' '}
      </div>
    </section>
    <SiteMapFooter/>
  </>
);

const UnauthWebPageWrapper = ({ component: Component, applyWelcomeClass = '', featureFlag, webPage, user}) => (
  <div className='web-pages-container'>
    <section className="unauth-web-page">
      <div className={`col-12 unauth-web-page-header-wrapper ${applyWelcomeClass}`}>
        <UnauthWebPageHeader user={user}/>
      </div>
      <div className={`unauth-web-page-component-wrapper ${applyWelcomeClass}`}>
        <Component featureFlag={featureFlag} webPage={webPage} />
        {' '}
      </div>
    </section>
    <FooterWebPage/>
  </div>
);

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      user: null,
      legal: null,
      globalError: false,
    };
  }

  getService(name) {
    const { container } = this.props;
    return container.get(name);
  }

  componentDidMount() {
    // feature flag is now unauth, just call it here
    this.getAppConfig();
    this.checkUserLoggedIn();

    if (LOGROCKET_APP_ID && LOGROCKET_APP_ID !== "LOGROCKET_APP_ID_PLACEHOLDER") {
      LogRocket.init(LOGROCKET_APP_ID);
    }
  }

  getAppConfig() {
    if (!this.props.featureFlag || utils.is_obj_empty(this.props.featureFlag)) {
      this.props.js_config_get(JS_FILES.APP_CONFIG);
    }
  }

  componentDidUpdate(prevProps) {
    const { loginUser } = this.props;

    // REMEMBER TO ADD OTHER TOKEN TYPES HERE IF NEEDED
    if (prevProps.loginUser && this.props.loginUser.token && prevProps.loginUser.token !== this.props.loginUser.token) {
      if (loginUser.token_type === TOKEN_TYPE.SESSION || loginUser.token_type === TOKEN_TYPE.ENROLLMENT || loginUser.token_type.includes(TOKEN_TYPE.REGISTRATION)) {
        this.setState({ user: this.props.loginUser });
      }
    }
    // logout
    if (utils.is_obj_empty(this.props.loginUser) && this.state.user) {
      this.setState({ user: null });
    }

    // check kick user out if their expired
    const user = this.props.userService.getCurrentUser();

    if (user && new Date(user.expiry) <= new Date() && user.token_type === TOKEN_TYPE.SESSION) {
      this.checkUserLoggedIn();
    }

    if (prevProps.globalError !== this.props.globalError) {
      this.setState({ globalError: this.props.globalError });
    }
  }

  onHideGlobalErrorModal() {
    this.props.dismiss_global_error();
    this.setState({ globalError: false });
  }

  checkUserLoggedIn() {
    const user = this.props.userService.getCurrentUser();
    if (user && new Date(user.expiry) > new Date()) {
      if (
        user.token_type === TOKEN_TYPE.SESSION ||
        user.token_type === TOKEN_TYPE.ENROLLMENT ||
        user.token_type.includes(TOKEN_TYPE.REGISTRATION)
      ) {
        this.setState({ user });
        if (utils.is_obj_empty(this.props.loginUser)) {
          this.props.set_login_user_from_local_storage();
        }
      }
    } else {
      this.setState({ user: null });
      utils.clear_local_storage({
        exclude: [LOCAL_STORAGE.INTENDED_DESTINATION],
      });
    }
  }

  renderPageNotFoundRoute(user) {
    return <Route path={PAGES.NOT_FOUND} render={() => {
      return <div className='not-found-page-container'>
        <PageNotFound user={user}/>
        <Footer />
      </div>
    }} />;
  }

  render() {
    const { user, globalError } = this.state;
    const {
      userService,
      featureFlag,
      workflow,
      userAttribute,
      spinner,
    } = this.props;
    const pathname = history.location.pathname ? history.location.pathname.toLowerCase() : '';

    const checkFeatureFlag = (feature) => !featureFlag || (featureFlag && featureFlag[feature] !== false);

    const consentFlag = checkFeatureFlag(FEATURE.CONSENT);
    const surveyFlag = checkFeatureFlag(FEATURE.SURVEY);
    const onBoardAppPoliciesFlag = checkFeatureFlag(FEATURE.ONBOARD_APP_POLICIES);
    const ancestryPage = checkFeatureFlag(FEATURE.ANCESTRY);
    const landingPageFlag = checkFeatureFlag(FEATURE.LANDING_PAGE);

    const consent = user ? user.esign : null;
    const appPoliciesSigned = user ? user.ga_cookie !== null : null;
    const browser = utils.get_browser();

    const isUserStatusActive = userService.isUserStatusActive(user)
      && (consent || !consentFlag)
      && (appPoliciesSigned || !onBoardAppPoliciesFlag);

    const isUserStatusEnrollment = userService.isUserStatusEnrollment(user)
      && (consent || !consentFlag)
      && (appPoliciesSigned || !onBoardAppPoliciesFlag);
  
    const forceUnauthLayout = pathname === PAGES.PRIVACY_POLICY
        || pathname === PAGES.TERMS_OF_SERVICE
        || pathname === PAGES.ABOUT_US
        || pathname === PAGES.CONTACT_US
        // || pathname === PAGES.SIGNUP_VERIFICATION
        || pathname === PAGES.LOGIN
        || pathname === PAGES.LOGIN_PASSWORD
        || pathname === PAGES.MAGIC_LINK
        || pathname === PAGES.MFA_OTP
        || pathname === PAGES.CONFIRM_EMAIL
        || pathname === PAGES.VERIFY_EMAIL
        || pathname.includes('signup')
        || !consent || !appPoliciesSigned;

    const showWebPageLayout = pathname.includes(PAGES.PRIVACY_POLICY)
      || pathname.includes(PAGES.CONTACT)
      || pathname.includes(PAGES.BLOG)
      || pathname.includes(PAGES.ABOUT_US)
      || pathname.includes(PAGES.STUDY_TEAM)
      || pathname.includes(PAGES.STUDY_PARTNERSHIPS)
      || pathname.includes(PAGES.SUPPORT)
      || pathname.includes(PAGES.RECRUITMENT)
      || pathname.includes(PAGES.DIVERSITY)
      || pathname.includes(PAGES.GENETICS_101)
      || pathname.includes(PAGES.DATA_PROTECTION)
      || pathname.includes(PAGES.RESEARCH_CONSENT)
      || pathname.includes(PAGES.COOKIE_POLICY)
      || pathname.includes(PAGES.FUNDING)
      || pathname.includes(PAGES.CORE_VALUES)
      || pathname.includes(PAGES.NEWS)
      || pathname.includes(PAGES.VOLUNTEER)
      || pathname.includes(PAGES.ANCESTRY_CONTENT)
      || pathname.includes(PAGES.WITHDRAWAL)
      || pathname.includes(PAGES.TERMS_OF_SERVICE)
      || pathname.includes(PAGES.FAQ);

    const isReferralLandingPage = pathname === PAGES.REFERRAL_LANDING_PAGE;

    const page = `${pathname.replace('/', '').replace(/\//g, '-')}-page`;
    const surveyId = pathname.includes(PAGES.SURVEY_START) ? pathname.split('/').pop() : null;

    const is404 = (Object.values(PAGES).filter((pageRoute) => pathname.includes(pageRoute)).length === 0)
      || (!ancestryPage && pathname === PAGES.ANCESTRY)
      || (!surveyFlag && pathname.includes('survey'));

    const isWorkflow = pathname.includes(PAGES.EMR_COMPLETE)
      || pathname.includes(PAGES.EMR_CONNECT_WORKFLOW)
      || pathname.includes('/workflow'); // TODO: use a better way to handle routing
      // || (workflow && workflow.workflow ? utils_workflow.incompleteEnforcedWorkflow(workflow.workflow, userAttribute) : false);

    const isVerifyEmailSkip = userService.isUserEmailVerificationSkipped(user);

    const isSKIP_VERIFY = utils.get_local_storage(LOCAL_STORAGE.SKIP_VERIFY);

    const forceEmailVerify = isVerifyEmailSkip && !isSKIP_VERIFY;
    const showDashboardLayout = isUserStatusActive && !isWorkflow && !forceUnauthLayout && !is404 && !forceEmailVerify && !showWebPageLayout && !isReferralLandingPage && pathname !== PAGES.NOT_FOUND;
    const showEnrollmentLayout = (isUserStatusEnrollment || pathname.includes(PAGES.ENROLLMENT) || isWorkflow)
    && !is404
    && !forceUnauthLayout
    && !showWebPageLayout
    && !isReferralLandingPage
    && pathname !== PAGES.NOT_FOUND;

    const isLandingPage = (landingPageFlag && (pathname === '/'));

    // current fader being used due to featureflag not loading in time on '/', and confirm-email cus slow
    // and if landing page is turned off, it flashes - use loader to fade in experience
    const isShowPageFaderPage = pathname === '/';
    const showPageFader = !featureFlag;
    return (
      <Router history={history}>
        <ScrollToTop>
          {isShowPageFaderPage ? <PageLoader loader={showPageFader} blankPage /> : null}
          <div data-testid="app" className={`App ${browser} ${isLandingPage ? 'start' : ''}${page} 
            ${ showDashboardLayout || showEnrollmentLayout || showWebPageLayout ? '' : 'unauthencated'}  ${showEnrollmentLayout ? 'enrollment' : ''}`} >
            {ENV_STRING ? <small className="badge badge-danger environment-indicator">{ENV_STRING}</small> : null}
            <main>
              <div className='flex-container'>
                <div className='header-content-container'>
                  {showDashboardLayout && pathname !== PAGES.NOT_FOUND ? <AuthHeader user={user} /> : null}
                  <div className={`${showDashboardLayout || showEnrollmentLayout ? 'auth-content' : 'unauth-content'} ${showEnrollmentLayout ? 'enrollment-page' : ''}`}>
                    <Switch>
                      <Route path={PAGES.CONFIRM_EMAIL} component={WrappedConfirmEmailLogin} />
                      { CONFIG.REFERRAL ? <Route path={PAGES.REFERRAL_LANDING_PAGE} component={ReferralLandingPage} /> : null}
                      <RootRoute path={PAGES.HOME_PAGE} exact user={user} props={this.props} landingPageFlag={landingPageFlag} />
                      <UnauthenticatedRoute user={user} props={this.props} path={PAGES.SIGNUP_LINK_EXPIRED} exact component={LinkExpired} />
                      <UnauthenticatedRoute user={user} props={this.props} path={PAGES.SIGNUP_LINK_EMAIL_SENT} exact component={LinkEmailSent} />
                      <Route path={PAGES.SIGNUP_STOP} exact render={() => <UnauthWrapper component={SignupStop} />} />
                      <UnauthenticatedRoute user={user} props={this.props} path={PAGES.SIGNUP} component={SignupPage} />
                      <UnauthenticatedRoute user={user} props={this.props} path={PAGES.LOGIN} exact component={Login} />
                      <UnauthenticatedRoute user={user} props={this.props} path={PAGES.ACCOUNT_DELETED} exact component={AccountDeleted} />
                      { CONFIG.MAGIC_LINK_ENABLED ? <UnauthenticatedRoute user={user} props={this.props} path={PAGES.LOGIN_PASSWORD} exact component={LoginPassword} /> : null }
                      { CONFIG.MAGIC_LINK_ENABLED ? <UnauthenticatedRoute user={user} props={this.props} path={PAGES.MAGIC_LINK} exact component={MagicLink} /> : null }
                      { CONFIG.MAGIC_LINK_ENABLED ? <UnauthenticatedRoute user={user} props={this.props} path={PAGES.MAGIC_LINK_ERROR} exact component={MagicLinkError} /> : null }
                      <UnauthenticatedRoute user={user} props={this.props} path={PAGES.MFA_OTP} exact component={MfaOtp} />
                      <UnauthenticatedRoute user={user} props={this.props} path={PAGES.RESET_PASSWORD_SEND_EMAIL} exact component={ResetPassword} />
                      <UnauthenticatedRoute user={user} props={this.props} path={PAGES.RESET_PASSWORD_SEND_EMAIL_COMPLETE} exact component={ResetPasswordEmailSent} />
                      <UnauthenticatedRoute user={user} props={this.props} path={PAGES.RESET_PASSWORD_EXPIRED} exact component={ResetPasswordExpired} />
                      <UnauthenticatedRoute user={user} props={this.props} path={PAGES.RESET_PASSWORD_SET_NEW} exact component={ResetPasswordUpdate} />
                      <UnauthenticatedRoute user={user} props={this.props} path={PAGES.RESET_PASSWORD_COMPLETE} exact component={ResetPasswordComplete} />
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={PAGES.DASHBOARD} exact component={Dashboard} />
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={`${PAGES.DASHBOARD_WORKFLOW_EMR}`} exact component={EMRv2PageComponent} />
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={`${PAGES.DASHBOARD_WORKFLOW_SPITKIT}`} exact component={OrderDnaKit} />
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={`${PAGES.DASHBOARD_WORKFLOW}/:workflow_code/:component_id`} exact component={DashboardWorkflow} />
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={`${PAGES.DASHBOARD_WORKFLOW}/:workflow_code`} exact component={DashboardWorkflow} />
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={PAGES.ENROLLMENT} exact component={Enrollment} />
                      {
                        !isUserStatusEnrollment
                          ? (
                            <AuthenticatedRoute
                              user={user}
                              consent={consent}
                              props={this.props}
                              path={PAGES.EMR_CONNECT_WORKFLOW}
                              exact
                              component={EMRConnectWorkflow}
                            />
                          )
                          : null
                      }
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={`${PAGES.ENROLLMENT}/:component_id`} exact component={EnrollmentWorkflow} />
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={`${PAGES.ENROLLMENT_THANKYOU}/:component_id`} exact component={EnrollmentThankyou} />
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={PAGES.ENROLLMENT_PROFILE} exact component={EnrollmentProfile} />
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={PAGES.ENROLLMENT_EXIT} exact component={EnrollmentExit} />
                      {surveyFlag ? <AuthenticatedRoute user={user} consent={consent} props={this.props} path={PAGES.SURVEYS} exact component={Survey} /> : null}
                      {surveyFlag ? <AuthenticatedRoute user={user} consent={consent} props={this.props} path={`${PAGES.SURVEY_CONSENT}/:survey_id`} exact component={SurveyConsent} /> : null}
                      {surveyFlag ? <AuthenticatedRoute user={user} consent={consent} props={this.props} path={`${PAGES.SURVEY_START}/:survey_id`} survey_id={surveyId} exact component={SurveyTake} /> : null}
                      {surveyFlag ? <AuthenticatedRoute user={user} consent={consent} props={this.props} path={PAGES.SURVEY_COMPLETE} component={SurveyComplete} /> : null}
                      {ancestryPage ? <AuthenticatedRoute user={user} consent={consent} props={this.props} path={PAGES.ANCESTRY} exact component={Ancestry} /> : null}
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={PAGES.EMR_COMPLETE} exact component={EMRComplete} />
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={PAGES.PROFILE} exact component={Profile} />
                      <AuthenticatedRoute user={user} consent={consent} props={this.props} path={PAGES.HEALTHCHECK} exact component={Healthcheck} />
                      {/* <Route path={PAGES.ABOUT_US} exact render={() => <UnauthWrapper component={AboutUs} />} /> */}
                      <Route path={PAGES.VERIFY_EMAIL} props={this.props} render={() => <UnauthWrapper component={ConfirmEmailWrapper}/>}/>
                      <Route path={PAGES.ABOUT_US} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.ABOUT_US} applyWelcomeClass="landing-wrapper" user={user}/>} />
                      <Route path={PAGES.CONTACT_US} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.CONTACT_US} applyWelcomeClass="landing-wrapper" user={user}/>} />
                      <Route path={PAGES.ANCESTRY_CONTENT} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.ANCESTRY_CONTENT} applyWelcomeClass="landing-wrapper" user={user}/>} />
                      {/* auxiliary pages  */}
                      { CONFIG.FF_WEBPAGE_TOS && <Route path={PAGES.TERMS_OF_SERVICE} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.TERMS_OF_SERVICE} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_PRIVACY_POLICY && <Route path={PAGES.PRIVACY_POLICY} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.PRIVACY_POLICY} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_CONTACT && <Route path={PAGES.CONTACT} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.CONTACT} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_FAQ && <Route path={PAGES.FAQ} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.FAQ} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_BLOG && <Route path={PAGES.BLOG} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.BLOG} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_COOKIE_POLICY && <Route path={PAGES.COOKIE_POLICY} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.COOKIE_POLICY} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_CORE_VALUES && <Route path={PAGES.CORE_VALUES} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.CORE_VALUES} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_DATA_PROTECTION && <Route path={PAGES.DATA_PROTECTION} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.DATA_PROTECTION} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_DIVERSITY && <Route path={PAGES.DIVERSITY} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.DIVERSITY} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_FUNDING && <Route path={PAGES.FUNDING} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.FUNDING} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_GENETICS_101 && <Route path={PAGES.GENETICS_101} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.GENETICS_101} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_NEWS && <Route path={PAGES.NEWS} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.NEWS} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_RECRUITMENT && <Route path={PAGES.RECRUITMENT} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.RECRUITMENT} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_RESEARCH_CONSENT && <Route path={PAGES.RESEARCH_CONSENT} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.RESEARCH_CONSENT} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_STUDY_PARTNERSHIPS && <Route path={PAGES.STUDY_PARTNERSHIPS} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.STUDY_PARTNERSHIPS} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_STUDY_TEAM && <Route path={PAGES.STUDY_TEAM} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.STUDY_TEAM} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_SUPPORT && <Route path={PAGES.SUPPORT} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.SUPPORT} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_VOLUNTEER && <Route path={PAGES.VOLUNTEER} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.VOLUNTEER} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      { CONFIG.FF_WEBPAGE_WITHDRAWAL && <Route path={PAGES.WITHDRAWAL} exact render={() => <UnauthWebPageWrapper component={WebPageWrapper} webPage={WEB_PAGE.WITHDRAWAL} applyWelcomeClass="landing-wrapper" user={user}/>} /> }
                      {/* auxiliary pages end */}
                      {this.renderPageNotFoundRoute(user)}
                      <Redirect to={PAGES.NOT_FOUND} />

                    </Switch>
                    {/* why did we check abandoned reg?? */}
                    {/* {this.props.spinner && !isAbandonedReg ? <Spinner error={this.props.spinner}></Spinner> : null} */}
                    {spinner ? <Spinner data={spinner} /> : null}

                    <GlobalError show={globalError} onHide={() => this.onHideGlobalErrorModal()} />

                  </div>
                </div>
                { showDashboardLayout || showEnrollmentLayout ? <Footer />  : null}
              </div>
            </main>
          </div>


        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

const dispatch = (dispatch) => dispatch;

export default connect(mapStateToProps, {
  set_login_user_from_local_storage, js_config_get, dismiss_global_error, dispatch,
})(withService(['userService'])(App));
