import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { post_user_action_post } from '../../actions/userAction';
import WorkflowLayout from './workflow_layout';
import { USER_ACTION } from '../../constants/userAction';
import PersonalInfo from '../profile/personal_info';
import Notifications from '../profile/notifications';
import Legal from '../profile/legal';
import Security from '../profile/security';
import EMRResultsProfile from '../profile/emr_results_profile';
import HtmlContentComponent from 'src/components/core/HtmlContent/HtmlContentComponent';

class EnrollmentProfile extends React.Component {
  renderContent() {
    return (
      <div className="profile workflow-component-wrapper bg-none rounded">
        <PersonalInfo />
        <>
          <Legal />
          <Security />
        </>
        <EMRResultsProfile hideConnect />
      </div>
    );
  }

  updateUserAction() {
    return this.props.post_user_action_post(USER_ACTION.PROFILE_VIEW, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };

    this._isMounted = false;
  }

  renderProfileDescription() {
    const { t } = this.props;
    return (
      <div className="get-started section-component">
        <h2 className="side-heading mb-3">{t('Profile')}</h2>
        <p className="mb-3">
          <HtmlContentComponent markup={t('content:copy_page_description_profile')} />
        </p>
      </div>
    );
  }

  render() {
    const aside = {
      name: 'Your profile info',
      details: {
        description: this.props.t('content:copy_page_description_profile'),
        allow_pause: true,
      },
    };
    return (
      <WorkflowLayout
        workflow={this.props.workflow}
        additionalClass="profile"
        aside={aside}
        mainSection={this.renderContent()}
        rightSection={<Notifications />}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  location: ownProps.location,
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
  ...ownProps,
});
export default withRouter(connect(mapStateToProps, { post_user_action_post })(withTranslation()(EnrollmentProfile)));
