import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

import { ReferralLink, ReferralLinkSmall } from './referral-link';
import { ReferralButtons } from './referral-buttons';

import styles from './enrollment-referral-banner.module.scss';

export function EnrollmentReferralBanner(props) {
  const { onRequestContinue } = props;
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        styles.referralBanner,
        'rounded mb-4 position-relative overflow-hidden'
      )}
    >
      <div className="p-4 position-relative">
        <h3 className={classNames(styles.header, 'mb-3')}>
          {t('You’ve Completed Enrollment, Now Share!')}
        </h3>
        <p className={styles.message}>
          {t(
            'Know someone who can benefit from this study? Invite them and together we can make a difference!'
          )}
        </p>
        <div className="mb-3 d-none d-sm-block">
          <ReferralLink />
        </div>
        <div className="mb-3 d-block d-sm-none">
          <ReferralLinkSmall />
        </div>
        <div className="mb-4 d-none d-sm-block">
          <ReferralButtons />
        </div>
        <Button
          className="primary-invert"
          variant="primary"
          onClick={onRequestContinue}
        >
          {t('Continue')}
        </Button>
      </div>
    </div>
  );
}
