import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';
import { api_ancestry } from '../api/api_ancestry.js';
import { utils_api } from '../utils/utils_api';

export function ancestry_get(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'ancestry_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE, 'ancestry_get');
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_ancestry.get_ancestry(success, fail);
  };
}

export function ancestrystatus_get(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'ancestrystatus_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE, 'ancestrystatus_get');
      dispatch({
        type: REDUX_ACTION.ANCESTRY_STATUS,
        data: response.data.data,
      });
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_ancestry.get_ancestrystatus(success, fail);
  };
}
