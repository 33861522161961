import { REDUX_ACTION } from 'src/constants/reduxAction';
import types, {ConsentAction} from './types';

export const doSetConsent = (consentConfig: object) => {
  const action: ConsentAction = {
    type: types.SET_CONSENT,
    consentConfig,
  };

  return (dispatch: any) => {
    dispatch({ type: REDUX_ACTION.SPINNER_COMPLETE });
    dispatch(action);
  };
};

export const doSetConsentLoading = (isLoading: boolean) => (dispatch: any) => {
  const spinnerType = isLoading ? REDUX_ACTION.SPINNER : REDUX_ACTION.SPINNER_COMPLETE;
  dispatch({ type: spinnerType });
  return dispatch({
    type: types.SET_CONSENT_LOADING,
    isLoading,
  });
};

export const doSetConsentFileLoading = (isLoading: boolean) => (dispatch: any) => {
  const spinnerType = isLoading ? REDUX_ACTION.SPINNER : REDUX_ACTION.SPINNER_COMPLETE;
  dispatch({ type: spinnerType });

  const actionToGo = {
    type: types.SET_CONSENT_FILE_LOADING,
  };
  return dispatch(actionToGo);
};
