import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import PAGES from 'src/constants/pages';

export default function MagicLinkError() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const error = location.state?.error;
  const handleSendNewLink = () => {
    history.push(PAGES.LOGIN);
  };
  const messages = {
    'invalid magiclink token': t('This link is invalid'),
    'magiclink token has expired': t('This link has expired'),
    'magiclink already claimed or activated': t(
      'This link has already been claimed'
    ),
  };
  const message = messages[error];
  return (
    <section>
      <div className="login unauth-body-wrapper">
        <div>
          <h1>
            {t('Oops!')}
            <br />
            {message || ''}
          </h1>
        </div>
        {!message ? (
          <p>{t("We've hit an error while signing you in.")}</p>
        ) : null}
        <p>
          {t(
            'Don’t worry, we can send you a new one  or sign in with a password instead.'
          )}
        </p>
        <div className="text-right py-4">
          <Button variant="primary" onClick={handleSendNewLink}>
            {t('Go back to sign in')}
          </Button>
        </div>
      </div>
    </section>
  );
}
