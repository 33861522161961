import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';

import { LOCAL_STORAGE } from 'src/constants/localStorage';
import { utils } from 'src/utils/utils_general';

import { ReactComponent as CloseIconBlack } from '../../assets/icons/close-black.svg';
import { ReactComponent as CloseIconWhite } from '../../assets/icons/close.svg';

import { ReferralModal } from './referral-modal';
import styles from './modal-referral-banner.module.scss';

function ModalReferralBannerNotification(props) {
  const { t } = useTranslation();
  const { onRequestClose, handleInviteNow } = props;
  return createPortal(
    <div className={classNames(styles.notificationPortal, 'd-block d-md-none')}>
      <div className={classNames(styles.notificationContainer, 'container')}>
        <div className={classNames(styles.notification, 'rounded-bottom')}>
          <button
            className={styles.closeButton}
            aria-label={t('Close')}
            onClick={onRequestClose}
          >
            <span aria-hidden="true">
              <CloseIconBlack />
            </span>
          </button>
          <h3 className={styles.notificationTitle}>{t('Invite a friend')}</h3>
          <p>
            {t(
              'Spread the word and help our study. Each person who participates is another step closer to understanding the disease.'
            )}{' '}
            <button
              onClick={handleInviteNow}
              className={styles.inviteLinkButton}
            >
              {t('Invite now ')}
            </button>
          </p>
        </div>
      </div>
    </div>,
    document.body
  );
}

function ModalReferralBannerBanner(props) {
  const { t } = useTranslation();
  const { onRequestClose, handleInviteNow } = props;
  return (
    <div
      className={classNames(
        styles.referralBanner,
        'd-none d-md-block section-component rounded px-5 py-3 text-white'
      )}
    >
      <div>
        <h3 className={classNames(styles.bannerTitle, 'mb-1')}>
          {t('Invite a friend')}
        </h3>
        <button
          className={styles.bannerCloseButton}
          aria-label={t('Close')}
          onClick={onRequestClose}
        >
          <span aria-hidden="true">
            <CloseIconWhite />
          </span>
        </button>
      </div>
      <div className="d-flex">
        <p className="mb-0 mr-3">
          {t(
            'Spread the word and help our study. Each person who participates is another step closer to understanding the disease.'
          )}
        </p>
        <div>
          <Button
            variant="secondary"
            onClick={handleInviteNow}
            className="text-nowrap dark-hover"
          >
            {t('Invite now ')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export function ModalReferralBanner() {
  const hasBannerBeenClosed = Boolean(
    utils.get_local_storage(LOCAL_STORAGE.REFERRAL_BANNER_CLOSED)
  );
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onRequestClose = () => {
    utils.set_local_storage(LOCAL_STORAGE.REFERRAL_BANNER_CLOSED, true);
    setIsBannerOpen(false);
  };
  const handleInviteNow = () => {
    setIsModalOpen(true);
  };
  const handleRequestCloseModal = () => {
    setIsModalOpen(false);
  };
  const isBannerShown = isBannerOpen && !hasBannerBeenClosed;
  return (
    <>
      {isBannerShown ? (
        <>
          <ModalReferralBannerNotification
            onRequestClose={onRequestClose}
            handleInviteNow={handleInviteNow}
          />
          <ModalReferralBannerBanner
            onRequestClose={onRequestClose}
            handleInviteNow={handleInviteNow}
          />
        </>
      ) : null}
      <ReferralModal
        show={isModalOpen}
        onRequestClose={handleRequestCloseModal}
      />
    </>
  );
}
