import Joi, { ObjectSchema } from "joi";
import { email, firstName, lastName, password, phone, zip } from "./Common";

export interface SelfRegister<
  TStepFields = string[],

  TPassword = string,
  TEmail = string,
  TFirstName = string,
  TLastName = string,
  TPhoneMobile = string,
  TPhoneHome = string,
  TZipCode = string,
  TNewSurveyNotifications = boolean,
  TAncestryNotifications = boolean,
  > {
  StepFields: TStepFields,

  password: TPassword,
  email: TEmail,
  first_name: TFirstName,
  last_name: TLastName,
  ph_mobile: TPhoneMobile,
  ph_home: TPhoneHome,
  zip: TZipCode,
  new_survey_notifications: TNewSurveyNotifications,
  ancestry_notifications: TAncestryNotifications,
}

export type SelfRegisterJoi = SelfRegister<
  Joi.ArraySchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.StringSchema,
  Joi.BooleanSchema,
  Joi.BooleanSchema
>

/**
 * Makes sure only the fields related to the current step will be validated.
 * 
 * @param fieldName The name of the field as in the signupFields prop.
 * @param schema The field validation.
 * @returns A conditional validation.
 */
const selfRegisterField = (fieldName: keyof SelfRegister, schema: Joi.Schema): Joi.Schema => {
  return Joi.when(
    'StepFields', {
    is: Joi.array().items(Joi.string().valid(fieldName).required(), Joi.any()),
    then: schema,
    otherwise: Joi.string().optional().forbidden(),
  });
};

export const SELF_REGISTER_VALIDATION: ObjectSchema<SelfRegisterJoi> = Joi.object({
  StepFields: Joi.array().required(),

  password: selfRegisterField('password', password),
  email: selfRegisterField('email', email),
  first_name: selfRegisterField('first_name', firstName),
  last_name: selfRegisterField('last_name', lastName),
  ph_mobile: selfRegisterField('ph_mobile', phone.required()),
  ph_home: selfRegisterField('ph_home', phone.required()),
  zip: selfRegisterField('zip', zip.required()),
  new_survey_notifications: selfRegisterField('new_survey_notifications', Joi.bool()),
  ancestry_notifications: selfRegisterField('ancestry_notifications', Joi.bool()),
})