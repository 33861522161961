import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PAGES from 'src/constants/pages';
import { REDUX_ACTION } from 'src/constants/reduxAction';
import LoginPassword from './login-password';
import LoginMagicLink from './login-magic-link';


import { CONFIG } from 'src/constants/config';

export default function Login() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // if we are in /login clear local storage and reset redux store
    if (location.pathname === PAGES.LOGIN) {
      localStorage.clear();
      dispatch({
        type: REDUX_ACTION.LOGOUT_GET,
        data: null,
      });
    }
  }, [location, dispatch]);

  return CONFIG.MAGIC_LINK_ENABLED ? (
    <LoginMagicLink />
  ) : (
    <LoginPassword />
  );
}
