import { REDUX_ACTION } from '../constants/reduxAction.js';
import { utils } from '../utils/utils_general';
import { api_user } from '../api/api_user';
import { utils_api } from '../utils/utils_api.js';
import { loginSuccess } from './loginAction';

export function update_user_put(data, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'update_user_put');
    }
    const success = (response) => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.update_user(success, fail, data);
  };
}

export function delete_user_delete(type, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'delete_user_delete');
    }
    const success = (response) => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.delete_user(success, fail, type);
  };
}

export function get_user_attributes_get(hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'get_user_attributes_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      dispatch({
        type: REDUX_ACTION.USER_ATTRIBUTE,
        data: response.data.data,
      });
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.get_user_attribute(success, fail);
  };
}

export function get_one_user_attribute_get(attr_code, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'get_one_user_attribute_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data.value;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.get_one_user_attribute(success, fail, attr_code);
  };
}

export function post_user_attributes_post(code, value, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'post_user_attributes_post');
    }
    const success = (response) => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.post_user_attribute(success, fail, code, value);
  };
}

export function post_user_action_post(actiondata, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'post_user_action_post');
    }
    const success = (response) => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.post_user_action(success, fail, actiondata);
  };
}

export function post_mfa_attribute_post(data, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'post_mfa_attribute_post');
    }
    const success = (response) => utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.post_mfa_attribute(success, fail, data);
  };
}

export function create_self_register_post(data, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'create_self_register_post');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response.data.data;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.post_self_register(success, fail, data);
  };
}

export function post_workflow_complete_post(hideSpinner = true) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'post_workflow_complete_post');
    }
    
    const success = loginSuccess(dispatch, REDUX_ACTION.REGISTRATION_COMPLETE);

    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.post_workflow_complete(success, fail);
  };
}

export function check_email_avail_get(email, hideSpinner = null) {
  return (dispatch) => {
    if (!hideSpinner) {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER, 'check_email_avail_get');
    }
    const success = (response) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return response;
    };
    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_user.get_check_email_avail(success, fail, email);
  };
}
