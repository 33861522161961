import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { utils } from '../../utils/utils_general';
import SurveyConsentText from './survey_consent_text';
import IconGenerator from '../icons/icon_generator';
import PAGES from 'src/constants/pages';

class SurveyConsentModal extends React.Component {
  goToSurveys() {
    if (this.props.fromSurvey) {
      this.props.onHide();
    } else {
      this.props.onHide();
      this.props.history.push(PAGES.SURVEYS);
    }
  }

  hide() {
    this.props.onHide();
  }

  render() {
    const { survey, t } = this.props;
    const browser = utils.get_browser();
    if (!survey) {
      return null;
    }
    return (
      <Modal
        show={Boolean(this.props.show)}
        onHide={() => this.hide()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`survey-consent-modal ${browser}`}
      >
        <div className="survey-consent-modal-icon"><IconGenerator iconKey={survey.limesurvey_id} /></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h1 className="surveys-h1">{t(survey.title)}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SurveyConsentText survey={survey} />
        </Modal.Body>
        <div className="mb-3 text-center">
          <Button variant="link" onClick={() => this.goToSurveys()} className="btn-link-callout m-0 p-0">
            {' '}
            {this.props.t(this.props.fromSurvey ? 'Back to all surveys' : 'View all surveys')}
          </Button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(SurveyConsentModal)));
