import React from 'react';
import { connect } from 'react-redux';

import { get_user_attributes_get } from '../../actions/userAction';
import { get_survey_list_get } from '../../actions/surveyAction';
import { get_highlighted_survey } from '../../actions/surveyAction';
import { actioncards_get } from '../../actions/actioncardsAction';
import { poll_get } from '../../actions/pollsAction';
import { ancestrystatus_get } from '../../actions/ancestryAction';

class Prefetch extends React.Component {
  componentDidMount() {
    const hideSpinner = true;
    // prefetch
    if (!this.props.actionCards) {
      this.props.actioncards_get(hideSpinner);
    }
    if (!this.props.surveys) {
      this.props.get_survey_list_get(hideSpinner);
    }
    if (!this.props.userAttribute) {
      this.props.get_user_attributes_get(hideSpinner);
    }
    if (!this.props.quickpolls) {
      this.props.poll_get(hideSpinner);
    }
    if (!this.props.ancestry_status) {
      this.props.ancestrystatus_get(hideSpinner);
    }
    if (!this.props.highlightedSurvey) {
      this.props.get_highlighted_survey(hideSpinner);
    }
  }

  // componentWillUnmount() {

  //     this.props.actioncards_get(true, true);
  // }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  get_user_attributes_get, get_survey_list_get, get_highlighted_survey, actioncards_get, poll_get, ancestrystatus_get,
})(Prefetch);
