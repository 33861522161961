import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';

import PAGES from 'src/constants/pages';
import { TOKEN_TYPE } from 'src/constants/tokenType';
import OTPInput from 'src/components/core/otp-input';
import { login_mfa_otp_request_post } from 'src/actions/loginAction';
import { login_mfa_otp_validation_post } from 'src/actions/loginAction';
import { workflow_get } from 'src/actions/workflowAction';
import {
  UNEXPECTED_ERROR,
  OTP_ERROR_DISPLAY,
  RESPONSE_CODE,
} from 'src/constants/errors';
import { goToDestination } from './utils';

function formatPhone(phone) {
  const lastDigits = phone.slice(-4);
  return `(***) ***-${lastDigits}`;
}

function MfaOtpValidation(props) {
  const { t } = useTranslation();
  const { otpId, phone, error, setError, onRequestOtp } = props;

  const isAppLoading = useSelector(state => state.spinner);

  const initialOtpDigits = ['', '', '', '', '', ''];
  const [otpDigits, setOtpDigits] = useState(initialOtpDigits);
  const dispatch = useDispatch();

  const history = useHistory();
  const userAttribute = useSelector(state => state.userAttribute);

  const submitMfaOtp = async otp => {
    const resultPromise = dispatch(login_mfa_otp_validation_post(otpId, otp));
    const goToApp = (user, flow) => {
      goToDestination(history, user, userAttribute, flow);
    };
    resultPromise
      .then(user => {
        const valid_login_session = [
          TOKEN_TYPE.SESSION,
          TOKEN_TYPE.REGISTRATION_SELF,
          TOKEN_TYPE.REGISTRATION_SUBACCOUNT,
          TOKEN_TYPE.REGISTRATION_ADMIN,
          TOKEN_TYPE.ENROLLMENT,
        ];
        if (!valid_login_session.includes(user.token_type)) {
          // TODO: Show invalid token screen
          //setErrors({ system: t(LOGIN_ERROR_DISPLAY.SESSION_INVALID) });
        } else if (new Date(user.expiry) < new Date()) {
          // TODO: Show invalid token screen
          // setErrors({ system: t(LOGIN_ERROR_DISPLAY.SESSION_EXPIRED) });
        } else if (user.status === 'pending') {
          // DONT GET THE WORKFLOW IF USER IS STILL IN REG MODE
          goToApp(user);
        } else {
          dispatch(workflow_get())
            .then(flow => goToApp(user, flow))
            .catch(() => goToApp(user));
        }
      })
      .catch(error => {
        const errorMessage = error?.response?.data?.msg;
        if (errorMessage === 'incorrect otp code') {
          return setError(
            t('The code you entered is incorrect. Please try again.')
          );
        }
        if (errorMessage === 'invalid magiclink token') {
          // TODO: Show invalid token screen
          return setError(
            t('The code you entered is incorrect. Please try again.')
          );
        } else {
          return setError(t(UNEXPECTED_ERROR));
        }
      });
  };

  const handleSubmitOtp = e => {
    e.preventDefault();
    setError('');
    const otp = otpDigits.join('');
    if (otp.length === 6) {
      submitMfaOtp(otp);
    }
  };

  const handleOtpPaste = value => {
    setOtpDigits(String(value).split(''));
    setError('');
  };

  const handleOtpChange = value => {
    setOtpDigits(value);
    setError('');
  };

  const handleRequestNewOtp = () => {
    setOtpDigits(initialOtpDigits);
    setError('');
    onRequestOtp();
  };

  const isSubmitEnabled = () => {
    const isOtpFilled = otpDigits.join('').length === 6;
    return !isAppLoading && isOtpFilled;
  };

  return (
    <section>
      <div className="login unauth-body-wrapper">
        <div>
          <h1>{t('Verify User')}</h1>
          <p>
            {t('A 6-digit verification code has been sent to {{phone}}', {
              phone: phone ? formatPhone(phone) : 'you',
            })}
          </p>
        </div>

        <div>
          <Form noValidate onSubmit={handleSubmitOtp}>
            <div className="py-3">
              <OTPInput
                handlePaste={handleOtpPaste}
                handleChange={handleOtpChange}
                token={otpDigits}
                inputType="number"
              />
            </div>

            <div className="text-right">
              <Button
                variant={isSubmitEnabled() ? 'primary' : 'disabled'}
                type="submit"
              >
                {t('Continue')}
              </Button>
            </div>

            <div className="text-center py-2">
              <Form.Text className="text-danger form-error" aria-live="polite">
                {error || <br />}
              </Form.Text>
            </div>
          </Form>
          <div className="text-right py-4">
            <Button variant="link" onClick={handleRequestNewOtp}>
              {t('Re-Send Code')}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

function LimitReached() {
  const { t } = useTranslation();
  return (
    <section>
      <div className="login unauth-body-wrapper">
        <div>
          <h1>{t('Limit Reached')}</h1>
          <p className=" mb-2 warning-text font-weight-bold">
            {t(
              'You have reached the maximum number of Re-Sends for this number.'
            )}
          </p>
          <p className=" mb-2 warning-text font-weight-bold">
            {t('Please try again in 30 minutes.')}
          </p>
          <div className="text-right py-4">
            <Link to={PAGES.LOGIN}>{t('Sign in')}</Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default function MfaOtp() {
  const [step, setStep] = useState('request'); // 'request', 'validation', 'limit-reached'
  const [otpId, setOtpId] = useState(null);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const phone = useSelector(state => state.loginUser.ph_mobile);

  useEffect(() => {
    // request otp with JWT
    if (otpId === null) {
      const resultPromise = dispatch(login_mfa_otp_request_post());
      resultPromise
        .then(res => {
          setStep('validation');
          if (res.otp_id) {
            setOtpId(res.otp_id);
          } else {
            setError(UNEXPECTED_ERROR);
          }
        })
        .catch(error => {
          if (error.response?.status === RESPONSE_CODE['405_data_invalid']) {
            setStep('validation');
            setError(OTP_ERROR_DISPLAY.CODE_INCORRECT);
            return;
          }
          if (error.response?.status === RESPONSE_CODE['490_max_reached']) {
            setStep('limit-reached');
            return;
          }
          setStep('validation');
          setError(UNEXPECTED_ERROR);
        });
    }
  }, [dispatch, otpId]);

  const handleRequestOtp = () => {
    // this will trigger a new otp request
    setOtpId(null);
    setStep('request');
  };

  return (
    <>
      {step === 'validation' ? (
        <MfaOtpValidation
          otpId={otpId}
          error={error}
          setError={setError}
          phone={phone}
          onRequestOtp={handleRequestOtp}
        />
      ) : null}
      {step === 'limit-reached' ? <LimitReached /> : null}
    </>
  );
}
