import React from 'react';
import { withTranslation } from 'react-i18next';
import InfoPopup from './info_popup';

const getInfoTitle = (title) => (
  <h2>
    <span className="info-popup-button static" />
    <span>{title}</span>
  </h2>
);

const getInfoBody = (desc) => <p>{desc}</p>;

const KnowledgeCheck = withTranslation()((props) => {
  const { t, questions } = props;
  const { text } = questions;

  const title = text.title || 'What is a knowledge check?';
  const desc = text.knowledgeCheckDescription || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod'
  + ' incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi'
  + ' ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu'
  + ' fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit'
  + ' anim id est laborum.';

  return (
    <div className="knowledge-check">
      <h2 className="knowledge-check-title">
        {t('Knowledge Check')}
        <InfoPopup title={getInfoTitle(t(title))} body={getInfoBody(t(desc))} />
      </h2>
    </div>
  );
});

export default KnowledgeCheck;
