import { API_URL } from '../constants/api';
import { utils_api } from '../utils/utils_api';
// import { utils_registration_flow } from "../utils/utils_registration_flow";

export const api_reg_flow = {
  get_reg_flow(code, success, fail) {
    return utils_api.auth_get(`${API_URL.WORKFLOW_GET}?code=${code}`, success, fail);
  },
  get_self_reg_content(success, fail) {
    const SELF_REG_CONTENT_CODE = 'setup_account_config';
    return utils_api.unauth_get(`${API_URL.CONFIG_SELF_REG_CONTENT_GET}?code=${SELF_REG_CONTENT_CODE}`, success, fail);
  },
};
