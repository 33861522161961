import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

export type Option = {
  key: string,
  name: string,
  isCorrect?: boolean,
}

export type CheckboxListComponentProps = {
  items: Option[],
  questionAnswered?: boolean,
  selectedValues: string[],
  onOptionClick: (e: unknown, option: Option) => void,
  withCorrectIcons?: boolean,
}

const defaultProps: Partial<CheckboxListComponentProps> = {
  items: [],
  questionAnswered: false,
  selectedValues: [],
  withCorrectIcons: true,
};
function CheckboxListComponent({
  items, questionAnswered, selectedValues, onOptionClick, withCorrectIcons
}: CheckboxListComponentProps): React.ReactElement {
  const { t } = useTranslation('codes');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, option: Option) => {
    if (e.key === ' ' || e.key === 'Enter') {
      onOptionClick(e, option);
    }
  };

  return (
    <>
      {items.map((option) => {
        const { key, name, isCorrect } = option;

        const isSelected = selectedValues.includes(key);

        return (
          <div
            key={key}
            className={classNames(
              'question-answer-item',
              {
                'is-selected': isSelected,
                'is-answered': questionAnswered,
                'is-correct': isCorrect !== undefined && isCorrect && questionAnswered,
                'is-wrong': isCorrect !== undefined && !isCorrect && isSelected && questionAnswered,
                'with-correct-icons': withCorrectIcons
              },
            )}
            onClick={(e) => onOptionClick(e, option)}
            onKeyDown={(e) => handleKeyDown(e, option)}
            role="button"
            tabIndex={0}
          >
            <Form.Check
              custom
              type="radio"
              id={`option-${key}`}
              label={name}
              name="category"
              value={key}
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            />
            <i className="check-icon" />
            {isCorrect
              ? <div className="question-answer-subtext">{t('correct response')}</div>
              : <div className="question-answer-subtext">{t('your response')}</div>}
          </div>
        );
      })}
    </>
  );
}

CheckboxListComponent.defaultProps = defaultProps;

export default CheckboxListComponent;
