import types from './types';

const doDispatch = (action) => (dispatch) => dispatch(action);

export const doSetWorkflow = (workflowCode, workflowData) => {
  const action = {
    type: types.SET_WORKFLOW,
    workflowCode,
    data: workflowData,
  };

  return doDispatch(action);
};

export const doSetWorkflowsIsLoading = () => doDispatch({
  type: types.SET_WORKFLOW_LOADING,
});

export const doSetWorkflowsIsCompleted = () => doDispatch({
  type: types.SET_WORKFLOW_COMPLETED,
});

export const doClearWorkflow = (workflowCode) => doDispatch({
  type: types.CLEAR_WORKFLOW,
  workflowCode,
});

export const doSetComponent = (componentData) => {
  const action = {
    type: types.SET_NEXT_COMPONENT,
    data: componentData,
  };

  return doDispatch(action);
};

/**
 * 
 * @param {string} error Error message to be added
 */
export const doSetStepError = (error) => {
  const action = {
    type: types.SET_STEP_ERROR,
    data: error,
  }

  return doDispatch(action);
}

export const doSetComponentIsLoading = () => doDispatch({
  type: types.SET_NEXT_COMPONENT_LOADING,
});
