// @ts-ignore
import { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { IDnaKitService } from "src/services/DnaKitService";
import { useInjection } from "src/services/ServiceProvider";
import { DnaKitOrderData } from "src/services/types/DnaKit";
import { InnerWorkflowStepProps } from "src/services/types/WorkflowTypes";
import { selectDnaKitCreatedOrder, selectDnaKitOrderRequesting } from "src/store/DnaKit/DnaKitSelectors";
import { Loading } from "../Loading";
import OrderDnaKitReceipt from "./OrderDnaKitReceipt";

type OrderDnaKitCheckStateProps = {
  dnaKitOrderRequesting: boolean,
  dnaKitCreatedOrder?: DnaKitOrderData,
}

// These are the props directly used when returning the component: <OrderDnaKitCheck prop1={} prop2={} />
export type OrderDnaKitCheckComponentProps = {
  onComponentComplete: () => Promise<void>,
}

const defaultProps: OrderDnaKitCheckComponentProps = {
  onComponentComplete: () => Promise.resolve(),
}

export type OrderDnaKitCheckProps = OrderDnaKitCheckComponentProps & InnerWorkflowStepProps & OrderDnaKitCheckStateProps;

function OrderDnaKitCheck({ dnaKitOrderRequesting, dnaKitCreatedOrder, onComponentComplete, ...innerWorkflowStepProps }: OrderDnaKitCheckProps) {

  const dnaKitService = useInjection<IDnaKitService>('dnaKitService');

  // Check if user has active orders.
  useEffect(() => {
    if (!dnaKitOrderRequesting && dnaKitCreatedOrder === undefined) {
      dnaKitService.loadActiveDnaKitOrder();
    }
  }, [dnaKitService, dnaKitOrderRequesting, dnaKitCreatedOrder]);

  // User has no active orders.
  useEffect(() => {
    if (dnaKitCreatedOrder !== undefined && dnaKitCreatedOrder.order_id.length === 0) {
      innerWorkflowStepProps.onStepComplete();
    }
  }, [dnaKitCreatedOrder]);

  const userHasActiveOrder = dnaKitCreatedOrder !== undefined && dnaKitCreatedOrder.order_id.length > 0;

  return (
    <>
      {dnaKitCreatedOrder === undefined && <Loading />}
      {userHasActiveOrder && (
        <OrderDnaKitReceipt {...innerWorkflowStepProps} onStepComplete={onComponentComplete} title="You Have An Existing Order" />
      )}
    </>
  )
}

OrderDnaKitCheck.defaultProps = defaultProps;

const mapDispatchToProps = {};

const mapStateToProps = createStructuredSelector<any, OrderDnaKitCheckStateProps>({
  dnaKitOrderRequesting: selectDnaKitOrderRequesting,
  dnaKitCreatedOrder: selectDnaKitCreatedOrder,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDnaKitCheck);