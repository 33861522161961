import React from "react";
import DOMPurify from "dompurify";

type ElementName =
    'a' | 'abbr' | 'address' | 'area' | 'article' | 'aside' | 'audio' | 'b' | 'base' | 'bdo' | 'big' | 'blockquote' | 'body' | 'br' | 'button' | 'canvas' | 'caption' | 'cite' | 'code' | 'col' | 'colgroup' | 'datalist' | 'dd' | 'del' | 'details' | 'dfn' | 'dialog' | 'div' | 'dl' | 'dt' | 'em' | 'embed' | 'fieldset' | 'figcaption' | 'figure' | 'footer' | 'form' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'head' | 'header' | 'hgroup' | 'hr' | 'html' | 'i' | 'iframe' | 'img' | 'input' | 'ins' | 'kbd' | 'keygen' | 'label' | 'legend' | 'li' | 'link' | 'map' | 'mark' | 'menu' | 'meta' | 'meter' | 'nav' | 'noscript' | 'object' | 'ol' | 'optgroup' | 'option' | 'output' | 'p' | 'param' | 'pre' | 'progress' | 'q' | 'rp' | 'rt' | 'ruby' | 's' | 'samp' | 'script' | 'section' | 'select' | 'small' | 'source' | 'span' | 'strong' | 'style' | 'sub' | 'sup' | 'table' | 'tbody' | 'td' | 'textarea' | 'tfoot' | 'th' | 'thead' | 'time' | 'title' | 'tr' | 'track' | 'u' | 'ul' | 'video' | 'wbr';

const defaultProps = {
    tag: 'div',
    sanitize: true,
    sanitizationSettings: {}
}

export type UseProfiles = {
    html?: boolean,
    svg?: boolean,
    svgFilters?: boolean,
    mathMl?: boolean,
}

export type SanitizationSettings = {
    allowedTags?: ElementName[],
    allowedAttr?: string[],
    useProfiles?: UseProfiles,
    namespace?: string,
    forbidTags?: string[],
    forbidAttr?: string[],
    addTags?: string[],
    addAttr?: string[],
    allowDataAttr?: boolean,
    addDataUriTags?: string[],
    addUriSafeAttr?: string[],
    allowUnknownProtocols?: boolean,
    allowedUriRegexp?: RegExp,
}

const attributeMap: { [key: string]: string } = {
    allowedTags: 'ALLOWED_TAGS',
    allowedAttr: 'ALLOWED_ATTR',
    useProfiles: 'USE_PROFILES',
    namespace: 'NAMESPACE',
    forbidTags: 'FORBID_TAGS',
    forbidAttr: 'FORBID_ATTR',
    addTags: 'ADD_TAGS',
    addAttr: 'ADD_ATTR',
    allowDataAttr: 'ALLOW_DATA_ATTR',
    addDataUriTags: 'ADD_DATA_URI_TAGS',
    addUriSafeAttr: 'ADD_URI_SAFE_ATTR',
    allowUnknownProtocols: 'ALLOW_UNKNOWN_PROTOCOLS',
    allowedUriRegexp: 'ALLOWED_URI_REGEXP',
}

const toPurifierSettings = (settings: SanitizationSettings = {}) =>
    Object.entries(settings)
        .reduce((accumulate, [key, value])=> {
            const translatedKey: string = attributeMap[key];
            return ({...accumulate, [translatedKey]: value})
        }, {});

export type HtmlContentComponentProps = {
    tag?: ElementName,
    markup: string,
    sanitize?: boolean,
    sanitizationSettings?: SanitizationSettings,
    [key: string]: any,
};

function HtmlContentComponent({ tag, markup, sanitizationSettings, sanitize, ...other }: HtmlContentComponentProps) {
    const MarkupContainer = tag as keyof JSX.IntrinsicElements;
    const purifySettings = toPurifierSettings(sanitizationSettings);
    const __html = sanitize ? DOMPurify.sanitize(markup, purifySettings) : markup;
    
    return (
        <MarkupContainer {...other} dangerouslySetInnerHTML={{ __html }} />
    );
}

HtmlContentComponent.defaultProps = defaultProps;


export default HtmlContentComponent