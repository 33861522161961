import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { subworkflow_get, update_subworkflows } from '../../actions/subworkflowAction';
import { update_workflow } from '../../actions/workflowAction';
import { WORKFLOW, WORKFLOW_VALUE, WORKFLOW_TYPE } from '../../constants/workflow';
import { get_user_attributes_get, post_user_attributes_post } from '../../actions/userAction';
import { login_get } from '../../actions/loginAction';
import { utils_workflow } from '../../utils/utils_workflow';
import { utils } from '../../utils/utils_general';
import { get_survey_by_id_post } from '../../actions/surveyAction';
import { RESPONSE_CODE, UNEXPECTED_ERROR } from '../../constants/errors';
import { utils_registration_flow } from '../../utils/utils_registration_flow';
import WorkflowContentRenderer from './workflow_content_renderer';
import { LOCAL_STORAGE } from '../../constants/localStorage';

import PageLoader from '../global/page-loader';
import WorkflowLayout from './workflow_layout';

class Subworkflow extends React.Component {
  constructor() {
    super();
    this.state = {
      body: null,
      id: null,
      step: null,
      errors: null,
      workflow_code: null, // for action card triggered
      subworkflow: null,
    };
  }

  componentDidMount() {
    const workflow_code = this.props.subworkflowInfo.subworkflow_code;
    this.setState({ workflow_code });
    this.getSubworkflow(workflow_code);
  }

  componentWillUnmount() {
    this.handleSubWorkflowExit();
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  getSubworkflow(workflow_code) {
    // send temporary workflow start attribute
    const subworkflow = this.props.subworkflows[workflow_code] || utils.get_local_storage(`${LOCAL_STORAGE.WORKFLOW}-${workflow_code}`);
    if (!subworkflow) {
      this.props.subworkflow_get(workflow_code)
        .then((resp) => this.setBody(resp));
    } else {
      const sanitizedSubworkflow = subworkflow.map((c) => {
        if (c.value === WORKFLOW_VALUE.DONE_FOR_NOW) {
          c.value = null;
        }
        return c;
      });
      this.setBody(sanitizedSubworkflow);
      if (!this.props.subworkflows[workflow_code]) {
        const subworkflow_data = { data: { data: sanitizedSubworkflow, code: workflow_code } };
        this.props.update_subworkflows(subworkflow_data);
      }
    }
  }

  setBody(subworkflow) {
    this.setState({ subworkflow });
    if (subworkflow) {
      const step = subworkflow.find((c) => !c.value);
      if (!step) {
        this.props.get_user_attributes_get()
          .then((res) => {
            this.onSubworkflowComplete(res, subworkflow);
          });
      } else {
        this.setState({ body: step ? step.body : null, step });
        if (step.component === WORKFLOW.SURVEY) {
          this.getSurveyDetails(step.survey_id);
        }
      }
    }
  }

  getSurveyDetails(surveyId) {
    if (!surveyId) {
      return this.setState({ errors: 'Survey not found' });
    }
    this.props.get_survey_by_id_post(surveyId)
      .then((response) => {
        this.setState({ surveyUrl: response });
      })
      .catch((error) => {
        if (error.response && error.response.status === RESPONSE_CODE['404_notfound']) {
          this.setState({ errors: 'Survey Unavailable' });
        } else {
          this.setState({ errors: UNEXPECTED_ERROR });
        }
      });
  }

  async handleSubWorkflowExit() {
    const { step } = this.state || {};
    const { details } = step;
    const { onWorkflowExit } = this.props;
    if (details?.allow_pause && typeof onWorkflowExit === 'function') {
      await onWorkflowExit();
    }
  }

  async onComponentComplete() {
    const exitAttrSpec = this.state.step.exit_attribute[0];
    if (exitAttrSpec === '*') {
      return this.onFormComplete();
    }
    // ugh some components handle their own submissions others dont
    // we need to submit attributes here for components:
    // - text_media
    const attr = exitAttrSpec.split('|')[0];
    this.props.post_user_attributes_post(attr, true)
      .then(() => {
        this.onFormComplete();
      });
  }

  onFormComplete() {
    this.getAttributesAndComplete();
  }

  getAttributesAndComplete() {
    // check attribute is complete
    this.props.get_user_attributes_get()
      .then((res) => {
        const attr_errors = utils_registration_flow.checkAttrCompleted(this.state.step.exit_attribute, res);
        if (attr_errors.length > 0) {
          console.log('attrerrors', attr_errors);
        } else {
          const incompleteSteps = this.state.subworkflow.filter((c) => !c.value);
          const firstIncompleteStep = incompleteSteps.length > 0 ? incompleteSteps[0] : null;
          const justCompletedLastStep = incompleteSteps.length === 1 && firstIncompleteStep.id === this.state.step.id;
          if (!firstIncompleteStep || justCompletedLastStep) {
            // check if ready to complete:
            // if all steps complete OR
            // there is only one step marked as "incomplete" and that step is the step you just completed
            this.onSubworkflowComplete(res, this.state.subworkflow);
          } else {
            const new_subworkflow = utils_workflow.goToNextWorkflowStep(this.state.subworkflow, this.state.step, this.props.update_subworkflows, this.props.history.push, WORKFLOW_TYPE.DASHBOARD_ENFORCED, this.state.workflow_code || 'cant find code wtf');
            // this is so weird, for some reason the update_workflow doesnt show up when comparing prevprops and props
            this.setBody(new_subworkflow);
          }
        }
      });
  }

  onSubworkflowComplete(dbAttr, subworkflow) {
    const req_attr = subworkflow.filter((c) => c.exit_attribute && c.exit_attribute[0] !== '*').map((c) => c.exit_attribute);
    const req_attr_flat = [].concat.apply([], req_attr);
    const attr_errors = utils_registration_flow.checkAttrCompleted(req_attr_flat, dbAttr);
    if (attr_errors.length > 0) {
      console.log('subworkflowerror', attr_errors);
      const incompleteSubworkflow = subworkflow.map((c) => {
        attr_errors.forEach((e) => {
          if (c.exit_attribute.includes(e)) {
            c.value = null;
          }
        });
        return c;
      });
      const data = { data: incompleteSubworkflow, code: this.state.workflow_code };
      this.props.update_subworkflows(data);
      this.setBody(incompleteSubworkflow);
    } else {
      this.markWorkflowStepsAsDone(this.props.workflow, subworkflow, this.state.workflow_code, this.state.step);
      this.props.onComplete();
    }
  }

  markWorkflowStepsAsDone(workflow, subworkflow, subworkflow_code, step) {
    // done this subworkflow, mark current step as done, go to dash
    const updatedSubworkflow = subworkflow.map((c) => {
      if (step && c.id === step.id) {
        if (c.exit_attribute[0] !== '*') {
          c.value = WORKFLOW_VALUE.COMPLETE;
        } else if (c.exit_attribute[0] === '*') {
          c.value = WORKFLOW_VALUE.DONE_FOR_NOW;
        } else if (c.value === WORKFLOW_VALUE.DONE_FOR_NOW) {
          c.value = null;
        }
      }
      return c;
    });
    this.props.update_subworkflows({ data: updatedSubworkflow, code: subworkflow_code });
    this.setState({ subworkflow: updatedSubworkflow });

    // mark this subworkflow as done in main workflow
    const updatedWorkflow = workflow.map((c) => {
      if (c.subworkflow_code === subworkflow_code) {
        if (c.exit_attribute[0] !== '*') {
          c.value = WORKFLOW_VALUE.COMPLETE;
        } else if (c.exit_attribute[0] === '*') {
          c.value = WORKFLOW_VALUE.DONE_FOR_NOW;
        } else if (c.value === WORKFLOW_VALUE.DONE_FOR_NOW) {
          c.value = null;
        }
      }
      return c;
    });
    this.props.update_workflow(updatedWorkflow);
  }

  renderContent() {
    return (
      <WorkflowContentRenderer
        body={this.state.body}
        step={this.state.step}
        surveyUrl={this.state.surveyUrl}
        onFormComplete={() => this.onFormComplete()}
        onComponentComplete={() => this.onComponentComplete()}
        onWorkflowExit={() => this.props.onWorkflowExit()}
        onWorkflowSkip={() => this.props.onWorkflowSkip()}
      />
    );
  }

  render() {
    if (!this.state.step) {
      return <PageLoader loader={!this.state.step} loaderMessage={this.props.t('loading data')} />;
    }
    const aside = this.state.step;
    return (
      <>
        <PageLoader loader={!this.props.workflow} loaderMessage={this.props.t('loading data')} />
        {
          this.state.step.component === WORKFLOW.SUBWORKFLOW
            ? this.renderContent()
            : (
              <WorkflowLayout
                aside={aside}
                mainSection={this.renderContent()}
                flowName={this.props.subworkflowInfo.subworkflow_step}
                onWorkflowExit={() => this.props.onWorkflowExit()}
                onWorkflowSkip={() => this.props.onWorkflowSkip()}
              />
            )
        }
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  subworkflows: state.workflow.subworkflows,
  workflow: state.workflow.workflow,
  workflowIsLoading: state.workflow.workflowIsLoading,
  ...ownProps,
  loginUser: state.loginUser,
});

export default withRouter(connect(mapStateToProps, {
  login_get,
  subworkflow_get,
  update_subworkflows,
  update_workflow,
  get_user_attributes_get,
  post_user_attributes_post,
  get_survey_by_id_post,
})(withTranslation(['workflow', 'texts'])(Subworkflow)));
