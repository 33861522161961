import DOMPurify from "dompurify";
import Joi, { CustomValidator, LanguageMessages } from "joi";
import { FIELD_ERROR_DISPLAY, OTP_ERROR_DISPLAY, SET_PASSWORD_ERROR_RESPONSES } from "src/constants/errors";

const email = Joi.string().required().email({ minDomainSegments: 2, tlds: false }).messages({
  'any.base': FIELD_ERROR_DISPLAY.err_field_email_invalid,
  'any.empty': FIELD_ERROR_DISPLAY.err_field_email_invalid,
  'any.required': FIELD_ERROR_DISPLAY.err_field_email_invalid,
  'string.email': FIELD_ERROR_DISPLAY.err_field_email_invalid,
});
const password = Joi.string().required().messages({
  'any.base': SET_PASSWORD_ERROR_RESPONSES.PASSWORD_INVALID,
  'any.empty': SET_PASSWORD_ERROR_RESPONSES.PASSWORD_INVALID,
  'any.required': SET_PASSWORD_ERROR_RESPONSES.PASSWORD_INVALID,
});
const zip = Joi.string().regex(/^[0-9]{5}$/).messages({
  'any.base': FIELD_ERROR_DISPLAY.err_field_zip_invalid,
  'any.regex': FIELD_ERROR_DISPLAY.err_field_zip_invalid,
  'string.pattern.base': FIELD_ERROR_DISPLAY.err_field_zip_invalid,
});
const phone = Joi.string().regex(/^\d{10}$/).messages({
  'any.empty': FIELD_ERROR_DISPLAY.err_field_required,
  'any.required': FIELD_ERROR_DISPLAY.err_field_required,
  'string.pattern.base': OTP_ERROR_DISPLAY.INVALID_PHONE,
});

export type MessagesFactory = (defaultMessages: LanguageMessages) => LanguageMessages;

const createOwnNameValidation = (messagesFactory: MessagesFactory = (defaultMessages) => defaultMessages): Joi.StringSchema => {
  return Joi.string().required().min(2).max(36).regex(/^[a-zñ\'\. \-áéíóú]+$/i).messages(messagesFactory({
    'any.base': FIELD_ERROR_DISPLAY.err_field_name_invalid,
    'any.empty': FIELD_ERROR_DISPLAY.err_field_required,
    'any.required': FIELD_ERROR_DISPLAY.err_field_required,
    'string.max': FIELD_ERROR_DISPLAY.err_field_name_too_long,
    'string.min': FIELD_ERROR_DISPLAY.err_field_name_too_short,
    'string.pattern.base': FIELD_ERROR_DISPLAY.err_field_invalid_characters,
  }));
};

const firstName = createOwnNameValidation();
const lastName = createOwnNameValidation((defaultMessages) => ({
  ...defaultMessages,
  'string.max': FIELD_ERROR_DISPLAY.err_field_last_name_too_long,
  'string.min': FIELD_ERROR_DISPLAY.err_field_last_name_too_short,
}));

const checkXss: CustomValidator = (value, helpers) => {
  if (DOMPurify.sanitize(value) !== value) {
    return helpers.error("any.xss");
  }

  return value;
}

export { email, password, zip, phone, createOwnNameValidation, checkXss, firstName, lastName };