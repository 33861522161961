import React from 'react';
import { CONSENT_COMPONENT } from 'src/constants/consentComponents';
import ConsentSubcompText from './consent_components/consent_subcomp_text';
import ConsentSubcompCollapsibleSection from './consent_components/consent_subcomp_collapsible_section';
import ConsentSubcompLink from './consent_components/consent_subcomp_link';
import ConsentSubcompVideo from './consent_components/consent_subcomp_video';
import ConsentSubcompQn from './consent_components/consent_subcomp_qn';
import ConsentSubcompQnRadio from './consent_components/consent_subcomp_qn_radio';

export const ConsentSubcompRenderer = (props) => {
  switch (props.component.type) {
    case CONSENT_COMPONENT.TEXT:
      return <ConsentSubcompText text={props.component} />;
    case CONSENT_COMPONENT.COLLAPSIBLE_SECTION:
      return <ConsentSubcompCollapsibleSection subcomponentKey={props.subcomponentKey} collapsibleSection={props.component} onRequiredCollapsibleRead={props.onRequiredCollapsibleRead} />;
    case CONSENT_COMPONENT.LINK:
      return <ConsentSubcompLink subcomponentKey={props.subcomponentKey} link={props.component} />;
    case CONSENT_COMPONENT.VIDEO:
      return <ConsentSubcompVideo video={props.component} onVidComplete={() => props.onVidComplete()} />;
    case CONSENT_COMPONENT.QN_LIST:
      return <ConsentSubcompQn questions={props.component} index={props.index} user_response={props.user_response} onQnComplete={(value) => props.onQnComplete(value)} />;
    case CONSENT_COMPONENT.QN_RADIO:
      return <ConsentSubcompQnRadio questions={props.component} index={props.index} user_response={props.user_response} onRadioAnswered={(value) => props.onRadioAnswered && props.onRadioAnswered(value)} />;
    default:
      return null;
  }
};

export default ConsentSubcompRenderer;
