import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class ConsentSubcompQnRadio extends React.Component {
  constructor() {
    super();
    this.state = {
      ans: null,
    };
  }

  handleChange(e, qnnr) {
    const { name, value } = e.target;
    this.setState({ ans: { qn_code: name, ans_code: value } });
    // TODO: refactor this, so the logic
    // of where this is coming from is more obvious
    if (this.props.onRadioAnswered) this.props.onRadioAnswered({ qnnr, qn_code: name, ans_code: value });
  }

  renderAnswer(ans, key, qn) {
    return (
      <div className="consent-radio-answer text-center" key={key}>
        <div className="radio-square">
          <label className="radio-square-wrapper" htmlFor={ans.code}>
            <div>{this.props.t(ans.text[this.props.lang] || ans.text.en)}</div>
            <input type="radio" id={ans.code} value={ans.code} name={qn.code} onChange={(e) => this.handleChange(e, qn.qnnr)} />
            <div className="checkmark" />
          </label>
        </div>
      </div>
    );
  }

  render() {
    const question = this.props.questions;
    return (
      <div className="consent-component consent-component-qn-radio mb-3">
        <div id={question.qn.code} className="consent-radio-wrapper clearfix">
          <div className="consent-radio-qn"><strong>{this.props.t(question.qn.text[this.props.lang])}</strong></div>
          <div className="consent-radio-answer-wrapper">
            {question && question.qn && question.qn.child ? question.qn.child.map((a, key) => this.renderAnswer(a, key, question.qn)) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.app.language,
});

export default connect(mapStateToProps)(withTranslation('codes')(ConsentSubcompQnRadio));
