// this is pretty much identical to categorization.js, consider combining into one
import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { utils } from 'src/utils/utils_general';
import { utils_questionnaire } from 'src/utils/utils_questionnaire';
import { utils_workflow } from 'src/utils/utils_workflow';
import { LOCAL_STORAGE } from 'src/constants/localStorage';
import { questionnaire_get, questionnaire_resp_post } from 'src/actions/questionnaireAction';
import PAGES from 'src/constants/pages';
import { WORKFLOW_VALUE } from 'src/constants/workflow';
import { post_user_attributes_post } from 'src/actions/userAction';
import BlankComponent from 'src/components/core/Blank/BlankComponent';
import KnowledgeCheck from './knowledge_check';
import Questionnaire from './questionnaire';

class CategorizationValidation extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      errors: {},
      questions: null,
      qn: null,
      isHardStop: false,
      isComplete: false,
    };
  }

  componentDidMount() {
    const { qn } = this.props;
    this.prepGetQuestions(qn);
  }

  componentDidUpdate(prevProps) {
    if (this.props.qn && prevProps.qn !== this.props.qn) {
      this.prepGetQuestions(this.props.qn);
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  prepGetQuestions(qn) {
    this.setState({ qn });
    if (qn) {
      if (qn === 'age') {
        this.setState({ isHardStop: true });
      }
      this.getQuestions(qn);
    }
  }

  getQuestions(qn) {
    this.props.questionnaire_get(qn)
      .then((response) => {
        const questions = utils_questionnaire.get_question_data(response);
        this.setState({ questions });
        this.checkIfTheresPreviousValues(qn, questions);
      })
      .catch((error) => this.setState({ error: { system: error } }));
  }

  checkIfTheresPreviousValues(qn, questions) {
    // in case corner case of getting email already registered on pw, and we have to go back, we need their old data
    if (this.props.prevValues && !utils.is_obj_empty(this.props.prevValues)) {
      const { prevValues } = this.props;// eg. {age: [{question: "age_qn1", answers: ['age_ans1']}]};
      const prevQnnr = prevValues[qn];
      const qnsWithAnswers = questions.qns.map((q, i) => {
        const matchedQuestionWithAnswer = prevQnnr.find((prevQ) => prevQ.question === q.code);
        if (matchedQuestionWithAnswer) {
          q.selected_answers = matchedQuestionWithAnswer.answers ? matchedQuestionWithAnswer.answers : null;
        }
        return q;
      });
      const questionWithAnswers = questions;
      questionWithAnswers.qns = qnsWithAnswers;
      this.setState({ questions: questionWithAnswers });
    }
  }

  onSubmit(questionnaireResp) {
    const data = { [this.state.questions.code]: questionnaireResp.questionnaire };
    // if on signup page, signup.js will submit this, otherwise submit
    if (this.props.location.pathname !== PAGES.SIGNUP) {
      this.props.questionnaire_resp_post(this.state.questions.code, questionnaireResp.questionnaire)
        .then(() => {
          this.onComplete(data);
        })
        .catch((e) => {
          this.setState({ errors: e });
        });
    } else {
      this.onComplete(data);
    }
  }

  async onComplete(data) {
    const { workflowStep } = this.props;
    const flow = this.props.registrationFlow || utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
    this.setState({ isComplete: true });
    if (this.props.onComplete) {
    if (workflowStep) {
      // send step exit attributes if in workflow
      const attributes = utils_workflow.getPostAttributes(workflowStep.exit_attribute);
        await Promise.all(Object.entries(attributes).map(([attr, value]) => this.props.post_user_attributes_post(attr, value)));
    }
    if (flow) {
        if (this.state.isHardStop && data.age && data.age[0].answers[0] === 'age_qn1_ans_no') {
          return window.location = PAGES.SIGNUP_STOP;
      }
        if (this.props.location.pathname === PAGES.SIGNUP) {
        flow.map((c) => {
            if (c.qn === this.state.qn) {
            c.value = WORKFLOW_VALUE.COMPLETE;
          }
          return c;
        });
      }
        return this.props.onComplete(flow, data);
    }
      return this.props.onComplete(data);
    }
  }

  render() {
    const { questions } = this.state;

    if (questions === undefined) {
      return (
        <Redirect to={PAGES.DASHBOARD} />
      );
    }

    if (questions === null) {
      return (
        <BlankComponent />
      );
    }

    const isKnowledgeCheck = questions.knowledge_check;
    return (
      <div className="validation">
        {isKnowledgeCheck ? <KnowledgeCheck questions={questions} /> : null}
        <Questionnaire
          onFormComplete={(data) => this.onSubmit(data)}
          questions={questions.qns}
          isKnowledgeCheck={isKnowledgeCheck}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = {
  questionnaire_get,
  questionnaire_resp_post,
  post_user_attributes_post,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CategorizationValidation)));
