// if you get an error for module not found, create a file in constants/api_config.js, then build config
import * as CONFIG from './api_config';

export const URL_ROOT_API = CONFIG.api_endpoint;
export const URL_STATIC_API = CONFIG.api_static_host;
export const ENV_STRING = CONFIG.current_environment;
export const GA4_ID = CONFIG.GA4_ID;

export const API_URL = {
  LOGIN_POST: '/app/login',
  LOGIN_GET: '/app/login',
  LOGIN_EMAILVERIF: '/app/login/emailverf',
  LOGIN_MAGIC_LINK: '/app/magiclink/validation',
  LOGIN_MAGIC_LINK_REQUEST: '/app/magiclink/request',
  LOGIN_MAGIC_LINK_VALIDATION: '/app/magiclink/validation',
  LOGIN_MFA_OTP_REQUEST: '/app/mfa/otp-request',
  LOGIN_MFA_OTP_VALIDATION: '/app/mfa/otp-validation',
  LOGOUT_GET: '/app/logout',
  FEATURE_GET: ENV_STRING === 'QA' || ENV_STRING === 'QA2' ? '/app/featuretesting' : '/app/feature',
  USERS_GET: '/app/user',
  SELF_GET: '/app/user',
  USER_POST: '/app/user',
  USER_PUT: '/app/user',
  USER_DELETE: '/app/user',
  USER_SELFREGISTER_POST: '/app/user/selfregister',
  USER_ATTRIBUTE_GET: '/app/user/attribute',
  USER_ATTRIBUTE_POST: '/app/user/attribute',
  USER_ACTION_POST: '/app/user/action',
  USER_WORKFLOWCOMPLETE_POST: '/app/user/workflowcomplete',
  USER_ORDERS: '/app/user/orders',
  UPDATE_PASSWORD_PUT: '/app/updatepassword',
  RESET_PASSWORD_PUT: '/app/resetpassword',
  VERIFY_PASSWORD_POST: '/app/verifypassword',
  REQUEST_PASSWORD_RESET_POST: '/app/request_password_reset',
  REQUEST_APP_INVITE_POST: '/app/user/request_app_invite',
  ACTIONCARDS_GET: '/app/actioncards',
  ACTIONCARDS_DELETE: '/app/actioncards',

  CHECK_EMAIL_AVAIL: '/app/checkemailavail',

  CONSENT_GET: '/appconsent',
  CONSENT_POST: '/appconsent/resp',
  CONSENT_FILE_GET: '/appconsent/userfile',

  CATEGORIZATION_GET: '/questionnaire/psc_categorization',

  CONSENT_KNOWLEDGE_CHECK_GET: '/questionnaire/temp',
  QUESTIONNAIRE_GET: '/questionnaire',

  VERIFY_POST: '/app/user/verify',
  KBA_POST: '/app/user/kba',

  POLL_GET: '/poll',
  POLL_POST: '/poll',

  HEALTHCHECK_GET: '/healthcheck',

  OTP_POST: '/app/otp',
  OTP_GET: '/app/otp',
  MFA_POST: '/app/user/attribute/mfa',

  CONFIG_TERM_GET: '/config/app_term',
  CONFIG_PRIVACY_GET: '/config/app_privacy',
  CONFIG_REGISTRATION_FLOW_GET: '/app/config/registrationflow',
  CONFIG_SELF_REG_CONTENT_GET: '/app/config',

  ANCESTRY_STATUS_GET: '/ancestry/statusmessage',
  ANCESTRY_GET: '/ancestry',
  ANCESTRY_GEOMETRY: '/ancestry/region/geometry',

  WORKFLOW_GET: '/app/user/workflow',
  WORKFLOW_GET_NEXT_COMPONENT: '/workflow/nextcomponent',
  WORKFLOW_PROGRESS_POST: '/workflow/progress',
  WORKFLOW_SKIP: '/workflow/skip',
  WORKFLOW_COMPLETE: '/workflow/complete',

  EMR_GET: '/ehr/healthsystem',
  EMR_CONNECTION: '/ehr/connection',

  PROXY_PLACE_AUTOCOMPLETE: '/proxy/place/autocomplete',
  PROXY_PLACE_DETAILS: '/proxy/place/details',
};

export const SURVEY_API_URL = {
  SURVEYS_GET: '/surveys',
  SURVEYS_GROUP_GET: '/surveys/group',
  SURVEY_BASE: '/survey',
  SURVEYS_GET_PARTICIPANT_URL: '/participant_url',
};

export const RESPONSE_TYPE = {
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const SURVEY_STARTED = 'started';
