import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReferralLink, ReferralLinkSmall } from './referral-link';
import { ReferralButtons } from './referral-buttons';
import { ReactComponent as InvitePeopleSvg } from './svg/invite-people.svg';
import classNames from 'classnames';

import styles from './referral-modal.module.scss';

function ModalContentMobile() {
  const { t } = useTranslation();
  return (
    <>
      <Modal.Body>
        <p className="text-center px-3">
          {t(
            'Invite someone and help us lead discoveries that can save lives.'
          )}
        </p>
        <div className="text-center pt-2 pb-3">
          <ReferralLinkSmall />
        </div>
        <div className="text-center py-2">
          <ReferralButtons />
        </div>
      </Modal.Body>
    </>
  );
}

function ModalContentDesktop() {
  const { t } = useTranslation();
  return (
    <>
      <Modal.Body>
        <h2 className={classNames(styles.modalTitle, 'text-center p-3')}>
          {t(
            'Invite someone and help us lead discoveries that can save lives.'
          )}
        </h2>
        <div className="text-center pt-2 pb-3">
          <div className={classNames(styles.referralLink, "d-inline-block m-auto")}>
            <ReferralLink withBorder />
          </div>
        </div>
        <p className="p-3">
          {t('Referral_Modal_Description')}
        </p>
        <div className="text-center py-2">
          <ReferralButtons />
        </div>
      </Modal.Body>
    </>
  );
}

export function ReferralModal(props) {
  const { show, onRequestClose } = props;
  return (
    <Modal show={show} onHide={onRequestClose} centered>
      <Modal.Header closeButton></Modal.Header>
      <div className={styles.container}>
        <div className={styles.headerImage}>
          <InvitePeopleSvg />
        </div>
      </div>
      <div className="d-block d-md-none">
        <ModalContentMobile />
      </div>
      <div className="d-none d-md-block">
        <ModalContentDesktop />
      </div>
    </Modal>
  );
}
