import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { registration_flow_get } from '../../actions/registrationFlowAction';
import { LOCAL_STORAGE } from '../../constants/localStorage';
import { utils } from '../../utils/utils_general';
import { WORKFLOW } from '../../constants/workflow';
import Progress from '../global/progress';
import { workflowNames } from 'src/utils/utils_ga';

class SignupProgress extends React.Component {
  constructor({ t }) {
    super();
    this.t = t;
    this.state = {
      steps: null,
      regFlow: null,
      // tabNumber: null
    };
  }

  componentDidMount() {
    if (this.props.regFlow) {
      this.setState({ regFlow: this.props.regFlow });
    }

    // this crazy stuff where in case user has multiple tabs open due to using email verification link from email
    // refresh each tab of the registration flow as we go
    window.addEventListener('storage', (e) => {
      this.shouldRefresh();
    });
  }

  componentDidUpdate() {
    if (this.props.regFlow && !utils.check_objects_identical(this.props.regFlow, this.state.regFlow)) {
      this.setState({ regFlow: this.props.regFlow });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('storage', () => this.shouldRefresh());
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {

    };
  }

  shouldRefresh() {
    const { currentStep } = this.props;
    const flow = utils.get_local_storage(LOCAL_STORAGE.REGISTRATION_FLOW);
    const possibleComponents = [WORKFLOW.CONFIRM_EMAIL, WORKFLOW.VERIFICATION, WORKFLOW.CONSENT, WORKFLOW.POLICIES ];
    // we only care to refresh if its after confirm email
    const userInDb = this.props && currentStep && possibleComponents.includes(currentStep.component);
    if (flow && this.props && currentStep && userInDb) {
      const currentStepStorage = flow.find((step) => step.component === currentStep.component);
      if (currentStepStorage.value && currentStepStorage.value !== '') {
        window.location.reload();
      }
    }
  }

  render() {
    const reg_flow = this.state.regFlow;
    if (!reg_flow || !this.props.currentStep) {
      return null;
    }
    return (
      <Progress workflowName={workflowNames.signUp} flow={reg_flow} currentStep={this.props.currentStep} />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

export default withRouter(connect(mapStateToProps, { registration_flow_get })(withTranslation()(SignupProgress)));
