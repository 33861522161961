import React from 'react';
import SurveySideDetails from './survey_side_details';
import IconGenerator from '../icons/icon_generator';
import { withTranslation } from 'react-i18next';

const SurveyWrapper = (props) => {
  const { survey, t } = props;
  if (!survey) {
    return null;
  }
  const { title, limesurvey_id } = survey;
  return (
    <section className="survey-take container">
      <div className="row">
        <aside className="col-12 col-xl-2 left-section">
          <SurveySideDetails survey={survey} />
        </aside>
        <div className="col-12 col-xl-8 center-section">
          <div className="mobile-only">
            <h2 className="mb-3">{t(title)}</h2>

            <div className="survey-page-icon-wrapper">
              <IconGenerator iconKey={limesurvey_id} />
            </div>
          </div>

          {props.content}

        </div>
        <aside className="col-12 col-xl-2 right-section">
          <div className="survey-page-icon-wrapper">
            <IconGenerator iconKey={limesurvey_id} />
          </div>
        </aside>

      </div>
    </section>

  );
};

export default withTranslation()(SurveyWrapper);
