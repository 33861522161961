import { REDUX_ACTION } from '../constants/reduxAction.js';
import { api_user } from '../api/api_user';
import { utils_registration_flow } from '../utils/utils_registration_flow';
import { api_workflow } from '../api/api_workflow.js';
import { utils } from '../utils/utils_general.js';
import { utils_api } from '../utils/utils_api.js';
import { LOCAL_STORAGE } from '../constants/localStorage.js';
import { WORKFLOW_VALUE } from '../constants/workflow.js';

export function subworkflow_get(code) {
  return (dispatch) => {
    const success = (workflow) => {
      utils.loadingSpinner(dispatch, REDUX_ACTION.SPINNER_COMPLETE);
      return api_user.get_user_attribute((response) => {
        const attrs = response.data.data;
        const processedWorkflow = workflow.data.data.map((c) => {
          if (c.exit_attribute[0] !== '*') {
            const attr_errors = utils_registration_flow.checkAttrCompleted(c.exit_attribute, attrs);
            if (attr_errors.length === 0) {
              c.value = WORKFLOW_VALUE.COMPLETE;
            }
          }
          if (c.entry_attribute[0] !== '*') {
            const attr_errors = utils_registration_flow.checkAttrCompleted(c.entry_attribute, attrs);
            if (attr_errors.length > 0) {
              c.locked = true;
            }
          }
          return c;
        });

        const processedSubWorkflow = { data: processedWorkflow, code };
        dispatch(subworkflow_update_data(processedSubWorkflow));

        return processedWorkflow;
      }, (error) => utils_api.on_fail_default(dispatch, error));
    };

    const fail = (error) => utils_api.on_fail_default(dispatch, error);
    return api_workflow.get_workflow_by_code(code, success, fail);
  };
}

export function update_subworkflows(flow) {
  // this flow must contain the subworkflow code
  utils.set_local_storage(`${LOCAL_STORAGE.WORKFLOW}-${flow.code}`, flow.data);
  return (dispatch) => dispatch(subworkflow_update_data(flow.data));
}

function subworkflow_update_data(flowAndData = null) {
  // this flow must contain the subworkflow code
  return {
    type: REDUX_ACTION.SUBWORKFLOW,
    data: flowAndData,
  };
}
