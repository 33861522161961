import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import PAGES from 'src/constants/pages';
import { utils } from 'src/utils/utils_general';
import HtmlContentComponent from 'src/components/core/HtmlContent/HtmlContentComponent';
import {
  get_survey_by_id_post,
  get_survey_list_get,
} from 'src/actions/surveyAction';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

// TODO: GA tracking for survey

export function SurveyWidgetHeader({ children }) {
  return (
    <div className="dashboard-survey-header py-4 px-5 min-h-3 rounded-top">
      {children}
    </div>
  );
}

function SurveyWidgetContent({ children }) {
  return <div className="p-4">{children}</div>;
}

function SurveyWidget({ children }) {
  return (
    <div className="rounded bg-light">
      {children}
      <div className="dashboard-survey-footer rounded-bottom"></div>
    </div>
  );
}

function Survey({ survey, surveyUrl }) {
  const iframeStyle = {
    width: '100%',
    minHeight: 'min(60vh, 700px)',
    overflow: 'hidden',
    border: '0',
  };
  return (
    <SurveyWidget>
      <SurveyWidgetHeader>
        <h3 className="h2 py-3 w-75">{survey.title}</h3>
        <HtmlContentComponent
          className="dashboard-survey-header-content"
          markup={survey.description}
        />
      </SurveyWidgetHeader>
      <div className="dashboard-survey-iframe">
        <iframe src={surveyUrl} title="survey" style={iframeStyle} />
      </div>
    </SurveyWidget>
  );
}

function SurveyCompleted({ surveyTitle, onRequestClose }) {
  const { t } = useTranslation();
  return (
    <SurveyWidget>
      <SurveyWidgetHeader>
        <button
          className="dashboard-survey-header-close"
          variant="link"
          aria-label={t('Close')}
          onClick={onRequestClose}
        >
          <span aria-hidden="true">
            <CloseIcon />
          </span>
        </button>

        <h3 className="h2 py-3 w-50">
          {t('Thank you for taking the survey!')}
        </h3>
      </SurveyWidgetHeader>
      <SurveyWidgetContent>
        <div className="p-4">
          <p>
            <strong>
              {t("You've completed the {{surveyTitle}} Survey.", {
                surveyTitle,
              })}
            </strong>
          </p>
          <p>
            {t(
              'You can retake it anytime—the questions will be the same, but your responses over time can provide important insights about long-term health.'
            )}
          </p>
          <div className="d-flex pt-4 justify-content-end">
            <Button variant="primary" href={PAGES.SURVEYS}>
              {t('Retake the survey')}
            </Button>
          </div>
        </div>
      </SurveyWidgetContent>
    </SurveyWidget>
  );
}

export default function DashboardSurvey({ surveyId, survey, onRequestClose }) {
  const featureFlags = useSelector(state => state.featureFlag);
  const surveys = useSelector(state => state.surveys) || [];
  const isFeatureEnabled = featureFlags?.surveys_board_widget;
  const { t } = useTranslation();
  const [isSurveyFinished, setIsSurveyFinished] = useState(false);
  const language = useSelector(state => state.app.language);
  const surveyUrl = useSelector(state => state.surveyUrl);
  const highlightedSurvey = surveys.find(s => s.survey_id === surveyId);
  const isCompleted = highlightedSurvey?.completed;
  const showWidget = (!isCompleted || isSurveyFinished) && isFeatureEnabled;

  useEffect(() => {
    // iframe will send 'SURVEY_ENDED' in a message event when the
    // survey is finished
    if (!showWidget) {
      return;
    }
    const onMessage = event => {
      if (event.data === 'SURVEY_ENDED') {
        dispatch(get_survey_list_get(true));
        setIsSurveyFinished(true);
      }
    };
    window.addEventListener('message', onMessage, false);
    return () => {
      window.removeEventListener('message', onMessage, false);
    };
  }, [showWidget]);

  // fetch surveyUrl when surveyId or language changes
  const dispatch = useDispatch();
  useEffect(() => {
    if (!showWidget) {
      return;
    }
    if (surveyId) {
      const customFail = () => {
        onRequestClose();
        console.error('error fetching dashboard survey url');
      };
      dispatch(get_survey_by_id_post(surveyId, customFail));
    }
  }, [surveyId, dispatch, language, onRequestClose, showWidget]);

  if (!surveyUrl || !showWidget) {
    return null;
  }

  if (isSurveyFinished) {
    return (
      <div className="section-component">
        <SurveyCompleted
          surveyTitle={survey.title}
          onRequestClose={onRequestClose}
        />
      </div>
    );
  }

  const urlWithLanguage = utils.getSurveyLangUrl(surveyUrl, language);
  return (
    <div className="section-component">
      <h2 className="mb-3 h3">{t('Take a Survey')}</h2>
      <Survey survey={survey} surveyUrl={urlWithLanguage} />
    </div>
  );
}
