/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PAGES from 'src/constants/pages';
import RGCLogo from 'src/assets/images/logo.png';
import RGCLogoWhite from 'src/assets/images/logo-white.png';
import { config_privacy_get, config_terms_get } from 'src/actions/configAction';
import CopyrightText from './copyright_text';
import CobrandingLogo from './cobranding_logo';

class FooterUnauth extends React.Component {
  constructor() {
    super();
    this.state = {
      width: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  prefetchPrivacyPolicy() {
    const { privacyContent, config_privacy_get: configPrivacyGet } = this.props;
    if (!privacyContent) {
      configPrivacyGet(true);
    }
  }

  prefetchTerms() {
    const { termsContent, config_terms_get: configTermsGet } = this.props;
    if (!termsContent) {
      configTermsGet(true);
    }
  }

  render() {
    const { t, additionalClass } = this.props;
    const { width } = this.state;

    return (
      <footer className={`unauth-footer container-fluid row ${additionalClass || ''}`}>
        <div className="col-7">
          <a
            href={PAGES.PRIVACY_POLICY}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() => this.prefetchPrivacyPolicy()}
          >
            {t('Privacy Statement')}
          </a>
          <span className="unauth-footer-separator" />
          <a
            href={PAGES.TERMS_OF_SERVICE}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() => this.prefetchTerms()}
          >
            {t('Terms & Conditions')}
          </a>

          <br />
          {' '}
          <CopyrightText />
        </div>
        <div className="col-5 text-right">
          <CobrandingLogo variant="light" />
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { config_privacy_get, config_terms_get })(withTranslation()(FooterUnauth));
