import types from './types';

export const doSetFormConfig = (formConfig) => (dispatch) => dispatch({
  type: types.SET_FREEFORM_CONFIG,
  formConfig,
});

export const doSetFormConfigLoading = (isLoading) => (dispatch) => dispatch({
  type: types.SET_FREEFORM_LOADING,
  isLoading,
});

export const doSetFormFieldValue = (field, formId) => (dispatch) => dispatch({
  type: types.SET_FREEFORM_FIELD_VALUE,
  field,
  formId
});
