import 'reflect-metadata';
import { Container } from 'inversify';
import FreeFormService from './FreeFormService';
import ConsentService from './ConsentService';
import { IUserService, UserService } from './UserService';
import AddressValidationService from './AddressValidationService';
import ValidationService from './validation/ValidationService';
import WorkflowService, { IWorkflowService, withFakeWorkflows } from './WorkflowService';
import apiFetch from 'src/utils/apiWrapper';
import { IStore } from './types/IStore';
import { DnaKitService, IDnaKitService } from './DnaKitService';

const injectServices = (store: IStore) => {
  const container = new Container();
  container.bind('freeFormService').to(FreeFormService).inSingletonScope();
  container.bind('consentService').to(ConsentService).inSingletonScope();
  container.bind<IUserService>('userService').to(UserService).inSingletonScope();
  container.bind('addressValidationService').to(AddressValidationService).inSingletonScope();
  container.bind('validationService').to(ValidationService).inSingletonScope();

  container.bind<IWorkflowService>('workflowService').to(WorkflowService).inSingletonScope()
    .onActivation(withFakeWorkflows);

  container.bind<IDnaKitService>('dnaKitService').to(DnaKitService).inSingletonScope();

  container.bind('apiFetch').toFunction(apiFetch);
  container.bind('store').toConstantValue(store);

  return container;
};

export default injectServices;
