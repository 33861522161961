import React, { ReactNode } from 'react';
import { Button as BootStrapButton } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/types';
import enumTranslator from './ButtonVariants';
import classNames from 'classnames';

export type ButtonType = {
  onClick?: ()=>void,
  innerValue?: string,
  className?: string,
  children?: [ReactNode] | string,
  variant?: ButtonVariant
  size?: 'lg' | 'sm',
  disabled?: boolean,
  block?: boolean,
  type?: string,
}

function Button({
  onClick,
  innerValue,
  className,
  children,
  variant = enumTranslator('primary'),
  size = 'lg',
  disabled,
  ...props
} : ButtonType & any) {

  return (
    <BootStrapButton
      {...props}
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled}
      className={classNames(className, { 'btn-disable': disabled })}
    >
      {innerValue || children}
    </BootStrapButton>
  );
}

export default Button;
