import React from 'react';
import ProgressPlaceholder from 'src/components/global/progress-placeholder';
import Progress from 'src/components/global/progress';
import {GenericWorkflow, GenericWorkflowStep} from 'src/services/types/WorkflowTypes';

type WorkflowProgressComponentProps = {
  isWorkflowLoading: boolean,
  workflow: GenericWorkflow | null,
  currentStep: GenericWorkflowStep
}

function WorkflowProgressComponent({ isWorkflowLoading, workflow, currentStep }: WorkflowProgressComponentProps) {

  if (isWorkflowLoading || !currentStep || !workflow) {
    return <ProgressPlaceholder />;
  }

  return <Progress flow={workflow} currentStep={currentStep} />;
}

export default WorkflowProgressComponent;
