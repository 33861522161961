/**
 * @file components/dashboard/get_started.js
 */

import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { utils } from '../../utils/utils_general';
import { LOCAL_STORAGE } from '../../constants/localStorage';
import { FEATURE } from '../../constants/features';
import HtmlContentComponent from 'src/components/core/HtmlContent/HtmlContentComponent'; 

const isFirstTime = utils.get_local_storage(LOCAL_STORAGE.FIRST_TIME_REG) || false;

export const GetStarted = (props) => {
  const feature_flag = (feature) => !props.featureFlag || (props.featureFlag && props.featureFlag[feature] !== false);
  const { t } = useTranslation();
  let description = '';
  if (feature_flag(FEATURE.QUICKPOLL) && feature_flag(FEATURE.SURVEY)) {
    description = t('content:copy_page_description_dashboard');
  } else if (feature_flag(FEATURE.QUICKPOLL)) {
    description = t('content:copy_page_description_dashboard_no_survey');
  } else if (feature_flag(FEATURE.SURVEY)) {
    description = t('content:copy_page_description_dashboard_no_qp');
  }

  return (

    <div className="get-started section-component">
      <h2 className="mb-3 side-heading">{t(isFirstTime ? "Let's get started!" : 'Welcome back!')}</h2>
      <HtmlContentComponent className="mb-3" tag={"p"} markup={description} />
    </div>
  );
};

export default withTranslation('content')(GetStarted);
