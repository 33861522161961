import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PAGES from 'src/constants/pages';
import { LOGIN_ERROR_DISPLAY, UNEXPECTED_ERROR } from 'src/constants/errors';
import { TOKEN_TYPE } from 'src/constants/tokenType';
import { login_magic_link_validation_post } from 'src/actions/loginAction';
import { workflow_get } from 'src/actions/workflowAction';
import { goToDestination } from './utils';

export default function MagicLink() {
  // Automatically sign in with token.

  const dispatch = useDispatch();
  const featureFlags = useSelector(state => state.featureFlag);
  const userAttribute = useSelector(state => state.userAttribute);
  const history = useHistory();

  const token = new URLSearchParams(window.location.search).get('token');

  useEffect(() => {
    if (token) {
      const submitMagicLinkToken = async token => {
        const resultPromise = dispatch(login_magic_link_validation_post(token));
        const goToApp = (user, flow) => {
          goToDestination(history, user, userAttribute, flow);
        };
        resultPromise
          .then(user => {
            if (user.token_type === TOKEN_TYPE.MFA_OTP) {
              // User has 2FA enabled
              history.push(PAGES.MFA_OTP);
              return;
            }
            const valid_login_session = [
              TOKEN_TYPE.SESSION,
              TOKEN_TYPE.REGISTRATION_SELF,
              TOKEN_TYPE.REGISTRATION_SUBACCOUNT,
              TOKEN_TYPE.REGISTRATION_ADMIN,
              TOKEN_TYPE.ENROLLMENT,
            ];
            if (!valid_login_session.includes(user.token_type)) {
              history.push(PAGES.MAGIC_LINK_ERROR, {
                error: LOGIN_ERROR_DISPLAY.SESSION_INVALID,
              });
              return;
            } else if (new Date(user.expiry) < new Date()) {
              history.push(PAGES.MAGIC_LINK_ERROR, {
                error: LOGIN_ERROR_DISPLAY.SESSION_EXPIRED,
              });
              return;
            } else if (user.status === 'pending') {
              // DONT GET THE WORKFLOW IF USER IS STILL IN REG MODE
              goToApp(user);
            } else {
              dispatch(workflow_get())
                .then(flow => goToApp(user, flow))
                .catch(() => goToApp(user));
            }
          })
          .catch(error => {
            const errorMessage = error?.response?.data?.msg;
            if (errorMessage) {
              history.push(PAGES.MAGIC_LINK_ERROR, { error: errorMessage });
            } else {
              history.push(PAGES.MAGIC_LINK_ERROR, { error: UNEXPECTED_ERROR });
            }
          });
      };
      // Wait for the app_config.js to be loaded, by checking the
      // featureFlags are present. Otherwise we get a race condition
      // when showing the spinner
      if (featureFlags) {
        submitMagicLinkToken(token);
      }
    }
  }, [dispatch, featureFlags, history, token, userAttribute]);

  if (!token) {
    window.location = PAGES.LOGIN;
  }

  return null;
}
