import React from 'react';
import { Button } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';
import { withTranslation } from 'react-i18next';

class ConsentSignature extends React.Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      showSig: null,
      sig: null,
    };
    this.clearSig = this.clearSig.bind(this);
    // this.onShowSig = this.onShowSig.bind(this);
  }

  clearSig() {
    this.sigPad.clear();
    this.setState({ sig: null });
    this.props.onSignatureDelete();
  }

  onSignEnd() {
    if (this.sigPad.isEmpty()) {
      // this may never be a case.. but in case we add erase or something
      this.setState({ sig: null });
    } else {
      const sig = this.sigPad.toDataURL('base64string');
      this.setState({ sig });
      this.props.onSignatureComplete(this.state.sig);
    }
  }

  render() {
    return (
      <div className="consent-sig-wrapper mt-2 mb-2 text-left">
        <p className="consent-sig-aknowledgement mb-2">{this.props.t('plsSignText')}</p>

        <small className="mb-3">{this.props.t('Enter signature below')}</small>

        <div className="text-center">
          <div className="sig-container">
            <SignatureCanvas
              maxWidth={1.5}
              penColor="black"
              ref={(ref) => { this.sigPad = ref; }}
              onEnd={() => this.onSignEnd()}
              canvasProps={{ width: 536, height: 100, className: 'sigCanvas' }}
            />
          </div>
          <div className="sig-redo text-center">
            <Button className="m-0 p-0" variant="link" onClick={() => this.clearSig()}>{this.props.t('Re-Do Signature')}</Button>
          </div>
        </div>

      </div>
    );
  }
}

export default withTranslation()(ConsentSignature);
